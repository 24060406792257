import * as React from 'react';

import { CaretDownIcon } from '~components/Icons/CaretDownIcon';
import { joinStrings } from '~services/utilities';

import './index.scss';

interface ICollapsePanelProps {
    title: string;
    className?: string;
}

const CollapsePanelComponent: React.FunctionComponent<ICollapsePanelProps> = (props) => {
    const { title, className } = props;
    const [isClosed, toggleIsClosed] = React.useState(false);

    const handleToggleIsOpen = () => {
        toggleIsClosed(!isClosed);
    };

    const classNames = joinStrings(['collapse-panel', isClosed && 'collapse-panel--hidden', className]);

    return (
        <div className={classNames}>
            <div className="collapse-panel__title" onClick={handleToggleIsOpen}>
                <CaretDownIcon />
                <h3>{title}</h3>
            </div>
            <div className="collapse-panel__body">{props.children}</div>
        </div>
    );
};

const CollapsePanel: React.NamedExoticComponent<React.PropsWithChildren<ICollapsePanelProps>> = React.memo(CollapsePanelComponent);

export { CollapsePanel };
