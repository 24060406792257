import { createContext } from 'react';

import placeholderImg from '~components/Editor/video-placeholder/video-placeholder.jpg';
import { initialPlayerState } from '~containers/EditorPageContainer/constants';

import { IVideoContext, PlayerKeys } from './types';

const DEFAULT_FUNCTION = () => null;
const INITIAL_STATE: IVideoContext = {
    getCurrentTime: () => 0,
    getCurrentEditorTime: () => 0,
    goLive: DEFAULT_FUNCTION,
    seek: DEFAULT_FUNCTION,
    seekToWallClock: DEFAULT_FUNCTION,
    loop: DEFAULT_FUNCTION,
    stopLoop: DEFAULT_FUNCTION,
    play: DEFAULT_FUNCTION,
    mounted: DEFAULT_FUNCTION,
    pause: DEFAULT_FUNCTION,
    toggleMute: DEFAULT_FUNCTION,
    nudge: DEFAULT_FUNCTION,
    nudgeVolume: DEFAULT_FUNCTION,
    togglePlay: DEFAULT_FUNCTION,
    destroy: DEFAULT_FUNCTION,
    [PlayerKeys.EDITOR]: initialPlayerState,
    [PlayerKeys.PREVIEW]: initialPlayerState,

    // todo: this looks like a duplication of IEditorStreamState
    hlsStreamUrl: '',
    title: '',
    thumbnail: placeholderImg,
    assetType: null,
    assetId: null,
    durationChangeDelta: 0,
};

export const VideoContext = createContext<IVideoContext>(INITIAL_STATE); // TODO Initial state
export const VideoProvider = VideoContext.Provider;
export const VideoConsumer = VideoContext.Consumer;
