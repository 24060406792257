import * as React from 'react';

import { CardGrid } from '~components/CardGrid';
import { RootContext } from '~components/Root/context';
import { TSearchTerm } from '~components/Root/types';
import { SearchPagination } from '~components/SearchPagination';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { PaginationContent } from '~pages/Browse/components/SearchSection/PaginationContent';
import { HITS_PER_PAGE, SEARCH_CLIP_PAGER_FEEDBACK, SEARCH_VOD_PAGER_FEEDBACK } from '~pages/Browse/components/constants';
import { IFeedback, OpenPreviewModal } from '~pages/Browse/components/types';
import { getClipsDveVideoIds } from '~services/clip';
import { AlgoliaClient } from '~services/search/algolia';
import { IAlgoliaSettings, TAlgoliaSettings } from '~services/settings';
import { getVodsByDveVideoIds } from '~services/vod';
import { IContentType, ISearchableContentType } from '~src/types';

interface IContainerProps {
    onOpenPreviewModal: OpenPreviewModal;
    searchTerm: TSearchTerm;
}

interface IProps extends IContainerProps {
    algoliaSettings: TAlgoliaSettings;
}

const canInitAlgolia = (algoliaSettings): algoliaSettings is IAlgoliaSettings => algoliaSettings.appName !== undefined;

const algoliaCallbackMiddleware = (callback) => async (algoliaResults: ISearchableContentType[]): Promise<ISearchableContentType[]> => {
    const videoIds = algoliaResults.map((result) => result.id);
    return videoIds.length ? await callback(videoIds) : [];
};

const useAlgoliaClient = (algoliaSettings: TAlgoliaSettings): { algoliaClient: AlgoliaClient } => {
    const algoliaClientRef = React.useRef<AlgoliaClient>(null);

    if (!algoliaClientRef.current && canInitAlgolia(algoliaSettings)) {
        const { appName: applicationId, apiKey: searchOnlyApiKey } = algoliaSettings;
        algoliaClientRef.current = new AlgoliaClient({ applicationId, searchOnlyApiKey });
    }

    return {
        algoliaClient: algoliaClientRef.current,
    };
};

const getPaginationContent = (
    pagerFeedback: IFeedback,
    onOpenPreviewModal: OpenPreviewModal,
    assetType: Exclude<EAssetType, EAssetType.ARCHIVE_VOD>,
    searchTerm: TSearchTerm,
    loadMoreDataTestId: string
) => (results: IContentType[], status: string, hasMoveResults: boolean, getMoreResults: VoidFunction) => (
    <PaginationContent
        results={results}
        status={status}
        hasMoveResults={hasMoveResults}
        getMoreResults={getMoreResults}
        searchTerm={searchTerm}
        onOpenPreviewModal={onOpenPreviewModal}
        pagerFeedback={pagerFeedback}
        assetType={assetType}
        loadMoreDataTestId={loadMoreDataTestId}
    />
);

const SearchSectionComponent: React.FunctionComponent<IProps> = ({ onOpenPreviewModal, algoliaSettings, searchTerm }) => {
    const { algoliaClient } = useAlgoliaClient(algoliaSettings);
    const clipsCallback = algoliaCallbackMiddleware(getClipsDveVideoIds);
    const vodsCallback = algoliaCallbackMiddleware(getVodsByDveVideoIds);

    return (
        <React.Fragment>
            <CardGrid.Grid dataTestId="cardGrid-recentlyCreated">
                <CardGrid.Title dataTestId="cardGrid-recentlyCreatedTitle">Recently Created</CardGrid.Title>
                <SearchPagination
                    stateKey="clip"
                    searchTerm={searchTerm}
                    pageService={algoliaClient}
                    facetFilters={['type:CLIP']}
                    hitsPerPage={HITS_PER_PAGE}
                    indexName={algoliaSettings.index}
                    callbackMiddleware={clipsCallback}
                    render={getPaginationContent(
                        SEARCH_CLIP_PAGER_FEEDBACK,
                        onOpenPreviewModal,
                        EAssetType.CLIP,
                        searchTerm,
                        'cardGrid-recentlyCreatedLoadMoreButton'
                    )}
                />
            </CardGrid.Grid>

            <CardGrid.Grid dataTestId="cardGrid-videos">
                <CardGrid.Title dataTestId="cardGrid-videosTitle">Videos</CardGrid.Title>
                <SearchPagination
                    stateKey="vod"
                    searchTerm={searchTerm}
                    pageService={algoliaClient}
                    facetFilters={['type:VOD']}
                    hitsPerPage={HITS_PER_PAGE}
                    indexName={algoliaSettings.index}
                    callbackMiddleware={vodsCallback}
                    render={getPaginationContent(
                        SEARCH_VOD_PAGER_FEEDBACK,
                        onOpenPreviewModal,
                        EAssetType.VOD,
                        searchTerm,
                        'cardGrid-videosLoadMoreButton'
                    )}
                />
            </CardGrid.Grid>
        </React.Fragment>
    );
};

const SearchSectionMemo: React.NamedExoticComponent<IProps> = React.memo(SearchSectionComponent);

const SearchSection: React.FunctionComponent<IContainerProps> = ({ searchTerm, onOpenPreviewModal }) => {
    const { algoliaSettings } = React.useContext(RootContext);
    return <SearchSectionMemo searchTerm={searchTerm} onOpenPreviewModal={onOpenPreviewModal} algoliaSettings={algoliaSettings} />;
};

export { SearchSection };
