import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IContentPodBase } from '~services/channels/types';
import { convertSecondsToMomentTimeFormat } from '~services/utilities';
import { podUpdated } from '~src/store/channelManager/channelManager.actions';
import { getPodManagementState } from '~src/store/channelManager/channelManager.selectors';
import { isCurrentStepPodSelector } from '~src/store/channelManager/channelManager.utilities';
import { PodSelectorView } from '~src/store/channelManager/constants';
import { IPodSelectorStep } from '~src/store/channelManager/types';

import { ContentCardPreview } from '../ContentCardPreview';
import { DefaultPodDetails, PodDetails } from '../PodDetails';
import './index.scss';

const enum AdMarkerInfo {
    HAS_MARKERS = 'This content contains ad markers and the ads will be inserted at:',
    NO_MARKERS = 'This content contains no ad markers - breaks will be inserted evenly',
}

export const PodSelector: React.FC = () => {
    const { currentStep } = useSelector(getPodManagementState);

    if (isCurrentStepPodSelector(currentStep)) {
        return <PodSelectorComponent {...currentStep} />;
    }

    return null;
};

const PodSelectorComponent: React.FC<IPodSelectorStep> = (props) => {
    const defaultPodRef = React.useRef<HTMLDivElement>(null);

    const {
        podData: { content, defaultPod, extendedPodOptions },
        selectedPod,
        kind,
    } = props;

    const dispatch = useDispatch();

    const handleOnSelect = (podSelected: IContentPodBase) => () => {
        dispatch(podUpdated({ pod: podSelected, content }));
    };

    const handleLoopFocus = () => {
        defaultPodRef.current?.focus();
    };

    const isSelectable = kind !== PodSelectorView.PREVIEW;

    const cuePointsString = content.adBreaks.map(({ cuePoint }) => convertSecondsToMomentTimeFormat(cuePoint)).join(', ');

    return (
        <div className="selected-content">
            <section className="selected-content__info-wrapper">
                <div className="selected-content__text section-header-text">Selected Content</div>
                <ContentCardPreview {...content} showTags={false} />
                <div className="selected-content__ads">
                    <span className="selected-content__ads-label">AD</span>
                    <span className="selected-content__ads-info">
                        {content.adBreaks.length ? AdMarkerInfo.HAS_MARKERS : AdMarkerInfo.NO_MARKERS}
                    </span>
                    {!!content.adBreaks.length && <div className="selected-content__ads-distribution">{cuePointsString}</div>}
                </div>
            </section>
            <section className="selected-content__pod-options-wrapper">
                <div className="section-header-text">Default pod</div>
                <DefaultPodDetails
                    isSelected={defaultPod.duration === selectedPod}
                    isSelectable={isSelectable}
                    contentDuration={content.duration}
                    podDuration={defaultPod.duration}
                    adBreaks={defaultPod.adBreaks}
                    onSelect={handleOnSelect(defaultPod)}
                    ref={defaultPodRef}
                />
                {!!extendedPodOptions.length && <div className="section-header-text">Extended Options</div>}
                {extendedPodOptions.map((option) => (
                    <PodDetails
                        key={option.duration}
                        isSelected={option.duration === selectedPod}
                        isSelectable={isSelectable}
                        contentDuration={content.duration}
                        podDuration={option.duration}
                        adBreaks={option.adBreaks}
                        onSelect={handleOnSelect(option)}
                    />
                ))}
                <div tabIndex={0} onFocus={handleLoopFocus} />
            </section>
        </div>
    );
};
