import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { ThumbnailMarker } from '~components/Editor/ThumbnailRail/ThumbnailMarker';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { joinStrings, valuesToPercentage } from '~services/utilities';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { IStudioState } from '~src/store/types';
import { Seconds } from '~src/types';
import { useShallowEqualSelector } from '~src/views/hooks';

import './index.scss';

interface IThumbnailRailContainerProps {
    duration: Seconds;
    timelineStartTime: Seconds;
    assetType: EAssetType;
    isLive: boolean;
    timelineWidth: number;
}

interface IThumbnailRailProps extends IThumbnailRailContainerProps {
    thumbnails: ITimelineThumbnail[];
    dispatch: Dispatch;
    hoverThumbnailId: ITimelineThumbnail['id'];
    displayThumbnails: boolean;
}

const getThumbnailRenderTime = (assetType: EAssetType, isLive: boolean, thumbnail: ITimelineThumbnail): Seconds => {
    const { renderThumbnailTime, exportThumbnailTime } = thumbnail;
    // event starts off as a live event, but transitions into vod mode
    if (assetType === EAssetType.LIVE && !isLive) {
        return exportThumbnailTime;
    }

    return renderThumbnailTime;
};

const ThumbnailRailComponent: React.FunctionComponent<IThumbnailRailProps> = (props) => {
    const { thumbnails, timelineStartTime, duration, hoverThumbnailId, displayThumbnails, assetType, isLive, timelineWidth } = props;

    const className = joinStrings(['thumbnails-rail', !displayThumbnails && 'thumbnails-rail--hidden']);

    return (
        <div className={className}>
            {thumbnails.map((thumbnail: ITimelineThumbnail) => {
                const { id, imageData } = thumbnail;
                const renderThumbnailTime: Seconds = getThumbnailRenderTime(assetType, isLive, thumbnail);
                const position = valuesToPercentage(renderThumbnailTime - timelineStartTime, duration);
                const isActive = hoverThumbnailId === id;
                return (
                    <ThumbnailMarker
                        key={id}
                        renderPosition={position}
                        imageData={imageData}
                        isActive={isActive}
                        timelineWidth={timelineWidth}
                    />
                );
            })}
        </div>
    );
};

const ThumbnailRailMemo: React.NamedExoticComponent<IThumbnailRailProps> = React.memo(ThumbnailRailComponent);

const getThumbnailState = (state: IStudioState) => {
    const { list: thumbnails, hoverThumbnailId, displayThumbnails } = state.timeline.thumbnails;
    return {
        thumbnails,
        hoverThumbnailId,
        displayThumbnails,
    };
};

const ThumbnailRail: React.FunctionComponent<IThumbnailRailContainerProps> = ({
    duration,
    timelineStartTime,
    assetType,
    isLive,
    timelineWidth,
}) => {
    const { thumbnails, hoverThumbnailId, displayThumbnails } = useShallowEqualSelector(getThumbnailState);
    const dispatch = useDispatch();

    return (
        <ThumbnailRailMemo
            thumbnails={thumbnails}
            hoverThumbnailId={hoverThumbnailId}
            duration={duration}
            timelineStartTime={timelineStartTime}
            displayThumbnails={displayThumbnails}
            assetType={assetType}
            isLive={isLive}
            dispatch={dispatch}
            timelineWidth={timelineWidth}
        />
    );
};

export { ThumbnailRail };
