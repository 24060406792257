import * as React from 'react';
import { useSelector } from 'react-redux';
import { SortableContainer, SortEnd } from 'react-sortable-hoc';

import { useControls } from '~containers/ChannelManagerContainer/ChannelManagerContext';
import { StorageKeys } from '~services/storage/constants';
import {
    getContentPodsState,
    getChannelDetailsState,
    getLastEditedState,
    getLastActionState,
    getPodManagementState,
    getBatchActionState,
} from '~src/store/channelManager/channelManager.selectors';
import { ChangeAction } from '~src/store/channelManager/types';
import { useLocalStorage } from '~src/views/hooks/useLocalStorage';
import useUpdateEffect from '~src/views/hooks/useUpdateEffect';

import { VirtualisedList } from './VirtualisedList';
import { END_LABEL } from './constants';
import './index.scss';
import { VirtualisedListItems } from './types';

const SortableList = SortableContainer(VirtualisedList);

export const PodScroller: React.FC = () => {
    const { mode } = useSelector(getBatchActionState);
    const contentPods = useSelector(getContentPodsState);
    const { editableRange, scrollableDateTimeRange, isLive } = useSelector(getChannelDetailsState);
    const lastEditedUuid = useSelector(getLastEditedState);
    const lastAction = useSelector(getLastActionState);
    const { isActive: isAddModalActive } = useSelector(getPodManagementState);
    const [showUSDateTimeFormat] = useLocalStorage(StorageKeys.SHOW_US_DATE_TIME_FORMAT, false);

    const { onPodMove } = useControls();

    // Get Virtualised list items with `End of scheduling` label at the end
    // Label needs to be part of the Virtualised list for the scroller to work properly
    const getVirtualisedListItems = (): VirtualisedListItems => [...contentPods, END_LABEL];

    const [isSorting, setIsSorting] = React.useState<boolean>(false);
    const [listItems, setListItems] = React.useState<VirtualisedListItems>(getVirtualisedListItems);

    useUpdateEffect(() => {
        setListItems(getVirtualisedListItems());
    }, [contentPods, setListItems]);

    const onSortStart = React.useCallback(() => {
        setIsSorting(true);
    }, [setIsSorting]);

    const onSortEnd = React.useCallback(
        ({ oldIndex, newIndex }: SortEnd) => {
            if (newIndex + 1 >= editableRange.first && oldIndex !== newIndex) {
                onPodMove(contentPods[oldIndex].podNumber, contentPods[newIndex].podNumber, ChangeAction.MOVE_BY_DRAG);
            }
            setIsSorting(false);
        },
        [setIsSorting, onPodMove, editableRange.first, contentPods]
    );

    return (
        <section className="channel-manager__scroller-container">
            {contentPods.length ? (
                <SortableList
                    axis="x"
                    lockAxis="x"
                    useDragHandle={true}
                    helperClass="sortable-item--is-active" // class added to the item which is currently dragged
                    onSortEnd={onSortEnd}
                    onSortStart={onSortStart}
                    items={listItems}
                    isInBatchActionMode={!!mode}
                    lastEditedUuid={lastEditedUuid}
                    lastAction={lastAction}
                    scrollableDateTimeRangeStart={scrollableDateTimeRange.start}
                    scrollableDateTimeRangeEnd={scrollableDateTimeRange.end}
                    isSorting={isSorting}
                    isChannelLive={isLive}
                    isAddModalActive={isAddModalActive}
                    showUSDateTimeFormat={showUSDateTimeFormat}
                />
            ) : (
                <div className="content-page__no-content text-center">No content found</div>
            )}
        </section>
    );
};
