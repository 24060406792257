enum FeedbackActions {
    ADD_SAGA = 'feedback/ADD_SAGA',

    ADD = 'feedback/ADD',
    ADD_IGNORED = 'feedback/ADD_IGNORED',

    DISMISS = 'feedback/DISMISS',
}

export { FeedbackActions };
