import { put, takeEvery, select } from 'redux-saga/effects';

import { ClipActions } from '~src/store/timeline/clip/constants';
import { ThumbnailActions } from '~src/store/timeline/thumbnail/constants';
import { removeThumbnail } from '~src/store/timeline/thumbnail/thumbnail.actions';
import { getThumbnailState } from '~src/store/timeline/thumbnail/thumbnail.selectors';
import { ITimelineThumbnailState } from '~src/store/timeline/thumbnail/types';
import { ToastAction } from '~src/store/toast/constants';
import { Toaster } from '~src/store/toast/toast.actions';

function* handleRemoveThumbnail(action) {
    const { id } = action.payload;

    yield put({ type: ThumbnailActions.REMOVE, payload: { id } });
    yield put({ type: ClipActions.REMOVE_THUMBNAIL_BY_THUMBNAIL_ID, payload: { thumbnailId: id } });
}

function* handleCancelThumbnail() {
    const { pendingThumbnailId }: ITimelineThumbnailState = yield select(getThumbnailState);

    if (pendingThumbnailId) {
        // remove pending thumbnail and associated clip references
        yield put(removeThumbnail(pendingThumbnailId));

        // reset pending states
        yield put({ type: ThumbnailActions.CANCEL });

        const toastSlice = Toaster('Thumbnail Capture', 'Thumbnail capture cancelled');
        yield put({ type: ToastAction.ADD, payload: toastSlice });
    }
}

export const thumbnailSagas = [
    takeEvery(ThumbnailActions.REMOVE_SAGA, handleRemoveThumbnail),
    takeEvery(ThumbnailActions.CANCEL_SAGA, handleCancelThumbnail),
];
