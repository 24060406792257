import throttle from 'lodash.throttle';
import * as moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VariableSizeList as List, ListOnItemsRenderedProps } from 'react-window';

import { IContentPod } from '~services/channels/types';
import { joinStrings } from '~services/utilities';
import { resetLastEditedChanges } from '~src/store/channelManager/channelManager.actions';
import { getContentPodScrollState } from '~src/store/channelManager/channelManager.selectors';
import { ChangeAction, UUID, IScrollableDateTimeRange } from '~src/store/channelManager/types';
import useDebounce from '~src/views/hooks/useDebounce';
import useWindowSize from '~src/views/hooks/useWindowSize';
import { KeyCode } from '~src/views/types';

import { PodDayIdentifier } from '../PodCard/SortableWrapper';
import { DateStrip } from './DateStrip';
import { ScrollButtons } from './ScrollButtons';
import { VirtualisedListItem } from './VirtualisedListItem';
import {
    ListItemWidth,
    WINDOW_RESIZE_DEBOUNCE,
    RESET_LAST_EDITED_TIMEOUT,
    STICKY_DATE_OFFSET,
    FIRST_POD_ITEM_EXTRA_WIDTH,
    LIST_ITEM_HEIGHT,
    ListItems,
} from './constants';
import { IEndLabel, IScrollPositionState, VirtualisedListItems } from './types';

interface IVLListProps {
    lastEditedUuid: UUID;
    lastAction: ChangeAction;
    items: VirtualisedListItems;
    isSorting: boolean;
    isChannelLive: boolean;
    isAddModalActive: boolean;
    isInBatchActionMode: boolean;
    scrollableDateTimeRangeStart: IScrollableDateTimeRange['start'];
    scrollableDateTimeRangeEnd: IScrollableDateTimeRange['end'];
    showUSDateTimeFormat: boolean;
}

export const itemIsContentPod = (item: IContentPod | IEndLabel): item is IContentPod => {
    return item.kind === ListItems.CONTENT_POD;
};

const getScrollByOptions = (left: number): ScrollToOptions => {
    return { left, top: 0, behavior: 'smooth' };
};

const isInViewport = (windowWidth: number, { left, right }: DOMRect): boolean => {
    const isLeftInView = left >= 0;
    const isRightInView = right <= windowWidth;
    return isLeftInView && isRightInView;
};

export const VirtualisedList: React.FC<IVLListProps> = React.memo((props) => {
    const {
        items,
        isSorting,
        lastEditedUuid,
        lastAction,
        isChannelLive,
        isAddModalActive,
        isInBatchActionMode,
        scrollableDateTimeRangeStart,
        scrollableDateTimeRangeEnd,
        showUSDateTimeFormat,
    } = props;
    const listRef = React.useRef<List>(null);
    const outerListRef = React.useRef<HTMLDivElement>(null);
    const innerListRef = React.useRef<HTMLDivElement>(null);
    const renderedItemsRange = React.useRef<{ start: number; stop: number }>({ start: 0, stop: 0 });
    // works along with the timer to hide date labels while UI updates are happening
    const isUpdating = React.useRef<boolean>(false);
    const resetTimer = React.useRef<number>(null);
    const stickyDateDisplayRef = React.useRef<HTMLDivElement>(null);
    const [scrollPositions, setScrollPositions] = React.useState<IScrollPositionState>(getScrollButtonVisibility());

    const dispatch = useDispatch();
    const contentPodScrollState = useSelector(getContentPodScrollState);

    React.useEffect(() => {
        if (!contentPodScrollState) {
            return;
        }
        const contentPodIndex = items.findIndex((item) => itemIsContentPod(item) && item.uuid === contentPodScrollState.uuid);
        if (contentPodIndex === -1) {
            return;
        }
        listRef.current.scrollToItem(contentPodIndex);
    }, [contentPodScrollState]);

    const windowSize = useWindowSize();
    const windowCenterLine = windowSize.width / 2;
    const minimumPodsVisible = Math.floor(windowSize.width / ListItemWidth.CONTENT_POD);
    const debouncedWindowSize = useDebounce(windowSize, WINDOW_RESIZE_DEBOUNCE);

    const updateDateLabels = React.useCallback(() => {
        const stickyDateDisplayCoords = stickyDateDisplayRef.current?.getBoundingClientRect();

        if (stickyDateDisplayCoords) {
            for (let i = renderedItemsRange.current.start; i <= renderedItemsRange.current.stop; i++) {
                const podEl = getPodElementByIndex(i);

                if (podEl) {
                    const podCoords = podEl.getBoundingClientRect();
                    const podDateLabel = podEl.getAttribute('data-pod-item-date');
                    const podDayIdentifier = podEl.getAttribute('data-day');

                    if (podDayIdentifier) {
                        const podDateDisplayEl = podEl.querySelector<HTMLDivElement>('.sortable-item__date-display');
                        const podDateDisplayCoords = podDateDisplayEl.getBoundingClientRect();
                        const isStartDayPod = podDayIdentifier === PodDayIdentifier.START;
                        const shouldShowDate = isStartDayPod
                            ? podDateDisplayCoords.left > stickyDateDisplayCoords.right + STICKY_DATE_OFFSET
                            : podDateDisplayCoords.right < stickyDateDisplayCoords.left - STICKY_DATE_OFFSET;

                        toggleDateDisplay(podDateDisplayEl, podDateLabel, shouldShowDate);
                    }

                    const podElStart = podCoords.left;
                    const podElEnd = podElStart + podCoords.width;

                    if (podElStart < windowCenterLine && podElEnd > windowCenterLine) {
                        stickyDateDisplayRef.current.textContent = podDateLabel;
                    }
                }
            }
        }
    }, [windowCenterLine]);

    const scrollNext = React.useCallback(() => {
        for (let i = renderedItemsRange.current.stop; i >= renderedItemsRange.current.start; i--) {
            const currentEl = getPodElementByIndex(i);
            const currentElCoords = currentEl?.getBoundingClientRect();

            if (currentEl && isInViewport(windowSize.width, currentElCoords)) {
                outerListRef.current.scrollBy(getScrollByOptions(currentElCoords.right));
                break;
            }
        }
    }, [windowSize.width]);

    const scrollPrev = React.useCallback(() => {
        for (let i = renderedItemsRange.current.start; i <= renderedItemsRange.current.stop; i++) {
            const currentEl = getPodElementByIndex(i);
            const currentElCoords = currentEl?.getBoundingClientRect();

            if (currentEl && isInViewport(windowSize.width, currentElCoords)) {
                const scrollBy = minimumPodsVisible * ListItemWidth.CONTENT_POD - currentElCoords.left;
                outerListRef.current.scrollBy(getScrollByOptions(-scrollBy));
                break;
            }
        }
    }, [minimumPodsVisible, windowSize.width]);

    const scrollFirst = React.useCallback(() => {
        listRef.current.scrollToItem(0, 'start');
    }, []);

    const scrollLast = React.useCallback(() => {
        listRef.current.scrollToItem(items.length - 1, 'end');
    }, [items]);

    React.useEffect((): void | VoidFunction => {
        if (!isAddModalActive) {
            const handleKeyDown = ({ keyCode }) => {
                switch (keyCode) {
                    case KeyCode.ARROW_LEFT:
                        scrollPrev();
                        break;
                    case KeyCode.ARROW_RIGHT:
                        scrollNext();
                        break;
                    case KeyCode.HOME:
                        scrollFirst();
                        break;
                    case KeyCode.END:
                        scrollLast();
                        break;
                }
            };

            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isAddModalActive, scrollFirst, scrollLast, scrollNext, scrollPrev]);

    React.useEffect(() => {
        if (lastAction) {
            // Delay rendering of date labels until lastEdited is reset
            // Fixes issue with date labels being misplaced while UI updates is happening
            isUpdating.current = true;
            listRef.current.resetAfterIndex(0, true);

            // Only need to scroll to item for certain last action
            const shouldScrollToItem = lastAction === ChangeAction.MOVE_BY_POSITION || lastAction === ChangeAction.ADD_TO_POSITION;

            if (shouldScrollToItem && lastEditedUuid) {
                const lastEditedPodIndex = items.findIndex((item) => itemIsContentPod(item) && item.uuid === lastEditedUuid);
                // scroll to the very end if last action is ADD
                listRef.current.scrollToItem(lastEditedPodIndex);
            }

            resetTimer.current = window.setTimeout(() => {
                isUpdating.current = false;
                updateDateLabels();
                dispatch(resetLastEditedChanges());
            }, RESET_LAST_EDITED_TIMEOUT);
        }

        return () => {
            clearTimeout(resetTimer.current);
            resetTimer.current = null;
        };
    }, [lastEditedUuid, lastAction, items, updateDateLabels, dispatch]);

    function getScrollButtonVisibility(): IScrollPositionState {
        let atRightEnd = false;
        let atLeftEnd = false;

        if (outerListRef.current) {
            const { scrollWidth, scrollLeft, clientWidth } = outerListRef.current;
            atRightEnd = scrollWidth - scrollLeft === clientWidth;
            atLeftEnd = scrollLeft === 0;
        }
        return {
            atRightEnd,
            atLeftEnd,
        };
    }

    const toggleDateDisplay = (el: HTMLDivElement, value: string, shouldShow: boolean) => {
        el.classList.add('sortable-item__date-display--hide');

        if (shouldShow) {
            el.textContent = value;
            el.classList.toggle('sortable-item__date-display--hide');
        }
    };

    const updateScrollButtonVisibility = () => {
        if (!isSorting) {
            setScrollPositions((prevPositions) => {
                const newScrollPositions = getScrollButtonVisibility();

                if (
                    newScrollPositions.atLeftEnd !== prevPositions.atLeftEnd ||
                    newScrollPositions.atRightEnd !== prevPositions.atRightEnd
                ) {
                    return newScrollPositions;
                } else {
                    // avoid unnecessary rerenders
                    return prevPositions;
                }
            });
        }
    };

    const throttledUpdateScrollButtonVisibility = React.useCallback(throttle(updateScrollButtonVisibility, 500), [
        isSorting,
        setScrollPositions,
    ]);

    const handleOnScroll = React.useCallback(() => {
        updateDateLabels();
        throttledUpdateScrollButtonVisibility();
    }, [updateDateLabels, throttledUpdateScrollButtonVisibility]);

    const handleItemsRendered = React.useCallback(({ overscanStartIndex, overscanStopIndex }: ListOnItemsRenderedProps) => {
        renderedItemsRange.current.start = overscanStartIndex;
        renderedItemsRange.current.stop = overscanStopIndex;
    }, []);

    const getPodElementByIndex = (index: number): HTMLDivElement =>
        outerListRef.current.querySelector<HTMLDivElement>(`[data-pod-item-index='${index}']`);

    const getItemSize = React.useCallback(
        (index: number): number => {
            // useCallback here makes sure that the List component doesn't unmount children unnecessarily
            const isFirst = index === 0;
            const isLast = index + 1 === items.length;

            if (isLast) {
                return ListItemWidth.END_OF_SCHEDULE_LABEL;
            }

            return isFirst ? ListItemWidth.CONTENT_POD + FIRST_POD_ITEM_EXTRA_WIDTH : ListItemWidth.CONTENT_POD;
        },
        [items]
    );

    const onDateSelected = React.useCallback(
        (selectedDateTime: moment.Moment) => {
            const selectedDateTimeValueOf = selectedDateTime.valueOf();
            const podNumberToScrollTo = items.findIndex((item) => {
                if (itemIsContentPod(item)) {
                    const podStartTimeValueOf = moment(item.startTime).valueOf();
                    return selectedDateTimeValueOf < podStartTimeValueOf;
                }
                return false;
            });

            if (podNumberToScrollTo) {
                listRef.current.scrollToItem(podNumberToScrollTo, 'center');
            }
        },
        [items]
    );

    const className = joinStrings([
        'channel-manager__pods-scroller',
        isSorting && 'channel-manager__pods-scroller--is-sorting',
        isUpdating.current && 'channel-manager__pods-scroller--is-updating',
    ]);

    const memoisedListItemData = React.useMemo(
        () => ({
            isInBatchActionMode,
            items,
            showUSDateTimeFormat,
        }),
        [isInBatchActionMode, items, showUSDateTimeFormat]
    );

    return (
        <div>
            <DateStrip
                ref={stickyDateDisplayRef}
                onDateSelected={onDateSelected}
                minDate={scrollableDateTimeRangeStart}
                maxDate={scrollableDateTimeRangeEnd}
                showUSDateTimeFormat={showUSDateTimeFormat}
            />
            <List
                className={className}
                ref={listRef}
                outerRef={outerListRef}
                innerRef={innerListRef}
                height={LIST_ITEM_HEIGHT}
                itemCount={items.length}
                itemSize={getItemSize}
                itemData={memoisedListItemData}
                layout="horizontal"
                width={debouncedWindowSize.width}
                onScroll={handleOnScroll}
                onItemsRendered={handleItemsRendered}
                overscanCount={minimumPodsVisible} // over render extra pods based on screen size
            >
                {VirtualisedListItem}
            </List>
            <ScrollButtons
                scrollFirst={scrollFirst}
                scrollLast={scrollLast}
                scrollNext={scrollNext}
                scrollPrev={scrollPrev}
                isChannelLive={isChannelLive}
                scrollPositions={scrollPositions}
            />
        </div>
    );
});

VirtualisedList.displayName = 'VirtualisedList';
