import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { AlgoliaClient } from '~services/search/algolia';
import { searchAddBucket, searchRemoveBucket, searchProcessSearch, searchGetNextPage } from '~src/store/search/search.actions';
import { getSearchBucketState } from '~src/store/search/search.selectors';
import { SearchTerm } from '~src/store/search/types';
import { ISearchableContentType, StateKey } from '~src/types';
import { useShallowEqualSelector } from '~src/views/hooks';

interface IContainerProps {
    stateKey: StateKey;
    pageService: AlgoliaClient;
    render: (results: ISearchableContentType[], status: string, hasMoreResults: boolean, getNextPage: VoidFunction) => JSX.Element;
    searchTerm: SearchTerm;
    facetFilters: string[];
    indexName: string;
    hitsPerPage: number;
    callbackMiddleware: (algoliaResults: ISearchableContentType[]) => Promise<ISearchableContentType[]>;
}

interface IProps extends IContainerProps {
    results: ISearchableContentType[];
    hasMoreResults: boolean;
    status: string;
    dispatch: Dispatch;
}

const SearchPaginationComponent: React.FunctionComponent<IProps> = ({
    dispatch,
    render,
    stateKey,
    results,
    hasMoreResults,
    status,
    pageService,
    searchTerm,
    facetFilters,
    indexName,
    hitsPerPage,
    callbackMiddleware,
}) => {
    React.useEffect(() => {
        dispatch(searchAddBucket(stateKey));
        return () => {
            dispatch(searchRemoveBucket(stateKey));
        };
    }, [pageService]);

    React.useEffect(() => {
        dispatch(searchProcessSearch(stateKey, pageService, searchTerm, facetFilters, indexName, hitsPerPage, callbackMiddleware));
    }, [searchTerm]);

    const getNextPage = () => {
        dispatch(searchGetNextPage(stateKey, pageService, searchTerm, facetFilters, indexName, hitsPerPage, callbackMiddleware));
    };

    return render(results, status, hasMoreResults, getNextPage);
};

const SearchPagination: React.FunctionComponent<IContainerProps> = ({
    stateKey,
    pageService,
    render,
    searchTerm,
    facetFilters,
    indexName,
    hitsPerPage,
    callbackMiddleware,
}) => {
    const dispatch = useDispatch();
    const { results = [], hasMoreResults = false, status = null } = useShallowEqualSelector(getSearchBucketState(stateKey));
    return (
        <SearchPaginationComponent
            pageService={pageService}
            searchTerm={searchTerm}
            stateKey={stateKey}
            render={render}
            dispatch={dispatch}
            results={results}
            hasMoreResults={hasMoreResults}
            status={status}
            facetFilters={facetFilters}
            indexName={indexName}
            hitsPerPage={hitsPerPage}
            callbackMiddleware={callbackMiddleware}
        />
    );
};

export { SearchPagination };
