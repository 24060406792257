enum ExportActions {
    SET_STEP = 'editor/export/SET_STEP',
    RESET_STEP = 'editor/export/RESET_STEP',
    RESET_SAGA = 'editor/export/RESET_SAGA',
    RESET = 'editor/export/RESET',
    EXPORT_SINGLE_CLIP_SAGA = 'editor/export/EXPORT_SINGLE_CLIP_SAGA',
    SET_ERROR_COUNT = 'editor/export/SET_ERROR_COUNT',
    SET_SAVE_ACTION = 'editor/export/SET_SAVE_ACTION',
    INIT_EXPORT_FROM_LIVE = 'editor/export/INIT_EXPORT_FROM_LIVE',
}

export { ExportActions };
