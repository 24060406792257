import * as React from 'react';

const MultipleMoveFilledIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width={26} height={21} viewBox="0 0 26 21" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.119 14.747a1.7 1.7 0 01-1.198-.472 1.502 1.502 0 01-.5-.976l-.007-.164V1.652c0-.446.169-.833.507-1.16A1.663 1.663 0 017.95.008L8.119 0h9.275c.46 0 .86.164 1.198.492.296.286.462.618.5.995l.007.165v6.24l-1.705-.737V1.652H8.12v11.483h6.665l.655 1.613H8.12zm-3.41 3.127c-.46 0-.853-.164-1.177-.492a1.596 1.596 0 01-.48-.996l-.008-.164V4.739H4.71v11.483h10.148v1.652H4.71zm14.212 1.504L13.822 7.58 26 12.52l-5.602 1.43-1.477 5.428zM1.664 21c-.46 0-.852-.164-1.177-.492a1.596 1.596 0 01-.48-.995L0 19.348V7.865h1.664v11.483h10.149V21H1.664z"
                fill="#FFF"
                fillRule="nonzero"
            />
        </svg>
    );
};

export { MultipleMoveFilledIcon };
