import * as React from 'react';

import { ChevronLeft } from '~components/Icons/ChevronLeft';
import { ChevronRight } from '~components/Icons/ChevronRight';

interface IArrowProps {
    clickHandler: (e: React.MouseEvent) => void;
}

export const LeftArrow: React.FC<IArrowProps> = (props: IArrowProps) => {
    return (
        <button className="scroll-arrow scroll-arrow--left" onClick={props.clickHandler}>
            <ChevronLeft />
        </button>
    );
};

export const RightArrow: React.FC<IArrowProps> = (props: IArrowProps) => {
    return (
        <button className="scroll-arrow scroll-arrow--right" onClick={props.clickHandler}>
            <ChevronRight />
        </button>
    );
};
