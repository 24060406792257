import * as React from 'react';

import { ZOOM_SETTING_INTERVAL, ZoomSettingBoundary } from '~components/ChannelManager/PreviewSchedule/constants';
import { ResetIcon } from '~components/Icons/ResetIcon';
import { joinStrings } from '~services/utilities';
import { SECONDS_IN_MINUTE } from '~services/utilities/time/constants';
import { Seconds } from '~src/types';

import './index.scss';

interface IProps<T extends string> {
    zoomSetting: Seconds;
    className?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onMouseUp: (e: React.SyntheticEvent<HTMLInputElement>) => void;
    onReset: VoidFunction;
}

const getZoomLabels = (zoomSetting: Seconds): JSX.Element[] => {
    const zoomLabels = [];

    for (let seconds = ZoomSettingBoundary.MIN; seconds <= ZoomSettingBoundary.MAX; seconds += ZOOM_SETTING_INTERVAL) {
        const label = seconds / SECONDS_IN_MINUTE;

        zoomLabels.push(
            <div
                className={joinStrings(['zoom__container__label', seconds === zoomSetting && 'zoom__container__label--selected'])}
                key={seconds}
            >
                {label}
            </div>
        );
    }

    return zoomLabels;
};

export const ZoomFilter = <T extends string>(props: IProps<T>) => {
    const { zoomSetting, onChange, onMouseUp, className, onReset } = props;

    const zoomLabels = getZoomLabels(zoomSetting);

    return (
        <div className={joinStrings(['zoom', className])} title="Zoom Level">
            <div className="zoom__container">
                <input
                    type="range"
                    min={ZoomSettingBoundary.MIN}
                    max={ZoomSettingBoundary.MAX}
                    step={ZOOM_SETTING_INTERVAL}
                    value={zoomSetting}
                    onChange={onChange}
                    onMouseUp={onMouseUp}
                />
                <div className="zoom__container__labels">{zoomLabels}</div>
            </div>
            <div className="zoom__icon" onClick={onReset} title="Reset to 15 Minutes">
                <ResetIcon height={24} width={30} />
            </div>
        </div>
    );
};
