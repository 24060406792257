import * as moment from 'moment';
import * as React from 'react';

import placeholder from '~assets/images/no-thumbnail.jpg';
import { Badge } from '~components/Badge';
import { CardDescription } from '~components/Card/CardDescription';
import { CardSubTitle } from '~components/Card/CardSubTitle';
import { CardThumbnail } from '~components/Card/CardThumbnail';
import { CardTitle } from '~components/Card/CardTitle';
import { FileTimesLightIcon } from '~components/Icons/FileTimesLightIcon';
import { Tooltip, TooltipPositions } from '~components/Tooltip';

interface IComponentProps {
    title: string;
    duration: React.ReactText;
    thumbnail: string;
    createdAt?: string;
    badgeText?: string;
    remove?: () => void;
}

const ExportClipPreview: React.FunctionComponent<IComponentProps> = (props) => {
    const { title, duration, thumbnail, createdAt, badgeText, remove } = props;

    return (
        <div className="card card--vertical" data-test-id="clipPreviewCard">
            <div className="card-content">
                <CardThumbnail thumbnailSrc={thumbnail || placeholder} dataTestId="clipCard-thumbnail">
                    <div className="card__tag-wrapper">
                        <div className="card__status-badge card__status-badge--justify-right">
                            {badgeText && <Badge text={badgeText} />}
                        </div>
                        <div className="card__tag card__tag--time">{duration}</div>
                    </div>
                </CardThumbnail>

                <CardDescription>
                    <CardTitle dataTestId="clipCard-title">{title}</CardTitle>
                    {createdAt && <CardSubTitle>Created {moment(createdAt).fromNow()}</CardSubTitle>}
                </CardDescription>
            </div>
            {remove && (
                <ul className="inline-list clip-item__actions">
                    <li className="list-item">
                        <button type="button" onClick={remove}>
                            <Tooltip message="Remove combined clip" position={TooltipPositions.LEFT}>
                                <FileTimesLightIcon />
                            </Tooltip>
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};

export { ExportClipPreview };
