import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { Card } from '~components/Card';
import { CardGrid } from '~components/CardGrid';
import { LoadMoreButton } from '~components/LoadMoreButton';
import { Pagination } from '~components/Pagination';
import { PlayerModal } from '~components/PlayerModal';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { IClip } from '~services/clip/types';
import { EServiceStatus } from '~services/http/enums';
import { getRecentlyCreatedService } from '~services/recentlyCreated';
import { getStreamState } from '~src/store/editor/stream/stream.selectors';
import { useShallowEqualSelector } from '~src/views/hooks';

interface IComponentProps {
    assetType: EAssetType;
    assetId: number;
    dispatch: Dispatch;
}

interface IState {
    assetId: number | null;
    assetType: EAssetType;
    isModal: boolean;
    canCreateClip: boolean;
    externalUrl: string;
}

const INITIAL_STATE: IState = {
    assetId: null,
    assetType: null,
    isModal: false,
    canCreateClip: false,
    externalUrl: null,
};

const CARD_SIZE_SETTINGS = {
    [EAssetType.CLIP]: {
        height: 90,
        width: 140,
        layout: 'horizontal' as 'horizontal',
    },
};

const PAGER_KEY = 'recent-clip';
const RECENTLY_CREATED_PAGE_SIZE = 6;

const PAGER_FEEDBACK = {
    noResults: {
        title: 'No recently created clips exported from this event',
    },
    serverError: {
        title: 'Unable to view videos',
        message: 'Due to access permissions you are unable to view this content, please contact your administrator to gain access',
    },
};

class RecentlyCreatedPanelComponent extends React.PureComponent<IComponentProps, IState> {
    public readonly state = INITIAL_STATE;
    public clipsService = null;

    constructor(props) {
        super(props);
        const { assetType, assetId } = props;
        this.clipsService = getRecentlyCreatedService(assetType, RECENTLY_CREATED_PAGE_SIZE, assetId);
    }

    public render() {
        const { assetId, assetType, isModal, canCreateClip, externalUrl } = this.state;
        return (
            <React.Fragment>
                <CardGrid.Grid>
                    <Pagination
                        stateKey={PAGER_KEY}
                        pageService={this.clipsService}
                        // todo: leaving this in for now, before breaking out the sections into separate containers
                        // tslint:disable-next-line jsx-no-lambda
                        render={(results: IClip[], status, hasMoveResults, getMoreResults) => {
                            return (
                                <React.Fragment>
                                    {!!results.length && (
                                        <CardGrid.Results type={EAssetType.CLIP}>
                                            {results.map((item) => (
                                                <Card
                                                    key={item.id}
                                                    assetType={EAssetType.CLIP}
                                                    card={item}
                                                    settings={CARD_SIZE_SETTINGS[EAssetType.CLIP]}
                                                    onPreviewClick={this.onOpenPreviewModal}
                                                    className="card--full-width"
                                                />
                                            ))}
                                            {hasMoveResults && <LoadMoreButton onClick={getMoreResults}>Load more</LoadMoreButton>}
                                        </CardGrid.Results>
                                    )}
                                    {status === EServiceStatus.LOADING && <CardGrid.Loading />}
                                    {status === EServiceStatus.LOADED && !results.length && (
                                        <CardGrid.Message message={PAGER_FEEDBACK.noResults.title} />
                                    )}
                                    {status === EServiceStatus.ERROR && (
                                        <CardGrid.Message
                                            title={PAGER_FEEDBACK.serverError.title}
                                            message={PAGER_FEEDBACK.serverError.message}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }}
                    />
                </CardGrid.Grid>
                {isModal && (
                    <PlayerModal
                        assetId={assetId}
                        assetType={assetType}
                        canCreateClip={canCreateClip}
                        externalUrl={externalUrl}
                        close={this.onCloseModal}
                    />
                )}
            </React.Fragment>
        );
    }

    private onOpenPreviewModal = (assetId: number, assetType: EAssetType, canCreateClip: boolean, externalUrl: string) => {
        this.setState({ isModal: true, assetId, assetType, canCreateClip, externalUrl });
    };

    private onCloseModal = (): void => {
        this.setState(INITIAL_STATE);
    };
}

const RecentlyCreatedPanelMemo: React.NamedExoticComponent<IComponentProps> = React.memo(RecentlyCreatedPanelComponent);

const RecentlyCreatedPanel: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { assetType, assetId } = useShallowEqualSelector(getStreamState);

    return <RecentlyCreatedPanelMemo assetType={assetType} assetId={assetId} dispatch={dispatch} />;
};

export { RecentlyCreatedPanel };
