export enum LoginPageToastTitle {
    SUCCESS = 'Success',
    ERROR = 'Error',
}

export enum LoginPageToastMessage {
    REQUEST_NEW_PIN_SUCCESS = 'Successfully requested a new verification PIN',
    REQUEST_NEW_PIN_FAILED = 'Unable to request a new verification PIN',
    LOGIN_FAILED_USERNAME_PASSWORD = 'Incorrect login credentials',
}

export enum AuthenticationMode {
    BASIC = 'BASIC',
    MFA = 'MFA',
    NEW_PIN = 'NEW_PIN',
}

export enum AutenticationLinkLabel {
    REQUEST_NEW_PIN = 'Request new pin',
    BACK_TO_LOGIN = 'Back to login',
}
