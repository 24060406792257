import * as React from 'react';

import { PageTheme } from '~components/Root/types';
import { joinStrings } from '~services/utilities';

import './index.scss';

export enum NotificationType {
    DEFAULT = '',
    ERROR = 'ERROR',
}

interface IComponentProps extends React.HTMLAttributes<HTMLDivElement> {
    notificationTitle?: string;
    message?: string;
    theme?: PageTheme;
    notificationType?: NotificationType;
}

const InlineNotification: React.FunctionComponent<IComponentProps> = (props) => {
    const { notificationTitle, message, theme = PageTheme.DARK, className, notificationType = NotificationType.DEFAULT } = props;

    const classNames = joinStrings([
        'inline-notification',
        `inline-notification--${theme.toLowerCase()}`,
        `inline-notification--${notificationType.toLowerCase()}`,
        className,
    ]);
    return (
        <div className={classNames}>
            {notificationTitle && <h4 className="inline-notification__title">{notificationTitle}</h4>}
            {message && <p className="inline-notification__message">{message}</p>}
        </div>
    );
};

export { InlineNotification };
