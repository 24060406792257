import * as React from 'react';

import { ThumbnailItem } from '~components/Editor/ThumbnailList/ThumbnailItem';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';

import './index.scss';

interface IProps {
    thumbnails: ITimelineThumbnail[];
    selectThumbnail: (thumbnailId: ITimelineThumbnail['id'], imageData: ITimelineThumbnail['imageData']) => void;
}

const ThumbnailList: React.FunctionComponent<IProps> = ({ thumbnails, selectThumbnail }) => {
    const onClickHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        selectThumbnail(null, null);
    };

    return (
        <div className="thumbnail-list">
            <div className="thumbnail-item" onClick={onClickHandler}>
                <div className="thumbnail-item__empty">No thumbnail</div>
            </div>
            {thumbnails.map(({ id, imageData }) => (
                <ThumbnailItem key={id} id={id} imageData={imageData} selectThumbnail={selectThumbnail} />
            ))}
        </div>
    );
};

export { ThumbnailList };
