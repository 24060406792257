import * as moment from 'moment-timezone';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useClickOff } from '@dicetechnology/dice-backoffice-ui-components';

import { CloseLightIcon } from '~components/Icons/CloseLightIcon';
import { IContentPod } from '~services/channels/types';
import { getTimezoneConformedMoment } from '~services/utilities/time';
import { closePreviewSchedule, scrollToContentPod } from '~src/store/channelManager/channelManager.actions';

import './index.scss';

interface ICalendarCardPreviewProps {
    contentPod: IContentPod;
    timezone?: string;
    closePreview: VoidFunction;
}

export const CalendarCardPreview: React.FC<ICalendarCardPreviewProps> = (props) => {
    const { contentPod, timezone, closePreview } = props;
    const { seasonNumber, episodeNumber } = contentPod.content;
    const previewRef = React.useRef<HTMLDivElement>(null);

    const startTime = getTimezoneConformedMoment(moment(contentPod.startTime), timezone);

    const startTimeString = startTime.format('HH:mm');
    const endTimeString = startTime.add(contentPod.duration, 'second').format('HH:mm');

    const seasonEpisodeArray: string[] = [];

    if (seasonNumber) {
        seasonEpisodeArray.push(`S${seasonNumber}`);
    }
    if (episodeNumber) {
        seasonEpisodeArray.push(`E${episodeNumber}`);
    }

    const dispatch = useDispatch();

    const scrollToView = () => {
        dispatch(closePreviewSchedule());
        dispatch(scrollToContentPod(contentPod));
    };

    useClickOff(previewRef, closePreview, 'click');

    const handleClosePreview = (e: React.MouseEvent) => {
        closePreview();
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div className="calendar-card-preview" ref={previewRef}>
            <div className="calendar-card-preview__close" onClick={handleClosePreview}>
                <CloseLightIcon width={40} height={40} />
            </div>
            <div className="calendar-card-preview__time">
                {startTimeString} - {endTimeString}
            </div>
            <div className="calendar-card-preview__thumbnail">
                <img src={contentPod.content.thumbnailUrl} alt="Thumbnail" />
            </div>
            <div className="calendar-card-preview__title">{contentPod.content.title}</div>
            <div className="calendar-card-preview__description">{contentPod.content.description}</div>
            {(!!seasonNumber || !!episodeNumber) && (
                <div className="calendar-card-preview__bottom-tag">
                    <span className="calendar-card-preview__season-episode-icon" />
                    {seasonEpisodeArray.join(' : ')}
                </div>
            )}
            <button className="calendar-card-preview__view" onClick={scrollToView}>
                View on Timeline
            </button>
        </div>
    );
};
