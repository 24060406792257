import * as React from 'react';

import './index.scss';

interface IProps {
    text: string;
}

const UnderlineFirstCharacterComponent: React.FC<IProps> = ({ text }) => {
    return <div className="underline-first-letter">{text}</div>;
};

const UnderlineFirstCharacter: React.NamedExoticComponent<IProps> = React.memo(UnderlineFirstCharacterComponent);

export { UnderlineFirstCharacter };
