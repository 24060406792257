import { IStreamUrl } from '~src/store/editor/stream/types';
import { MilliSeconds } from '~src/types';

enum ClipStatus {
    OK = 'OK',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    NOT_EDITABLE = 'NOT_EDITABLE',
    EXPIRED = 'EXPIRED',
    PROCESSING = 'PROCESSING',
}

interface IClip {
    id: number;
    title: string;
    createdAt: string;
    duration: number;
    externalUrl: string;
    status: ClipStatus;
    thumbnail: string;
    updatedAt: string;
    videoId: number;
    recentVersionCount?: number;
}

interface IClientClipCreate {
    hlsStreamUrl?: string;
    components: IClipComponent[];
    videoName: string;
    dashStreamUrl?: string;
    thumbnail?: IClientThumbnail;
    requestStreams?: IStreamUrl[];
    streamingConfigId?: number;
}

interface IClipComponent {
    offsetMS: MilliSeconds;
    durationMS: MilliSeconds;
    startTimeMS?: MilliSeconds;
}

interface IClientThumbnail {
    id: number;
    path: string;
    bucketName: string;
}

type IClientClipUpdate = Omit<IClientClipCreate, 'videoName' | 'thumbnail'>;

type IClientClip = IClientClipCreate | IClientClipUpdate;

export { IClip, IClientClipCreate, IClientClipUpdate, IClientClip, IClipComponent, IClientThumbnail, ClipStatus };
