import * as React from 'react';

import { RootContext } from '~components/Root/context';
import { isFeatureEnabled } from '~services/utilities';
import { FeatureDictionary } from '~src/types';

interface IContainerProps {
    featureName: string;
}

interface IProps {
    featureName: string;
    features: FeatureDictionary;
}

const FeatureToggleComponent: React.FunctionComponent<IProps> = (props) => {
    const { featureName, features, children } = props;
    const isEnabled: boolean = isFeatureEnabled(featureName, features);

    return <React.Fragment>{isEnabled && children}</React.Fragment>;
};

const FeatureToggleMemo: React.NamedExoticComponent<React.PropsWithChildren<IProps>> = React.memo(FeatureToggleComponent);

const FeatureToggle: React.FunctionComponent<IContainerProps> = ({ featureName, ...rest }) => {
    const { features } = React.useContext(RootContext);
    return <FeatureToggleMemo features={features} featureName={featureName} {...rest} />;
};

export { FeatureToggle };
