import { httpV2 } from '~services/http';

import { RealmEndpoints } from './constants';
import { ICreateRealm } from './types';

const REALMS_URL = RealmEndpoints.REALMS;

export const createRealm = async ({ realmConfiguration }): Promise<ICreateRealm> => {
    const { parsedData } = await httpV2.post(REALMS_URL, {
        realmName: realmConfiguration.realmName,
        dgeCredentials: {
            operatorId: realmConfiguration.dgeCredentialsOperatorId,
            operatorSecret: realmConfiguration.dgeCredentialsOperatorSecret,
            operatorSupplementarySecret: realmConfiguration.dgeCredentialsOperatorSupplementarySecret,
        },
        dveCredentials: {
            customerId: realmConfiguration.dveCredentialsCustomerId,
            customerSecret: realmConfiguration.dveCredentialsCustomerSecret,
            customerSpockSecret: realmConfiguration.dveCredentialsSpockSecret,
        },
        shieldInfo: {
            shieldOvp: realmConfiguration.shieldInfoShieldOvp,
            shieldClientId: realmConfiguration.shieldInfoShieldClientId,
        },
    });
    return parsedData;
};
