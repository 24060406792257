import { put, select, takeEvery } from 'redux-saga/effects';

import { getActiveClipId, getClips, getFocusClipId, getPreviewClipId, getCurrentClip } from '~src/store/timeline/clip/clip.selectors';
import { createRange, createTimelineClip, isRangeIntersecting } from '~src/store/timeline/clip/clip.utilities';
import { ClipActions } from '~src/store/timeline/clip/constants';
import { ITimelineClip } from '~src/store/timeline/clip/types';
import { addThumbnailWithClip, cancelThumbnail } from '~src/store/timeline/thumbnail/thumbnail.actions';
import { getClipIdPendingThumbnail } from '~src/store/timeline/thumbnail/thumbnail.selectors';
import { ThumbnailUtilities } from '~src/store/timeline/thumbnail/thumbnail.utilities';
import { EToastType, ToastAction } from '~src/store/toast/constants';
import { Toaster } from '~src/store/toast/toast.actions';

function* isClipIntersecting(
    startTime: ITimelineClip['exportClipInTime'],
    endTime: ITimelineClip['exportClipOutTime'],
    clipId?: ITimelineClip['id']
) {
    const clips = yield select(getClips);

    return clips.some(({ id, exportClipInTime: existingStartTime, exportClipOutTime: existingEndTime }: ITimelineClip) => {
        if (clipId === id) {
            return false;
        }

        const rangeA = createRange(startTime, endTime);
        const rangeB = createRange(existingStartTime, existingEndTime);

        return isRangeIntersecting(rangeA, rangeB);
    });
}

function* addNewClip(action) {
    const { renderClipInTime, exportClipInTime } = action.payload;

    const isIntersecting = yield isClipIntersecting(exportClipInTime, exportClipInTime, null);

    if (isIntersecting) {
        yield addIntersectingClipWarning();
        return;
    }

    // build thumbnail / with preloading state
    const thumbnail = ThumbnailUtilities.buildThumbnail(renderClipInTime, exportClipInTime);
    // build clip with placeholder thumbnail relationship
    const clip = createTimelineClip(renderClipInTime, exportClipInTime, thumbnail.id);

    yield put(addThumbnailWithClip(thumbnail, clip.id));

    yield put({ type: ClipActions.ADD_NEW_CLIP, payload: { clip } });
}

function* handleAddInMarker(action) {
    const activeClipId = yield select(getActiveClipId);
    if (!activeClipId) {
        yield addNewClip(action);
        return;
    }

    // process as an existing clip
    const { renderClipInTime, exportClipInTime } = action.payload;
    // handle the change of a existing clip marker
    const currentClip = yield select(getCurrentClip);

    // the out time can be overridden by a higher in time moving both markers. Take the highest value for the end point
    const newClipOutTime = Math.max(currentClip.exportClipOutTime, exportClipInTime);

    const isIntersecting: boolean = yield isClipIntersecting(exportClipInTime, newClipOutTime, currentClip.id);
    if (isIntersecting) {
        yield addIntersectingClipWarning();
        return;
    }

    yield put({ type: ClipActions.ADD_CLIP_IN_MARKER, payload: { renderClipInTime, exportClipInTime, id: activeClipId } });
}

function* handleAddOutMarker(action) {
    const { renderClipOutTime, exportClipOutTime } = action.payload;

    const currentClip: ITimelineClip = yield select(getCurrentClip);

    // todo: prevent action being called if no clip are defined
    if (!currentClip) {
        return;
    }

    // the in time can be overridden by a lower out time moving both markers. Take the lowest value for the start point
    const newClipInTime = Math.min(currentClip.exportClipInTime, exportClipOutTime);

    const isIntersecting = yield isClipIntersecting(newClipInTime, exportClipOutTime, currentClip.id);
    if (isIntersecting) {
        yield addIntersectingClipWarning();
        return;
    }

    yield put({ type: ClipActions.ADD_CLIP_OUT_MARKER_BY_ID, payload: { renderClipOutTime, exportClipOutTime, id: currentClip.id } });
}

function* addIntersectingClipWarning() {
    const toastSlice = Toaster('Adjusting Clip', "Can't move the marker to encapsulate an existing clip", EToastType.WARNING);
    yield put({ type: ToastAction.ADD, payload: toastSlice });
}

function* handleSetClipTitle(action) {
    yield put({ type: ClipActions.SET_TITLE, payload: action.payload });
    yield put({ type: ClipActions.SET_EDIT_CLIP, payload: { id: null } });
}

function* handleSetClipThumbnail(action) {
    yield put({ type: ClipActions.SET_THUMBNAIL, payload: action.payload });
    yield put({ type: ClipActions.SET_EDIT_CLIP, payload: { id: null } });
}

function* handleRemoveClip(action) {
    const previewClipId = yield select(getPreviewClipId);
    const focusClipId = yield select(getFocusClipId);
    const activeClipId = yield select(getActiveClipId);
    const clipPendingThumbnailId = yield select(getClipIdPendingThumbnail);

    if (previewClipId === action.payload.id) {
        yield put({ type: ClipActions.SET_PREVIEW_CLIP, payload: { id: null } });
    }
    if (focusClipId === action.payload.id) {
        yield put({ type: ClipActions.SET_FOCUS_CLIP, payload: { id: null } });
    }
    if (activeClipId === action.payload.id) {
        yield put({ type: ClipActions.SET_ACTIVE_CLIP, payload: { id: null } });
    }
    if (clipPendingThumbnailId === action.payload.id) {
        yield put(cancelThumbnail());
    }

    yield put({ type: ClipActions.REMOVE_CLIP, payload: action.payload });
}

function* handleSetPreviewClip(action) {
    const { id } = action.payload;
    if (id === undefined) {
        const [clip] = yield select(getClips);
        yield put({ type: ClipActions.SET_PREVIEW_CLIP, payload: { id: clip.id } });
    } else {
        yield put({ type: ClipActions.SET_PREVIEW_CLIP, payload: { id } });
    }
}

export const clipSagas = [
    takeEvery(ClipActions.ADD_CLIP_IN_MARKER_SAGA, handleAddInMarker),
    takeEvery(ClipActions.ADD_CLIP_OUT_MARKER_SAGA, handleAddOutMarker),
    takeEvery(ClipActions.SET_TITLE_SAGA, handleSetClipTitle),
    takeEvery(ClipActions.SET_THUMBNAIL_SAGA, handleSetClipThumbnail),
    takeEvery(ClipActions.REMOVE_CLIP_SAGA, handleRemoveClip),
    takeEvery(ClipActions.SET_PREVIEW_CLIP_SAGA, handleSetPreviewClip),
];
