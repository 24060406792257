import { EAssetType } from '~containers/EditorPageContainer/types';
import { ProcessStreamSuccess } from '~src/store/editor/stream/types';

import { StreamActions } from './constants';

const processStream = (id: number, assetType: EAssetType) => ({
    type: StreamActions.PROCESS_STREAM_SAGA,
    payload: {
        id,
        assetType,
    },
});

const processStreamSuccess: ProcessStreamSuccess = ({
    assetType,
    assetId,
    title,
    thumbnail,
    hlsStreamUrl,
    // optionals
    dashStreamUrl = null,
    hlsStreamDRM = null,
    dashStreamDRM = null,
    streamUrls = null,
    duration = null,
    createdAt = null,
    updatedAt = null,
    streamingConfigId = null,
}) => ({
    type: StreamActions.PROCESS_STREAM_SUCCESS,
    payload: {
        assetId,
        title,
        duration,
        thumbnail,
        hlsStreamUrl,
        dashStreamUrl,
        hlsStreamDRM,
        dashStreamDRM,
        streamUrls,
        createdAt,
        updatedAt,
        assetType,
        streamingConfigId,
    },
});

const processStreamFailure = () => ({
    type: StreamActions.PROCESS_STREAM_FAILURE,
});

const streamHasEnded = () => ({
    type: StreamActions.STREAM_HAS_ENDED,
});

export { processStream, processStreamSuccess, processStreamFailure, streamHasEnded };
