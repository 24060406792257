import * as React from 'react';

import { GroupedCardUI } from '~components/ChannelManager/PreviewSchedule/GroupedCardUI';
import { IContentPod } from '~services/channels/types';
import { joinStrings } from '~services/utilities';
import { getDurationLabel } from '~services/utilities/time';

import ScrollArea from '../ScrollArea';
import './index.scss';

export const GroupedTabs: React.FC<{
    contentPods: IContentPod[];
    showUSDateTimeFormat: boolean;
    timezone: string;
    selected: number;
    onSelect: (selectedContentPodIndex: number) => void;
}> = ({ contentPods, showUSDateTimeFormat, timezone, selected, onSelect }) => {
    const handleOnSelectTab = (selectedContentPodIndex: number) => (e: React.MouseEvent) => {
        onSelect(selectedContentPodIndex);
    };

    const tabs = contentPods.map((contentPod, i) => (
        <div
            className={joinStrings(['collapsed-pods__tab', selected === i && 'collapsed-pods__tab--is-selected'])}
            key={i}
            onClick={handleOnSelectTab(i)}
        >
            {getDurationLabel(showUSDateTimeFormat, contentPod.startTime, contentPod.duration, timezone)}
        </div>
    ));

    return (
        <div className="collapsed-pods">
            <div className="collapsed-pods__tabs">
                <ScrollArea>{tabs}</ScrollArea>
            </div>
            <GroupedCardUI contentPod={contentPods[selected]} />
        </div>
    );
};
