import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { useClickOff } from '@dicetechnology/dice-backoffice-ui-components';

import { ChevronRight } from '~components/Icons/ChevronRight';
import { joinStrings } from '~services/utilities';

import './index.scss';

export interface IOption<T> {
    label: string;
    value: T;
}

interface IProps<T> {
    uniqueId: string;
    options: Array<IOption<T>>;
    selected: T;
    className?: string;
    disabled?: boolean;
    onSelected: (selected: T) => void;
}

export const CUSTOM_DROPDOWN_PORTAL_ROOT_ID = 'custom-dropdown';

export const Dropdown = <T extends string | number>(props: IProps<T>) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [position, setPosition] = React.useState<DOMRect>(null);
    const optionsListPortalRef = React.useRef<HTMLElement>(document.getElementById(CUSTOM_DROPDOWN_PORTAL_ROOT_ID));
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    const closeDropdown = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    useClickOff(dropdownRef, closeDropdown, 'click');

    React.useLayoutEffect(() => {
        if (isOpen) {
            setPosition(dropdownRef.current.getBoundingClientRect());
        }
    }, [isOpen]);

    const toggleDropdown = () => {
        setIsOpen((prevState) => !prevState);
    };

    const selectOption = (option: IOption<T>) => {
        props.onSelected(option.value);
        closeDropdown();
    };

    const getOption = (option: IOption<T>) => {
        const onClick = () => selectOption(option);
        const isSelected = option.value === props.selected;

        return (
            <li
                key={option.value}
                onClick={onClick}
                className={joinStrings(['custom-dropdown__option', isSelected && 'custom-dropdown__option--is-selected'])}
            >
                <span>{option.label}</span>
            </li>
        );
    };

    const containerClassName = joinStrings([
        'custom-dropdown',
        isOpen && 'custom-dropdown--is-open',
        props.disabled && 'custom-dropdown--is-disabled',
        props.className,
    ]);
    const selectedOption = props.options.find((option) => option.value === props.selected);

    return (
        <div className={containerClassName} ref={dropdownRef}>
            <div className="custom-dropdown__selected-value" onClick={toggleDropdown}>
                {selectedOption?.label ?? 'Please select'}
            </div>
            <ChevronRight className="custom-dropdown__dropdown-icon" width={20} />

            {ReactDOM.createPortal(
                <OptionsListPortal isOpen={isOpen} offset={position}>
                    <ul className="custom-dropdown__options-list">{props.options.map(getOption)}</ul>
                </OptionsListPortal>,
                optionsListPortalRef.current
            )}
        </div>
    );
};

const OptionsListPortal: React.FC<{ offset: DOMRect; isOpen: boolean }> = ({ offset, children, isOpen }) => {
    const style = offset
        ? {
              top: offset.top + offset.height + window.scrollY,
              left: offset.left,
              width: offset.width,
          }
        : {};
    return (
        <div style={style} className={joinStrings(['custom-dropdown-options', isOpen && 'custom-dropdown-options--is-open'])}>
            {children}
        </div>
    );
};
