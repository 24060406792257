import * as React from 'react';

import placeholder from '~assets/images/no-thumbnail.jpg';
import { getFormattedDuration } from '~components/Card/utilities';
import { ExportClipPreview } from '~components/Editor/ExportClip/Components/ExportClipPreview';
import { Checkbox } from '~components/Form/Checkbox';
import { joinStrings } from '~services/utilities';
import { ClipUtilities } from '~src/store/timeline/clip/clip.utilities';
import { IExportClipWithMeta, ITimelineClip } from '~src/store/timeline/clip/types';

import './index.scss';

interface IComponentProps {
    clips: IExportClipWithMeta[];
    clipIds?: Array<ITimelineClip['id']>;
    selectClip?: (clipId: ITimelineClip['id']) => void;
    removeClip?: (clipId: string) => void;
    renderSingleList?: boolean;
}

const TOTAL_CARDS_BEFORE_SCROLL = 6;

const ClipPicker: React.FunctionComponent<IComponentProps> = ({
    clips,
    clipIds,
    selectClip,
    removeClip,
    renderSingleList = false,
    children,
}) => {
    const cardsCount = clips.length;
    const isMultiList = cardsCount > TOTAL_CARDS_BEFORE_SCROLL;

    const handleSelectClip = (id: ITimelineClip['id']) => () => {
        selectClip(id);
    };

    const renderList = () => {
        if (!renderSingleList && isMultiList) {
            const middleIndex = Math.ceil(cardsCount / 2);
            const row1 = clips.slice(0, middleIndex);
            const row2 = clips.slice(middleIndex, cardsCount);
            return getMultiList([row1, row2]);
        }
        return getSingleList();
    };

    const getPickerItem = (clip: IExportClipWithMeta) => {
        const {
            meta: { id, title, thumbnail, duration },
        } = clip;

        const handleRemoveCombinedClip = (combinedClipId: string) => {
            return ClipUtilities.isCombined(clip) ? () => removeClip(combinedClipId) : null;
        };

        const formattedDuration = getFormattedDuration(duration);
        const badgeText = ClipUtilities.isCombined(clip) && `Combined - ${ClipUtilities.getComponentCount(clip)} clips`;
        const isSelected = clipIds.some((selectedClipId) => selectedClipId === id);
        const className = joinStrings(['greyscale', !isSelected && 'greyscale--disabled']);

        return (
            <label key={id} className="clip-picker__item form-group__label no-transition" htmlFor={id}>
                <Checkbox
                    className="form-group__control"
                    id={id}
                    name={id}
                    checked={!!isSelected}
                    onChange={handleSelectClip(id)}
                    data-test-id="clipPicker-pickerCheckbox"
                />
                <div className={className}>
                    <ExportClipPreview
                        key={id}
                        title={title}
                        thumbnail={thumbnail || placeholder}
                        duration={formattedDuration}
                        badgeText={badgeText}
                        remove={handleRemoveCombinedClip(id)}
                    />
                </div>
            </label>
        );
    };

    const getSingleList = () => {
        return (
            <div className="clip-picker__single-row">
                {clips.map(getPickerItem)}
                {children}
            </div>
        );
    };

    const getMultiList = (clipRows: IExportClipWithMeta[][]) => {
        return clipRows.map((clipRow, rowIndex) => {
            return (
                <div className="clip-picker__multi-row" key={rowIndex}>
                    {clipRow.map(getPickerItem)}
                </div>
            );
        });
    };

    const classNames = joinStrings(['clip-picker', isMultiList && 'clip-picker--scrollable']);

    return <div className={classNames}>{renderList()}</div>;
};

export { ClipPicker };
