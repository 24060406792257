import * as React from 'react';

import './index.scss';

const Footer: React.NamedExoticComponent<{}> = React.memo(() => {
    return (
        <div className="footer-container text-center">
            <small>{new Date().getFullYear()} Endeavor Streaming.</small>
        </div>
    );
});

export { Footer };
