import { IClip } from '~services/clip/types';
import { IEvent } from '~services/live/types';
import { IVod } from '~services/vod/types';

type Seconds = number;
type MilliSeconds = number;
type V4Uid = string;
type Base64ImageUri = string;

type BooleanFunction = () => boolean;

interface IBooleanDictionary {
    [key: string]: boolean;
}

interface IStringDictionary {
    [key: string]: string;
}

interface IAnyDictionary {
    [key: string]: any;
}

interface IStringArrayDictionary {
    [key: string]: string[];
}

interface IGenericDictionary<T> {
    [key: string]: T;
}

type StringArray = string[];

type FeatureDictionary = IBooleanDictionary;

type Realm = string;

enum Role {
    ROOT = 'ROOT',
    ADMIN = 'ADMIN',
    RESIDENT = 'RESIDENT',
    SUPERVISOR = 'SUPERVISOR',
    AGENT = 'AGENT',
    OVERFLOW = 'OVERFLOW',
    CUSTOMER = 'CUSTOMER',
    GUEST = 'GUEST',
}

type StateKey = string;

enum VodType {
    VOD = 'vod',
    ARCHIVE = 'archive',
}

type IContentType = IClip | IEvent | IVod;
type ISearchableContentType = IClip | IVod;

enum TimeFormats {
    HHmmssSS = 'HH:mm:ss.SS',
    HHmmss = 'HH:mm:ss',
    HHmm = 'HH:mm',
    mmss = 'mm:ss',
    mss = 'm:ss',
    hmmA = 'h:mm A',
}

type Layout = 'horizontal' | 'vertical' | 'horizontal-live';

interface ICardSettings {
    width: number;
    height: number;
    layout?: Layout;
}

interface IParsedPagingResponse<T> {
    results: T[];
    hasMoreResults: boolean;
    lastSeen?: number;
}

type PagerService = (lastSeen: number) => Promise<IParsedPagingResponse<IContentType>>;

export {
    Seconds,
    MilliSeconds,
    V4Uid,
    IBooleanDictionary,
    IStringDictionary,
    IStringArrayDictionary,
    IAnyDictionary,
    IGenericDictionary,
    FeatureDictionary,
    Base64ImageUri,
    StringArray,
    Realm,
    StateKey,
    VodType,
    IContentType,
    ISearchableContentType,
    ICardSettings,
    Layout,
    TimeFormats,
    BooleanFunction,
    IParsedPagingResponse,
    PagerService,
    Role,
};
