import * as React from 'react';

import { LoadingSpinner, LoadingSpinnerSize } from '~components/LoadingSpinner';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';

import './index.scss';

interface IThumbnailContentProps {
    imageData: ITimelineThumbnail['imageData'];
}

const ThumbnailContentComponent: React.FunctionComponent<IThumbnailContentProps> = ({ imageData }) => {
    return (
        <div className="thumbnail-content">
            <img className="thumbnail-content__thumbnail" src={imageData} width="150" />
            {!imageData && <LoadingSpinner size={LoadingSpinnerSize.SMALL} />}
        </div>
    );
};

const ThumbnailContent: React.NamedExoticComponent<IThumbnailContentProps> = React.memo(ThumbnailContentComponent);

export { ThumbnailContent };
