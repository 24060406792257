import * as React from 'react';

import { PageTheme } from '~components/Root/types';
import { joinStrings } from '~services/utilities';

import './index.scss';

export enum TooltipPositions {
    LEFT = 'left',
    RIGHT = 'right',
    BOTTOM = 'bottom',
    TOP = 'top',
}

interface IProps {
    message: React.ReactNode;
    position: TooltipPositions;
    className?: string;
    theme?: PageTheme;
}

interface IBoundary {
    left: boolean;
    right: boolean;
}

const BOUNDARY_OFFSET = 10;

const TooltipComponent: React.FunctionComponent<IProps> = ({ message, position, children, theme = PageTheme.LIGHT, className }) => {
    const [displayTooltip, setDisplayTooltip] = React.useState<boolean>(false);
    const tooltip = React.useRef<HTMLDivElement>(null);
    const boundary = React.useRef<null | IBoundary>(null);

    const hideTooltip = (): void => {
        setDisplayTooltip(false);
    };

    const showTooltip = (): void => {
        moveIntoViewport();
        setDisplayTooltip(true);
    };

    const moveIntoViewport = (): void => {
        // Only position once
        if (boundary.current === null) {
            boundary.current = isInViewport(tooltip.current);
        }
    };

    const isInViewport = (element: HTMLElement): IBoundary => {
        // Only checks for left/right boundaries for now
        const { innerWidth } = window;
        const { left, right } = element.getBoundingClientRect();
        const isLeftInView = left >= BOUNDARY_OFFSET;
        const isRightInView = right + BOUNDARY_OFFSET <= innerWidth;
        return {
            left: isLeftInView,
            right: isRightInView,
        };
    };

    const getClassNames = (): string => {
        let overrideClassNames = '';
        if (boundary.current) {
            const { left = true, right = true } = boundary.current;
            overrideClassNames = joinStrings([!left && 'tooltip-override-left', !right && 'tooltip-override-right']);
        }

        return joinStrings(['tooltip__message', displayTooltip && 'tooltip--active', `tooltip-${position}`, overrideClassNames]);
    };

    if (!message) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    const wrapperClassName = joinStrings([className, 'tooltip', `tooltip--${theme.toLowerCase()}`]);

    return (
        <div className={wrapperClassName}>
            <div className={getClassNames()} ref={tooltip}>
                {message}
            </div>
            <div className="tooltip__body" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                {children}
            </div>
        </div>
    );
};

const Tooltip: React.NamedExoticComponent<React.PropsWithChildren<IProps>> = React.memo(TooltipComponent);

export { Tooltip };
