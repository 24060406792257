import * as React from 'react';

import { joinStrings } from '~services/utilities';
import { Footer } from '~src/views/layout/Footer';

import './index.scss';

interface IProps {
    showFooter: boolean;
    className?: string;
    verticalCenter?: boolean;
}

const Page: React.FC<IProps> = ({ children, showFooter, className, verticalCenter }) => {
    const classNames = joinStrings(['page-container', showFooter && 'page-container--with-footer', className]);
    const classNamesCentering = joinStrings(['centering-container', verticalCenter && 'centering-container--vertical']);
    return (
        <div className={classNames}>
            <div className={classNamesCentering}>{children}</div>
            {showFooter && <Footer />}
        </div>
    );
};

export { Page };
