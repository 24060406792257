import * as React from 'react';

import placeholder from '~assets/images/no-thumbnail.jpg';
import { ConfirmDialog } from '~components/ConfirmDialog';
import { ClockIcon } from '~components/Icons/ClockIcon';
import { EditFileLightIcon } from '~components/Icons/EditFileLightIcon';
import { ExclamationTriangleIcon } from '~components/Icons/ExclamationTriangleIcon';
import { ExportFileLightIcon } from '~components/Icons/ExportFileLightIcon';
import { FileTimesLightIcon } from '~components/Icons/FileTimesLightIcon';
import { FilmIcon } from '~components/Icons/FilmIcon';
import { LoadingSpinner, LoadingSpinnerSize } from '~components/LoadingSpinner';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { getTimeLabelIsoString, joinStrings } from '~services/utilities';
import { ITimelineClip, Seconds } from '~src/store/timeline/clip/types';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';

interface IProps {
    id: ITimelineClip['id'];
    title: ITimelineClip['title'];
    exportClipInTime: ITimelineClip['exportClipInTime'];
    exportClipOutTime: ITimelineClip['exportClipOutTime'];
    clipDuration: Seconds;
    thumbnail: ITimelineThumbnail;
    isPreviewing: boolean;
    isActive: boolean;
    isHovered: boolean;
    isValid: boolean;
    isInvalidMessage?: string;
    setClipFocus: (type: string, id: ITimelineClip['id']) => void;
    removeClip: (id: ITimelineClip['id']) => void;
    editClip: (id: ITimelineClip['id']) => void;
    setActiveClip: (id: ITimelineClip['id']) => void;
    previewClip: (id: ITimelineClip['id'], start, end) => void;
    stopPreviewClip: VoidFunction;
    exportSingleClip: (id: ITimelineClip['id']) => void;
    seekToClip: (time: Seconds) => void;
}

const REMOVE_TITLE = 'Remove Clip?';
const REMOVE_MESSAGE = 'Are you sure you want to remove this clip?';

const ClipItemComponent: React.FunctionComponent<IProps> = ({
    id,
    title,
    thumbnail,
    exportClipInTime,
    exportClipOutTime,
    clipDuration,
    isPreviewing,
    isActive,
    isHovered,
    isValid,
    isInvalidMessage,
    setClipFocus,
    removeClip,
    editClip,
    previewClip,
    stopPreviewClip,
    exportSingleClip,
    seekToClip,
    setActiveClip,
}) => {
    const [renderDialog, setRenderDialog] = React.useState<boolean>(false);

    const hasExportOutTime = exportClipOutTime !== null;

    const mouseMoveHandler = ({ type }: React.MouseEvent<HTMLDivElement>) => setClipFocus(type, id);

    const onClickHandler = (event: React.MouseEvent) => {
        event.stopPropagation();

        if (event.altKey) {
            event.stopPropagation();
            const activeId = isActive ? null : id;
            setActiveClip(activeId);
            return;
        }

        seekToClip(exportClipInTime);
    };

    const removeClipMenuHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        removeClip(id);
    };

    const editClipMenuHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        editClip(id);
    };

    const previewClipMenuHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        previewClip(id, exportClipInTime, exportClipOutTime);
    };

    const exportSingleClipMenuHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        exportSingleClip(id);
    };

    const durationString = getTimeLabelIsoString(clipDuration);

    const className = joinStrings([
        'clip-item',
        isActive && 'clip-item--active',
        isHovered && 'clip-item--hover',
        isPreviewing && 'clip-item--preview',
    ]);

    const handleShowDialog = (renderDialogFlag: boolean) => () => setRenderDialog(renderDialogFlag);

    const getThumbnail = () => {
        let imageSrc = null;

        if (!thumbnail) {
            imageSrc = placeholder;
        } else if (thumbnail.imageData) {
            imageSrc = thumbnail.imageData;
        }
        return (
            <React.Fragment>
                <img src={imageSrc} />
                {!imageSrc && <LoadingSpinner size={LoadingSpinnerSize.SMALL} />}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {renderDialog && (
                <ConfirmDialog
                    title={REMOVE_TITLE}
                    message={REMOVE_MESSAGE}
                    cancel={handleShowDialog(false)}
                    confirm={removeClipMenuHandler}
                />
            )}
            <div className={className} onMouseEnter={mouseMoveHandler} onMouseLeave={mouseMoveHandler} onClick={onClickHandler}>
                <div className="clip-item__thumbnail">{getThumbnail()}</div>
                <div className="clip-item__content">
                    <div className="clip-item__title">{title}</div>
                    {hasExportOutTime && (
                        <div className="clip-item__duration">
                            <ClockIcon /> {durationString}
                        </div>
                    )}
                </div>

                {!isValid && (
                    <div className="clip-item__status">
                        <Tooltip message={isInvalidMessage} position={TooltipPositions.TOP}>
                            <ExclamationTriangleIcon />
                        </Tooltip>
                    </div>
                )}

                <ul className="inline-list clip-item__actions">
                    {isValid && (
                        <React.Fragment>
                            <li className="list-item">
                                <button onClick={isPreviewing ? stopPreviewClip : previewClipMenuHandler}>
                                    <Tooltip message="Preview clip" position={TooltipPositions.TOP}>
                                        <FilmIcon className="preview-icon" />
                                    </Tooltip>
                                </button>
                            </li>
                            <li className="list-item clip-item__export-btn">
                                <button onClick={exportSingleClipMenuHandler}>
                                    <Tooltip message="Export clip" position={TooltipPositions.TOP}>
                                        <ExportFileLightIcon className="export-icon" />
                                    </Tooltip>
                                </button>
                            </li>
                            <li className="list-item">
                                <button onClick={editClipMenuHandler}>
                                    <Tooltip message="Edit clip metadata" position={TooltipPositions.TOP}>
                                        <EditFileLightIcon />
                                    </Tooltip>
                                </button>
                            </li>
                        </React.Fragment>
                    )}

                    <li className="list-item">
                        <button onClick={handleShowDialog(true)}>
                            <Tooltip message="Remove clip" position={TooltipPositions.TOP}>
                                <FileTimesLightIcon />
                            </Tooltip>
                        </button>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
};

const ClipItem: React.NamedExoticComponent<IProps> = React.memo(ClipItemComponent);

export { ClipItem };
