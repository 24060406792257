import * as React from 'react';

const FileTimesLightIcon = (props) => {
    return (
        <svg viewBox="0 0 384 512" {...props}>
            <path d="M369.9,97.9,286,14A48,48,0,0,0,252.1-.1H48A48.16,48.16,0,0,0,0,48V464a48,48,0,0,0,48,48H336a48,48,0,0,0,48-48V131.9A48.23,48.23,0,0,0,369.9,97.9Zm-22.6,22.7a15.73,15.73,0,0,1,4.2,7.4H256V32.5a15.73,15.73,0,0,1,7.4,4.2ZM336,480H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H224V136a23.94,23.94,0,0,0,24,24H352V464A16,16,0,0,1,336,480ZM262.71,369.05l-5.66,5.66a12,12,0,0,1-17,0L192,326.63l-48.08,48.08a12,12,0,0,1-17,0l-5.66-5.66a12,12,0,0,1,0-17L169.37,304l-48.08-48.08a12,12,0,0,1,0-17l5.66-5.66a12,12,0,0,1,17,0L192,281.37l48.08-48.08a12,12,0,0,1,17,0l5.66,5.66a12,12,0,0,1,0,17L214.63,304l48.08,48.08A12,12,0,0,1,262.71,369.05Z" />
        </svg>
    );
};

export { FileTimesLightIcon };
