import * as React from 'react';

const TrashBoldFilledIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width={16} height={18} viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.643 0c.591 0 1.261.446 1.484.993l.781 1.864h3.45c.2 0 .356.156.356.357v.715c0 .2-.156.357-.357.357h-1.071v10.58c0 1.228-.804 2.277-1.786 2.277H3.214c-.982 0-1.785-1.005-1.785-2.232V4.286H.357A.353.353 0 010 3.929v-.715c0-.2.156-.357.357-.357h3.449L4.587.993C4.81.446 5.48 0 6.071 0zM5.357 6.429h-.714c-.201 0-.357.156-.357.357v6.428c0 .201.156.357.357.357h.714c.201 0 .357-.156.357-.357V6.786c0-.201-.156-.357-.357-.357zm2.857 0H7.5c-.2 0-.357.156-.357.357v6.428c0 .201.156.357.357.357h.714c.201 0 .357-.156.357-.357V6.786c0-.201-.156-.357-.357-.357zm2.857 0h-.714c-.2 0-.357.156-.357.357v6.428c0 .201.156.357.357.357h.714c.201 0 .358-.156.358-.357V6.786c0-.201-.157-.357-.358-.357zm-1.44-5H6.095a.376.376 0 00-.19.122l-.547 1.306h5l-.536-1.306a.43.43 0 00-.19-.122z"
                fill="#FFF"
                fillRule="nonzero"
            />
        </svg>
    );
};

export { TrashBoldFilledIcon };
