import * as React from 'react';
import { useHistory } from 'react-router-dom';

type UnregisterCallback = () => void;

/**
 * Use to get confirmation from user before unloading page. Uses native confirmation dialogs
 * @param isActive condition to activate/deactivate prompt
 * @param message message to use in react router prompt
 */
export const usePrompt = (isActive: boolean, message: string = 'Are you sure you want to quit without saving your changes?') => {
    const history = useHistory();
    const unregisterBlock = React.useRef<UnregisterCallback>(null);

    React.useEffect(() => {
        const onUnload = (event: BeforeUnloadEvent): string | void => {
            if (isActive) {
                event.returnValue = message;

                return message;
            }
        };
        unregisterBlock.current = isActive ? history.block(message) : null;
        window.addEventListener('beforeunload', onUnload);

        return () => {
            unregisterBlock.current?.();
            unregisterBlock.current = null;
            window.removeEventListener('beforeunload', onUnload);
        };
    }, [message, isActive]);
};
