import placeholder from '~assets/images/no-thumbnail.jpg';
import { getTimeLabel } from '~services/utilities';
import { ICardSettings, IContentType, TimeFormats } from '~src/types';

const getAssetUrl = (baseUrl: string, assetId: IContentType['id']): string => {
    return baseUrl.replace('{assetId}', assetId.toString());
};

const isDveLinkDefined = (url: string): boolean => {
    return !!url;
};

const getThumbnailUrl = (thumbnail: IContentType['thumbnail'], { width, height }: ICardSettings) => {
    if (thumbnail && thumbnail.includes('original')) {
        const newHeight = Math.floor(height);
        const newWidth = Math.floor(width);
        return thumbnail.replace(/\/original\//gi, `/${newWidth}x${newHeight}/`);
    } else if (!thumbnail) {
        return placeholder;
    } else {
        return thumbnail;
    }
};

const getFormattedDuration = (duration: number): string => {
    const ONE_HOUR = 3600;
    let format;
    if (duration > ONE_HOUR) {
        format = TimeFormats.HHmmss;
    } else {
        format = TimeFormats.mmss;
    }
    return getTimeLabel(duration, format);
};

export { getAssetUrl, getThumbnailUrl, getFormattedDuration, isDveLinkDefined };
