import { ClipEndpoints } from '~services/clip/constants';
import { IClientClipCreate, IClientClipUpdate, IClip } from '~services/clip/types';
import { httpV2, httpV3 } from '~services/http';
import { parsePagingRequest } from '~services/utilities';
import { IParsedPagingResponse } from '~src/types';

export interface IClipStudio {
    id: number;
    title: string;
    duration: number;
    status: string;
    streamUrl: string;
    externalUrl: string;
    videoId: number;
    thumbnail: string;
    createdAt: string;
    updatedAt: string;
}

export interface IStudioClipResponse {
    parsedData: IClipStudio;
}

const getClips = (size: number) => async (lastSeen: number = null): Promise<IParsedPagingResponse<IClip>> => {
    return parsePagingRequest(httpV2, ClipEndpoints.GET, size, lastSeen);
};

const getClipsDveVideoIds = async (videoIdsList: number[]): Promise<IClip[]> => {
    const videoIds = videoIdsList.join(',');
    const {
        parsedData: { results },
    } = await httpV2.get(ClipEndpoints.GET, { queryParams: { videoIds } });
    return results;
};

const getStudioClipMeta = async (clipId: number): Promise<IClipStudio> => {
    const endpoint = ClipEndpoints.GET_BY_ID.replace('{id}', clipId.toString());
    const { parsedData }: IStudioClipResponse = await httpV3.get(endpoint);
    return parsedData;
};

const createClipFromClip = (clipId: IClip['id'], clip: IClientClipCreate): Promise<void> => {
    const endpoint = ClipEndpoints.CREATE.replace('{id}', clipId.toString());
    return httpV3.post(endpoint, clip);
};

const overwriteClipFromClip = (clipId: IClip['id'], clip: IClientClipUpdate): Promise<void> => {
    const endpoint = ClipEndpoints.OVERWRITE.replace('{id}', clipId.toString());
    return httpV3.put(endpoint, clip);
};

const getClipsOfClip = (size: number, clipId: number) => async (lastSeen: number = null): Promise<IParsedPagingResponse<IClip>> => {
    const endpoint = ClipEndpoints.GET_CLIPS_BY_ID.replace('{id}', clipId.toString());
    return parsePagingRequest(httpV2, endpoint, size, lastSeen);
};

export { getClips, getClipsDveVideoIds, getStudioClipMeta, createClipFromClip, overwriteClipFromClip, getClipsOfClip };
