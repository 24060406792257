enum StreamActions {
    PROCESS_STREAM_SAGA = 'editor/stream/PROCESS_STREAM_SAGA',

    PROCESS_STREAM_SUCCESS = 'editor/stream/PROCESS_STREAM_SUCCESS',
    PROCESS_STREAM_FAILURE = 'editor/stream/PROCESS_STREAM_FAILURE',

    STREAM_HAS_ENDED = 'editor/stream/STREAM_HAS_ENDED',
}

export { StreamActions };
