import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import * as Doris from '@dicetechnology/doris';

import ExternalLinkSquareIcon from '~components/Icons/EcternalLinkSquare';
import { PencilIcon } from '~components/Icons/PencilIcon';
import Modal from '~components/Modal';
import { SmartAnchor } from '~components/SmartAnchor';
import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { EAssetType, PlayerKeys } from '~containers/EditorPageContainer/types';
import { getStudioClipMeta } from '~services/clip';
import { Console } from '~services/console';
import { getDvePlaybackUrls } from '~services/dve';
import { getDgeStreamMeta, getLiveEventStudioMeta } from '~services/live';
import { IEvent } from '~services/live/types';
import { getStudioVodMeta } from '~services/vod';
import { addFeedback } from '~src/store/feedback/feedback.actions';

import './index.scss';

interface IContainerProps {
    assetId: number;
    assetType: EAssetType;
    canCreateClip: boolean;
    close: () => void;
    externalUrl: string;
}

interface IProps extends IContainerProps {
    isEditorMuted: boolean;
    isPreviewMuted: boolean;
    toggleMute: (playerKey: PlayerKeys) => void;
    dispatch: Dispatch;
}

interface IState {
    shouldUnmuteEditor: boolean;
    shouldUnmutePreview: boolean;
    isLoading: boolean;
    streamUrl: string;
    title?: string;
}

// internal routes
const EDITOR_LIVE_VIDEO_URL = '/editor/live/{assetId}';
const EDITOR_CLIP_VIDEO_URL = '/editor/clip/{assetId}';
const EDITOR_VOD_VIDEO_URL = '/editor/vod/{assetId}';

class PlayerModalComponent extends React.PureComponent<IProps, IState> {
    private player = null;

    public state: IState = {
        shouldUnmuteEditor: !this.props.isEditorMuted,
        shouldUnmutePreview: !this.props.isPreviewMuted,
        isLoading: true,
        streamUrl: null,
    };

    public componentDidMount(): void {
        const { assetId, assetType } = this.props;

        if (assetType === EAssetType.LIVE) {
            this.processDgeAsset(assetId);
        } else if (assetType === EAssetType.CLIP || assetType === EAssetType.VOD) {
            this.processDveAsset(assetId, assetType);
        }
    }

    private processHttpErrorFeedback = (status, messages, requestId) => {
        const feedback = {
            title: `ERROR ${status}`,
            message: `<p>
                    ${messages.join(' ')} <br /> RID: ${requestId}
                </p>`,
            canDismiss: true,
        };
        this.props.dispatch(addFeedback(feedback));
    };

    private processDveAsset = async (assetId, assetType): Promise<void> => {
        try {
            const { streamUrl, title } = await this.getAssetMeta(assetId, assetType);
            const { hlsStreamUrl } = await getDvePlaybackUrls(streamUrl);

            this.setState({
                title,
                isLoading: false,
                streamUrl: hlsStreamUrl,
            });
        } catch (error) {
            if ((error as Response).status) {
                const { status = '', messages = [], requestId = '' } = await error.clone().json();
                Console.warn("Processing 'DVE Asset Meta':", error);
                this.processHttpErrorFeedback(status, messages, requestId);
            } else {
                Console.warn('Modal Player Error:', error);
            }

            this.onClose();
        }
    };

    private processDgeAsset = async (assetId: number) => {
        const assetIdString = assetId.toString();
        try {
            const { nonDrmUrl, drmUrl, title } = await getLiveEventStudioMeta(assetIdString);

            const [firstStreamUrl] = await getDgeStreamMeta(nonDrmUrl, drmUrl);

            this.setState({
                isLoading: false,
                streamUrl: firstStreamUrl.url,
                title,
            });
        } catch (error) {
            if ((error as Response).status && error.status === 404) {
                const feedback = {
                    title: 'Event ended',
                    message: `<p>The event is no longer available</p>`,
                    canDismiss: true,
                };
                this.props.dispatch(addFeedback(feedback));
            } else if ((error as Response).status) {
                const { status = '', messages = [], requestId = '' } = await error.clone().json();
                Console.warn("Processing 'Live Asset Meta':", error);
                this.processHttpErrorFeedback(status, messages, requestId);
            } else {
                Console.warn('Modal Player Error:', error);
            }

            this.onClose();
        }
    };

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        const { isLoading, streamUrl } = this.state;

        if (!isLoading && streamUrl && !this.player) {
            this.setUpVodPlayer();
            this.mutePlayerAudioState();
            this.addPlayerDefaultControls();
        }
    }

    public componentWillUnmount(): void {
        if (this.player) {
            this.player.destroy();
        }
        this.restorePlayerAudioState();
    }

    private getAssetUrl = (assetId: number, assetType: EAssetType): string => {
        const assetIdString = assetId.toString();

        switch (assetType) {
            case EAssetType.LIVE:
                return EDITOR_LIVE_VIDEO_URL.replace('{assetId}', assetIdString);
            case EAssetType.CLIP:
                return EDITOR_CLIP_VIDEO_URL.replace('{assetId}', assetIdString);
            case EAssetType.VOD:
                return EDITOR_VOD_VIDEO_URL.replace('{assetId}', assetIdString);
            default:
                return null;
        }
    };

    public render() {
        const { isLoading, title } = this.state;
        const { assetId, assetType, canCreateClip, externalUrl } = this.props;

        const $Component = (
            <Modal.Main closeWithEscape={true} hasOverlay={true} onClose={this.onClose} isLoading={isLoading} className="player-modal">
                <Modal.Title>{title}</Modal.Title>
                <Modal.Body justify="right">
                    <div className="embed-responsive embed-responsive-16by9">
                        <div className="embed-responsive-item" id="modal-player" />
                    </div>

                    <div className="player-modal__links">
                        <ul className="inline-list">
                            {canCreateClip && assetType !== EAssetType.LIVE && (
                                <li className="list-item">
                                    <SmartAnchor to={this.getAssetUrl(assetId, assetType)}>
                                        <PencilIcon />
                                        <span>Create clip from video</span>
                                    </SmartAnchor>
                                </li>
                            )}
                            {!!externalUrl && (
                                <li className="list-item">
                                    <SmartAnchor to={externalUrl}>
                                        <ExternalLinkSquareIcon />
                                        <span>Open video in DVE</span>
                                    </SmartAnchor>
                                </li>
                            )}
                            {assetType === EAssetType.LIVE && (
                                <li className="list-item">
                                    <SmartAnchor to={this.getAssetUrl(assetId, assetType)}>
                                        <PencilIcon />
                                        <span>Create clip from live event</span>
                                    </SmartAnchor>
                                </li>
                            )}
                        </ul>
                    </div>
                </Modal.Body>
            </Modal.Main>
        );

        return ReactDOM.createPortal($Component, document.getElementById('modal'));
    }

    private getAssetMeta = (assetId, assetType: Exclude<EAssetType, IEvent>) => {
        switch (assetType) {
            case EAssetType.CLIP:
                return getStudioClipMeta(assetId);
            case EAssetType.VOD:
                return getStudioVodMeta(assetId);
            default:
                return Promise.reject('Unknown asset format');
        }
    };

    private onClose = () => {
        this.props.close();
    };

    private setUpVodPlayer = (): void => {
        this.player = new Doris.Player('modal-player');

        const source: Doris.Source = {
            type: Doris.StreamTypes.HLS,
            url: this.state.streamUrl,
        };

        const config: Doris.IConfig = {
            autoPlay: Doris.AutoPlayOptions.YES,
            hlsConfig: {
                startLevel: 8,
                capLevelToPlayerSize: false, // allow best rendition for connection
                startFragPrefetch: true,
            },
            debug: {
                enabled: false,
            },
            preferNative: true,
        };

        this.player.load(source, config);
    };

    private addPlayerDefaultControls = (): void => {
        const $modalPlayer: HTMLVideoElement = document.querySelector('#modal-player video');
        $modalPlayer.controls = true;
    };

    private mutePlayerAudioState = (): void => {
        const { isEditorMuted, isPreviewMuted, toggleMute } = this.props;

        if (!isEditorMuted) {
            toggleMute(PlayerKeys.EDITOR);
        }

        if (!isPreviewMuted) {
            toggleMute(PlayerKeys.PREVIEW);
        }
    };

    private restorePlayerAudioState = (): void => {
        const { shouldUnmuteEditor, shouldUnmutePreview } = this.state;
        const { toggleMute } = this.props;

        if (shouldUnmuteEditor) {
            toggleMute(PlayerKeys.EDITOR);
        }

        if (shouldUnmutePreview) {
            toggleMute(PlayerKeys.PREVIEW);
        }
    };
}

const PlayerModal = ({ assetId, assetType, canCreateClip, close, externalUrl }: IContainerProps) => {
    const {
        editorPlayer: { muted: isEditorMuted },
        previewPlayer: { muted: isPreviewMuted },
        toggleMute,
    } = React.useContext(VideoContext);

    const dispatch = useDispatch();

    return (
        <PlayerModalComponent
            assetId={assetId}
            assetType={assetType}
            canCreateClip={canCreateClip}
            externalUrl={externalUrl}
            close={close}
            isEditorMuted={isEditorMuted}
            isPreviewMuted={isPreviewMuted}
            toggleMute={toggleMute}
            dispatch={dispatch}
        />
    );
};

export { PlayerModal };
