import * as React from 'react';

/**
 * Returns a boolean to check if its first render
 * @returns boolean
 */
const useFirstMount = (): boolean => {
    const isFirst = React.useRef<boolean>(true);

    if (isFirst.current) {
        isFirst.current = false;

        return true;
    }

    return isFirst.current;
};

export default useFirstMount;
