import { IExportClipWithMeta } from '~src/store/timeline/clip/types';
import { MilliSeconds, Seconds } from '~src/types';

class ClipsUtilities {
    public static getIds(clips: IExportClipWithMeta[]): string[] {
        return clips.map((clip) => clip.meta.id);
    }

    public static findById(clips: IExportClipWithMeta[], id: string): IExportClipWithMeta {
        return clips.find((clip) => clip.meta.id === id);
    }

    public static sortClipsByOffset(clips: ReadonlyArray<IExportClipWithMeta>): IExportClipWithMeta[] {
        return [...clips].sort((a, b) => {
            return a.components[0].offsetMS - b.components[0].offsetMS;
        });
    }

    public static canCombineClips(clips: IExportClipWithMeta[], minimumClipSeparation: Seconds): boolean {
        if (clips.length <= 1) {
            return false;
        }

        const isBreakingSocialDistancingRules = clips
            .filter(({ components }) => components.length === 1)
            .sort((a, b) => {
                return a.components[0].offsetMS - b.components[0].offsetMS;
            })
            .some(({ components }, index, array) => {
                const [component] = components;
                const nextIndex = index + 1;
                if (nextIndex < array.length) {
                    const clipOutTime = component.offsetMS + component.durationMS;
                    const clipSeparation: MilliSeconds = array[nextIndex].components[0].offsetMS - clipOutTime;
                    return clipSeparation <= minimumClipSeparation * 1000;
                }
                return false;
            });

        return !isBreakingSocialDistancingRules;
    }

    /* enforce static usage: prevent instances being created */
    private constructor() {
        /* noop */
    }
}

export { ClipsUtilities };
