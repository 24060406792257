import * as React from 'react';
import { Dispatch } from 'redux';

import CheckCircleIcon from '~components/Icons/CheckCircleIcon';
import CheckCircleInvertIcon from '~components/Icons/CheckCircleMonoIcon';
import ExclamationCircleIcon from '~components/Icons/ExclamationCircleIcon';
import InfoCircleIcon from '~components/Icons/InfoCircleIcon';
import TimesCircleIcon from '~components/Icons/TimesCircleIcon';
import TimesCircleMonoIcon from '~components/Icons/TimesCircleMonoIcon';
import TimesThinIcon from '~components/Icons/TimesThinIcon';
import { PageTheme } from '~components/Root/types';
import { joinStrings } from '~services/utilities';
import { EToastType } from '~src/store/toast/constants';
import { dismissToast } from '~src/store/toast/toast.actions';
import { IToastSlice } from '~src/store/toast/types';
import { useTimeout } from '~src/views/hooks';

import './index.scss';

interface IProps {
    toast: IToastSlice;
    dispatch: Dispatch;
}

const getLightThemeToastIcon = (toastType: EToastType) => {
    switch (toastType) {
        case EToastType.SUCCESS:
            return <CheckCircleInvertIcon className="toast__icon" />;
        case EToastType.DANGER:
            return <TimesCircleMonoIcon className="toast__icon" />;
        default:
            return null;
    }
};

const getDarkThemeToastIcon = (toastType: EToastType) => {
    switch (toastType) {
        case EToastType.SUCCESS:
            return <CheckCircleIcon className="toast__icon" />;
        case EToastType.WARNING:
            return <ExclamationCircleIcon className="toast__icon" />;
        case EToastType.DANGER:
            return <TimesCircleIcon className="toast__icon" />;
        case EToastType.INFO:
            return <InfoCircleIcon className="toast__icon" />;
        default:
            return null;
    }
};

const Toast: React.FC<IProps> = ({ toast, dispatch }) => {
    const { id, title, message, dataTestId, type, timeout, theme = PageTheme.DARK } = toast;
    const { setTimer, clearTimer } = useTimeout(timeout);

    React.useEffect(() => {
        setTimer(closeToast);
        return () => {
            clearTimer();
        };
    }, []);

    const closeToast = () => {
        dispatch(dismissToast(id));
    };

    const classNames = joinStrings([`toast__slice toast__slice--${type}`, theme === PageTheme.LIGHT && 'toast__slice--light']);

    return (
        <li className={classNames} data-test-id={dataTestId}>
            <button className="toast__close-btn" title="Dismiss notification" onClick={closeToast}>
                <TimesThinIcon className="close-btn__icon" />
            </button>
            <div className="toast__body">
                {theme === PageTheme.DARK ? getDarkThemeToastIcon(type) : getLightThemeToastIcon(type)}
                <div className="toast__content">
                    <h3 className="toast__title">{title}</h3>
                    <p className="toast__message">{message}</p>
                </div>
            </div>
        </li>
    );
};

export { Toast };
