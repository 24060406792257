import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { KeyboardControls } from '~components/Editor/KeyboardControls';
import { Timeline } from '~components/Editor/Timeline';
import { RootContext } from '~components/Root/context';
import { EditorContext } from '~containers/EditorPageContainer/EditorContext';
import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { PlayerKeys, EAssetType } from '~containers/EditorPageContainer/types';
import { EZoomLevel } from '~services/zoomLevelService';
import { addClipInMarker, addClipOutMarker, setActiveClip, setPreviewClip } from '~src/store/timeline/clip/clip.actions';
import { addThumbnail } from '~src/store/timeline/thumbnail/thumbnail.actions';
import { FeatureDictionary, Seconds } from '~src/types';

interface IContainerProps {
    setPreviewMode: VoidFunction;
    setEditMode: VoidFunction;
    hlsStreamUrl: string;
    assetType: EAssetType;
}

interface IProps extends IContainerProps {
    // player
    currentTime: Seconds;
    seekable: {
        start: Seconds;
        end: Seconds;
    };
    isSeeking: boolean;
    frameRate: number;
    getCurrentEditorTime: () => number;
    seek: (key: PlayerKeys, position: number) => void;
    // media meta
    live: boolean;
    // editor
    zoomLevel: EZoomLevel;
    features: FeatureDictionary;
    captureImageData: VoidFunction;
    dispatch: Dispatch;
}

interface ITimelineContainerState {
    currentTime: Seconds;
    isPreSeeking: boolean;
}

export class TimelineContainerComponent extends React.Component<IProps, ITimelineContainerState> {
    public state = {
        currentTime: 0,
        isPreSeeking: false,
    };

    public static getDerivedStateFromProps(props: IProps, state) {
        const { currentTime: playerCurrentTime, isSeeking } = props;
        const { currentTime: userPreSeekTime, isPreSeeking } = state;
        const currentTime = isSeeking || isPreSeeking ? userPreSeekTime : playerCurrentTime;
        return {
            currentTime,
            isPreSeeking: false,
        };
    }

    public setTimelineTime = (timeSeconds: number) => {
        const { frameRate, seek } = this.props;
        // seek to best guess frame snapping
        const modulus = timeSeconds % (1 / frameRate);
        const seekTime = Math.round((timeSeconds - modulus) * 100) / 100;
        this.setState({
            currentTime: seekTime,
            isPreSeeking: true,
        });
        seek(PlayerKeys.EDITOR, seekTime);
    };

    private addClipInMarker = () => {
        const { currentTime } = this.state;
        const { getCurrentEditorTime, captureImageData, dispatch } = this.props;
        const editorTime = getCurrentEditorTime();

        // add callback to action for thumbnail capture
        const action = addClipInMarker(currentTime, editorTime);
        dispatch(action);

        // trigger call for imageData
        captureImageData();
    };

    private resetActiveClipId = () => {
        const action = setActiveClip(null);
        this.props.dispatch(action);
    };

    private resetPreviewClipId = () => {
        const { dispatch, setEditMode } = this.props;
        const action = setPreviewClip(null);
        dispatch(action);
        setEditMode();
    };

    private addClipOutMarker = () => {
        const { currentTime } = this.state;
        const { getCurrentEditorTime, dispatch } = this.props;
        const editorTime = getCurrentEditorTime();
        const action = addClipOutMarker(currentTime, editorTime);
        dispatch(action);
    };

    private addThumbnail = () => {
        const { currentTime } = this.state;
        const { getCurrentEditorTime, captureImageData, dispatch } = this.props;
        const editorTime = getCurrentEditorTime();

        // dispatch new thumbnail
        const action = addThumbnail(currentTime, editorTime);
        dispatch(action);

        // trigger call for imageData
        captureImageData();
    };

    public render() {
        const {
            seekable: { start, end },
            zoomLevel,
            live,
            setPreviewMode,
            assetType,
            hlsStreamUrl,
        } = this.props;
        const { currentTime } = this.state;

        return (
            (start !== 0 || end !== 0) && (
                <React.Fragment>
                    <Timeline
                        zoomLevel={zoomLevel}
                        currentTime={currentTime}
                        assetType={assetType}
                        rawTimelineStart={start}
                        rawTimelineEnd={end}
                        hlsStreamUrl={hlsStreamUrl}
                        isLive={live}
                        setTimelineTime={this.setTimelineTime}
                    />

                    <KeyboardControls
                        addClipInMarker={this.addClipInMarker}
                        addClipOutMarker={this.addClipOutMarker}
                        resetActiveClipId={this.resetActiveClipId}
                        resetPreviewClipId={this.resetPreviewClipId}
                        addThumbnail={this.addThumbnail}
                        setPreviewMode={setPreviewMode}
                    />
                </React.Fragment>
            )
        );
    }
}

const TimelineContainerWrapper: React.FunctionComponent<IContainerProps> = ({ setPreviewMode, setEditMode, assetType, hlsStreamUrl }) => {
    const dispatch = useDispatch();
    const { features } = React.useContext(RootContext);
    const { zoomLevel, captureImageData } = React.useContext(EditorContext);
    const {
        seek,
        getCurrentEditorTime,
        editorPlayer: { seekable, currentTime, waiting, live, frameRate },
    } = React.useContext(VideoContext);

    return (
        <TimelineContainerComponent
            currentTime={currentTime}
            seekable={seekable}
            isSeeking={waiting}
            frameRate={frameRate}
            getCurrentEditorTime={getCurrentEditorTime}
            seek={seek}
            hlsStreamUrl={hlsStreamUrl}
            assetType={assetType}
            live={live}
            zoomLevel={zoomLevel}
            setPreviewMode={setPreviewMode}
            setEditMode={setEditMode}
            features={features}
            captureImageData={captureImageData}
            dispatch={dispatch}
        />
    );
};

export { TimelineContainerWrapper };
