import * as React from 'react';

import { joinStrings } from '~services/utilities';

import './index.scss';

enum LoadingSpinnerSize {
    SMALL = 'small',
}

interface IProps {
    size?: LoadingSpinnerSize;
}

const LoadingSpinner: React.FC<IProps> = ({ size }) => {
    const classNames = joinStrings(['loading-spinner', size === LoadingSpinnerSize.SMALL && 'loading-spinner--small']);
    return (
        <div className={classNames} data-testid="loading-spinner">
            <div className="static-ring" />
            <div className="animate" />
            <div className="animate" />
            <div className="animate" />
            <div className="animate" />
            <div className="animate" />
        </div>
    );
};

export { LoadingSpinner, LoadingSpinnerSize };

export default LoadingSpinner;
