import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ChannelAdminViewModal } from '~components/ChannelManager/ChannelDetails/ChannelAdminViewModal';
import { ChannelDeleteModal } from '~components/ChannelManager/ChannelDetails/ChannelDeleteModal';
import { CalendarScheduleIcon } from '~components/Icons/CalendarSchedule';
import { ChevronRight } from '~components/Icons/ChevronRight';
import { CopyPasteFilledIcon } from '~components/Icons/CopyPasteFilledIcon';
import { CopyPasteIcon } from '~components/Icons/CopyPasteIcon';
import { LongArrowIcon } from '~components/Icons/LongArrowIcon';
import { MultipleMoveFilledIcon } from '~components/Icons/MultipleMoveFilledIcon';
import { MultipleMoveIcon } from '~components/Icons/MultipleMoveIcon';
import { TrashBoldFilledIcon } from '~components/Icons/TrashBoldFilledIcon';
import { TrashBoldIcon } from '~components/Icons/TrashBoldIcon';
import { LinkListDropdownMenu } from '~components/LinkListDropdownMenu';
import { ILinkMenuItem } from '~components/LinkListDropdownMenu/MenuItem';
import { DropdownAlign, SimpleDropdownMenu } from '~components/SimpleDropdownMenu';
import { useControls } from '~containers/ChannelManagerContainer/ChannelManagerContext';
import { IChannelManagerContext } from '~containers/ChannelManagerContainer/types';
import { authenticationService } from '~services/authentication';
import { convertSecondsToDurationLabel, DURATION_DISPLAY_UNITS_START_WITH_MONTH, joinStrings } from '~services/utilities';
import { closedActiveModal, openedActiveModal, toggleBatchActionMode } from '~src/store/channelManager/channelManager.actions';
import {
    getBatchActionState,
    getChannelAdminDetailsState,
    getChannelDetailsState,
    getMuxerVersionsState,
    getPendingPublishPodNumbersState,
    getFeatureFlagsState,
} from '~src/store/channelManager/channelManager.selectors';
import { BatchActionMode } from '~src/store/channelManager/constants';
import { IBatchAction, IChannelDetailsState, IChannelManagerState, IChannelSettings } from '~src/store/channelManager/types';
import { Role } from '~src/types';
import { KeyCode } from '~src/views/types';

import { ChannelSettingsModal } from './ChannelSettingsModal';
import './index.scss';
import './index.scss';

interface IProps extends IChannelDetailsState {
    batchActionMode: IBatchAction['mode'];
    hasPendingChangesToPublish: boolean;
    onGoBack: VoidFunction;
    onAddContent: IChannelManagerContext['onAddContent'];
    onSneekPreviewSchedule: IChannelManagerContext['onSneekPreviewSchedule'];
    onToggleBatchActionMode: IChannelManagerContext['onToggleBatchActionMode'];
    onChannelSettingsChanged: IChannelManagerContext['onChannelSettingsChanged'];
    channelAdminDetails: IChannelManagerState['channelAdminDetails'];
    onSynchroniseChannelStreamingConfigs: IChannelManagerContext['onSynchroniseChannelStreamingConfigs'];
    onProvisionMuxer: IChannelManagerContext['onProvisionMuxer'];
    onMuxerAction: IChannelManagerContext['onMuxerAction'];
    onSetChannelDgeEventId: IChannelManagerContext['onSetChannelDgeEventId'];
    onRefreshChannelAdminDetails: IChannelManagerContext['onRefreshChannelAdminDetails'];
    onCancelMuxerAction: IChannelManagerContext['onCancelMuxerAction'];
    onCheckMuxerStatus: IChannelManagerContext['onCheckMuxerStatus'];
    muxerVersions: IChannelManagerState['muxerVersions'];
    featureFlags: IChannelManagerState['featureFlags'];
}

const ChannelDetailsComponent: React.FC<IProps> = (props) => {
    const {
        channelId,
        title,
        channelLogoUrl,
        logo,
        timeRemaining,
        queuedContentPods,
        hasPendingChangesToPublish,
        targetAdvertDuration,
        enableBackupStream,
        onSynchroniseChannelStreamingConfigs,
        onProvisionMuxer,
        onMuxerAction,
        onSetChannelDgeEventId,
        onRefreshChannelAdminDetails,
        onCancelMuxerAction,
        onCheckMuxerStatus,
        onGoBack,
    } = props;
    const [showChannelSettingsModal, setShowChannelSettingsModal] = React.useState(false);
    const [showDeleteChannelModal, setShowDeleteChannelModal] = React.useState(false);
    const [showChannelAdminViewModal, setShowChannelAdminViewModal] = React.useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        const modalOpened = showChannelSettingsModal || showDeleteChannelModal || showChannelAdminViewModal;
        if (modalOpened) {
            dispatch(openedActiveModal());
        } else {
            dispatch(closedActiveModal());
        }
    }, [dispatch, showChannelSettingsModal, showDeleteChannelModal, showChannelAdminViewModal]);

    const initialMenuOptions = [{ title: 'EDIT CHANNEL', callback: onEditChannelSettings }];

    const [menuOptions, setMenuOptions] = React.useState<ILinkMenuItem[]>(initialMenuOptions);

    useEffect(() => {
        const setAdminView = async () => {
            const role = await authenticationService.getRole();
            if (role === Role.ADMIN) {
                setMenuOptions([
                    ...initialMenuOptions,
                    { title: 'ADMIN VIEW', callback: onShowAdminView },
                    { title: 'DELETE CHANNEL', callback: onShowDeleteChannelModel },
                ]);
            }
        };

        setAdminView();
    }, []);

    const isInBatchMode = !!props.batchActionMode;
    const isInCopyPasteMode = props.batchActionMode === BatchActionMode.COPY_PASTE;
    const isInMoveMode = props.batchActionMode === BatchActionMode.MULTIPLE_MOVE;
    const isInDeleteMode = props.batchActionMode === BatchActionMode.MULTIPLE_DELETE;

    function onEditChannelSettings() {
        setShowChannelSettingsModal(true);
    }

    function onShowAdminView() {
        setShowChannelAdminViewModal(true);
    }

    const onShowDeleteChannelModel = () => {
        setShowDeleteChannelModal(true);
    };

    const onHideDeleteChannelModel = () => {
        setShowDeleteChannelModal(false);
    };

    const handleSettingsChangeDiscard = () => {
        setShowChannelSettingsModal(false);
    };

    const onToggleBatchMode = (mode: BatchActionMode) => () => {
        dispatch(toggleBatchActionMode(mode));
    };

    const handleAdminViewChangeDiscard = () => {
        setShowChannelAdminViewModal(false);
    };

    const handleSettingsChangeConfirm = (updatedSettings: IChannelSettings) => {
        setShowChannelSettingsModal(false);
        props.onChannelSettingsChanged(updatedSettings);
    };

    const handleScheduleShortcut = ({ keyCode, shiftKey, ctrlKey }: KeyboardEvent) => {
        if (shiftKey && ctrlKey && keyCode === KeyCode.Y) {
            props.onSneekPreviewSchedule();
        }
    };

    React.useEffect(() => {
        window.addEventListener('keypress', handleScheduleShortcut);

        return () => {
            window.removeEventListener('keypress', handleScheduleShortcut);
        };
    }, []);

    const handleAddContent = () => {
        props.onAddContent();
    };

    return (
        <>
            {showDeleteChannelModal && (
                <ChannelDeleteModal
                    channelLogoUrl={channelLogoUrl}
                    channelTitle={title}
                    channelId={channelId}
                    onCancel={onHideDeleteChannelModel}
                    onDelete={onGoBack}
                />
            )}
            {showChannelSettingsModal && (
                <ChannelSettingsModal
                    canEditSettings={!hasPendingChangesToPublish && !isInBatchMode}
                    canEditTargetAdvertDuration={true}
                    channelTitle={title}
                    onCancel={handleSettingsChangeDiscard}
                    onConfirm={handleSettingsChangeConfirm}
                    editState={{
                        targetAdvertDuration,
                        title,
                        logo,
                        enableBackupStream,
                    }}
                />
            )}
            {showChannelAdminViewModal && (
                <ChannelAdminViewModal
                    channelLogoUrl={channelLogoUrl}
                    channelTitle={title}
                    onCancel={handleAdminViewChangeDiscard}
                    channelAdminDetails={props.channelAdminDetails}
                    muxerVersions={props.muxerVersions}
                    onSynchroniseChannelStreamingConfigs={onSynchroniseChannelStreamingConfigs}
                    onProvisionMuxer={onProvisionMuxer}
                    onMuxerAction={onMuxerAction}
                    onSetChannelDgeEventId={onSetChannelDgeEventId}
                    onRefreshChannelAdminDetails={onRefreshChannelAdminDetails}
                    onCancelMuxerAction={onCancelMuxerAction}
                    onCheckMuxerStatus={onCheckMuxerStatus}
                />
            )}
            <section className="channel-details">
                <button className="channel-details__back-button" onClick={onGoBack}>
                    <LongArrowIcon />
                </button>
                <div className="channel-details__channel-info-wrapper">
                    <div className="channel-details__controls channel-info channel-info--is-row ">
                        <span className="channel-info__icon">{channelLogoUrl && <img src={channelLogoUrl} width={44} />}</span>
                        <span className="channel-info__value channel-info__title-wrapper">
                            {title}
                            <LinkListDropdownMenu menuItems={menuOptions} className="channel-details__settings-menu" />
                        </span>
                    </div>
                    <div className="channel-info__divider" />
                    <div className="channel-info channel-details__videos-count">
                        <span className="channel-info__value">{queuedContentPods}</span>
                        <span className="channel-info__label">Videos</span>
                    </div>
                    <div className="channel-info__divider" />
                    <div className="channel-info">
                        <span className="channel-info__value">
                            {convertSecondsToDurationLabel(timeRemaining, DURATION_DISPLAY_UNITS_START_WITH_MONTH)}
                        </span>
                        <span className="channel-info__label">Duration Remaining</span>
                    </div>
                    <div className="channel-info__divider" />
                    <button className="channel-details__add-content-button" disabled={isInBatchMode} onClick={handleAddContent}>
                        Add Content
                    </button>
                    <SimpleDropdownMenu
                        triggerChildren={EditButton}
                        dropdownAlign={DropdownAlign.CENTER}
                        dropdownClassName="channel-details__edit-dropdown"
                        triggerClassName="channel-details__edit-button"
                        className="channel-details__edit-menu"
                    >
                        <button
                            className={joinStrings([
                                'channel-details__batch-button',
                                isInCopyPasteMode && 'channel-details__batch-button--is-active',
                            ])}
                            onClick={onToggleBatchMode(BatchActionMode.COPY_PASTE)}
                            title="Copy & Paste Mode"
                        >
                            {isInCopyPasteMode ? <CopyPasteFilledIcon /> : <CopyPasteIcon />}
                        </button>
                        <button
                            className={joinStrings([
                                'channel-details__batch-button',
                                isInMoveMode && 'channel-details__batch-button--is-active',
                            ])}
                            onClick={onToggleBatchMode(BatchActionMode.MULTIPLE_MOVE)}
                            title="Multiple Move Mode"
                        >
                            {isInMoveMode ? <MultipleMoveFilledIcon /> : <MultipleMoveIcon />}
                        </button>
                        <button
                            className={joinStrings([
                                'channel-details__batch-button',
                                isInDeleteMode && 'channel-details__batch-button--is-active',
                            ])}
                            onClick={onToggleBatchMode(BatchActionMode.MULTIPLE_DELETE)}
                            title="Multiple Delete Mode"
                        >
                            {isInDeleteMode ? <TrashBoldFilledIcon /> : <TrashBoldIcon />}
                        </button>
                    </SimpleDropdownMenu>
                    <button
                        className="channel-details__schedule-button"
                        disabled={isInBatchMode}
                        onClick={props.onSneekPreviewSchedule}
                        title="Calendar View"
                    >
                        <CalendarScheduleIcon />
                    </button>
                </div>
            </section>
        </>
    );
};

export const ChannelDetails: React.FC = () => {
    const channelDetails = useSelector(getChannelDetailsState);
    const channelAdminDetails = useSelector(getChannelAdminDetailsState);
    const muxerVersions = useSelector(getMuxerVersionsState);
    const featureFlags = useSelector(getFeatureFlagsState);
    const pendingPublish = useSelector(getPendingPublishPodNumbersState);
    const batchAction = useSelector(getBatchActionState);
    const {
        onAddContent,
        onToggleBatchActionMode,
        onChannelSettingsChanged,
        onSynchroniseChannelStreamingConfigs,
        onFullScreenPreviewSchedule,
        onProvisionMuxer,
        onMuxerAction,
        onSetChannelDgeEventId,
        onRefreshChannelAdminDetails,
        onCancelMuxerAction,
        onCheckMuxerStatus,
    } = useControls();
    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <ChannelDetailsComponent
            {...channelDetails}
            channelAdminDetails={channelAdminDetails}
            muxerVersions={muxerVersions}
            batchActionMode={batchAction.mode}
            hasPendingChangesToPublish={!!pendingPublish.length}
            onAddContent={onAddContent}
            onSynchroniseChannelStreamingConfigs={onSynchroniseChannelStreamingConfigs}
            onProvisionMuxer={onProvisionMuxer}
            onMuxerAction={onMuxerAction}
            onSetChannelDgeEventId={onSetChannelDgeEventId}
            onRefreshChannelAdminDetails={onRefreshChannelAdminDetails}
            onCancelMuxerAction={onCancelMuxerAction}
            onCheckMuxerStatus={onCheckMuxerStatus}
            onGoBack={handleGoBack}
            onToggleBatchActionMode={onToggleBatchActionMode}
            onChannelSettingsChanged={onChannelSettingsChanged}
            onSneekPreviewSchedule={onFullScreenPreviewSchedule} // Always full screen view for now
            featureFlags={featureFlags}
        />
    );
};

const EditButton = (
    <>
        Edit <ChevronRight width={20} />
    </>
);
