import { http } from '~services/http';
import { FeatureDictionary, MilliSeconds } from '~src/types';

import { Endpoints } from './constants';

interface IAlgoliaSettings {
    apiKey: string;
    appName: string;
    index: string;
}

interface ISettingsSettings {
    minimumClipDuration?: MilliSeconds;
    minimumClipSeparation?: MilliSeconds;
}

interface ISettings {
    searchProviders: {
        algolia: IAlgoliaSettings;
    };
    features: FeatureDictionary;
    settings: ISettingsSettings;
}

interface ISettingsResponse {
    parsedData: ISettings;
}

type TAlgoliaSettings = IAlgoliaSettings;

const getSettings = async (): Promise<Partial<ISettings>> => {
    try {
        const { parsedData }: ISettingsResponse = await http.get(Endpoints.GET);
        return parsedData;
    } catch (e) {
        return {};
    }
};

export { getSettings, TAlgoliaSettings, IAlgoliaSettings, ISettingsSettings };
