import { IDgeStreamMetaResponse, StreamType } from '~services/live/types';
import { isStreamUrlValid } from '~src/services/live/utils/is-stream-url-valid';
import { IStreamUrl } from '~src/store/editor/stream/types';

export const extractDgeStreams = (
    streamUrls: IDgeStreamMetaResponse['hls'] | IDgeStreamMetaResponse['dash'],
    type: StreamType,
    drm: boolean
): IStreamUrl[] => {
    return streamUrls
        .filter((streamUrl) => isStreamUrlValid(type, streamUrl))
        .map(({ url, drm: { contentId = null, algorithm } = {} }) => ({
            url,
            type,
            drm,
            contentId,
            algorithm,
        }));
};
