import * as React from 'react';

import { CheckSolidIcon } from '~components/Icons/CheckSolidIcon';

import './index.scss';

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string | JSX.Element;
}

const Checkbox: React.NamedExoticComponent<IProps> = React.memo(({ label, ...otherProps }: IProps) => {
    return (
        <div className="custom-checkbox">
            <input {...otherProps} type="checkbox" />
            <span className="custom-checkbox__checkmark">
                <CheckSolidIcon height="10" width="10" />
            </span>
            {!!label && <span className="custom-checkbox__label">{label}</span>}
        </div>
    );
});

export { Checkbox };
