import * as React from 'react';

import { PageTheme } from '~components/Root/types';

type TTitle = string;

interface IProps {
    title: TTitle;
    theme: PageTheme;
}

const PAGE_THEME_CLASSNAME = {
    [PageTheme.DARK]: 'page--dark',
    [PageTheme.LIGHT]: 'page--light',
    [PageTheme.DARK_BLUE]: 'page--dark-blue',
};

const useDocumentTitle = (title: TTitle) => {
    React.useEffect(() => {
        document.title = title;
    }, [title]);
};

const useBodyClassName = (theme: PageTheme) => {
    React.useEffect(() => {
        // Add a class to the body.
        document.body.classList.add(PAGE_THEME_CLASSNAME[theme]);
        return () => {
            // Remove the class from the body.
            document.body.classList.remove(PAGE_THEME_CLASSNAME[theme]);
        };
    }, [theme]);
};

const Page: React.FunctionComponent<IProps> = ({ title, theme, children }) => {
    const { STD__APP_NAME: appName } = process.env;
    const documentTitle = `${appName}${title ? ` - ${title}` : ''}`;
    useDocumentTitle(documentTitle);
    useBodyClassName(theme);
    // <div /> is required as it is mutated by <CSSTransition /> to inject page transition classes
    return <div>{children}</div>;
};

export { Page };
