import * as React from 'react';
import * as ReactDOM from 'react-dom';

import TimesThinIcon from '~components/Icons/TimesThinIcon';
import Modal from '~components/Modal';

interface IProps {
    className: string;
    confirmButtonClassName: string;
    closeButtonClassName: string;
    confirmButtonTitle: string;
    message: string;
    onConfirm: (event: React.MouseEvent) => void;
    onCancel: (event: React.MouseEvent) => void;
}

export const ConfirmModal: React.FunctionComponent<IProps> = (props) => {
    const { className, message, confirmButtonTitle, confirmButtonClassName, closeButtonClassName } = props;

    const $component = (
        <Modal.Main hasOverlay={true} className={className}>
            <button className={closeButtonClassName} onClick={props.onCancel}>
                <TimesThinIcon />
            </button>
            {message !== null && <Modal.Body justify="center">{message}</Modal.Body>}

            <div className="text-center">
                <button className={confirmButtonClassName} type="button" onClick={props.onConfirm}>
                    {confirmButtonTitle}
                </button>
            </div>
        </Modal.Main>
    );

    return ReactDOM.createPortal($component, document.getElementById('modal'));
};
