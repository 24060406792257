import { FeedbackActions } from './constants';
import { IFeedbackState } from './types';

const DEFAULT_STATE: IFeedbackState = {
    list: [],
};

const feedbackReducer = (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FeedbackActions.ADD:
            return {
                list: state.list.concat(payload),
            };
        case FeedbackActions.DISMISS:
            const feedbackList = state.list.filter((feedback) => feedback.id !== payload.id);

            return {
                list: feedbackList,
            };
        default:
            return state;
    }
};

export { feedbackReducer };
