import { AuthenticationLink } from '@dicetechnology/dice-backoffice-ui-components';

import { PageRoutes } from '~components/Root/constants';

export const AUTHENTICATION_LINKS: AuthenticationLink[] = [
    {
        label: 'Forgot your password',
        to: PageRoutes.FORGOTTEN_PASSWORD,
    },
];
