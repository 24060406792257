import * as React from 'react';

import { Badge } from '~components/Badge';
import { CardDVELink } from '~components/Card/CardDVELink';
import { CardDescription } from '~components/Card/CardDescription';
import { CardPreviewLink } from '~components/Card/CardPreviewLink';
import { CardThumbnail } from '~components/Card/CardThumbnail';
import { CardTitle } from '~components/Card/CardTitle';
import { getAssetUrl, getFormattedDuration, getThumbnailUrl, isDveLinkDefined } from '~components/Card/utilities';
import { RootContext } from '~components/Root/context';
import { SmartAnchor } from '~components/SmartAnchor';
import { joinStrings } from '~services/utilities';
import { IVod } from '~services/vod/types';
import { ICardSettings } from '~src/types';

import './index.scss';

interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    settings: ICardSettings;
    card: IVod;
    onPreviewClick: (canCreateClips: boolean, externalUrl: string) => (event: React.MouseEvent) => void;
}

interface IProps extends IContainerProps {
    isDebugMode: boolean;
}

const EDITOR_VOD_VIDEO_URL = '/editor/vod/{assetId}';

const CardComponent: React.FunctionComponent<IProps> = ({ className, settings, card, onPreviewClick, isDebugMode }) => {
    const cardClasses = joinStrings(['card', settings.layout ? `card--${settings.layout}` : 'card--vertical', className]);
    const hasDveLink = isDveLinkDefined(card.externalUrl);
    const thumbnailSrc = getThumbnailUrl(card.thumbnail, settings);

    return (
        <div className={cardClasses} data-test-id="vodCard">
            <SmartAnchor to={getAssetUrl(EDITOR_VOD_VIDEO_URL, card.id)} className="card-content">
                <CardThumbnail
                    thumbnailSrc={thumbnailSrc}
                    thumbnailHeight={settings.height}
                    thumbnailWidth={settings.width}
                    dataTestId="vodCard-thumbnail"
                >
                    <div className="card__tag-wrapper">
                        {isDebugMode && card.draft !== undefined && (
                            <div className="card__status-badge">
                                <Badge text={card.draft ? 'Draft' : 'Published'} />
                            </div>
                        )}
                        <div className="card__tag card__tag--time">{getFormattedDuration(card.duration)}</div>
                    </div>
                </CardThumbnail>
                <CardDescription>
                    <CardTitle dataTestId="vodCard-title">{card.title}</CardTitle>
                </CardDescription>
            </SmartAnchor>

            <ul className="inline-list card__actions">
                <li className="list-item">
                    <CardPreviewLink
                        onClick={onPreviewClick(true, hasDveLink && card.externalUrl)}
                        tooltipText="Preview vod"
                        dataTestId="vodCard-previewLink"
                    />
                </li>
                {hasDveLink && (
                    <li className="list-item">
                        <CardDVELink externalUrl={card.externalUrl} dataTestId="vodCard-dveLink" />
                    </li>
                )}
            </ul>
        </div>
    );
};

const CardMemo: React.NamedExoticComponent<IProps> = React.memo(CardComponent);

const VodCard: React.FunctionComponent<IContainerProps> = ({ className, settings, card, onPreviewClick }) => {
    const { isDebugMode } = React.useContext(RootContext);
    return <CardMemo className={className} settings={settings} card={card} onPreviewClick={onPreviewClick} isDebugMode={isDebugMode} />;
};

export { VodCard };
