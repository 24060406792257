import * as React from 'react';

import { joinStrings } from '~services/utilities';

import './index.scss';

enum BadgeType {
    Error = 'error',
}

interface IBadgeProps {
    text?: string;
    type?: BadgeType;
}

const Badge: React.FunctionComponent<IBadgeProps> = ({ text, type, children }) => {
    const className = joinStrings(['badge', type === BadgeType.Error && 'badge--error']);

    return <div className={className}>{text || children}</div>;
};

export { Badge, BadgeType };
