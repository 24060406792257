import * as moment from 'moment';
import * as React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { IEndLabel } from '~components/ChannelManager/PodScroller/types';
import { IContentPod } from '~services/channels/types';
import { joinStrings } from '~services/utilities';

import { PodCard } from '.';

interface IProps {
    className?: string;
    style: React.CSSProperties;
    itemIndex: number;
    item: IContentPod;
    showUSDateTimeFormat: boolean;
    prev: IContentPod | IEndLabel;
    next: IContentPod | IEndLabel;
}

export enum PodDayIdentifier {
    START = 'START',
    LAST = 'LAST',
}

const DATE_DISPLAY_FORMAT = 'dddd, Do MMMM';

export const SortableWrapper = SortableElement((props: IProps) => {
    const { style, item, itemIndex, showUSDateTimeFormat, next, prev } = props;

    const formattedDateLabel = moment(item.startTime).startOf('day').format(DATE_DISPLAY_FORMAT);
    const dataProps = {};

    if (!item.isRemoved) {
        dataProps['data-pod-item-date'] = formattedDateLabel;
    }

    if (item.isFirstOfDay || item.isLastOfDay) {
        dataProps['data-day'] = item.isFirstOfDay ? PodDayIdentifier.START : PodDayIdentifier.LAST;
    }

    return (
        <div className="sortable-item" style={style} data-pod-item-index={itemIndex} {...dataProps}>
            {(item.isFirstOfDay || item.isLastOfDay) && (
                <div className={joinStrings(['sortable-item__date-display', item.isLastOfDay && 'sortable-item__date-display--end'])} />
            )}
            <PodCard {...item} showUSDateTimeFormat={showUSDateTimeFormat} next={next} prev={prev} />
        </div>
    );
});

SortableWrapper.displayName = 'SortableWrapper';
