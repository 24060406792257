import * as React from 'react';

import EditorContainer from '~containers/EditorPageContainer';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { Page } from '~src/views/layout/Page';

import './styles.scss';

interface IProps {
    assetType: EAssetType;
}

const EditorPage: React.NamedExoticComponent<IProps> = React.memo(({ assetType }: IProps) => {
    return (
        <Page showFooter={false}>
            <EditorContainer assetType={assetType} />
        </Page>
    );
});

export { EditorPage };
