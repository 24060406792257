import * as React from 'react';

import { EyeIcon } from '~components/Icons/EyeIcon';
import { PlusIcon } from '~components/Icons/PlusIcon';
import { TickIcon } from '~components/Icons/TickIcon';
import { IVllContentBase } from '~services/channels/types';
import { joinStrings } from '~services/utilities';
import { KeyCode } from '~src/views/types';

import { ContentCardPreview } from '../../ContentCardPreview';
import './index.scss';

interface IProps {
    content: IVllContentBase;
    onPreview: VoidFunction;
    onSelect: VoidFunction;
}

const JUST_ADDED_TIMEOUT = 500;

export const SearchResult: React.FC<IProps> = (props) => {
    const [justAdded, setJustAdded] = React.useState<boolean>(false);
    const justAddedTimer = React.useRef<number>(null);

    React.useEffect((): void | VoidFunction => {
        if (justAdded) {
            justAddedTimer.current = window.setTimeout(() => {
                setJustAdded(false);
            }, JUST_ADDED_TIMEOUT);

            return () => {
                clearTimeout(justAddedTimer.current);
            };
        }
    }, [justAdded]);

    const handleOnSelect = () => {
        if (!justAdded) {
            setJustAdded(true);
            props.onSelect();
        }
    };

    const handleOnPreview = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onPreview();
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.which === KeyCode.ENTER) {
            handleOnSelect();
        }
    };

    return (
        <div
            className={joinStrings(['search-result', justAdded && 'search-result--just-added'])}
            onClick={handleOnSelect}
            onKeyPress={handleKeyPress}
            tabIndex={1}
        >
            <span className="search-result__add-button">{justAdded ? <TickIcon /> : <PlusIcon />}</span>

            <ContentCardPreview {...props.content}>
                <button className="content-card__preview-pod-button" onClick={handleOnPreview} tabIndex={-1}>
                    <EyeIcon width={20} />
                    Preview pod
                </button>
            </ContentCardPreview>
        </div>
    );
};
