import * as React from 'react';

import { Button, ButtonTheme } from '~components/Button';
import { getFormattedDuration } from '~components/Card/utilities';
import { ExportClipPreview } from '~components/Editor/ExportClip/Components/ExportClipPreview';
import Modal, { TextJustify } from '~components/Modal';
import { ClipUtilities } from '~src/store/timeline/clip/clip.utilities';
import { IExportClipWithMeta } from '~src/store/timeline/clip/types';

interface IComponentProps {
    clips: IExportClipWithMeta[];
    back: VoidFunction;
    submit: VoidFunction;
}

const ConfirmCreate: React.FunctionComponent<IComponentProps> = ({ clips, back, submit }) => {
    const isSingular = clips.length === 1;

    const renderClip = (clip: IExportClipWithMeta): JSX.Element => {
        const {
            meta: { id, title, thumbnail, duration },
        } = clip;

        const badgeText = ClipUtilities.isCombined(clip) && `Combined - ${ClipUtilities.getComponentCount(clip)} clips`;
        const formattedDuration = getFormattedDuration(duration);
        return (
            <div className="clip-picker__item" key={id}>
                <ExportClipPreview title={title} thumbnail={thumbnail} duration={formattedDuration} badgeText={badgeText} />
            </div>
        );
    };

    return (
        <React.Fragment>
            <Modal.Title justify={TextJustify.CENTER}>
                Are you sure you want to create {isSingular ? 'a new clip' : 'new clips'}?
            </Modal.Title>
            <Modal.Body className="export-modal__confirm-clip-create">
                <div className="export-modal__step-description">
                    <p>
                        You have chosen to create {isSingular ? 'a new clip' : 'new clips'}, please verify the{' '}
                        {isSingular ? 'content is' : 'contents are'} accurate.
                    </p>
                </div>
                <div className="clip-picker">
                    <div className="clip-picker__single-row">{clips.map(renderClip)}</div>
                </div>
                <div className="button-group mt-4 text-center">
                    <Button theme={ButtonTheme.BLUE} onClick={submit}>
                        Create {isSingular ? 'clip' : 'clips'}
                    </Button>
                    {back && (
                        <Button theme={ButtonTheme.BLACK} type="button" onClick={back}>
                            Back
                        </Button>
                    )}
                </div>
            </Modal.Body>
        </React.Fragment>
    );
};

export { ConfirmCreate };
