import * as React from 'react';

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    customOnBlur?: (e: any, errorMessages: string[]) => void;
    validation?: Array<(id: string, value: string) => string | void>;
}

const InputComponent = (props: IProps, ref?: any) => {
    const { onChange, onFocus, customOnBlur, onBlur, validation, ...inputProps } = props;

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (validation && customOnBlur) {
            const {
                currentTarget: { id, value, placeholder },
            } = event;

            const identifier = placeholder || id;

            const errorMessages = validation.map((validationMethod) => validationMethod(identifier, value)).filter((message) => !!message);
            customOnBlur(event, errorMessages as string[]);
            return;
        } else if (onBlur) {
            onBlur(event);
        }
    };

    return <input {...inputProps} onChange={onChange} onFocus={onFocus} onBlur={handleOnBlur} ref={ref} />;
};

const Input = React.forwardRef(InputComponent);

const AutoFocusInput = (props: IProps) => {
    const inputRef = React.useRef(null);

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    return <Input ref={inputRef as any} {...props} />;
};

export { Input, AutoFocusInput };
