import * as React from 'react';

import CaretLeftIcon from '~components/Icons/CaretLeftIcon';
import CaretRightIcon from '~components/Icons/CaretRightIcon';
import Modal from '~components/Modal';
import { RootContext } from '~components/Root/context';
import { FeatureDictionary } from '~src/types';

import './index.scss';

interface IProps {
    isOpen: boolean;
    toggleIsOpen: () => void;
    features: FeatureDictionary;
}

const HotKeysComponent: React.FunctionComponent<IProps> = ({ isOpen, toggleIsOpen, features }) => {
    return (
        isOpen && (
            <Modal.Main onClose={toggleIsOpen} hasOverlay={true} closeWithEscape={true} className="shortcuts-modal">
                <Modal.Title>Shortcuts</Modal.Title>
                <Modal.Body>
                    <div className="shortcuts-modal__shortcuts">
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <CaretLeftIcon />
                            </div>
                            <h4>Previous frame</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <CaretRightIcon />
                            </div>
                            <h4>Next frame</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn editor__btn-space">
                                <span>space</span>
                            </div>
                            <h4>Play/Pause</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>Esc</span>
                            </div>
                            <h4>Close window</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>I</span>
                            </div>
                            <h4>Set in marker</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>O</span>
                            </div>
                            <h4>Set out marker</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>P</span>
                            </div>
                            <h4>Preview clip</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>R</span>
                            </div>
                            <h4>Reset markers</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>T</span>
                            </div>
                            <h4>Set thumbnail</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>F</span>
                            </div>
                            <h4>Toggle fullscreen</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>1</span>
                            </div>
                            <h4>10 Seconds</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>2</span>
                            </div>
                            <h4>1 Minute</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>3</span>
                            </div>
                            <h4>10 Minutes</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>4</span>
                            </div>
                            <h4>1 Hour</h4>
                        </div>
                        <div className="shortcut-key">
                            <div className="editor__btn">
                                <span>5</span>
                            </div>
                            <h4>Entire stream length</h4>
                        </div>
                    </div>
                </Modal.Body>
            </Modal.Main>
        )
    );
};

const HotKeysComponentMemo = React.memo(HotKeysComponent);

const HotKeys = (props) => {
    const { features } = React.useContext(RootContext);
    return <HotKeysComponentMemo features={features} {...props} />;
};

export { HotKeys };
