import * as React from 'react';

import { Button, ButtonTheme } from '~components/Button';
import CheckIcon from '~components/Icons/CheckIcon';
import ExclamationIcon from '~components/Icons/ExclamationIcon';
import { TimesIcon } from '~components/Icons/TimesIcon';
import Modal from '~components/Modal';
import { SaveAction } from '~containers/EditorPageContainer/types';
import { joinStrings } from '~services/utilities';

interface IProps {
    saveAction: SaveAction;
    errorCount: number;
    totalClips: number;
    close: VoidFunction;
}

enum ExportTitle {
    SUCCESS = 'Export Successful',
    WARNING = 'Export Warning',
    FAILED = 'Export Failed',
}

enum ExportMessages {
    SINGLE_SUCCESS = 'The clip exported successfully.',
    SINGLE_FAILED = 'There was a problem exporting the clip.',
    MULTI_SUCCESS = 'All clips exported successfully.',
    MULTI_FAILED = 'There was a problem exporting the clips.',
    MULTI_WARNING = '{{successCount}} of {{totalClips}} clips exported successfully.',
}

const Complete: React.FunctionComponent<IProps> = ({ saveAction, errorCount, totalClips, close }) => {
    const success = errorCount === 0;
    const warning = !!errorCount && errorCount < totalClips;
    const error = errorCount === totalClips;

    const getExportTitle = (): string => {
        if (success) {
            return ExportTitle.SUCCESS;
        } else if (warning) {
            return ExportTitle.WARNING;
        }
        return ExportTitle.FAILED;
    };

    const getExportMessage = (): string => {
        if (totalClips === 1) {
            return errorCount === 1 ? ExportMessages.SINGLE_FAILED : ExportMessages.SINGLE_SUCCESS;
        } else if (success) {
            return ExportMessages.MULTI_SUCCESS;
        } else if (error) {
            return ExportMessages.MULTI_FAILED;
        } else {
            const successCount = totalClips - errorCount;

            return ExportMessages.MULTI_WARNING.replace('{{successCount}}', successCount.toString()).replace(
                '{{totalClips}}',
                totalClips.toString()
            );
        }
    };

    const iconClassNames = joinStrings(['icon', warning && 'icon--warning', error && 'icon--error']);

    return (
        <Modal.Body justify="center" className="export-modal__complete">
            <div className={iconClassNames}>
                {success && <CheckIcon height="24" width="24" />}
                {warning && <ExclamationIcon height="24" width="24" />}
                {error && <TimesIcon height="24" width="24" />}
            </div>

            <Modal.Title>{getExportTitle()}</Modal.Title>

            <p>{getExportMessage()}</p>

            <div className="button-group mt-4 text-center">
                <Button theme={ButtonTheme.BLUE} type="button" onClick={close}>
                    Close
                </Button>
            </div>
        </Modal.Body>
    );
};

export { Complete };
