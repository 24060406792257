import { Console } from '~services/console';
import { http } from '~services/http';

import { Endpoints } from './constants';
import { IBEVersionResponse, IBEVersion, IFEVersion, IAppVersion } from './types';

const getFrontendVersion = (): IFEVersion => {
    const { STD__UI_VERSION, STD__DORIS_VERSION } = process.env;
    return {
        version: STD__UI_VERSION || null,
        player: STD__DORIS_VERSION || null,
    };
};

const getBackendVersion = async (): Promise<IBEVersion> => {
    try {
        const { parsedData }: IBEVersionResponse = await http.get(Endpoints.GET, { useAuth: false });
        return parsedData;
    } catch (e) {
        Console.warn("Processing 'Backend Version Meta'", e);
        return null;
    }
};

const getApplicationVersion = async (): Promise<IAppVersion> => {
    const frontend = getFrontendVersion();
    const backend = await getBackendVersion();
    return {
        frontend,
        backend,
    };
};

export { getApplicationVersion };
