import * as React from 'react';

import { getTimeLabel } from '~services/utilities';
import { Seconds, TimeFormats } from '~src/types';

import './index.scss';

interface IProps {
    currentTime: Seconds;
    duration: Seconds;
}
const TimeRange = (props: IProps) => {
    const { currentTime, duration } = props;
    return (
        <div className="time-range">
            <span className="time-range__elapsed-time">{getTimeLabel(currentTime, TimeFormats.HHmmssSS)}</span> /
            <span className="time-range__total-time">{getTimeLabel(duration, TimeFormats.HHmmssSS)}</span>
        </div>
    );
};

export { TimeRange };
