import { EAssetType } from '~containers/EditorPageContainer/types';
import { getClipsOfClip } from '~services/clip';
import { getClipsOfEvent } from '~services/live';
import { getClipsOfVod } from '~services/vod';
import { IContentType, IParsedPagingResponse, PagerService } from '~src/types';

type ServicePrimer = (size: number, eventId: number) => (lastSeen: number) => Promise<IParsedPagingResponse<IContentType>>;

const getRecentlyCreatedService = (assetType: EAssetType, pageSize: number, assetId: number): PagerService => {
    let service: ServicePrimer;

    if (assetType === EAssetType.LIVE) {
        service = getClipsOfEvent;
    } else if (assetType === EAssetType.CLIP) {
        service = getClipsOfClip;
    } else {
        service = getClipsOfVod;
    }

    return service(pageSize, assetId);
};

export { getRecentlyCreatedService };
