type FileExtension = string;

interface IExtensionTotals {
    /**
     * {
     *  jpg: count,
     *  png: count,
     * }
     */
    [key: string]: number;
}

class FileService {
    public static getExtensionTotals = (files: File[]): IExtensionTotals => {
        return files.reduce((map, file) => {
            const extension: FileExtension = FileService.getFIleExtension(file);
            map[extension] = (map[extension] || 0) + 1;
            return map;
        }, {});
    };

    public static dataURItoFile(dataUri: string, name: string, extension: FileExtension): File {
        const [mediaType, base64] = dataUri.split(';');
        const [, base64Value] = base64.split(',');
        const [, mimeString] = mediaType.split(':');

        const byteString: string = atob(base64Value);
        const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
        const intArray: ArrayBuffer = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }

        return new File([arrayBuffer], `${name}.${extension}`, { type: mimeString });
    }

    public static getFIleExtension({ name }: File): FileExtension {
        return name.split('.').pop();
    }
}

export { FileService };
