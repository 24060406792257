import * as React from 'react';

import { getTimeLabel, joinStrings } from '~services/utilities';
import { TimeFormats } from '~src/types';

const SubTickGroup: React.FunctionComponent<{ tickCount: number }> = ({ tickCount, children }) => {
    return (
        <React.Fragment>
            {Array(tickCount)
                .fill(undefined)
                .map((item, index) => (
                    <div key={index} className="sub-tick">
                        {children}
                    </div>
                ))}
        </React.Fragment>
    );
};

const MinorTickGroup: React.FunctionComponent<{ tickCount: number }> = ({ tickCount }) => {
    return (
        <React.Fragment>
            {Array(tickCount)
                .fill(undefined)
                .map((item, index) => (
                    <div key={index} className="minor-tick" />
                ))}
        </React.Fragment>
    );
};

interface IProps {
    style;
    label;
    columnIndex;
    columnWidth;
    isLive;
    finalLabel;
    subTickCount;
    minorTickCount;
}

const TimelineSegmentComponent: React.FunctionComponent<IProps> = ({
    style,
    label,
    columnIndex,
    columnWidth,
    isLive,
    finalLabel,
    subTickCount = 5,
    minorTickCount = 5,
}) => {
    const wrapperClassNames = joinStrings(['timeline__tick-wrapper', isLive && 'timeline__tick-wrapper--right']);
    const classNames = joinStrings(['timeline__tick__label', columnIndex === 0 && 'timeline__tick__label--left']);
    return (
        <div className={wrapperClassNames} style={style}>
            <span className={classNames}>{getTimeLabel(label, TimeFormats.HHmmss)}</span>
            {finalLabel !== null && (
                <span className="timeline__tick__label timeline__tick__label--right">{getTimeLabel(finalLabel, TimeFormats.HHmmss)}</span>
            )}

            <div className="timeline__tick timeline__tick--large" style={{ width: `${columnWidth}px` }}>
                <SubTickGroup tickCount={subTickCount}>
                    <MinorTickGroup tickCount={minorTickCount} />
                </SubTickGroup>
            </div>
        </div>
    );
};

const TimelineSegment: React.NamedExoticComponent<IProps> = React.memo(TimelineSegmentComponent);

export { TimelineSegment };
