import { IClientClipCreate, IClientClipUpdate } from '~services/clip/types';
import { httpV2, httpV3 } from '~services/http';
import { parsePagingRequest } from '~services/utilities';
import { VodEndpoints } from '~services/vod/constants';
import { IVod } from '~services/vod/types';
import { IParsedPagingResponse } from '~src/types';

interface IVodStudio {
    id: number;
    title: string;
    duration: number;
    streamUrl: string;
    externalUrl: string;
    thumbnail: string;
    draft: boolean;
}

interface IStudioVodResponse {
    parsedData: IVodStudio;
}

const getVods = (size: number) => async (lastSeen: number = null): Promise<IParsedPagingResponse<IVod>> => {
    const url = VodEndpoints.GET;
    return await parsePagingRequest(httpV2, url, size, lastSeen);
};

const getVodsByDveVideoIds = async (videoIdsList: number[]): Promise<IVod[]> => {
    const ids = videoIdsList.join(',');
    const {
        parsedData: { results },
    } = await httpV2.get(VodEndpoints.GET, { queryParams: { ids } });
    return results;
};

const getStudioVodMeta = async (vodId: number): Promise<IVodStudio> => {
    const endpoint = VodEndpoints.GET_BY_ID.replace('{id}', vodId.toString());
    const { parsedData }: IStudioVodResponse = await httpV3.get(endpoint);
    return parsedData;
};

const createClipFromVod = (vodId: IVod['id'], clip: IClientClipCreate): Promise<void> => {
    const endpoint = VodEndpoints.CREATE.replace('{id}', vodId.toString());
    return httpV3.post(endpoint, clip);
};

const overwriteClipFromVod = (vodId: IVod['id'], clip: IClientClipUpdate): Promise<void> => {
    const endpoint = VodEndpoints.OVERWRITE.replace('{id}', vodId.toString());
    return httpV3.put(endpoint, clip);
};

const getClipsOfVod = (size: number, vodId: number) => async (lastSeen: number = null): Promise<IParsedPagingResponse<IVod>> => {
    const endpoint = VodEndpoints.GET_CLIPS_BY_ID.replace('{id}', vodId.toString());
    return await parsePagingRequest(httpV2, endpoint, size, lastSeen);
};

export { getVods, getVodsByDveVideoIds, getStudioVodMeta, createClipFromVod, overwriteClipFromVod, getClipsOfVod, IVodStudio };
