import * as algoliasearch from 'algoliasearch';

type AlgoliaResponse<T> = algoliasearch.Response<T>;

interface ISearchQuery {
    indexName: string;
    query: string;
    params: algoliasearch.QueryParameters;
}

interface ISearchResponse<T> {
    results: AlgoliaResponse<T>['hits'];
    nbHits: AlgoliaResponse<T>['nbHits'];
    page: AlgoliaResponse<T>['page'];
    nbPages: AlgoliaResponse<T>['nbPages'];
    staleQuery: AlgoliaResponse<T>['query'];
}

class AlgoliaClient {
    public readonly client: algoliasearch.Client = null;

    constructor({ applicationId, searchOnlyApiKey }) {
        this.client = algoliasearch(applicationId, searchOnlyApiKey);
    }

    public async search<T>(queries: ISearchQuery[]): Promise<ISearchResponse<T>> {
        const {
            results: [searchResponse],
        }: algoliasearch.MultiResponse = await this.client.search(queries);
        const { hits: results, page, query: staleQuery, nbHits, nbPages }: AlgoliaResponse<T> = searchResponse;

        return {
            results,
            nbHits,
            page,
            nbPages,
            staleQuery,
        };
    }
}

export { AlgoliaClient };
