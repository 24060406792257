import { all, call, put, takeLatest } from 'redux-saga/effects';

import { PageTheme } from '~components/Root/types';
import * as service from '~services/realms';

import { EToastType } from '../toast/constants';
import { addToast } from '../toast/toast.actions';
import { RealmActions, CREATE_REALM_SUCCESS_NOTIFICATION, TOAST_TIMEOUT } from './constants';
import { createRealm, errorCreatingRealm, successfullyCreatedRealm, startedCreatingRealm } from './realm.actions';

const CREATE_REALM_SUCCESS_ACTION = addToast(
    CREATE_REALM_SUCCESS_NOTIFICATION.title,
    CREATE_REALM_SUCCESS_NOTIFICATION.message,
    EToastType.SUCCESS,
    TOAST_TIMEOUT,
    null,
    PageTheme.LIGHT
);

const CREATE_REALM_ERROR_ACTION = (message: string) => {
    return addToast(CREATE_REALM_SUCCESS_NOTIFICATION.title, message, EToastType.DANGER, TOAST_TIMEOUT, null, PageTheme.LIGHT);
};

function* handleCreateRealm(action: ReturnType<typeof createRealm>) {
    const { realmConfiguration, callback } = action.payload;
    try {
        yield put(startedCreatingRealm());
        yield call(service.createRealm, { realmConfiguration });
        yield all([put(successfullyCreatedRealm()), put(CREATE_REALM_SUCCESS_ACTION)]);
        callback();
    } catch (e) {
        console.warn(e);
        const responseBody = yield e.json();
        yield all([put(errorCreatingRealm()), put(CREATE_REALM_ERROR_ACTION(responseBody.messages[0]))]);
    }
}

export const realmSagas = [takeLatest(RealmActions.CREATE_REALM, handleCreateRealm)];
