import * as Hls from 'hls.js';
import * as debounce from 'lodash.debounce';
import * as React from 'react';

import * as Doris from '@dicetechnology/doris';

import LoadingSpinner from '~components/LoadingSpinner';
import Modal from '~components/Modal';
import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { PlayerKeys } from '~containers/EditorPageContainer/types';
import { joinStrings } from '~services/utilities';
import { Seconds } from '~src/types';
import { KeyCode } from '~src/views/types';

import './index.scss';

interface IContainerProps {
    hlsStreamUrl: string;
    isLive: boolean;
    duration: Seconds;
}

interface IProps extends IContainerProps {
    editorPDT: number;
    duration: number;
}

const PLAYER_DOM_ID = 'timeline-preview';
const DEBOUNCE_MOUSE_MOVE = 100;
const TIMELINE_OFFSET_X_POSITION = 30;

const isShiftKey = (keyCode: number) => keyCode === KeyCode.SHIFT_LEFT || keyCode === KeyCode.SHIFT_RIGHT;

const TimelinePreviewComponent: React.FunctionComponent<IProps> = ({ hlsStreamUrl, isLive, editorPDT, duration }) => {
    const [isSeeking, setIsSeeking] = React.useState(false);
    const [isActive, setIsPreviewActive] = React.useState(false);

    const playhead = React.useRef<HTMLDivElement>(null);
    const previewPlayer = React.useRef<Doris.Player>(null);
    const playerPDT = React.useRef<number>(null);

    React.useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keydown', onKeyUp);
        };
    }, []);

    React.useEffect(() => {
        setupPreview();
        listenToPlayerEvents();
        return () => {
            if (previewPlayer.current) {
                previewPlayer.current.destroy();
            }
        };
    }, []);

    const onKeyDown = (e: KeyboardEvent) => {
        if (isShiftKey(e.keyCode)) {
            setIsPreviewActive(true);
        }
    };

    const onKeyUp = (e: KeyboardEvent) => {
        if (isShiftKey(e.keyCode)) {
            setIsPreviewActive(false);
        }
    };

    const setupPreview = async () => {
        const config: Doris.IConfig = {
            autoPlay: Doris.AutoPlayOptions.NO,
            hlsConfig: {
                startLevel: 0,
                maxMaxBufferLength: 1,
                capLevelToPlayerSize: true,
                startFragPrefetch: true,
            },
            debug: {
                enabled: false,
            },
            preferNative: true,
        };
        const source: Doris.Source = {
            type: Doris.StreamTypes.HLS,
            url: hlsStreamUrl,
        };
        previewPlayer.current = new Doris.Player(PLAYER_DOM_ID);
        await previewPlayer.current.load(source, config);
        // keep the level on the lowest rendition
        previewPlayer.current.sourceHandler.engine.autoLevelCapping = 0;
    };

    const listenToPlayerEvents = () => {
        previewPlayer.current.on(Doris.Events.SEEKED, (e) => {
            setIsSeeking(false);
        });

        previewPlayer.current.once(Hls.Events.LEVEL_LOADED, (e, { details }) => {
            if (details && details.fragments && details.fragments[0]) {
                playerPDT.current = details.fragments[0].programDateTime;
            }
        });
    };

    const updateTimelinePreviewPosition = (event: React.MouseEvent<HTMLDivElement>) => {
        const { left } = event.currentTarget.getBoundingClientRect();
        const position = Math.abs(left - TIMELINE_OFFSET_X_POSITION) + event.clientX - TIMELINE_OFFSET_X_POSITION;
        playhead.current.style.left = `${position}px`;
    };

    const delayedSeekPreview = debounce((event: React.MouseEvent<HTMLDivElement>, currentTarget: HTMLDivElement) => {
        const { width, left } = currentTarget.getBoundingClientRect();
        const zeroClientX = event.clientX - TIMELINE_OFFSET_X_POSITION;
        const zeroLeft = Math.abs(left - TIMELINE_OFFSET_X_POSITION);
        const offsetXToPercent = (zeroLeft + zeroClientX) / width;

        const positiveSeekTime = offsetXToPercent * duration;
        previewPlayer.current.currentTime = !isLive ? positiveSeekTime : positiveSeekTime - duration;
    }, DEBOUNCE_MOUSE_MOVE);

    const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        event.persist();
        updateTimelinePreviewPosition(event);
        setIsPreviewActive(event.shiftKey);

        if (isActive) {
            setIsSeeking(true);
            delayedSeekPreview(event, event.currentTarget);
        }
    };

    const onMouseLeave = () => {
        setIsPreviewActive(false);
    };

    const classNames = joinStrings([
        'timeline-preview',
        isSeeking && 'timeline-preview--is-seeking',
        isActive && 'timeline-preview--is-active',
    ]);

    return (
        <div className={classNames} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove}>
            <div className="playhead timeline-preview__ghost-playhead" ref={playhead}>
                <span className="the-head">
                    <span className="the-head__tip" />
                </span>
                <div className="timeline-preview__wrapper">
                    <Modal.Main hasOverlay={false} className="timeline-preview__modal">
                        <Modal.Body>
                            <div className="timeline-preview__player" id={PLAYER_DOM_ID} />
                            <LoadingSpinner />
                        </Modal.Body>
                    </Modal.Main>
                </div>
            </div>
        </div>
    );
};

const TimelinePreview: React.FunctionComponent<IContainerProps> = ({ hlsStreamUrl, isLive, duration }) => {
    const {
        [PlayerKeys.EDITOR]: { loadPDT: editorPDT },
    } = React.useContext(VideoContext);
    return <TimelinePreviewComponent hlsStreamUrl={hlsStreamUrl} isLive={isLive} editorPDT={editorPDT} duration={duration} />;
};

export { TimelinePreview };
