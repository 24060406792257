import * as React from 'react';

import { ExclamationTriangleIcon } from '~components/Icons/ExclamationTriangleIcon';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { getTimeLabelIsoString, joinStrings } from '~services/utilities';
import { ITimelineClip, Seconds } from '~src/store/timeline/clip/types';

interface IClipRangeProps {
    id: ITimelineClip['id'];
    title: ITimelineClip['title'];
    isPreviewing: boolean;
    isActive: boolean;
    isHovered: boolean;
    isValid: boolean;
    isInvalidMessage: string;
    renderInPosition: number;
    renderOutPosition: number;
    clipDuration: Seconds;
    setActiveClip: (id: ITimelineClip['id']) => void;
    setClipFocus: (type: string, id: ITimelineClip['id']) => void;
}

const ClipRangeComponent: React.FunctionComponent<IClipRangeProps> = ({
    id,
    title,
    isPreviewing,
    isActive,
    isHovered,
    isValid,
    isInvalidMessage,
    renderInPosition,
    renderOutPosition,
    clipDuration,
    setActiveClip,
}) => {
    const hasRenderOutPosition = renderOutPosition !== null;

    const className = joinStrings([
        'clip-range',
        isPreviewing && 'clip-range--preview',
        isHovered && 'clip-range--hover',
        isActive && 'clip-range--active',
        !isValid && hasRenderOutPosition && 'clip-range--invalid',
    ]);

    const inHandleClassName = joinStrings([
        'clip-range__handle',
        renderOutPosition ? 'clip-range__handle--in' : 'clip-range__handle--in-solo',
    ]);

    const style: React.CSSProperties = {
        left: `${renderInPosition}%`,
        width: `${hasRenderOutPosition ? renderOutPosition - renderInPosition : 0}%`,
    };

    const selectClipHandler = (event: React.MouseEvent) => {
        if (event.altKey) {
            event.stopPropagation();
            const activeId = isActive ? null : id;
            setActiveClip(activeId);
        }
    };

    const formattedClipDuration = getTimeLabelIsoString(clipDuration);

    return (
        <div className={className} style={style} onClick={selectClipHandler}>
            <div className={inHandleClassName} />
            {renderOutPosition && (
                <React.Fragment>
                    <div className="clip-range__handle clip-range__handle--out" />

                    <div className="clip-range__title">{title}</div>

                    <div className="clip-range__time-range">
                        {!isValid && (
                            <div className="clip-range__status">
                                <Tooltip message={isInvalidMessage} position={TooltipPositions.TOP}>
                                    <ExclamationTriangleIcon /> {formattedClipDuration}
                                </Tooltip>
                            </div>
                        )}

                        {isValid && formattedClipDuration}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

const ClipRange: React.NamedExoticComponent<IClipRangeProps> = React.memo(ClipRangeComponent);

export { ClipRange };
