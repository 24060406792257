import * as React from 'react';

import { IDataTestId } from '~src/views/types';

const CardTitle: React.FunctionComponent<IDataTestId> = ({ children, dataTestId }) => {
    return (
        <div className="card__title" data-test-id={dataTestId}>
            {children}
        </div>
    );
};

export { CardTitle };
