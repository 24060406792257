import 'bootstrap-daterangepicker';
import * as jquery from 'jquery';
import { Moment } from 'moment';
import * as React from 'react';

export interface IDateRangePickerOptions {
    minDate?: Moment | Date | number;
    maxDate?: Moment | Date | number;
    startDate?: Moment | Date | number;
    endDate?: Moment | Date | number;
    parentEl?: string;
    singleDatePicker?: boolean;
    format?: string;
    timePicker?: boolean;
    timePickerIncrement?: number;
    autoUpdateInput?: boolean;
    drops?: 'down' | 'up' | 'auto';
    autoApply: boolean;
}

interface IDateRangeProps {
    onChange: (startDate: Moment, endDate?: Moment) => void;
    options: IDateRangePickerOptions;
    placeholder?: string;
    inlinePicker?: boolean;
    // children?: React.ReactNode;
}

export const DEFAULT_OPTIONS: Partial<IDateRangePickerOptions> = {
    timePicker: false,
    drops: 'auto',
    autoUpdateInput: false,
    singleDatePicker: false,
    format: 'DD/MM/YYYY',
};

export const DateRangePicker: React.FC<IDateRangeProps> = React.memo((props) => {
    const { onChange, options, children, placeholder, inlinePicker } = props;
    const { startDate, endDate, singleDatePicker, format } = options;
    const triggerRef = React.useRef<HTMLDivElement | HTMLInputElement>(null);
    const useInput = !children;

    const setInputValue = (start: Moment, end: Moment) => {
        if (useInput) {
            const values = [start.format(format)];

            if (!singleDatePicker) {
                values.push(end.format(format));
            }

            (triggerRef.current as HTMLInputElement).value = values.join(' - ');
        }
    };

    const changeListener = (start: Moment, end: Moment) => {
        onChange(start, end);

        if (useInput) {
            setInputValue(start, end);
        }
    };

    const initPicker = ($element) => {
        $element.daterangepicker(
            {
                ...DEFAULT_OPTIONS,
                ...options,
            },
            changeListener
        );
    };

    React.useEffect(() => {
        const $element = triggerRef.current && jquery(triggerRef.current);

        if (!$element) {
            return;
        }

        initPicker($element);

        if (startDate || endDate) {
            const $data = $element.data('daterangepicker');
            $data.setStartDate(startDate);
            $data.setEndDate(endDate);

            setInputValue($data.startDate, $data.endDate);
        } else if (useInput) {
            $element.val('');
        }

        $element.on('apply.daterangepicker', (ev, { startDate: selectedStartDate, endDate: selectedEndDate }) => {
            setInputValue(selectedStartDate, selectedEndDate);

            if (inlinePicker) {
                onChange(selectedStartDate, selectedEndDate);
            }
        });

        if (inlinePicker) {
            // show picker on load
            $element.data('daterangepicker').hide = () => {
                //
            };
            $element.data('daterangepicker').show();

            // Stop propagation to parent click handlers when datepicker is inline
            // This is only used when the datepickers are inline and in a dropdown
            const containerElement = $element.data('daterangepicker').container[0];
            jquery(containerElement).on('click', (e: MouseEvent) => e.stopPropagation());
        }
    }, [startDate, endDate]);

    return useInput ? (
        <input placeholder={placeholder} type="text" ref={triggerRef as React.RefObject<HTMLInputElement>} />
    ) : (
        <div className="daterangepicker__trigger" ref={triggerRef as React.RefObject<HTMLDivElement>}>
            {children}
        </div>
    );
});
