import * as React from 'react';

import { ChannelManagerContainer } from '~containers/ChannelManagerContainer';

import './index.scss';

export const ChannelManager = () => {
    return (
        <React.Suspense fallback={null}>
            <div className="channel-manager">
                <ChannelManagerContainer />
            </div>
        </React.Suspense>
    );
};
