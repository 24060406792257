import * as React from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, RouteComponentProps, matchPath } from 'react-router-dom';
import { Dispatch } from 'redux';

import { ThemeProvider } from '@dicetechnology/dice-backoffice-ui-components';
import { AUTH_TOKEN_KEY } from '@dicetechnology/dice-unity/lib/services/Http/constants';
import { IDiceFetch } from '@dicetechnology/dice-unity/lib/services/Http/types';

import { FeedbackModal } from '~components/FeedbackModal';
import LoadingSpinner from '~components/LoadingSpinner';
import '~components/PageAnimation/index.scss';
import { ToastRack } from '~components/ToastRack';
import { ChannelManager } from '~pages/ChannelManager';
import { ChannelsPage } from '~pages/Channels';
import { ChromeOnly } from '~pages/ChromeOnly';
import { ForgottenPasswordWithRouter } from '~pages/ForgottenPassword';
import { LandingPage } from '~pages/LandingPage';
import { LoginPageContainer } from '~pages/Login';
import { Logout } from '~pages/Logout';
import { NotFoundPage } from '~pages/NotFound';
import { SetPasswordContainer } from '~pages/SetPassword';
import { authenticationService } from '~services/authentication';
import { Console } from '~services/console';
import { USER_REALM_KEY } from '~services/http/constants';
import { ICurrentUserResponse, ProfileService } from '~services/profile';
import { getSettings, ISettingsSettings, TAlgoliaSettings } from '~services/settings';
import { storageProvider } from '~services/storage';
import { isSafeBrowser, milliSecsToSecs } from '~services/utilities';
import { Page } from '~src/routes/Page';
import { FeatureDictionary } from '~src/types';
import { AuthorisedLayout } from '~src/views/layout/AuthorisedLayout';
import EditorRoute from '~src/views/route/Editor';
import { KeyCode } from '~src/views/types';

import { PageRoutes, TabTypes } from './constants';
import { RootProvider } from './context';
import { PageTheme, StudioRoute } from './types';

interface IRootState {
    authorised: boolean;
    realms: string[];
    isDebugMode: boolean;
    algoliaSettings: TAlgoliaSettings;
    features: FeatureDictionary;
    settings: ISettingsSettings;
    appLoaded: boolean;
}

const INITIAL_STATE = {
    authorised: null,
    realms: null,
    isDebugMode: false,
    algoliaSettings: null,
    features: {},
    settings: {},
    appLoaded: false,
};
class RootComponent extends React.PureComponent<{ dispatch: Dispatch }, IRootState> {
    private isSafeBrowser = isSafeBrowser();

    public state: IRootState = INITIAL_STATE;

    private getRoutes = () => {
        return [
            {
                exact: true,
                title: '',
                path: PageRoutes.LOGIN,
                component: LoginPageContainer,
            },
            {
                exact: true,
                title: '',
                path: PageRoutes.FORGOTTEN_PASSWORD,
                restricted: false,
                component: ForgottenPasswordWithRouter,
            },
            {
                exact: true,
                title: '',
                path: PageRoutes.SET_PASSWORD,
                restricted: false,
                component: SetPasswordContainer,
            },
            {
                exact: true,
                title: '',
                path: PageRoutes.LIVE_CHANNELS,
                restricted: true,
                theme: PageTheme.LIGHT,
                component: () => <ChannelsPage tabType={TabTypes.LIVE} />,
            },
            {
                exact: true,
                title: '',
                path: PageRoutes.UPCOMING_CHANNELS,
                restricted: true,
                theme: PageTheme.LIGHT,
                component: () => <ChannelsPage tabType={TabTypes.UPCOMING} />,
            },
            {
                exact: true,
                title: '',
                path: PageRoutes.LIVE_CHANNEL_MANAGER,
                restricted: true,
                withAuthHeader: false,
                theme: PageTheme.LIGHT,
                component: ChannelManager,
            },
            {
                exact: true,
                title: '',
                path: PageRoutes.UPCOMING_CHANNEL_MANAGER,
                restricted: true,
                withAuthHeader: false,
                theme: PageTheme.LIGHT,
                component: ChannelManager,
            },
            {
                exact: false,
                title: '',
                path: PageRoutes.EDITOR,
                restricted: true,
                component: EditorRoute,
            },
            {
                exact: true,
                title: '',
                path: PageRoutes.CHROME_ONLY,
                restricted: false,
                component: ChromeOnly,
            },
            {
                exact: true,
                title: '',
                path: PageRoutes.LOGOUT,
                restricted: true,
                component: Logout,
            },
            {
                exact: true,
                title: '',
                path: PageRoutes.LANDING_PAGE,
                restricted: true,
                theme: PageTheme.DARK_BLUE,
                component: LandingPage,
            },
            {
                exact: false,
                title: '',
                path: '*',
                restricted: false,
                component: NotFoundPage,
            },
        ];
    };

    private getSettings = async (): Promise<void> => {
        try {
            const {
                searchProviders: { algolia = null },
                features,
                settings,
            } = await getSettings();

            if (settings.minimumClipDuration && settings.minimumClipDuration > 0) {
                settings.minimumClipDuration = milliSecsToSecs(settings.minimumClipDuration);
            } else {
                settings.minimumClipDuration = 0;
            }

            if (settings.minimumClipSeparation && settings.minimumClipSeparation > 0) {
                settings.minimumClipSeparation = milliSecsToSecs(settings.minimumClipSeparation);
            } else {
                settings.minimumClipSeparation = 0;
            }

            this.setState({
                algoliaSettings: algolia,
                features,
                appLoaded: true,
                settings,
            });
        } catch (e) {
            this.setState({ appLoaded: true });
            Console.warn("Processing 'Settings'", e);
        }
    };

    public static getDerivedStateFromProps(props, state) {
        if (!state.authorised) {
            return {
                ...state,
                algoliaSettings: INITIAL_STATE.algoliaSettings,
            };
        }
        return state;
    }

    public async componentDidMount() {
        // todo: non hard coded player debug key -> https://github.com/DiceTechnology/doris/projects/1#card-20068560
        // todo: use storage provider when this is done -> with an app specific debug key
        // start debug position based off player debug key
        const debugStorageValue = localStorage.getItem('PLAYER_DEBUG');
        const isDebugMode = debugStorageValue && debugStorageValue === 'ON';

        await authenticationService.validatateAppStorage();

        const authorised = !!(await storageProvider.get(AUTH_TOKEN_KEY));

        this.setState({
            isDebugMode,
            authorised,
        });

        window.addEventListener('keypress', this.handleDebugToggle);
    }

    public async componentDidUpdate(_, { authorised: staleAuthorised }: Readonly<IRootState>): Promise<void> {
        const { authorised, realms: stateRealms } = this.state;

        if (authorised && !staleAuthorised) {
            if (!stateRealms) {
                try {
                    const realm = await storageProvider.get(USER_REALM_KEY);

                    const fetchOptions: IDiceFetch = {
                        headers: {
                            Realm: realm,
                        },
                        useAuth: true,
                    };

                    const { realms } = await ProfileService.getCurrentUser(fetchOptions);
                    this.setState({ realms });
                } catch (e) {
                    await authenticationService.logout();
                    this.setAuthorised(false);
                    Console.warn("Processing 'CurrentUser'", e);
                    return;
                }
            }

            this.getSettings();
        } else if (!authorised) {
            this.setState({ appLoaded: true });
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener('keypress', this.handleDebugToggle);
    }

    public render() {
        const { authorised, appLoaded } = this.state;

        return (
            <ThemeProvider>
                <React.Fragment>
                    {!appLoaded && (
                        <div className="spinner-wrapper">
                            <LoadingSpinner />
                        </div>
                    )}

                    {appLoaded && authorised !== null && (
                        <RootProvider
                            value={{
                                ...this.state,
                                setAuthorised: this.setAuthorised,
                            }}
                        >
                            <Router>
                                <Route render={this.renderPropRoute} />
                            </Router>

                            <ToastRack />
                            <FeedbackModal />
                        </RootProvider>
                    )}

                    <div id="modal" />
                    <div id="custom-dropdown" />
                </React.Fragment>
            </ThemeProvider>
        );
    }

    private renderPropRoute = ({ location, history }) => {
        // @ts-ignore
        // TODO: this is a temp hack workaround while waiting on dice-unity pub/sub
        // TODO: Used in `handledRefresh` as part of http auth refresh failure
        window.APP = { history };

        // using a redirect withing a transition group causes the redirect to trigger multiple times and
        // warning within the console.
        // ref: https://github.com/ReactTraining/react-router/issues/6076
        const { authorised, realms } = this.state;
        const allRoutes = this.getRoutes();
        const { withAuthHeader = true } = allRoutes.find((route) => {
            return matchPath(location.pathname, route);
        });
        const useAuthLayout = authorised && isSafeBrowser() && withAuthHeader;
        const routes = <Switch location={location}>{allRoutes.map((route) => this.renderRoute(route, location))}</Switch>;
        return useAuthLayout ? <AuthorisedLayout realms={realms}>{routes}</AuthorisedLayout> : routes;
    };

    private renderRoute = (route, location) => {
        const { exact, path, restricted = false } = route;
        const { authorised } = this.state;
        const isRestricted = restricted && !authorised;

        /* tslint:disable:jsx-no-lambda */
        return (
            <Route
                key={path}
                exact={exact}
                path={path}
                render={(props) => {
                    if (!this.isSafeBrowser && location.pathname !== PageRoutes.CHROME_ONLY) {
                        return <Redirect to={PageRoutes.CHROME_ONLY} />;
                    } else if (isRestricted) {
                        const to = { pathname: PageRoutes.LOGIN, state: {} };

                        if (location.pathname !== PageRoutes.LOGOUT) {
                            to.state = { from: location };
                        }
                        return <Redirect key={path} to={to} />;
                    }
                    return this.renderRouteComponent(props, route, location.key);
                }}
            />
        );
    };

    private renderRouteComponent = (props: RouteComponentProps<{}>, route: StudioRoute, locationKey: string) => {
        const { theme = PageTheme.DARK, component: Component, title, path } = route;

        if (path === PageRoutes.EDITOR) {
            // routing for editor pages are handled here
            return <EditorRoute appLoaded={this.state.appLoaded} />;
        }

        const $component = (
            <React.Suspense fallback={null}>
                <Component {...props} />
            </React.Suspense>
        );

        return (
            <Page title={title} theme={theme}>
                {$component}
            </Page>
        );
    };

    private setAuthorised = (authorised: boolean, currentUser: ICurrentUserResponse = { realms: null }) => {
        this.setState({ authorised, realms: currentUser.realms });
    };

    private handleDebugToggle = ({ shiftKey, keyCode }: KeyboardEvent) => {
        if (shiftKey && keyCode === KeyCode.D) {
            this.setState({ isDebugMode: !this.state.isDebugMode });
        }
    };
}

const Root = () => {
    const dispatch = useDispatch();
    return <RootComponent dispatch={dispatch} />;
};

export default Root;
