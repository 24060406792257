import * as moment from 'moment';
import * as React from 'react';

import processingPlaceholder from '~assets/images/processing-video.jpg';
import { Badge, BadgeType } from '~components/Badge';
import { CardDVELink } from '~components/Card/CardDVELink';
import { CardDescription } from '~components/Card/CardDescription';
import { CardPreviewLink } from '~components/Card/CardPreviewLink';
import { CardSubTitle } from '~components/Card/CardSubTitle';
import { CardThumbnail } from '~components/Card/CardThumbnail';
import { CardTitle } from '~components/Card/CardTitle';
import { getAssetUrl, getFormattedDuration, getThumbnailUrl, isDveLinkDefined } from '~components/Card/utilities';
import { ExclamationTriangleIcon } from '~components/Icons/ExclamationTriangleIcon';
import InfoCircleIcon from '~components/Icons/InfoCircleIcon';
import { LoadingSpinner, LoadingSpinnerSize } from '~components/LoadingSpinner';
import { RootContext } from '~components/Root/context';
import { SmartAnchor } from '~components/SmartAnchor';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { ClipStatus, IClip } from '~services/clip/types';
import { joinStrings } from '~services/utilities';
import { ICardSettings } from '~src/types';

import './index.scss';

interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    settings: ICardSettings;
    card: IClip;
    onPreviewClick: (canCreateClips: boolean, externalUrl: string) => (event: React.MouseEvent) => void;
}

interface IProps extends IContainerProps {
    isDebugMode: boolean;
}

enum ClipErrorMessages {
    WARNING = 'There may be a problem. Please contact studio support for more info',
    ERROR = 'There is a problem. Please contact studio support for more info',
    NOT_EDITABLE = 'Unable to edit a processing clip, please check back soon',
    PROCESSING = 'Clip processing and will be ready shortly',
}

const EDITOR_CLIP_VIDEO_URL = '/editor/clip/{assetId}';

const getClipStatusIcon = (status: ClipStatus): JSX.Element => {
    switch (status) {
        case ClipStatus.NOT_EDITABLE:
            return <InfoCircleIcon />;
        case ClipStatus.PROCESSING:
            return <LoadingSpinner size={LoadingSpinnerSize.SMALL} />;
        default:
            return <ExclamationTriangleIcon />;
    }
};

const getProcessingStatus = (status: IClip['status']) => {
    if (status === ClipStatus.OK || ClipErrorMessages[status] === undefined) {
        return null;
    }
    return (
        <div className="card__processing-status">
            <Tooltip message={ClipErrorMessages[status]} position={TooltipPositions.TOP}>
                {getClipStatusIcon(status)}
            </Tooltip>
        </div>
    );
};

const getClipThumbnailUrl = (card: IClip, settings: ICardSettings): string => {
    if (card.status === ClipStatus.PROCESSING) {
        return processingPlaceholder;
    }
    return getThumbnailUrl(card.thumbnail, settings);
};

const CardComponent: React.FunctionComponent<IProps> = ({ className, settings, card, onPreviewClick, isDebugMode }) => {
    const canCreateClips = card.status === ClipStatus.OK;
    const hasDveLink = isDveLinkDefined(card.externalUrl);
    const thumbnailSrc = getClipThumbnailUrl(card, settings);

    const hasError = card.status === ClipStatus.ERROR;
    const hasWarning = card.status === ClipStatus.WARNING;
    const notEditable = card.status === ClipStatus.NOT_EDITABLE;
    const hasExpired = card.status === ClipStatus.EXPIRED;

    const canPreview = card.status === ClipStatus.OK || card.status === ClipStatus.NOT_EDITABLE || card.status === ClipStatus.WARNING;

    const cardClasses = joinStrings([
        'card',
        settings.layout ? `card--${settings.layout}` : 'card--vertical',

        hasError && 'card--has-error',
        hasWarning && 'card--has-warning',
        notEditable && 'card--has-info',
        !canCreateClips && 'card--no-edit',

        className,
    ]);

    return (
        <div className={cardClasses} data-test-id="clipCard">
            <SmartAnchor to={getAssetUrl(EDITOR_CLIP_VIDEO_URL, card.id)} className="card-content" disabled={!canCreateClips}>
                <CardThumbnail
                    thumbnailSrc={thumbnailSrc}
                    thumbnailHeight={settings.height}
                    thumbnailWidth={settings.width}
                    dataTestId="clipCard-thumbnail"
                >
                    <div className="card__tag-wrapper">
                        <div className="card__status-badge">{hasExpired && <Badge type={BadgeType.Error} text="Expired" />}</div>
                        <div className="card__tag card__tag--time">{getFormattedDuration(card.duration)}</div>
                    </div>
                </CardThumbnail>

                <CardDescription>
                    <CardTitle dataTestId="clipCard-title">{card.title}</CardTitle>
                    {card.createdAt && <CardSubTitle>Created {moment(card.createdAt).fromNow()}</CardSubTitle>}
                    {card.recentVersionCount && (
                        <CardSubTitle>
                            <Tooltip message="Number of recently created versions of this clip" position={TooltipPositions.TOP}>
                                {card.recentVersionCount} Revisions
                            </Tooltip>
                        </CardSubTitle>
                    )}
                </CardDescription>

                {getProcessingStatus(card.status)}
            </SmartAnchor>

            <ul className="inline-list card__actions">
                {canPreview && (
                    <li className="list-item">
                        <CardPreviewLink
                            onClick={onPreviewClick(canCreateClips, hasDveLink && card.externalUrl)}
                            tooltipText="Preview clip"
                            dataTestId="clipCard-previewLink"
                        />
                    </li>
                )}
                {card.status !== ClipStatus.PROCESSING && hasDveLink && (
                    <li className="list-item">
                        <CardDVELink externalUrl={card.externalUrl} dataTestId="clipCard-dveLink" />
                    </li>
                )}
            </ul>
        </div>
    );
};

const CardMemo: React.NamedExoticComponent<IProps> = React.memo(CardComponent);

const ClipCard: React.FunctionComponent<IContainerProps> = ({ className, settings, card, onPreviewClick }) => {
    const { isDebugMode } = React.useContext(RootContext);
    return <CardMemo className={className} settings={settings} card={card} onPreviewClick={onPreviewClick} isDebugMode={isDebugMode} />;
};

export { ClipCard };
