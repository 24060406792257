import { IEndLabel } from './types';

export const WINDOW_RESIZE_DEBOUNCE = 700;
export const RESET_LAST_EDITED_TIMEOUT = 1500;
export const FIRST_POD_ITEM_EXTRA_WIDTH = 100;
export const LIST_ITEM_HEIGHT = 600;
export const STICKY_DATE_OFFSET = 75;

export const enum ListItemWidth {
    CONTENT_POD = 410,
    END_OF_SCHEDULE_LABEL = 200,
}

export const enum ListItemPaddingLeft {
    FIRST = 150,
    REST = 50,
}

export enum ListItems {
    CONTENT_POD = 'CONTENT_POD',
    END_OF_SCHEDULE_LABEL = 'END_OF_SCHEDULE_LABEL',
}

export const END_LABEL: IEndLabel = { kind: ListItems.END_OF_SCHEDULE_LABEL };
