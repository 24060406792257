import * as React from 'react';

import { Button, ButtonSize, ButtonTheme } from '~components/Button';
import { ArrowFromLeftIcon } from '~components/Icons/ArrowFromLeftIcon';
import { ArrowFromRightIcon } from '~components/Icons/ArrowFromRightIcon';
import { FastBackwardIcon } from '~components/Icons/FastBackwardIcon';
import { FastForwardIcon } from '~components/Icons/FastForwardIcon';
import KeyboardIcon from '~components/Icons/KeyboardIcon';
import { PauseSolidIcon } from '~components/Icons/PauseSolidIcon';
import { PlaySolidIcon } from '~components/Icons/PlaySolidIcon';
import { StepBackwardIcon } from '~components/Icons/StepBackwardIcon';
import { StepForwardIcon } from '~components/Icons/StepForwardIcon';
import { UnderlineFirstCharacter } from '~components/Text/UnderlineFirstCharcter';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { KeyCode } from '~src/views/types';

import './index.scss';

interface IProps {
    isPaused: boolean;
    togglePlay: VoidFunction;
    playerNudge: (nudgeAmount: number) => void;
    canExport: () => boolean;
    setExportMode: VoidFunction;
    canTogglePreview: () => boolean;
    isPreviewing: () => boolean;
    togglePreviewMode: VoidFunction;
    canResetTimeLineState: () => boolean;
    resetTimeLineState: VoidFunction;
    toggleHotKeyModal: VoidFunction;
}

interface IHotKey {
    renderedDescription: JSX.Element;
    toolTipDescription: string;
    callback?: VoidFunction;
}

const TimelineControls: React.FunctionComponent<IProps> = ({
    isPaused,
    togglePlay,
    playerNudge,
    canExport,
    setExportMode,
    canTogglePreview,
    isPreviewing,
    togglePreviewMode,
    canResetTimeLineState,
    resetTimeLineState,
    toggleHotKeyModal,
}) => {
    const footerHotKeys: IHotKey[] = [
        {
            renderedDescription: <StepBackwardIcon height="14" width="14" />,
            toolTipDescription: 'Jump backwards 1 frame (shift+left cursor key)',
            callback: () => playerNudge((1 / 25) * -1),
        },
        {
            renderedDescription: <FastBackwardIcon height="14" width="14" />,
            toolTipDescription: 'Jump backwards 1 second (left cursor key)',
            callback: () => playerNudge(-1),
        },
        {
            renderedDescription: isPaused ? <PlaySolidIcon height="14" width="14" /> : <PauseSolidIcon height="14" width="14" />,
            toolTipDescription: 'Toggle between play and pause (space)',
            callback: togglePlay,
        },
        {
            renderedDescription: <FastForwardIcon height="14" width="14" />,
            toolTipDescription: 'Jump forwards 1 second (right cursor key)',
            callback: () => playerNudge(1),
        },
        {
            renderedDescription: <StepForwardIcon height="14" width="14" />,
            toolTipDescription: 'Jump forwards 1 frame (shift+right cursor key)',
            callback: () => playerNudge(1 / 25),
        },
        {
            renderedDescription: <ArrowFromLeftIcon height="14" width="14" />,
            toolTipDescription: 'Mark the start of a clip (i key)',
            callback: () => {
                // @ts-ignore: object literal may only specify known properties
                const event = new KeyboardEvent('keydown', { keyCode: KeyCode.I });
                window.dispatchEvent(event);
            },
        },
        {
            renderedDescription: <ArrowFromRightIcon height="14" width="14" />,
            toolTipDescription: 'Mark the end of a clip (o key)',
            callback: () => {
                // @ts-ignore: object literal may only specify known properties
                const event = new KeyboardEvent('keydown', { keyCode: KeyCode.O });
                window.dispatchEvent(event);
            },
        },
    ];

    const renderHotKey = ({ renderedDescription, toolTipDescription, callback }: IHotKey, index) => {
        return (
            <li className="footer-timeline-controls__item" key={index}>
                <Tooltip message={toolTipDescription} position={TooltipPositions.TOP}>
                    <Button theme={ButtonTheme.GREY} size={ButtonSize.SMALL} type="button" key={index} onClick={callback}>
                        {renderedDescription}
                    </Button>
                </Tooltip>
            </li>
        );
    };

    const getPreviewButtonText = () => {
        if (isPreviewing()) {
            return 'Stop Preview';
        }

        return <UnderlineFirstCharacter text="Preview" />;
    };

    return (
        <section className="footer-timeline-controls">
            <ul className="footer-timeline-controls__list">
                <li className="footer-timeline-controls__item">
                    <button
                        type="button"
                        className="footer-timeline-controls__button footer-timeline-controls__button--hotkeys-modal"
                        onClick={toggleHotKeyModal}
                    >
                        <KeyboardIcon />
                        <span>Hot keys</span>
                    </button>
                </li>
                {footerHotKeys.map(renderHotKey)}
            </ul>
            <ul className="footer-timeline-controls__list">
                <li className="footer-timeline-controls__item">
                    <Button
                        size={ButtonSize.SMALL}
                        theme={ButtonTheme.GREY}
                        onClick={resetTimeLineState}
                        disabled={!canResetTimeLineState()}
                    >
                        <UnderlineFirstCharacter text="Reset" />
                    </Button>
                </li>
                <li className="footer-timeline-controls__item">
                    <Button size={ButtonSize.SMALL} theme={ButtonTheme.GREY} onClick={togglePreviewMode} disabled={!canTogglePreview()}>
                        {getPreviewButtonText()}
                    </Button>
                </li>
                <li className="footer-timeline-controls__item">
                    <Button size={ButtonSize.SMALL} theme={ButtonTheme.BLUE} onClick={setExportMode} disabled={!canExport()}>
                        <UnderlineFirstCharacter text="Export" />
                    </Button>
                </li>
            </ul>
        </section>
    );
};

export { TimelineControls };
