import { http } from '~services/http';

enum Endpoints {
    GET = '/searchProviders/algolia/newApiKey',
}

interface IGetProfileResponse {
    apiKey: string;
}

class SearchProviders {
    public static async getNewAlgoliaKey(): Promise<IGetProfileResponse> {
        const { parsedData } = await http.get(Endpoints.GET);
        return parsedData;
    }
}

export { SearchProviders };
