import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { PhotoVideoIcon } from '~components/Icons/PhotoVideoIcon';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { joinStrings } from '~services/utilities';
import { toggleThumbnailVisibility } from '~src/store/timeline/thumbnail/thumbnail.actions';
import { IStudioState } from '~src/store/types';
import { useShallowEqualSelector } from '~src/views/hooks';

interface IProps {
    displayThumbnails: boolean;
    isDisabled: boolean;
    dispatch: Dispatch;
}

const ThumbnailsButtonComponent: React.NamedExoticComponent<IProps> = React.memo(({ displayThumbnails, isDisabled, dispatch }) => {
    const toggleThumbnailsHandler = () => {
        dispatch(toggleThumbnailVisibility());
    };

    const message = `${displayThumbnails ? 'Hide' : 'Show'} thumbnail markers on the timeline`;
    const className = joinStrings(['timeline-controls__btn', displayThumbnails && !isDisabled && 'timeline-controls__btn--active']);

    return (
        <Tooltip message={!isDisabled && message} position={TooltipPositions.TOP}>
            <button className={className} type="button" onClick={toggleThumbnailsHandler} disabled={isDisabled}>
                <PhotoVideoIcon fill="#ffffff" width="15" height="15" />
            </button>
        </Tooltip>
    );
});

const getThumbnailState = (state: IStudioState) => {
    const { list: thumbnails, displayThumbnails } = state.timeline.thumbnails;
    return {
        thumbnails,
        displayThumbnails,
    };
};

const ThumbnailsButton = () => {
    const { thumbnails, displayThumbnails } = useShallowEqualSelector(getThumbnailState);
    const dispatch = useDispatch();
    const isDisabled = !thumbnails.length;

    return <ThumbnailsButtonComponent displayThumbnails={displayThumbnails} isDisabled={isDisabled} dispatch={dispatch} />;
};

export { ThumbnailsButton };
