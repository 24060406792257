import * as React from 'react';

const CopyPasteIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width={16} height={19} viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M1.64 4.82V16.5h10v1.68h-10a1.55 1.55 0 01-1.16-.5A1.647 1.647 0 010 16.5V4.82h1.64zM14.14 0c.453 0 .847.167 1.18.5.333.333.5.727.5 1.18v11.68c0 .453-.167.84-.5 1.16-.333.32-.727.48-1.18.48H5c-.453 0-.847-.16-1.18-.48a1.55 1.55 0 01-.5-1.16V1.68c0-.453.167-.847.5-1.18C4.153.167 4.547 0 5 0h9.14zm0 1.68H5v11.68h9.14V1.68z"
                    fill="#485F6E"
                    fillRule="nonzero"
                />
                <path fill="#FFF" d="M14.14 13.36H5V1.68h9.14z" />
            </g>
        </svg>
    );
};

export { CopyPasteIcon };
