import * as React from 'react';

import ExternalLinkSquareIcon from '~components/Icons/EcternalLinkSquare';
import { SmartAnchor } from '~components/SmartAnchor';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { IDataTestId } from '~src/views/types';

interface IProps {
    externalUrl: string;
}

const CardDVELink: React.FunctionComponent<IProps & IDataTestId> = ({ externalUrl, dataTestId }) => {
    return (
        <SmartAnchor to={externalUrl} dataTestId={dataTestId}>
            <Tooltip message="Open in DVE" position={TooltipPositions.TOP}>
                <ExternalLinkSquareIcon />
            </Tooltip>
        </SmartAnchor>
    );
};

export { CardDVELink };
