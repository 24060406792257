import * as React from 'react';

import { Buffer } from '~components/Editor/Buffer';
import { RootContext } from '~components/Root/context';
import { valuesToPercentage } from '~services/utilities';

import './index.scss';

interface IProps {
    clientWidth: number;
    scrollLeft: number;
    scrollWidth: number;
    playheadPosition: number;
    timelineOnClickHandler: (event: React.MouseEvent) => void;
}

const Seekbar: React.NamedExoticComponent<IProps> = React.memo(
    ({ clientWidth, scrollLeft, scrollWidth, playheadPosition, timelineOnClickHandler }: IProps) => {
        const { isDebugMode } = React.useContext(RootContext);

        // process play head representation
        const trainStyles: React.CSSProperties = {
            left: `${playheadPosition}%`,
        };

        // process zoom level representation
        const timelineZoomPosition = valuesToPercentage(scrollLeft, scrollWidth);
        const timelineZoomWidth = valuesToPercentage(clientWidth, scrollWidth);
        const blockStyles = {
            left: `${timelineZoomPosition}%`,
            width: `${timelineZoomWidth}%`,
        };

        return (
            <div className="railway" onClick={timelineOnClickHandler}>
                <div className="railway__line">
                    <div className="railway__block" style={blockStyles} />
                    {isDebugMode && <Buffer />}
                    <div className="railway__train" style={trainStyles} />
                </div>
            </div>
        );
    }
);

export { Seekbar };
