import * as React from 'react';
import { SVGProps } from 'react';

const ChromeIcon: React.NamedExoticComponent<SVGProps<SVGSVGElement>> = React.memo((props) => {
    return (
        <svg {...props} viewBox="0 0 192 192">
            <defs>
                <circle id="a" cx="96" cy="96" r="88" />
            </defs>
            <clipPath id="b">
                <use xlinkHref="#a" overflow="visible" />
            </clipPath>
            <g clipPath="url(#b)">
                <path fill="#DB4437" d="M21.97 8v108h39.39L96 56h88V8z" />
                <linearGradient id="c" gradientUnits="userSpaceOnUse" x1="29.337" y1="75.021" x2="81.837" y2="44.354">
                    <stop offset="0" stopColor="#a52714" stopOpacity=".6" />
                    <stop offset=".66" stopColor="#a52714" stopOpacity="0" />
                </linearGradient>
                <path fill="url(#c)" d="M21.97 8v108h39.39L96 56h88V8z" />
            </g>
            <path clipPath="url(#b)" fill="#3E2723" fillOpacity=".15" d="M62.31 115.65L22.48 47.34l-.58 1 39.54 67.8z" />
            <g clipPath="url(#b)">
                <path fill="#0F9D58" d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z" />
                <linearGradient id="d" gradientUnits="userSpaceOnUse" x1="110.872" y1="164.495" x2="52.538" y2="130.329">
                    <stop offset="0" stopColor="#055524" stopOpacity=".4" />
                    <stop offset=".33" stopColor="#055524" stopOpacity="0" />
                </linearGradient>
                <path fill="url(#d)" d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z" />
            </g>
            <path clipPath="url(#b)" fill="#263238" fillOpacity=".15" d="M129.84 117.33l-.83-.48L90.62 184h1.15l38.1-66.64z" />
            <g clipPath="url(#b)">
                <defs>
                    <path id="e" d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z" />
                </defs>
                <clipPath id="f">
                    <use xlinkHref="#e" overflow="visible" />
                </clipPath>
                <g clipPath="url(#f)">
                    <path fill="#FFCD40" d="M96 56l34.65 60-38.88 68H184V56z" />
                    <linearGradient id="g" gradientUnits="userSpaceOnUse" x1="121.858" y1="49.804" x2="136.547" y2="114.13">
                        <stop offset="0" stopColor="#ea6100" stopOpacity=".3" />
                        <stop offset=".66" stopColor="#ea6100" stopOpacity="0" />
                    </linearGradient>
                    <path fill="url(#g)" d="M96 56l34.65 60-38.88 68H184V56z" />
                </g>
            </g>
            <g clipPath="url(#b)">
                <path fill="#FFCD40" d="M96 56l34.65 60-38.88 68H184V56z" />
                <linearGradient id="h" gradientUnits="userSpaceOnUse" x1="121.858" y1="49.804" x2="136.547" y2="114.13">
                    <stop offset="0" stopColor="#ea6100" stopOpacity=".3" />
                    <stop offset=".66" stopColor="#ea6100" stopOpacity="0" />
                </linearGradient>
                <path fill="url(#h)" d="M96 56l34.65 60-38.88 68H184V56z" />
            </g>
            <g clipPath="url(#b)">
                <defs>
                    <path id="i" d="M96 56l34.65 60-38.88 68H184V56z" />
                </defs>
                <clipPath id="j">
                    <use xlinkHref="#i" overflow="visible" />
                </clipPath>
                <g clipPath="url(#j)">
                    <path fill="#DB4437" d="M21.97 8v108h39.39L96 56h88V8z" />
                    <linearGradient id="k" gradientUnits="userSpaceOnUse" x1="29.337" y1="75.021" x2="81.837" y2="44.354">
                        <stop offset="0" stopColor="#a52714" stopOpacity=".6" />
                        <stop offset=".66" stopColor="#a52714" stopOpacity="0" />
                    </linearGradient>
                    <path fill="url(#k)" d="M21.97 8v108h39.39L96 56h88V8z" />
                </g>
            </g>
            <radialGradient id="l" cx="668.176" cy="55.948" r="84.078" gradientTransform="translate(-576)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#3e2723" stopOpacity=".2" />
                <stop offset="1" stopColor="#3e2723" stopOpacity="0" />
            </radialGradient>
            <path clipPath="url(#b)" fill="url(#l)" d="M96 56v20.95L174.4 56z" />
            <g clipPath="url(#b)">
                <defs>
                    <path id="m" d="M21.97 8v40.34L61.36 116 96 56h88V8z" />
                </defs>
                <clipPath id="n">
                    <use xlinkHref="#m" overflow="visible" />
                </clipPath>
                <g clipPath="url(#n)">
                    <path fill="#0F9D58" d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z" />
                    <linearGradient id="o" gradientUnits="userSpaceOnUse" x1="110.872" y1="164.495" x2="52.538" y2="130.329">
                        <stop offset="0" stopColor="#055524" stopOpacity=".4" />
                        <stop offset=".33" stopColor="#055524" stopOpacity="0" />
                    </linearGradient>
                    <path fill="url(#o)" d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z" />
                </g>
            </g>
            <radialGradient id="p" cx="597.875" cy="48.52" r="78.044" gradientTransform="translate(-576)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#3e2723" stopOpacity=".2" />
                <stop offset="1" stop-cp-color="#3e2723" stopOpacity="0" />
            </radialGradient>
            <path clipPath="url(#b)" fill="url(#p)" d="M21.97 48.45l57.25 57.24L61.36 116z" />
            <radialGradient id="q" cx="671.844" cy="96.138" r="87.87" gradientTransform="translate(-576)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#263238" stopOpacity=".2" />
                <stop offset="1" stopColor="#263238" stopOpacity="0" />
            </radialGradient>
            <path clipPath="url(#b)" fill="url(#q)" d="M91.83 183.89l20.96-78.2L130.65 116z" />
            <g clipPath="url(#b)">
                <circle fill="#F1F1F1" cx="96" cy="96" r="40" />
                <circle fill="#4285F4" cx="96" cy="96" r="32" />
            </g>
            <g clipPath="url(#b)">
                <path fill="#3E2723" fillOpacity=".2" d="M96 55c-22.09 0-40 17.91-40 40v1c0-22.09 17.91-40 40-40h88v-1H96z" />
                <path
                    fill="#FFF"
                    fillOpacity=".1"
                    d="M130.6 116c-6.92 11.94-19.81 20-34.6 20-14.8 0-27.69-8.06-34.61-20h-.04L8 24.48v1L61.36 117h.04c6.92 11.94 19.81 20 34.61 20 14.79 0 27.68-8.05 34.6-20h.05v-1h-.06z"
                />
                <path
                    opacity=".1"
                    fill="#3E2723"
                    d="M97 56c-.17 0-.33.02-.5.03C118.36 56.3 136 74.08 136 96s-17.64 39.7-39.5 39.97c.17 0 .33.03.5.03 22.09 0 40-17.91 40-40s-17.91-40-40-40z"
                />
                <path
                    fill="#FFF"
                    fillOpacity=".2"
                    d="M131 117.33c3.4-5.88 5.37-12.68 5.37-19.96 0-4.22-.66-8.28-1.87-12.09.95 3.42 1.5 7.01 1.5 10.73 0 7.28-1.97 14.08-5.37 19.96l.02.04-38.88 68h1.16l38.09-66.64-.02-.04z"
                />
            </g>
            <g clipPath="url(#b)">
                <path
                    fill="#FFF"
                    fillOpacity=".2"
                    d="M96 9c48.43 0 87.72 39.13 87.99 87.5 0-.17.01-.33.01-.5 0-48.6-39.4-88-88-88S8 47.4 8 96c0 .17.01.33.01.5C8.28 48.13 47.57 9 96 9z"
                />
                <path
                    fill="#3E2723"
                    fillOpacity=".15"
                    d="M96 183c48.43 0 87.72-39.13 87.99-87.5 0 .17.01.33.01.5 0 48.6-39.4 88-88 88S8 144.6 8 96c0-.17.01-.33.01-.5C8.28 143.87 47.57 183 96 183z"
                />
            </g>
            <radialGradient id="r" cx="34.286" cy="32.014" r="176.746" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#fff" stopOpacity=".1" />
                <stop offset="1" stopColor="#fff" stopOpacity="0" />
            </radialGradient>
            <circle fill="url(#r)" cx="96" cy="96" r="88" />
            <path fill="none" d="M0 0h192v192H0z" />
        </svg>
    );
});

export { ChromeIcon };
