import * as React from 'react';
import { Link } from 'react-router-dom';

import * as logoSrc from '~assets/images/svgs/brand-logo.svg';
import { PageRoutes } from '~components/Root/constants';

interface IProps {
    className?: string;
}

export const NavLogo: React.FC<IProps> = (props) => {
    return (
        <Link to={PageRoutes.LANDING_PAGE} className={props.className}>
            <img width={32} src={logoSrc} alt="Endeavor Streaming logo" />
        </Link>
    );
};
