import debounce from 'lodash.debounce';
import * as React from 'react';

const RESIZE_DEBOUNCE_WAIT = 500;

export default function useWindowSize(delay = RESIZE_DEBOUNCE_WAIT) {
    const [windowSize, setWindowSize] = React.useState<{ width: number; height: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // Handler to call on window resize
    const handleResize = React.useCallback(
        debounce(() => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }, delay),
        [delay]
    );

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return windowSize;
}
