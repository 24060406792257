import { ISearchBucketState, SearchTerm } from 'src/store/search/types';

import { SearchActions } from '~src/store/search/constants';
import { IGenericDictionary, ISearchableContentType } from '~src/types';

interface ISearchState {
    searchTerm: SearchTerm;
    buckets: {
        [key: string]: ISearchBucketState;
    };
}

const DEFAULT_STATE: ISearchState = {
    searchTerm: '',
    buckets: {},
};

const defaultSearchBucketState: ISearchBucketState = {
    results: [],
    nbHits: null,
    page: null,
    nbPages: null,
    status: 'loading',
};

const updateSearchBucketResultsStates = (
    state: ISearchBucketState['results'],
    updatedResult: ISearchableContentType
): ISearchBucketState['results'] => {
    return state.map((result) => {
        if (result.id === updatedResult.id) {
            return updatedResult;
        }
        return result;
    });
};

const updateSearchBucketStates = (state: IGenericDictionary<ISearchBucketState>, payload): IGenericDictionary<ISearchBucketState> => {
    return payload.stateKeys.reduce((accumulator, key) => {
        if (state[key]) {
            return {
                ...accumulator,
                [key]: {
                    ...state[key],
                    results: updateSearchBucketResultsStates(state[key].results, payload.result),
                },
            };
        }
    }, {});
};

const searchReducer = (state: ISearchState = DEFAULT_STATE, { type, payload }): ISearchState => {
    switch (type) {
        case SearchActions.SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: payload.searchTerm,
            };
        case SearchActions.ADD_BUCKET:
            return {
                ...state,
                buckets: {
                    ...state.buckets,
                    [payload.stateKey]: defaultSearchBucketState,
                },
            };
        case SearchActions.REMOVE_BUCKET: {
            const stateKey = payload.stateKey;
            const { [stateKey]: removeBucket, ...keepBuckets } = state.buckets;
            return {
                ...state,
                buckets: {
                    ...keepBuckets,
                },
            };
        }
        case SearchActions.ADD_BUCKET_RESULTS: {
            const { stateKey, results, nbHits, page, nbPages, status } = payload;
            return {
                ...state,
                buckets: {
                    ...state.buckets,
                    [stateKey]: {
                        results,
                        nbHits,
                        page,
                        nbPages,
                        status,
                    },
                },
            };
        }
        case SearchActions.UPDATE_RESULT: {
            return {
                ...state,
                buckets: {
                    ...state.buckets,
                    ...updateSearchBucketStates(state.buckets, payload),
                },
            };
        }
        default:
            return state;
    }
};

export { searchReducer };
