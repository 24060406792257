import v4 from 'uuid/v4';

import { PageTheme } from '~components/Root/types';
import { EToastType, ToastAction } from '~src/store/toast/constants';
import { IAddToast, IToastSlice } from '~src/store/toast/types';

const DEFAULT_TOAST_TIMEOUT = 5000;

const Toaster = (
    title: string,
    message: string,
    type: EToastType = EToastType.INFO,
    timeout: number = DEFAULT_TOAST_TIMEOUT,
    dataTestId: string = null,
    theme: PageTheme = PageTheme.DARK
): IToastSlice => ({
    id: v4(),
    title,
    message,
    type,
    timeout,
    dataTestId,
    theme,
});

const addToast = (title: string, message: string, type: EToastType, timeout: number, dataTestId: string, theme?: PageTheme): IAddToast => ({
    type: ToastAction.ADD,
    payload: Toaster(title, message, type, timeout, dataTestId, theme),
});

const dismissToast = (id: IToastSlice['id']) => ({
    type: ToastAction.DISMISS,
    payload: { id },
});

export { Toaster, addToast, dismissToast };
