import * as React from 'react';

import PausedIcon from '~components/Icons/PausedIcon';
import PlayIcon from '~components/Icons/PlayIcon';
import VolumeMuteIcon from '~components/Icons/VolumeMuteIcon';
import VolumeUpIcon from '~components/Icons/VolumeUpIcon';
import LoadingSpinner from '~components/LoadingSpinner';
import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { PlayerKeys } from '~containers/EditorPageContainer/types';

import './index.scss';

interface IContainerProps {
    playerKey: PlayerKeys;
    play?: VoidFunction;
    pause?: VoidFunction;
    toggleMute: (playerKey: PlayerKeys) => void;
}

interface IProps extends IContainerProps {
    toggleMute: (playerKey: PlayerKeys) => void;
    muted: boolean;
    waiting: boolean;
    paused: boolean;
}

const PlayerControlsComponent: React.FunctionComponent<IProps> = ({ playerKey, muted, waiting, paused, play, pause, toggleMute }) => {
    const mutePlayer = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.blur();
        toggleMute(playerKey);
    };

    const playPauseToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.blur();
        paused ? play() : pause();
    };

    const getPlayPauseControl = () => {
        if (!play || !pause) {
            return null;
        }
        return (
            <button className="player-action-button" onClick={playPauseToggle}>
                {paused ? <PlayIcon className="player-icon" /> : <PausedIcon className="player-icon" />}
            </button>
        );
    };

    return (
        <div className="player-controls-wrapper">
            <div className="player-feedback-wrapper">
                {waiting && <LoadingSpinner />}
                {getPlayPauseControl()}
            </div>
            <div className="footer-controls">
                <ul className="inline-list pull-right">
                    <li className="list-item">
                        <button type="button" className="player-control" onClick={mutePlayer}>
                            {muted ? <VolumeMuteIcon /> : <VolumeUpIcon />}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

const PlayerControlsMemo: React.NamedExoticComponent<IProps> = React.memo(PlayerControlsComponent);

const PlayerControls: React.FunctionComponent<IContainerProps> = (props) => {
    const {
        [props.playerKey as PlayerKeys]: { muted, paused, waiting },
    } = React.useContext(VideoContext);
    return <PlayerControlsMemo muted={muted} paused={paused} waiting={waiting} {...props} />;
};

export { PlayerControls };
