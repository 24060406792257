import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import {
    SetPasswordFormState,
    SetPasswordPage,
    useSearchParams,
    getPayloadFromJwt,
    Loader,
} from '@dicetechnology/dice-backoffice-ui-components';

import { ApplicationVersion } from '~components/application-version';
import { RelativeContainer } from '~components/styled';
import { TOAST_TIMEOUT, TOAST_TEST_ID } from '~pages/constants';
import { authenticationService } from '~services/authentication';
import { PasswordConfigurationService } from '~services/password-configuration';
import { PasswordConfiguration } from '~services/password-configuration/types';
import { EToastType } from '~src/store/toast/constants';
import { addToast } from '~src/store/toast/toast.actions';

import {
    SET_PASSWORD_TOAST_TITLE,
    SET_PASSWORD_TOAST_MESSAGE,
    SET_PASSWORD_FORM_SERVER_ERROR_KEY,
    PASSWORD_USED_TOO_RECENTLY_ERROR,
    PASSWORD_IS_TOO_COMMON,
    AUTHENTICATION_LINKS,
} from './constants';
import './index.scss';
import { JwtPayload } from './types';

interface SetPasswordContainerProps extends RouteComponentProps<unknown, unknown, { from: string }> {}

export const SetPasswordContainer: React.FC<SetPasswordContainerProps> = () => {
    const [passwordConfiguration, setPasswordConfiguration] = React.useState<PasswordConfiguration>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [processComplete, setProcessComplete] = React.useState(false);
    const [passwordAsyncError, setpasswordAsyncError] = React.useState('');

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const tokenPayload = React.useMemo(() => {
        return getPayloadFromJwt<JwtPayload>(token);
    }, [token]);

    const realm = tokenPayload?.aud;
    const userExid = tokenPayload?.sub;

    const dispatch = useDispatch();

    const handleError = async (e: Response): Promise<void> => {
        setpasswordAsyncError('');
        try {
            const { messages } = await e.json();
            const [message] = messages;

            const isInvalidResetPasswordToken = message === SET_PASSWORD_FORM_SERVER_ERROR_KEY.INVALID_RESET_PASSWORD_TOKEN;
            const isNotFound = message === SET_PASSWORD_FORM_SERVER_ERROR_KEY.NOT_FOUND;
            const isPasswordTooRecentlyUsed = message.startsWith(SET_PASSWORD_FORM_SERVER_ERROR_KEY.PASSWORD_ALREADY_USED);
            const isPasswordIsTooCommon = message === SET_PASSWORD_FORM_SERVER_ERROR_KEY.PASSWORD_IS_TOO_COMMON;

            if (isInvalidResetPasswordToken || isNotFound) {
                const action = addToast(
                    SET_PASSWORD_TOAST_TITLE,
                    SET_PASSWORD_TOAST_MESSAGE.FAILED_TOKEN_INVALID,
                    EToastType.DANGER,
                    TOAST_TIMEOUT,
                    TOAST_TEST_ID
                );
                dispatch(action);

                setProcessComplete(true);
            } else if (isPasswordTooRecentlyUsed) {
                setpasswordAsyncError(PASSWORD_USED_TOO_RECENTLY_ERROR);
            } else if (isPasswordIsTooCommon) {
                setpasswordAsyncError(PASSWORD_IS_TOO_COMMON);
            }
        } catch (e) {
            const action = addToast(
                SET_PASSWORD_TOAST_TITLE,
                SET_PASSWORD_TOAST_MESSAGE.FAILED,
                EToastType.DANGER,
                TOAST_TIMEOUT,
                TOAST_TEST_ID
            );
            dispatch(action);
            console.warn('Failed to set password', e);
        }
    };

    const onHandleSubmit = async ({ newPassword }: SetPasswordFormState): Promise<void> => {
        try {
            await authenticationService.setPassword(newPassword, token);
            const action = addToast(
                SET_PASSWORD_TOAST_TITLE,
                SET_PASSWORD_TOAST_MESSAGE.SUCCESS,
                EToastType.SUCCESS,
                TOAST_TIMEOUT,
                TOAST_TEST_ID
            );
            dispatch(action);
            setProcessComplete(true);
        } catch (e) {
            console.warn('Set password failed', e);
            handleError(e);
        }
    };

    const getPasswordConfig = React.useCallback(async () => {
        try {
            setIsLoading(true);
            const passwordConfig = await PasswordConfigurationService().getPasswordConfigurationByExid(userExid);
            setPasswordConfiguration(passwordConfig);
        } catch (err) {
            console.warn('fetching config failed', err);
        } finally {
            setIsLoading(false);
        }
    }, [userExid]);

    React.useEffect(() => {
        if (realm && userExid) {
            getPasswordConfig();
        }
    }, [getPasswordConfig, realm, userExid]);

    if (!realm || !userExid || processComplete) {
        return <Redirect to="./login" />;
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <RelativeContainer>
            <SetPasswordPage
                onSubmit={onHandleSubmit}
                passwordConfiguration={passwordConfiguration}
                passwordAsyncError={passwordAsyncError}
                authenticationLinks={AUTHENTICATION_LINKS}
            />
            <ApplicationVersion />
        </RelativeContainer>
    );
};
