import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { Button, ButtonTheme } from '~components/Button';
import { ClipTitleField } from '~components/Editor/EditClip/ClipTitleField';
import { EditClipThumbnailPreview } from '~components/Editor/EditClip/EditClipThumbnailPreview';
import { ThumbnailPicker } from '~components/Editor/EditClip/ThumbnailPicker';
import Modal, { TextJustify } from '~components/Modal';
import { setClipThumbnail, setClipTitle, setEditClip } from '~src/store/timeline/clip/clip.actions';
import { ITimelineClip } from '~src/store/timeline/clip/types';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { IStudioState } from '~src/store/types';
import { useShallowEqualSelector } from '~src/views/hooks';

import './index.scss';

interface IEditClipState {
    clip: ITimelineClip | undefined;
}

interface IEditClipProps extends IEditClipState {
    dispatch: Dispatch;
    thumbnails: ITimelineThumbnail[];
}

enum EditStep {
    CLIP_META = 'meta',
    THUMBNAIL_SELECTION = 'thumbnail',
}

const EditClipComponent: React.FunctionComponent<IEditClipProps> = ({
    clip: { id, title, thumbnailId: originalThumbnailId },
    thumbnails,
    dispatch,
}) => {
    const [clipEditStep, setEditStep] = React.useState<EditStep>(EditStep.CLIP_META);

    const [clipTitle, setTitle] = React.useState<string>(title);
    const [newThumbnailId, setThumbnail] = React.useState<string>(originalThumbnailId);

    const setClipThumbnailHandler = (thumbnailId: ITimelineThumbnail['id'] = null) => {
        setThumbnail(thumbnailId);
        toggleEditStep();
    };

    const cancelClickHandler = () => {
        const action = setEditClip(null);
        dispatch(action);
    };

    const submitHandler = (event) => {
        event.preventDefault();
        const titleAction = setClipTitle(id, clipTitle);
        dispatch(titleAction);

        if (originalThumbnailId !== newThumbnailId) {
            const thumbnailAction = setClipThumbnail(id, newThumbnailId);
            dispatch(thumbnailAction);
        }
    };

    // edit clip selection
    const toggleEditStep = () => {
        if (clipEditStep === EditStep.CLIP_META) {
            setEditStep(EditStep.THUMBNAIL_SELECTION);
        } else {
            setEditStep(EditStep.CLIP_META);
        }
    };

    const getClipEditStep = (currentEditStep: EditStep): JSX.Element => {
        switch (currentEditStep) {
            case EditStep.CLIP_META:
                return getUpdateMetaStep();
            case EditStep.THUMBNAIL_SELECTION:
                return getThumbnailSelectionStep();
        }
    };

    // clip meta step of clip editing
    const getUpdateMetaStep = () => {
        const isSaveDisabled = (title === clipTitle && originalThumbnailId === newThumbnailId) || !clipTitle.trim().length;
        return (
            <React.Fragment>
                <div className="form-group">
                    <div className="form-group__control-wrapper">
                        <label className="form-group__label no-transition">Select thumbnail</label>
                    </div>

                    <EditClipThumbnailPreview thumbnailId={newThumbnailId} thumbnails={thumbnails} toggleEditStep={toggleEditStep} />
                </div>

                <ClipTitleField setTitle={setTitle} title={clipTitle} />

                <div className="button-group mt-4 text-center">
                    <Button theme={ButtonTheme.BLUE} type="submit" disabled={isSaveDisabled}>
                        Save
                    </Button>
                    <Button theme={ButtonTheme.BLACK} onClick={cancelClickHandler}>
                        Cancel
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    // thumbnail selection step of clip editing
    const getThumbnailSelectionStep = () => {
        return (
            <React.Fragment>
                <div className="form-group__control-wrapper">
                    <label className="form-group__label no-transition">Select thumbnail</label>
                </div>
                <ThumbnailPicker thumbnails={thumbnails} selectThumbnail={setClipThumbnailHandler} />
                <div className="button-group mt-4 text-center">
                    <Button theme={ButtonTheme.BLUE} onClick={toggleEditStep}>
                        Back
                    </Button>
                    <Button theme={ButtonTheme.BLACK} onClick={cancelClickHandler}>
                        Cancel
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    const $component = (
        <Modal.Main closeWithEscape={true} hasOverlay={true} onClose={cancelClickHandler} className="edit-clip__modal">
            <Modal.Title justify={TextJustify.CENTER}>Edit Clip</Modal.Title>
            <Modal.Body justify="left">
                <form onSubmit={submitHandler} autoComplete="off">
                    {getClipEditStep(clipEditStep)}
                </form>
            </Modal.Body>
        </Modal.Main>
    );

    return ReactDOM.createPortal($component, document.getElementById('modal'));
};

const getThumbnailState = (state: IStudioState): ITimelineThumbnail[] => {
    return state.timeline.thumbnails.list;
};

const getEditClipState = (state: IStudioState): IEditClipState | undefined => {
    const editClipId = state.timeline.clips.editClipId;

    if (editClipId) {
        const [clip]: ITimelineClip[] = state.timeline.clips.list.filter((c) => c.id === editClipId);
        return {
            clip,
        };
    }

    return undefined;
};

const EditClip: React.FunctionComponent = () => {
    const editClipState = useShallowEqualSelector<IEditClipState | undefined>(getEditClipState);
    const thumbnails = useShallowEqualSelector<ITimelineThumbnail[]>(getThumbnailState);
    const dispatch = useDispatch();
    return editClipState ? <EditClipComponent clip={editClipState.clip} thumbnails={thumbnails} dispatch={dispatch} /> : null;
};

export { EditClip };
