import * as React from 'react';

export const useClickOff = (targetRef: React.RefObject<HTMLElement>, callback: any) => {
    React.useEffect(() => {
        const handleClickOff = ({ target }: MouseEvent) => {
            if (!targetRef.current?.contains(target as Element)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOff);
        return () => document.removeEventListener('mousedown', handleClickOff);
    }, [callback]);
};
