interface IDataTestId {
    dataTestId?: string;
}

enum FeatureToggleKey {
    TimelinePreview = 'enableTimelinePreview',
    AdminMultiRealm = 'enableAdminMultiRealm',
}

enum KeyCode {
    B = 66,
    D = 68,
    E = 69,
    F = 70,
    I = 73,
    K = 75,
    L = 76,
    O = 79,
    P = 80,
    R = 82,
    T = 84,
    V = 86,
    Y = 25,

    ARROW_LEFT = 37,
    ARROW_RIGHT = 39,
    ARROW_UP = 38,
    ARROW_DOWN = 40,

    HOME = 36,
    END = 35,

    ENTER = 13,
    ESCAPE = 27,
    MINUS = 189,
    EQUAL = 187,
    SPACE = 32,
    SHIFT_LEFT = 16,
    SHIFT_RIGHT = 16,

    DIGIT_1 = 49,
    DIGIT_2 = 50,
    DIGIT_3 = 51,
    DIGIT_4 = 52,
    DIGIT_5 = 53,
}

export { FeatureToggleKey, KeyCode, IDataTestId };
