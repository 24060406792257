enum SearchActions {
    SET_SEARCH_TERM = 'search/SET_SEARCH_TERM',
    ADD_SAGA = 'search/ADD_SAGA',
    ADD_BUCKET = 'search/ADD_BUCKET',
    REMOVE_BUCKET = 'search/REMOVE_BUCKET',
    GET_NEXT_PAGE_SAGA = 'search/GET_NEXT_PAGE_SAGA',
    ADD_BUCKET_RESULTS = 'search/ADD_BUCKET_RESULTS',
    UPDATE_RESULT = 'search/UPDATE_RESULT ',
    PROCESS_SEARCH_SAGA = 'search/PROCESS_SEARCH_SAGA',
}

export { SearchActions };
