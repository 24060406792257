import { Http } from '@dicetechnology/dice-unity/lib/services/Http';
import {
    queryStringFormatter,
    baseUrlFormatter,
    jsonPayloadFormatter,
    tokenRefresh,
    defaultRequestHeaders,
    applyAuthorizationHeader,
} from '@dicetechnology/dice-unity/lib/services/Http/middlewares';
import { IRefreshOptions } from '@dicetechnology/dice-unity/lib/services/Http/types';

import { AuthEndpoints } from '~services/authentication/enums';
import { storageProvider } from '~services/storage';

import {
    AUTH_API,
    STD__STARGATE_API,
    STD__STARGATE_SPOCK_API,
    STUDIO_API_V1,
    STUDIO_API_V2,
    STUDIO_API_V3,
    STUDIO_API_V4,
    USER_REALM_KEY,
} from './constants';

export const simpleFetchApi = async <T>(input: RequestInfo, init?: RequestInit): Promise<T> => {
    const response: Response = await fetch(input, init);
    if (response.status === 204) {
        return null;
    }
    return await response.json();
};

const requestMiddleware = [
    defaultRequestHeaders(storageProvider),
    queryStringFormatter(),
    jsonPayloadFormatter(),
    applyAuthorizationHeader(storageProvider),
];

const refreshOptions: IRefreshOptions = {
    realmStorageKey: USER_REALM_KEY,
    url: `${AUTH_API}/authentication/refresh`,
};

// dice id authentication
export const authHttp = new Http({
    baseUrl: AUTH_API,
    refreshOptions,
    storageProvider,
    useDefaultErrorMiddleware: false,
    useDefaultRequestMiddleware: true,
    useDefaultResponseMiddleware: true,
    useAuth: true,
});

const errorMiddleware = [tokenRefresh(authHttp.refresh, [AuthEndpoints.LOGIN, AuthEndpoints.UPDATE_PASSWORD])];

authHttp.applyRequestMiddleware([baseUrlFormatter(AUTH_API), ...requestMiddleware]);

authHttp.applyErrorMiddleware(errorMiddleware);

// http v1 support
export const http = new Http({
    baseUrl: STUDIO_API_V1,
    storageProvider,
    useDefaultErrorMiddleware: false,
    useDefaultRequestMiddleware: false,
    useDefaultResponseMiddleware: true,
    useAuth: true,
});

http.applyRequestMiddleware([baseUrlFormatter(STUDIO_API_V1), ...requestMiddleware]);

http.applyErrorMiddleware(errorMiddleware);

// http v2 support
export const httpV2 = new Http({
    baseUrl: STUDIO_API_V2,
    storageProvider,
    useDefaultErrorMiddleware: false,
    useDefaultRequestMiddleware: false,
    useDefaultResponseMiddleware: true,
    useAuth: true,
});

httpV2.applyRequestMiddleware([baseUrlFormatter(STUDIO_API_V2), ...requestMiddleware]);

httpV2.applyErrorMiddleware(errorMiddleware);

// http v3 support
export const httpV3 = new Http({
    baseUrl: STUDIO_API_V3,
    storageProvider,
    useDefaultErrorMiddleware: false,
    useDefaultRequestMiddleware: false,
    useDefaultResponseMiddleware: true,
    useAuth: true,
});

httpV3.applyRequestMiddleware([baseUrlFormatter(STUDIO_API_V3), ...requestMiddleware]);

httpV3.applyErrorMiddleware(errorMiddleware);

// http v4 support
export const httpV4 = new Http({
    baseUrl: STUDIO_API_V4,
    storageProvider,
    useDefaultErrorMiddleware: false,
    useDefaultRequestMiddleware: false,
    useDefaultResponseMiddleware: true,
    useAuth: true,
});

httpV4.applyRequestMiddleware([baseUrlFormatter(STUDIO_API_V4), ...requestMiddleware]);

httpV4.applyErrorMiddleware(errorMiddleware);

// stargate api support
export const httpStargateV1 = new Http({
    baseUrl: STD__STARGATE_API,
    storageProvider,
    useDefaultErrorMiddleware: false,
    useDefaultRequestMiddleware: false,
    useDefaultResponseMiddleware: true,
    useAuth: true,
});

httpStargateV1.applyRequestMiddleware([baseUrlFormatter(STD__STARGATE_API), ...requestMiddleware]);

httpStargateV1.applyErrorMiddleware(errorMiddleware);

// stargate spock api support
export const httpStargateSpockV1 = new Http({
    baseUrl: STD__STARGATE_SPOCK_API,
    storageProvider,
    useDefaultErrorMiddleware: false,
    useDefaultRequestMiddleware: false,
    useDefaultResponseMiddleware: true,
    useAuth: true,
});

httpStargateSpockV1.applyRequestMiddleware([baseUrlFormatter(STD__STARGATE_SPOCK_API), ...requestMiddleware]);

httpStargateSpockV1.applyErrorMiddleware(errorMiddleware);
