import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { AuthenticationLink, LoginFormState, LoginPage, MFAPage } from '@dicetechnology/dice-backoffice-ui-components';

import { PageRoutes } from '~components/Root/constants';
import { RootContext } from '~components/Root/context';
import { ApplicationVersion } from '~components/application-version';
import { RelativeContainer } from '~components/styled';
import { TOAST_TEST_ID, TOAST_TIMEOUT } from '~pages/constants';
import { authenticationService } from '~services/authentication';
import { MfaChallengeResponse } from '~services/authentication/types';
import { getLoginRequestPayload } from '~services/authentication/utils/get-login-request-payload';
import { isMfaChallengeResponse } from '~services/authentication/utils/is-mfa-challenge-response';
import { isResetPasswordResponse } from '~services/authentication/utils/is-reset-password-response';
import { EToastType } from '~src/store/toast/constants';
import { addToast } from '~src/store/toast/toast.actions';

import { AUTHENTICATION_LINKS } from './constants';
import { AuthenticationMode, LoginPageToastTitle, LoginPageToastMessage, AutenticationLinkLabel } from './enums';
import './index.scss';
import {
    HandleLoginSuccess,
    HandleLoginFailed,
    HandleLoginFailedWithMfaResponse,
    HandleLoginRequest,
    OnLoginPageSubmit,
    OnMfaPageSubmit,
    GetNewMfaPin,
    HandleBackToLogin,
    UseLocationState,
} from './types';

export const LoginPageContainer: React.FC = () => {
    const history = useHistory();
    const { state = { from: '' } } = useLocation<UseLocationState>();
    const [mfaChallenge, setMfaChallenge] = React.useState<MfaChallengeResponse>(null);
    const [cachedLoginFormState, setCachedLoginFormState] = React.useState<LoginFormState>(null);

    const toast = (type: EToastType, title: string, message: string) => {
        const action = addToast(title, message, type, TOAST_TIMEOUT, TOAST_TEST_ID);
        dispatch(action);
    };

    const { setAuthorised, authorised } = React.useContext(RootContext);

    const dispatch = useDispatch();

    const handleLoginSuccess: HandleLoginSuccess = (loginResponse) => {
        if (isResetPasswordResponse(loginResponse)) {
            history.replace(`${PageRoutes.SET_PASSWORD}?token=${loginResponse.resetPasswordToken}`);
            return;
        }

        setAuthorised(true, loginResponse.currentUser);
    };

    const handleLoginFailed: HandleLoginFailed = (error, authenticationMode) => {
        // when new pin requests fails with a status is not 428 toast pin request failed
        if (authenticationMode === AuthenticationMode.NEW_PIN) {
            toast(EToastType.DANGER, LoginPageToastTitle.ERROR, LoginPageToastMessage.REQUEST_NEW_PIN_FAILED);
            return;
        }

        toast(EToastType.DANGER, LoginPageToastTitle.ERROR, LoginPageToastMessage.LOGIN_FAILED_USERNAME_PASSWORD);
        console.warn('failed to login', error);
    };

    const handleLoginFailedWithMfaResponse: HandleLoginFailedWithMfaResponse = async (error, payload, realm) => {
        try {
            const response = await error.json();

            if (isMfaChallengeResponse(response)) {
                setCachedLoginFormState({
                    emailAddress: payload.id,
                    password: payload.secret,
                    realm,
                });
                setMfaChallenge(response);
            }
        } catch (error) {
            console.warn('Failed to process MFA response', error);
        }
    };

    const handleLoginRequest: HandleLoginRequest = async (payload, realm, authenticationMode) => {
        try {
            const response = await authenticationService.login(payload, realm);

            handleLoginSuccess(response);
        } catch (error) {
            if (error.status === 428) {
                handleLoginFailedWithMfaResponse(error, payload, realm);

                if (authenticationMode === AuthenticationMode.NEW_PIN) {
                    toast(EToastType.SUCCESS, LoginPageToastTitle.SUCCESS, LoginPageToastMessage.REQUEST_NEW_PIN_SUCCESS);
                }
                return;
            } else {
                handleLoginFailed(error, authenticationMode);
            }
        }
    };

    // login from login page
    const onLoginPageSubmit: OnLoginPageSubmit = async (loginFormState) => {
        const payload = getLoginRequestPayload(loginFormState);

        return handleLoginRequest(payload, loginFormState.realm, AuthenticationMode.BASIC);
    };

    // login from mfa page
    const onMfaPageSubmit: OnMfaPageSubmit = async (mfaFormState) => {
        const payload = getLoginRequestPayload(cachedLoginFormState, mfaChallenge, mfaFormState);

        return handleLoginRequest(payload, cachedLoginFormState.realm, AuthenticationMode.MFA);
    };

    // request new MFA pin
    const getNewMfaPin: GetNewMfaPin = (event) => {
        event.preventDefault();

        const payload = getLoginRequestPayload(cachedLoginFormState);

        handleLoginRequest(payload, cachedLoginFormState.realm, AuthenticationMode.NEW_PIN);
    };

    const handleBackToLogin: HandleBackToLogin = (event) => {
        event.preventDefault();

        setCachedLoginFormState(null);
        setMfaChallenge(null);
    };

    const mfaAuthenticationLinks: AuthenticationLink[] = [
        {
            label: AutenticationLinkLabel.REQUEST_NEW_PIN,
            onClick: getNewMfaPin,
        },
        {
            label: AutenticationLinkLabel.BACK_TO_LOGIN,
            onClick: handleBackToLogin,
        },
    ];

    if (authorised) {
        return <Redirect to={state.from || PageRoutes.LANDING_PAGE} />;
    }

    return (
        <RelativeContainer>
            {mfaChallenge ? (
                <MFAPage
                    email={cachedLoginFormState.emailAddress}
                    onSubmit={onMfaPageSubmit}
                    authenticationLinks={mfaAuthenticationLinks}
                />
            ) : (
                <LoginPage onSubmit={onLoginPageSubmit} renderRealmField={true} authenticationLinks={AUTHENTICATION_LINKS} />
            )}
            <ApplicationVersion />
        </RelativeContainer>
    );
};
