import { ISearchBucketState, ISearchState } from '~src/store/search/types';
import { IStudioState } from '~src/store/types';
import { StateKey } from '~src/types';

interface IGetSearchBucketState extends Omit<ISearchBucketState, 'nbHits'> {
    hasMoreResults: boolean;
}

const getSearchTermState = (state: IStudioState): ISearchState['searchTerm'] => {
    return state.search.searchTerm;
};

const getSearchBucketState = (stateKey: StateKey) => (state: IStudioState): IGetSearchBucketState => {
    const { results, page, nbPages, status } = state.search.buckets[stateKey] || {};
    const hasMoreResults = page < nbPages - 1;

    return {
        results,
        page,
        nbPages,
        status,
        hasMoreResults,
    };
};

export { getSearchBucketState, getSearchTermState };
