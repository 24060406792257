import * as React from 'react';

import { InfoCircleIconLight } from '~components/Icons/InfoCircleIconLight';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { IVllContent } from '~services/channels/types';
import { joinStrings } from '~services/utilities';

const TooltipIcon = <InfoCircleIconLight />;

export const ContentPreview: React.FC<IVllContent> = ({ seasonNumber, episodeNumber, title, thumbnailUrl, description }) => {
    const [hasImageLoaded, setHasImageLoaded] = React.useState(false);

    const handleImageLoad = () => {
        setHasImageLoaded(true);
    };

    const thumbnailClassName = joinStrings(['pod-card__video-thumbnail', hasImageLoaded && 'pod-card__video-thumbnail--is-loaded']);
    const tooltipMessageArr: string[] = [];

    if (seasonNumber) {
        tooltipMessageArr.push(`Season ${seasonNumber}`);
    }
    if (episodeNumber) {
        tooltipMessageArr.push(`Episode ${episodeNumber}`);
    }

    return (
        <React.Fragment>
            <div className={thumbnailClassName}>
                <img src={thumbnailUrl} alt={title} onLoad={handleImageLoad} />
            </div>
            <div className="pod-card__video-meta">
                <div className="pod-card__video-title">
                    <span>{title}</span>
                    {!!tooltipMessageArr.length && (
                        <Tooltip message={tooltipMessageArr.join(': ')} position={TooltipPositions.TOP}>
                            {TooltipIcon}
                        </Tooltip>
                    )}
                </div>
                <div className="pod-card__video-description">{description}</div>
            </div>
        </React.Fragment>
    );
};
