import * as Doris from '@dicetechnology/doris';

import { EZoomLevel, ZoomNudge } from '~services/zoomLevelService';
import { BooleanFunction, Seconds } from '~src/types';

export enum PlayerKeys {
    EDITOR = 'editorPlayer',
    PREVIEW = 'previewPlayer',
}

export interface IPlayerControls {
    mounted: () => void; // called when play element mounts
    play: (playerKey: PlayerKeys) => void;
    pause: (playerKey: PlayerKeys) => void;
    togglePlay: () => void;
    toggleMute: (playerKey: PlayerKeys) => void;
    nudge: (amount: number) => void;
    nudgeVolume: (player: PlayerKeys, amount: number) => void;
    seek: (playerKey: PlayerKeys, position: number) => void;
    seekToWallClock: (time: Seconds) => void;
    // live: -dw to 0 - VOD: 0 to duration - you can get the seekable range from player.seekable
    getCurrentEditorTime: () => number;
    goLive: () => void;
    loop: (start: number, end: number) => void;
    stopLoop: () => void;
    getCurrentTime: (player?: PlayerKeys) => number; // used for precise current time updates (can be used in a loop)
    destroy: () => void;
}

export interface ITimeRange {
    start: number;
    end: number;
}

export interface IPlayerState {
    ready: boolean;
    paused: boolean;
    currentTime: number;
    atLiveEdge: boolean; // Is the stream at live, including a tolerance
    duration: number; // Length of stream
    live: boolean; // is this Live or VOD
    seekable: Doris.ISeekable; // seekable range, start/end
    waiting: boolean;
    muted: boolean;
    bufferedTimeRanges?: ITimeRange[];
    loadPDT: number;
    frameRate?: number;
    loop?: ITimeRange;
}

export interface IVideoContext extends IPlayerControls {
    [PlayerKeys.EDITOR]: IPlayerState;
    [PlayerKeys.PREVIEW]: IPlayerState;
    hlsStreamUrl: string;
    title: string;
    thumbnail: string;
    assetType: EAssetType;
    assetId: number;
    durationChangeDelta: number;
}

export interface IEditorParams {
    zoomLevel: EZoomLevel;
    editorMode: EditorModes;
}

export interface IEditorMethods {
    toggleEditorMode: (editorMode: EditorModes) => void;
    resetTimeLineState: VoidFunction;
    canTogglePreview: BooleanFunction;
    canPreview: BooleanFunction;
    isPreviewing: BooleanFunction;
    canExport: BooleanFunction;
    canResetTimeLineState: BooleanFunction;
    canZoom: (zoomLevel: EZoomLevel) => boolean;

    // thumbnails
    canAddThumbnail: BooleanFunction;
    captureImageData: VoidFunction;

    // timeline zooming
    handleTimelineZoom: (value: string) => void;
    handleTimelineZoomNudge: (zoomNudge: ZoomNudge) => void;
}

export interface IEditorContext extends IEditorParams, IEditorMethods {}

export enum EditorModes {
    EDIT = 'EDIT',
    PREVIEW = 'PREVIEW',
    EXPORT = 'EXPORT',
}

export enum EAssetType {
    CLIP = 'CLIP',
    LIVE = 'LIVE',
    VOD = 'VOD',
    ARCHIVE_VOD = 'ARCHIVE_VOD',
}

export enum ExportStep {
    SAVE_SELECTION = 'saveSelection',
    CLIP_SELECTION = 'clipSelection',
    COMBINE_CLIP = 'combineClip',
    CONFIRM_CREATE = 'confirmCreate',
    CONFIRM_OVERWRITE = 'confirmOverwrite',
    LOADING = 'loading',
    COMPLETE = 'complete',
}

export enum SaveAction {
    CREATE = 'create',
    OVERWRITE = 'overwrite',
}
