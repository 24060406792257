import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { FormSwitch } from '@dicetechnology/dice-backoffice-ui-components';

import TimesThinIcon from '~components/Icons/TimesThinIcon';
import { TrashBoldIcon } from '~components/Icons/TrashBoldIcon';
import { InlineNotification, NotificationType } from '~components/InlineNotification';
import LoadingSpinner from '~components/LoadingSpinner';
import Modal from '~components/Modal';
import { PageTheme } from '~components/Root/types';
import { makePermanentImage } from '~services/channels';
import { IChannelDetails } from '~services/channels/types';
import { joinStrings } from '~services/utilities';
import { uploadThumbnail } from '~src/store/channelManager/channelManager.utilities';

import { Dropdown } from './Dropdown';
import './index.scss';

interface IProps {
    canEditSettings: boolean;
    canEditTargetAdvertDuration: boolean;
    channelTitle: string;
    editState: {
        targetAdvertDuration: number;
        title: string;
        logo: IChannelDetails['logo'];
        enableBackupStream: boolean;
    };
    onConfirm: (state: IFormState) => void;
    onCancel: (event: React.MouseEvent) => void;
}

enum FieldKeys {
    TARGET_ADVERT_DURATION = 'targetAdvertDuration',
    TITLE = 'title',
    LOGO = 'logo',
    ENABLE_BACKUP_STREAM = 'enableBackupStream',
    // add new settings here
}

interface IFormState {
    [FieldKeys.TARGET_ADVERT_DURATION]: number;
    [FieldKeys.TITLE]: string;
    [FieldKeys.LOGO]: IChannelDetails['logo'];
    [FieldKeys.ENABLE_BACKUP_STREAM]: boolean;
}

const TARGET_ADVERT_DURATION_OPTIONS = Array.from({ length: 9 }, (_, i) => {
    const seconds = i * 15;
    return { label: `${seconds} Seconds`, value: seconds };
});

export const ChannelSettingsModal: React.FunctionComponent<IProps> = (props) => {
    const [formState, setFormState] = React.useState<IFormState>(props.editState);
    const logoInputRef = React.useRef<HTMLInputElement>(null);
    const [formFieldErrors, setFormFieldErrors] = React.useState({});
    const [formIsValid, setFormIsValid] = React.useState(false);

    const validateForm = () => {
        const newFormFieldErrors = {};
        let isValid = true;
        if (formState[FieldKeys.TITLE].trim().length === 0 || formState[FieldKeys.TITLE].length > 64) {
            newFormFieldErrors[FieldKeys.TITLE] = true;
            isValid = false;
        }

        setFormFieldErrors(newFormFieldErrors);
        setFormIsValid(isValid);
    };

    React.useEffect(() => {
        validateForm();
    }, [formState]);

    const getFileName = (url: string) => {
        return url.split('/').pop();
    };

    const [logoName, setLogoName] = React.useState<string>('');
    const [logoSize, setLogoSize] = React.useState<number>(0);
    const [logoUploading, setLogoUploading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLogoName(getFileName(props.editState.logo?.url || ''));
    }, [props.editState.logo]);

    const onConfirm = () => {
        props.onConfirm(formState);
    };

    const onFormChange = (key: FieldKeys) => (value: IFormState[typeof key]) => {
        setFormState((prevFormState) => ({
            ...prevFormState,
            [key]: value,
        }));
    };

    const onFormInputChange = (key: FieldKeys) => {
        if (key === FieldKeys.ENABLE_BACKUP_STREAM) {
            return ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
                onFormChange(key)(checked);
            };
        }
        return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            onFormChange(key)(value);
        };
    };

    const onFormFileInputChange = (key: FieldKeys) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files[0];
            if (!file) {
                onFormChange(key)('');
            }
            setLogoUploading(true);
            const fileName = file.name;
            const fileSize = file.size;
            const reader = new FileReader();
            // convert image file to base64 string
            reader.readAsDataURL(file);

            reader.addEventListener(
                'load',
                async () => {
                    const fileData = await uploadThumbnail(reader.result.toString(), true);
                    const permanentFile = await makePermanentImage(fileData.id);
                    setLogoName(fileName);
                    setLogoSize(fileSize);
                    onFormChange(key)({
                        fileId: permanentFile.permFileId,
                        url: permanentFile.permFileUrl,
                    });
                    setLogoUploading(false);
                },
                false
            );
        };
    };

    const onRemoveLogo = () => {
        onFormChange(FieldKeys.LOGO)('');
        setLogoName('');
        logoInputRef.current.value = '';
    };

    const onClickFileInput = () => {
        logoInputRef?.current?.click();
    };

    const $component = (
        <Modal.Main hasOverlay={true} className="channel-manager__modal channel-manager__channel-settings-modal">
            <div className="channel-manager__channel-settings-modal__title">
                <img src={formState.logo?.url} width={48} />
                {props.channelTitle}
            </div>
            <button className="channel-manager__channel-settings-modal__close-btn" onClick={props.onCancel}>
                <TimesThinIcon />
            </button>
            <Modal.Body className="channel-manager__channel-settings-modal__body">
                <div className="form-field-row">
                    <label htmlFor="title">Name</label>
                    <input
                        className={joinStrings(['form-field-row__input', formFieldErrors[FieldKeys.TITLE] && 'error'])}
                        type="text"
                        value={formState.title}
                        onChange={onFormInputChange(FieldKeys.TITLE)}
                        tabIndex={1}
                    />
                </div>
                {formFieldErrors[FieldKeys.TITLE] && (
                    <div className="form-field-row__error">
                        <span>Name is required and must be less than 64 characters.</span>
                    </div>
                )}
                <div className="form-field-row">
                    <label htmlFor="logo">Logo</label>
                    <div className="form-field-row__file-input">
                        {logoUploading && <LoadingSpinner />}
                        <h3>Drag and Drop Files</h3>
                        <h4>
                            Or{' '}
                            <a className="form-field-row__file-input__select" onClick={onClickFileInput}>
                                select from your computer
                            </a>
                        </h4>
                        <h5>Recommended size: 48 x 48</h5>
                        <input
                            className="form-field-row__input"
                            type="file"
                            ref={logoInputRef}
                            onChange={onFormFileInputChange(FieldKeys.LOGO)}
                            tabIndex={1}
                        />
                    </div>
                </div>
                {formState[FieldKeys.LOGO] && (
                    <div className="form-field-row">
                        <div />
                        <div className="form-field-row__file-preview">
                            <div className="form-field-row__file-preview__image-container">
                                <img src={formState[FieldKeys.LOGO]?.url} />
                            </div>
                            <div className="form-field-row__file-preview__file-meta">
                                <h4>{logoName}</h4>
                                {!!logoSize && <h4>{Math.round(logoSize / 1000)}kb</h4>}
                            </div>
                            <TrashBoldIcon className="form-field-row__file-preview__file-delete" onClick={onRemoveLogo} />
                        </div>
                    </div>
                )}
                {!props.canEditSettings && (
                    <InlineNotification
                        message={`Target Ad Duration cannot be changed until you publish (or delete) your pending changes`}
                        theme={PageTheme.LIGHT}
                        notificationType={NotificationType.ERROR}
                    />
                )}
                {!props.canEditTargetAdvertDuration && (
                    <div className="form-field-row">
                        <label htmlFor="targetAdvertDurationDropdown">Target Ad Duration</label>
                        <Dropdown
                            options={TARGET_ADVERT_DURATION_OPTIONS}
                            selected={formState.targetAdvertDuration}
                            uniqueId="targetAdvertDurationDropdown"
                            onSelected={onFormChange(FieldKeys.TARGET_ADVERT_DURATION)}
                        />
                    </div>
                )}
                <div className="form-field-row">
                    <label htmlFor="field-switch-input-enableBackupStream">Include backup</label>
                    <FormSwitch
                        name={FieldKeys.ENABLE_BACKUP_STREAM}
                        checked={formState.enableBackupStream}
                        onChange={onFormInputChange(FieldKeys.ENABLE_BACKUP_STREAM)}
                        id="enableBackupStream"
                        useLegacyStyles={true}
                    />
                </div>
            </Modal.Body>
            <div className="channel-manager__channel-settings-modal__footer">
                <button
                    className="channel-manager__channel-settings-modal__confirm-btn"
                    type="button"
                    onClick={onConfirm}
                    disabled={!props.canEditSettings || logoUploading || !formIsValid}
                >
                    Confirm
                </button>
            </div>
        </Modal.Main>
    );

    return ReactDOM.createPortal($component, document.getElementById('modal'));
};
