import { ExportStep, SaveAction } from '~containers/EditorPageContainer/types';
import { ExportActions } from '~src/store/editor/export/constants';

const resetExportProcess = () => ({
    type: ExportActions.RESET_SAGA,
});

const setExportStep = (step: ExportStep, saveAction?: SaveAction) => ({
    type: ExportActions.SET_STEP,
    payload: { step, saveAction },
});

const resetExportStep = () => ({
    type: ExportActions.RESET_STEP,
});

export { resetExportProcess, setExportStep, resetExportStep };
