import { SaveAction } from '~containers/EditorPageContainer/types';
import { PublishActions } from '~src/store/editor/publish/constants';
import { IExportClipWithMeta } from '~src/store/timeline/clip/types';

const publishClip = (exportClips: IExportClipWithMeta[], saveAction: SaveAction) => ({
    type: PublishActions.PUBLISH_CLIP_SAGA,
    payload: {
        exportClips,
        saveAction,
    },
});

export { publishClip };
