import { ToastAction } from '~src/store/toast/constants';
import { IToastState } from '~src/store/toast/types';

const DEFAULT_STATE: IToastState = {
    list: [],
};

const toastReducer = (state: IToastState = DEFAULT_STATE, { type, payload }): IToastState => {
    switch (type) {
        case ToastAction.ADD:
            return {
                list: state.list.concat(payload),
            };
        case ToastAction.DISMISS:
            return {
                list: state.list.filter((feedback) => feedback.id !== payload.id),
            };
        default:
            return state;
    }
};

export { toastReducer };
