import { IFeedbackContent, IFeedbackItem } from '~components/FeedbackModal';

import { FeedbackActions } from './constants';

const addFeedback = (payload: IFeedbackContent) => ({
    type: FeedbackActions.ADD_SAGA,
    payload,
});

const dismissFeedback = (id: IFeedbackItem['id']) => ({
    type: FeedbackActions.DISMISS,
    payload: { id },
});

export { addFeedback, dismissFeedback };
