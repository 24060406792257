import { IHttpResponse } from '@dicetechnology/dice-unity/lib/services/Http/types';

import { authHttp } from '~services/http';

import { PASSWORD_CONFIG_URL } from './constants';
import { PasswordConfiguration } from './types';

export const PasswordConfigurationService = () => {
    const getPasswordConfiguration = async (): Promise<PasswordConfiguration> => {
        const { parsedData } = await authHttp.get<IHttpResponse<PasswordConfiguration>>(PASSWORD_CONFIG_URL);
        return parsedData;
    };

    const getPasswordConfigurationByExid = async (userExid: string): Promise<PasswordConfiguration> => {
        const { parsedData } = await authHttp.get<IHttpResponse<PasswordConfiguration>>(`${PASSWORD_CONFIG_URL}/${userExid}`, {
            useAuth: false,
        });
        return parsedData;
    };

    const updatePasswordConfiguration = async (passwordConfiguration: PasswordConfiguration) =>
        authHttp.put<IHttpResponse<void>>(PASSWORD_CONFIG_URL, passwordConfiguration);

    const deletePasswordConfiguration = async () => authHttp.delete<IHttpResponse<void>>(PASSWORD_CONFIG_URL);

    return {
        getPasswordConfiguration,
        getPasswordConfigurationByExid,
        updatePasswordConfiguration,
        deletePasswordConfiguration,
    };
};
