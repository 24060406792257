import { combineReducers, Reducer } from 'redux';

import { editorReducer } from '~src/store//editor';
import { timelineReducer } from '~src/store//timeline';
import { feedbackReducer } from '~src/store/feedback/feedback.reducer';
import { paginationReducer } from '~src/store/pagination/pagination.reducer';
import { realmReducer } from '~src/store/realm/realm.reducer';
import { searchReducer } from '~src/store/search/search.reducer';
import { toastReducer } from '~src/store/toast/toast.reducer';

import { channelManagerReducer } from './channelManager/channelManager.reducer';

const rootReducer: Reducer = combineReducers({
    timeline: timelineReducer,
    editor: editorReducer,
    feedback: feedbackReducer,
    toast: toastReducer,
    pagination: paginationReducer,
    search: searchReducer,
    channelManager: channelManagerReducer,
    realm: realmReducer,
});

export { rootReducer };
