import * as moment from 'moment';
import * as React from 'react';

import { SimpleDropdownMenu, DropdownAlign } from '~components/SimpleDropdownMenu';
import { SwitchToggle } from '~components/SwitchToggle';
import { Meridiem } from '~services/utilities/time/constants';

interface IProps {
    minDate: string;
    maxDate: string;
    showUSDateTimeFormat: boolean;
    onDateSelected: (selectedDate: moment.Moment) => void;
}

interface IState {
    hour: number | null;
    minute: number | null;
    day: number | null;
    month: number | null;
}

enum ErrorMessages {
    INVALID_DATE = 'Date is invalid',
    OUTSIDE_DATE_RANGE = 'Date is unavailable',
}

const getDefaultState = (): IState => {
    return {
        hour: 9,
        minute: 0,
        day: null,
        month: null,
    };
};

const getHourIn24HrFormat = (hour: number, meridiem: Meridiem): number => {
    let updatedHour = hour;
    if (hour === 12) {
        updatedHour = 0;
    }

    if (meridiem === Meridiem.PM) {
        updatedHour += 12;
    }
    return updatedHour;
};

export const DateStripComponent = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const [error, setError] = React.useState<ErrorMessages>(null);
    const [meridiem, setMeridiem] = React.useState<Meridiem>(Meridiem.AM);
    const [state, setState] = React.useState<IState>(getDefaultState());

    const onChange = (key: keyof IState) => (value: number) => {
        setError(null);
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const monthCallback = React.useCallback(onChange('month'), []);
    const dayCallback = React.useCallback(onChange('day'), []);
    const hourCallback = React.useCallback(onChange('hour'), []);
    const minuteCallback = React.useCallback(onChange('minute'), []);

    const onToggleChange = () => {
        setError(null);
        setMeridiem((prevOption) => (prevOption === Meridiem.AM ? Meridiem.PM : Meridiem.AM));
    };

    const onGo = (setDropdownVisibility: React.Dispatch<React.SetStateAction<boolean>>) => () => {
        const selectedDate = moment({
            month: state.month - 1,
            day: state.day,
            hour: getHourIn24HrFormat(state.hour, meridiem),
            minute: state.minute,
        });
        const isInvalid = !selectedDate.isValid();
        const isOutOfRange = selectedDate.isBefore(props.minDate) || selectedDate.isAfter(props.maxDate);

        if (isInvalid) {
            setError(ErrorMessages.INVALID_DATE);
        } else if (isOutOfRange) {
            setError(ErrorMessages.OUTSIDE_DATE_RANGE);
        } else {
            props.onDateSelected(selectedDate);
            setDropdownVisibility(false);
            setState(getDefaultState());
        }
    };

    const orderBasedOnDateTimeFormat = () => {
        const monthField = (
            <TextField id="month" label="MM" min={1} max={12} callback={monthCallback} key="month" defaultValue={state.month} />
        );
        const dateField = <TextField id="day" label="DD" min={1} max={31} callback={dayCallback} key="day" defaultValue={state.day} />;
        const separator = (
            <span className="move-to__input-separator" key="separator">
                /
            </span>
        );
        const order = props.showUSDateTimeFormat ? [monthField, separator, dateField] : [dateField, separator, monthField];
        return <div className="move-to__input-group">{order}</div>;
    };

    const renderDropdown = (setVisibility: React.Dispatch<React.SetStateAction<boolean>>) => (
        <>
            <div className="move-to__label">Move to</div>
            {error && <div className="move-to__error">{error}</div>}
            <div className="move-to__input-group">
                <TextField id="hour" label="HH" min={1} max={12} callback={hourCallback} defaultValue={state.hour} />
                <span className="move-to__input-separator">:</span>
                <TextField id="minute" label="MM" min={0} max={60} callback={minuteCallback} defaultValue={state.minute} />
                <SwitchToggle
                    firstOption={Meridiem.AM}
                    secondOption={Meridiem.PM}
                    onToggleChange={onToggleChange}
                    activeOption={meridiem}
                />
            </div>
            {orderBasedOnDateTimeFormat()}
            <button className="move-to__go-button" onClick={onGo(setVisibility)} disabled={!canSubmit}>
                Go
            </button>
        </>
    );

    const canSubmit = Object.values(state).every((value) => value !== null);

    return (
        <React.Fragment>
            <div className="channel-manager__grid-line" />
            <div className="channel-manager__sticky-date-wrapper">
                <SimpleDropdownMenu
                    dropdownClassName="move-to__dropdown"
                    triggerChildren={
                        /* Sticky date will be shown inside this div */
                        <div className="channel-manager__sticky-date" ref={ref} />
                    }
                    dropdownAlign={DropdownAlign.CENTER}
                    closeOnMouseOut={false}
                    destroyOnClose={true}
                >
                    {renderDropdown}
                </SimpleDropdownMenu>
            </div>
        </React.Fragment>
    );
});

interface ITextFieldProps {
    defaultValue?: number;
    min: number;
    max: number;
    id: string;
    label: string;
    callback: (value: number) => void;
}

const TextField: React.FC<ITextFieldProps> = ({ min, max, callback, id, label, defaultValue }) => {
    const [error, setError] = React.useState<boolean>(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            value,
            validity: { valid },
        } = e.target;

        callback(value.trim().length && valid ? parseInt(value, 10) : null);
        setError(!valid);
    };

    return (
        <div className="move-to__input-wrapper">
            <label htmlFor={id}>{label}</label>
            <input
                defaultValue={defaultValue}
                min={min}
                max={max}
                type="number"
                onChange={onChange}
                className={error ? 'move-to__input-error' : ''}
            />
        </div>
    );
};

export const DateStrip = React.memo(DateStripComponent);

DateStrip.displayName = 'DateStrip';
