enum ThumbnailActions {
    ADD = 'timeline/thumbnail/ADD',
    ADD_WITH_CLIP = 'timeline/thumbnail/ADD_WITH_CLIP',

    REMOVE_SAGA = 'timeline/thumbnail/REMOVE_SAGA',
    REMOVE = 'timeline/thumbnail/REMOVE',

    CANCEL_SAGA = 'timeline/thumbnail/CANCEL_SAGA',
    CANCEL = 'timeline/thumbnail/CANCEL',

    // async update for image data
    UPDATE_IMAGE_DATA = 'timeline/thumbnail/UPDATE_IMAGE_DATA',

    // focus toggles
    SET_FOCUS_THUMBNAIL = 'timeline/thumbnail/SET_FOCUS_THUMBNAIL',

    // toggles
    TOGGLE_VISIBILITY = 'timeline/thumbnail/TOGGLE_VISIBILITY',
}

export { ThumbnailActions };
