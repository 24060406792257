import * as React from 'react';

import './index.scss';

interface IProps {
    onToggleChange: VoidFunction;
    activeOption: string;
    firstOption: string;
    secondOption: string;
}

export const SwitchToggle: React.FC<IProps> = ({ activeOption, firstOption, secondOption, onToggleChange }) => {
    return (
        <div className="toggle-switch-wrapper">
            <div className="toggle-switch" onClick={onToggleChange}>
                <div title={firstOption} className={`toggle-switch--button ${activeOption === firstOption && 'active'}`} />
                <div title={secondOption} className={`toggle-switch--button ${activeOption === secondOption && 'active'}`} />
            </div>
        </div>
    );
};
