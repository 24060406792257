import * as debounce from 'lodash.debounce';
import * as React from 'react';

import ZoomInIcon from '~components/Icons/ZoomInIcon';
import ZoomOutIcon from '~components/Icons/ZoomOutIcon';
import { EditorContext } from '~containers/EditorPageContainer/EditorContext';
import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import {
    DefaultZoomLevel,
    EZoomLevel,
    getZoomLevelFromValue,
    getZoomPointsFromDuration,
    getZoomValueFromLevel,
    MIN_ZOOM_VALUE,
    ZoomLevels,
    ZoomNudge,
} from '~services/zoomLevelService';

interface IProps {
    zoomLevel: EZoomLevel;
    handleTimelineZoom: (value: EZoomLevel) => void;
    handleTimelineZoomNudge: (zoomNudge: ZoomNudge) => void;
    duration: number;
}

const ZOOM_DEBOUNCE = 10;

const ZoomSlider: React.FunctionComponent<IProps> = ({ duration, zoomLevel, handleTimelineZoom, handleTimelineZoomNudge }) => {
    const delayedCallback = debounce(handleTimelineZoom, ZOOM_DEBOUNCE);

    const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const { value, max } = e.currentTarget;
        const nextZoomLevel: EZoomLevel = getZoomLevelFromValue(parseInt(value, 10), parseInt(max, 10));
        delayedCallback(nextZoomLevel);
    };

    const handleMouseUp = (e: React.SyntheticEvent<HTMLInputElement>) => e.currentTarget.blur();

    const handleZoomOutNudge = () => {
        handleTimelineZoomNudge(ZoomNudge.OUT);
    };

    const handleZoomInNudge = () => {
        handleTimelineZoomNudge(ZoomNudge.IN);
    };

    const maxZoomValue = getZoomPointsFromDuration(duration);
    const zoomScaleItem = ZoomLevels[zoomLevel] || DefaultZoomLevel;

    return (
        <div className="zoom-slider">
            <div className="zoom-slider__label">{zoomScaleItem.label}</div>
            <button onClick={handleZoomOutNudge}>
                <ZoomOutIcon />
            </button>
            <input
                type="range"
                min={MIN_ZOOM_VALUE}
                max={maxZoomValue}
                step="1"
                value={getZoomValueFromLevel(zoomLevel, maxZoomValue)}
                onChange={handleOnChange}
                onMouseUp={handleMouseUp}
            />
            <button onClick={handleZoomInNudge}>
                <ZoomInIcon />
            </button>
        </div>
    );
};

export default () => {
    const {
        editorPlayer: {
            seekable: { start, end },
        },
    } = React.useContext(VideoContext);
    const { zoomLevel, handleTimelineZoom, handleTimelineZoomNudge } = React.useContext(EditorContext);

    const duration = Math.abs(start - end) || 0;

    return (
        <ZoomSlider
            duration={duration}
            zoomLevel={zoomLevel}
            handleTimelineZoom={handleTimelineZoom}
            handleTimelineZoomNudge={handleTimelineZoomNudge}
        />
    );
};
