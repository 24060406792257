import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Button, ButtonTheme } from '~components/Button';
import Modal, { TextJustify } from '~components/Modal';

interface IProps {
    confirm: (event: React.MouseEvent) => void;
    cancel: (event: React.MouseEvent) => void;
    title?: string;
    message?: string;
    className?: string;
}

const ConfirmDialogComponent: React.FunctionComponent<IProps> = (props) => {
    const { title = null, message = null, confirm, cancel, className = '' } = props;

    const $component = (
        <Modal.Main hasOverlay={true} className={className}>
            {title !== null && <Modal.Title justify={TextJustify.CENTER}>{title}</Modal.Title>}
            {message !== null && (
                <Modal.Body justify="center" className="export-modal-form-panel">
                    {message}
                </Modal.Body>
            )}

            <div className="button-group mt-4 text-center">
                <Button theme={ButtonTheme.BLUE} type="button" onClick={confirm}>
                    Confirm
                </Button>
                <Button theme={ButtonTheme.BLACK} type="button" onClick={cancel}>
                    Cancel
                </Button>
            </div>
        </Modal.Main>
    );

    return ReactDOM.createPortal($component, document.getElementById('modal'));
};

const ConfirmDialog: React.NamedExoticComponent<IProps> = React.memo(ConfirmDialogComponent);

export { ConfirmDialog };
