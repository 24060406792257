import { IDiceFetch, IHttpResponse } from '@dicetechnology/dice-unity/lib/services/Http/types';

import { authHttp, http } from '~services/http';
import { USER_REALM_KEY } from '~services/http/constants';
import { storageProvider } from '~services/storage';
import { Realm } from '~src/types';

enum UserEndpoints {
    GET = '/user/profile',
    CURRENT_USER = '/users/current',
}

interface IGetProfileResponse {
    name?: {
        fullName?: string;
        preferredName?: string;
    };
    id: string;
    contactEmail: string;
    createdDate: number;
}

interface ICurrentUserResponse {
    realms: Realm[];
}

class ProfileService {
    public static async getProfile(): Promise<IGetProfileResponse> {
        const realm = await storageProvider.get(USER_REALM_KEY);

        const fetchOptions: IDiceFetch = {
            headers: {
                Realm: realm,
            },
        };

        const { parsedData } = await authHttp.get(UserEndpoints.GET, fetchOptions);
        return parsedData;
    }

    public static async getCurrentUser(fetchOptions: IDiceFetch = {}): Promise<ICurrentUserResponse> {
        const { parsedData } = await http.get<IHttpResponse<ICurrentUserResponse>>(UserEndpoints.CURRENT_USER, fetchOptions);
        return parsedData;
    }
}

export { ProfileService, UserEndpoints, ICurrentUserResponse };
