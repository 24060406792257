import { combineReducers, Reducer } from 'redux';

import { clipReducer } from './clip/clip.reducer';
import { thumbnailReducer } from './thumbnail/thumbnail.reducer';
import { toggleReducer } from './toggle/toggle.reducer';

const timelineReducer: Reducer = combineReducers({
    clips: clipReducer,
    thumbnails: thumbnailReducer,
    toggles: toggleReducer,
});

export { timelineReducer };
