import * as React from 'react';

import { Card } from '~components/Card';
import { CardGrid } from '~components/CardGrid';
import { LoadMoreButton } from '~components/LoadMoreButton';
import { TSearchTerm } from '~components/Root/types';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { CARD_SIZE_SETTINGS } from '~pages/Browse/components/constants';
import { EServiceStatus } from '~services/http/enums';
import { IContentType, IStringDictionary } from '~src/types';

interface IProps {
    results: IContentType[];
    status: string;
    hasMoveResults: boolean;
    searchTerm: TSearchTerm;
    getMoreResults: VoidFunction;
    onOpenPreviewModal: (assetId: number, assetType: EAssetType, canCreateClip: boolean, externalUrl: string) => void;
    pagerFeedback: {
        [key: string]: IStringDictionary;
    };
    assetType: Exclude<EAssetType, EAssetType.ARCHIVE_VOD>;
    loadMoreDataTestId?: string;
}

const PaginationContent: React.FunctionComponent<IProps> = ({
    results,
    status,
    hasMoveResults,
    getMoreResults,
    searchTerm,
    onOpenPreviewModal,
    pagerFeedback,
    assetType,
    loadMoreDataTestId,
}) => {
    return (
        <React.Fragment>
            {!!results.length && (
                <React.Fragment>
                    <CardGrid.Results type={assetType}>
                        {results.map((item) => (
                            <Card
                                key={item.id}
                                assetType={assetType}
                                card={item}
                                settings={CARD_SIZE_SETTINGS[assetType]}
                                onPreviewClick={onOpenPreviewModal}
                            />
                        ))}
                    </CardGrid.Results>
                    {hasMoveResults && <LoadMoreButton onClick={getMoreResults} dataTestId={loadMoreDataTestId} />}
                </React.Fragment>
            )}
            {status === EServiceStatus.LOADING && <CardGrid.Loading />}
            {status === EServiceStatus.LOADED && !results.length && (
                <CardGrid.Message title={pagerFeedback.noResults.title.replace('{{searchTerm}}', searchTerm)} />
            )}
            {status === EServiceStatus.ERROR && (
                <CardGrid.Message title={pagerFeedback.serverError.title} message={pagerFeedback.serverError.message} />
            )}
        </React.Fragment>
    );
};

export { PaginationContent };
