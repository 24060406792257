import styled from 'styled-components';

import { RGBA } from '@dicetechnology/dice-backoffice-ui-components';

export const Version = styled.div`
    position: absolute;
    bottom: 10px;
    width: 100%;
    font-size: 10px;
    text-align: center;
    color: ${({ theme }) => RGBA(theme.colors.trueWhite, 0.6)};
`;
