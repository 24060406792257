import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { Toast } from '~components/ToastRack/Toast';
import { getToastState } from '~src/store/toast/toast.selectors';
import { IToastSlice } from '~src/store/toast/types';
import { useShallowEqualSelector } from '~src/views/hooks';

import './index.scss';

interface IProps {
    list: IToastSlice[];
    dispatch: Dispatch;
}

const ToastRackComponent = ({ list, dispatch }: IProps) => {
    return (
        <div className="toast-rack">
            <ul className="toast__list">
                {list.map((toast: IToastSlice) => (
                    <Toast key={toast.id} toast={toast} dispatch={dispatch} />
                ))}
            </ul>
        </div>
    );
};

const ToastRack = () => {
    const dispatch = useDispatch();
    const { list } = useShallowEqualSelector(getToastState);
    return <ToastRackComponent list={list} dispatch={dispatch} />;
};

export { ToastRack };
