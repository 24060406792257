import { IStorageProvider } from '@dicetechnology/dice-unity/src/services/Storage/types';

export class SessionStorageProvider implements IStorageProvider {
    private readonly keyPrefix: string;

    constructor(prefix: string) {
        this.keyPrefix = `${prefix}_`;
    }

    private getKey = (key: string) => `${this.keyPrefix}${key}`;

    public get = (key: string): Promise<string | null> => {
        const value = sessionStorage.getItem(this.getKey(key));
        return Promise.resolve(value);
    };

    public set = (key: string, value: string): Promise<void> => {
        sessionStorage.setItem(this.getKey(key), value);
        return Promise.resolve();
    };

    public remove = (key: string): Promise<void> => {
        sessionStorage.removeItem(this.getKey(key));
        return Promise.resolve();
    };
}
