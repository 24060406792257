import * as React from 'react';
import { Link } from 'react-router-dom';

import * as logoSrc from '~assets/images/svgs/brand-logo.svg';
import { PageRoutes } from '~components/Root/constants';

import './index.scss';

export const LandingPage: React.FC = () => {
    return (
        <div className="landing-page">
            <div className="landing-page__logo-container">
                <img src={logoSrc} alt="Endeavor Streaming logo" />
            </div>

            <div className="landing-page__welcome-message">Welcome to</div>
            <div className="landing-page__app-name">Studio</div>
            <div className="landing-page__modes-container">
                <div className="landing-page__mode landing-page--edit-mode">
                    <div className="landing-page__background landing-page__background--top" />
                    <Link to={PageRoutes.EDITOR}>Editor</Link>
                    <div className="landing-page__background landing-page__background--bottom" />
                </div>
                <div className="landing-page__mode landing-page--channels-mode">
                    <div className="landing-page__background landing-page__background--top" />
                    <Link to={PageRoutes.LIVE_CHANNELS}>Broadcaster</Link>
                    <div className="landing-page__background landing-page__background--bottom" />
                </div>
            </div>
        </div>
    );
};
