import { ThumbnailActions } from '~src/store/timeline/thumbnail/constants';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { Base64ImageUri, Seconds, V4Uid } from '~src/types';

const addThumbnail = (renderThumbnailTime: Seconds, exportThumbnailTime: Seconds) => ({
    type: ThumbnailActions.ADD,
    payload: {
        renderThumbnailTime,
        exportThumbnailTime,
    },
});

const addThumbnailWithClip = (thumbnail: ITimelineThumbnail, clipIdPendingThumbnail: V4Uid = null) => ({
    type: ThumbnailActions.ADD_WITH_CLIP,
    payload: {
        thumbnail,
        clipIdPendingThumbnail,
    },
});

const updateThumbnailImageData = (imageData: Base64ImageUri) => ({
    type: ThumbnailActions.UPDATE_IMAGE_DATA,
    payload: imageData,
});

const removeThumbnail = (id: ITimelineThumbnail['id']) => ({
    type: ThumbnailActions.REMOVE_SAGA,
    payload: {
        id,
    },
});

const cancelThumbnail = () => ({
    type: ThumbnailActions.CANCEL_SAGA,
});

const setFocusThumbnail = (id: ITimelineThumbnail['id']) => ({
    type: ThumbnailActions.SET_FOCUS_THUMBNAIL,
    payload: {
        id,
    },
});

const toggleThumbnailVisibility = () => ({
    type: ThumbnailActions.TOGGLE_VISIBILITY,
});

export {
    addThumbnail,
    removeThumbnail,
    cancelThumbnail,
    setFocusThumbnail,
    toggleThumbnailVisibility,
    addThumbnailWithClip,
    updateThumbnailImageData,
};
