import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { EAssetType, ExportStep, SaveAction } from '~containers/EditorPageContainer/types';
import { createClipFromClip, overwriteClipFromClip } from '~services/clip';
import { IClientClip, IClientClipCreate, IClientThumbnail, IClipComponent } from '~services/clip/types';
import { Console } from '~services/console';
import { createClipFromEvent } from '~services/live';
import { StreamType } from '~services/live/types';
import { getRecentlyCreatedService } from '~services/recentlyCreated';
import { createClipFromVod, overwriteClipFromVod } from '~services/vod';
import { uploadThumbnail } from '~src/store/channelManager/channelManager.utilities';
import { ExportActions } from '~src/store/editor/export/constants';
import { PublishActions } from '~src/store/editor/publish/constants';
import { getStreamState } from '~src/store/editor/stream/stream.selectors';
import { IStreamUrl, IEditorStreamState } from '~src/store/editor/stream/types';
import { PaginationActions } from '~src/store/pagination/constants';
import { IExportClipWithMeta } from '~src/store/timeline/clip/types';

const RECENTLY_CREATED_PAGE_SIZE = 6;

function filterStreamUrls(streamUrls: IStreamUrl[], drmFilter: boolean = null, typeFilter: StreamType = null): IStreamUrl[] {
    return streamUrls.filter((streamUrl: IStreamUrl) => {
        if (drmFilter !== null) {
            if (streamUrl.drm !== drmFilter) {
                return false;
            }
        }

        if (typeFilter !== null) {
            if (streamUrl.type !== typeFilter) {
                return false;
            }
        }

        return true;
    });
}

function isCombinedClip(components: IClipComponent[]) {
    return components.length > 1;
}

function isCreateLiveClip(saveAction: SaveAction, clientClip: IClientClip, assetType: EAssetType) {
    return isCreateClip(saveAction, clientClip) && assetType === EAssetType.LIVE;
}

function isCreateClip(saveAction: SaveAction, clientClip: IClientClip): clientClip is IClientClipCreate {
    return saveAction === SaveAction.CREATE;
}

const clientClipFactory = (
    title: string,
    components,
    thumbnail: IClientThumbnail,
    hlsStreamUrl: string,
    dashStreamUrl: string,
    streamUrls: IEditorStreamState['streamUrls'],
    saveAction: SaveAction,
    assetType: EAssetType,
    streamingConfigId: number
): IClientClip => {
    const { origin, pathname }: URL = new URL(hlsStreamUrl);

    const clientClip: IClientClip = {
        hlsStreamUrl: origin + pathname,
        components,
    };

    if (dashStreamUrl) {
        const { origin: dashOrigin, pathname: dashPathname }: URL = new URL(dashStreamUrl);
        clientClip.dashStreamUrl = dashOrigin + dashPathname;
    }

    if (isCreateClip(saveAction, clientClip)) {
        clientClip.videoName = title;
        if (thumbnail) {
            clientClip.thumbnail = thumbnail;
        }
    }

    if (isCreateLiveClip(saveAction, clientClip, assetType)) {
        // Live Clips now use the v4 API, which now uses streamUrls.
        delete clientClip.hlsStreamUrl;
        delete clientClip.dashStreamUrl;
        // Stream URLs should not have any auth tokens applied
        let sanitizedStreamUrls = streamUrls.map((streamUrl: IStreamUrl) => {
            let url = streamUrl.url;
            const queryStringIndex = url.indexOf('?');
            if (queryStringIndex !== -1) {
                url = url.substr(0, queryStringIndex);
            }
            return {
                ...streamUrl,
                url,
            } as IStreamUrl;
        });

        const liveStreamHasDrm = sanitizedStreamUrls.some((stream: IStreamUrl) => stream.drm);
        const clipIsCombinedClip = isCombinedClip(components);

        /*
          If the live stream is non-DRM:
              send all non-DRM streams for single clips (ie. both HLS + DASH if DASH is available)
              send just the HLS non-DRM stream for combined clips
          If the live stream is DRM:
              send all DRM streams + HLS non-DRM stream for single clips
              send just the HLS non-DRM stream for combined clips
         */

        if (liveStreamHasDrm) {
            if (clipIsCombinedClip) {
                // send just the HLS non-DRM stream for combined clips
                sanitizedStreamUrls = filterStreamUrls(sanitizedStreamUrls, false, 'HLS');
            } else {
                // send all DRM streams + HLS non-DRM stream for single clips
                // i.e. send all streams
            }
        } else {
            if (clipIsCombinedClip) {
                // send just the HLS non-DRM stream for combined clips
                sanitizedStreamUrls = filterStreamUrls(sanitizedStreamUrls, false, 'HLS');
            } else {
                // send all non-DRM streams for single clips (ie. both HLS + DASH if DASH is available)
                sanitizedStreamUrls = filterStreamUrls(sanitizedStreamUrls, false);
            }
        }

        clientClip.requestStreams = sanitizedStreamUrls;

        if (streamingConfigId) {
            clientClip.streamingConfigId = streamingConfigId;
        }
    }

    return clientClip;
};

const buildPublishClipRequest = async (clip: IExportClipWithMeta, streamState: IEditorStreamState, saveAction: SaveAction) => {
    const {
        components,
        meta: { title, thumbnail },
    } = clip;
    const { assetId, hlsStreamUrl, dashStreamUrl, assetType, streamUrls, streamingConfigId } = streamState;

    let clientThumbnail: IClientThumbnail | undefined;

    try {
        // do not overwrite the thumbnail on clip updating
        if (saveAction === SaveAction.CREATE && thumbnail) {
            clientThumbnail = await uploadThumbnail(thumbnail);
        }

        const clientClip = clientClipFactory(
            title,
            components,
            clientThumbnail,
            hlsStreamUrl,
            dashStreamUrl,
            streamUrls,
            saveAction,
            assetType,
            streamingConfigId
        );

        if (isCreateClip(saveAction, clientClip)) {
            if (assetType === EAssetType.LIVE) {
                return await createClipFromEvent(assetId, clientClip);
            } else if (assetType === EAssetType.CLIP) {
                return await createClipFromClip(assetId, clientClip);
            } else if (assetType === EAssetType.VOD) {
                return await createClipFromVod(assetId, clientClip);
            }
        } else {
            if (assetType === EAssetType.CLIP) {
                return await overwriteClipFromClip(assetId, clientClip);
            } else if (assetType === EAssetType.VOD) {
                return await overwriteClipFromVod(assetId, clientClip);
            }
        }
    } catch (error) {
        Console.warn("Processing 'Publish Clips'", error);
        return undefined;
    }
};

function* resetRecentClipsPagination(assetType: EAssetType, assetId: number) {
    const pageService = getRecentlyCreatedService(assetType, RECENTLY_CREATED_PAGE_SIZE, assetId);
    yield put({ type: PaginationActions.ADD_SAGA, payload: { stateKey: 'recent-clip', pageService } });
}

function* handlePublishClip(action) {
    const { exportClips, saveAction } = action.payload;

    // set export step loading
    yield put({ type: ExportActions.SET_STEP, payload: { step: ExportStep.LOADING } });

    // get stream meta
    const streamState: IEditorStreamState = yield select(getStreamState);

    // process publish via a 'loop' ¯\_(ツ)_/¯ backend short-come
    const requests = exportClips.map((exportClip) => {
        return call(buildPublishClipRequest, exportClip, streamState, saveAction);
    });

    const responses = yield all(requests);

    // used just to count the number of errored exports
    const errors: undefined[] = responses.filter((response) => response === undefined);

    yield put({ type: ExportActions.SET_ERROR_COUNT, payload: { errorCount: errors.length, totalClips: requests.length } });

    // complete
    yield put({ type: ExportActions.SET_STEP, payload: { step: ExportStep.COMPLETE } });

    yield resetRecentClipsPagination(streamState.assetType, streamState.assetId);
}

export const publishSagas = [takeEvery(PublishActions.PUBLISH_CLIP_SAGA, handlePublishClip)];
