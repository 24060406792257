const required = (id: string, value: string): string | void => {
    if (value.trim() === '') {
        // break string on uppercase characters, lower case the entire sting - 'testTitleString' -> 'test title string'
        const words: string = id.replace(/([A-Z])/g, ' $1').toLowerCase();
        const friendlyFieldName = words.charAt(0).toUpperCase() + words.slice(1);
        return `${friendlyFieldName} is a required field`;
    }
};

export { required };
