import { ISettingsSettings } from '~services/settings';
import * as ClipUtils from '~src/store/timeline/clip/clip.utilities';
import { ITimelineClip, ITimelineClipState } from '~src/store/timeline/clip/types';
import { IStudioState } from '~src/store/types';

const getExportableClips = (minimumClipDuration: ISettingsSettings['minimumClipDuration']) => (state: IStudioState): ITimelineClip[] => {
    const { list, exportClipId } = state.timeline.clips;

    return exportClipId ? [ClipUtils.getClipById(list, exportClipId)] : ClipUtils.getExportableClips(list, minimumClipDuration).reverse();
};

const getPreviewClipId = (state: IStudioState): ITimelineClipState['previewClipId'] => {
    return state.timeline.clips.previewClipId;
};

const getFocusClipId = (state: IStudioState): ITimelineClipState['hoverClipId'] => {
    return state.timeline.clips.hoverClipId;
};

const getActiveClipId = (state: IStudioState): ITimelineClipState['activeClipId'] => {
    return state.timeline.clips.activeClipId;
};

const getClips = (state: IStudioState): ITimelineClipState['list'] => {
    return state.timeline.clips.list;
};

const getCurrentClip = (state: IStudioState): ITimelineClip => {
    const { activeClipId, list } = state.timeline.clips;
    return activeClipId ? list.find((clip) => clip.id === activeClipId) : list[0];
};

export { getPreviewClipId, getFocusClipId, getActiveClipId, getClips, getCurrentClip, getExportableClips };
