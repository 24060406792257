import * as React from 'react';

import placeholder from '~assets/images/no-thumbnail.jpg';
import { Button, ButtonTheme } from '~components/Button';
import { getFormattedDuration } from '~components/Card/utilities';
import { ExportClipPreview } from '~components/Editor/ExportClip/Components/ExportClipPreview';
import ArrowRightIcon from '~components/Icons/ArrowRightIcon';
import Modal, { TextJustify } from '~components/Modal';
import { getStreamState } from '~src/store/editor/stream/stream.selectors';
import { IEditorStreamState } from '~src/store/editor/stream/types';
import { ClipUtilities } from '~src/store/timeline/clip/clip.utilities';
import { IExportClipWithMeta } from '~src/store/timeline/clip/types';
import { useShallowEqualSelector } from '~src/views/hooks';

interface IContainerProps {
    clip: IExportClipWithMeta;
    back: VoidFunction;
    submit: VoidFunction;
}

interface IComponentProps extends IContainerProps {
    streamState: IEditorStreamState;
}

const ConfirmOverwriteComponent: React.FunctionComponent<IComponentProps> = (props) => {
    const { streamState, clip, submit, back } = props;
    const { title, duration: existingDuration, createdAt, thumbnail } = streamState;
    const {
        meta: { duration: newDuration },
    } = clip;

    const isCombined = ClipUtilities.isCombined(clip);
    const badgeText = isCombined && `Combined - ${ClipUtilities.getComponentCount(clip)} clips`;

    const existingFormattedDuration = getFormattedDuration(existingDuration);
    const newFormattedDuration = `${isCombined ? 'Combined' : 'New'} duration - ${getFormattedDuration(newDuration)}`;

    return (
        <React.Fragment>
            <Modal.Title justify={TextJustify.CENTER}>Are you sure you want to replace this clip?</Modal.Title>
            <Modal.Body justify={TextJustify.CENTER} className="export-modal__confirm-clip-overwrite">
                <div className="export-modal__step-description">
                    <p>You have chosen to replace an existing clip, please verify the updates are accurate.</p>
                </div>

                <div className="overwrite-comparison">
                    <ExportClipPreview
                        title={title}
                        thumbnail={thumbnail || placeholder}
                        duration={existingFormattedDuration}
                        createdAt={createdAt}
                    />

                    <ArrowRightIcon className="overwrite-comparison__separator" height="30" width="30" />
                    <ExportClipPreview
                        title={title}
                        thumbnail={thumbnail || placeholder}
                        duration={newFormattedDuration}
                        badgeText={badgeText}
                    />
                </div>

                <div className="button-group mt-4 text-center">
                    <Button theme={ButtonTheme.BLUE} onClick={submit}>
                        Replace Content
                    </Button>
                    {back && (
                        <Button theme={ButtonTheme.BLACK} type="button" onClick={back}>
                            Back
                        </Button>
                    )}
                </div>
            </Modal.Body>
        </React.Fragment>
    );
};

const ConfirmOverwriteMemo: React.NamedExoticComponent<IComponentProps> = React.memo(ConfirmOverwriteComponent);

const ConfirmOverwrite: React.FunctionComponent<IContainerProps> = (props) => {
    const { clip, submit, back } = props;
    const streamState = useShallowEqualSelector(getStreamState);

    return <ConfirmOverwriteMemo streamState={streamState} clip={clip} submit={submit} back={back} />;
};

export { ConfirmOverwrite };
