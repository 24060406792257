import debounce from 'lodash.debounce';
import * as React from 'react';

import { LeftArrow, RightArrow } from './Arrow';
import './index.scss';

const SCROLL_OFFSET: number = 30;

type IScrollHTMLElement = HTMLDivElement | null;

const useWindowWidth = (): number => {
    const [width, setWidth] = React.useState<number>(window.innerWidth);

    React.useEffect(() => {
        const handleResize = debounce(() => setWidth(window.innerWidth), 500);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    return width;
};

const determineOverflow = (container: IScrollHTMLElement, content: IScrollHTMLElement): boolean[] => {
    let leftOverflown: boolean = false;
    let rightOverflown: boolean = false;
    if (container && content) {
        const { width: containerWidth } = container.getBoundingClientRect();
        const { scrollWidth, scrollLeft } = content;

        if (scrollLeft >= SCROLL_OFFSET) {
            leftOverflown = true;
        }

        if (scrollWidth - SCROLL_OFFSET > containerWidth + scrollLeft) {
            rightOverflown = true;
        }
    }

    return [leftOverflown, rightOverflown];
};

interface IScrollAreaProps {
    children: React.ReactNode;
}

const ScrollArea: React.FC<IScrollAreaProps> = (props) => {
    const [leftArrowActive, setLeftArrow] = React.useState<boolean>(false);
    const [rightArrowActive, setRightArrow] = React.useState<boolean>(false);
    const windowWidth: number = useWindowWidth();
    const scrollAreaRef = React.useRef<HTMLDivElement>(null);
    const scrollAreaWrapperRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        setArrowVisibility();
    }, [windowWidth]);

    const scrollLeft = (e: React.MouseEvent) => {
        if (scrollAreaRef.current) {
            scrollAreaRef.current.scrollLeft -= scrollAreaRef.current.clientWidth;
            setArrowVisibility();
        }
        e.stopPropagation();
        e.preventDefault();
    };

    const scrollRight = (e: React.MouseEvent) => {
        const { current: scrollArea } = scrollAreaRef;
        const { current: scrollAreaWrapper } = scrollAreaWrapperRef;

        if (scrollArea && scrollAreaWrapper) {
            scrollArea.scrollLeft += scrollAreaRef.current.clientWidth;
            setArrowVisibility();
        }
        e.stopPropagation();
        e.preventDefault();
    };

    const setArrowVisibility = () => {
        // Give a slight delay before re-calculating, so that the browser has time to reflow
        setTimeout(() => {
            const { current: scrollArea } = scrollAreaRef;
            const { current: scrollAreaWrapper } = scrollAreaWrapperRef;
            const [leftState, rightState]: boolean[] = determineOverflow(scrollAreaWrapper, scrollArea);
            setLeftArrow(leftState);
            setRightArrow(rightState);
        }, 100);
    };

    return (
        <div className="scroll-area" ref={scrollAreaWrapperRef}>
            {leftArrowActive && <LeftArrow clickHandler={scrollLeft} />}
            <div className="scroll-area__scroller" ref={scrollAreaRef}>
                {props.children}
            </div>
            {rightArrowActive && <RightArrow clickHandler={scrollRight} />}
        </div>
    );
};

export default React.memo(ScrollArea);
