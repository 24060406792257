import { ClipActions } from '~src/store/timeline/clip/constants';
import { ITimelineClipState } from '~src/store/timeline/clip/types';
import { PlayerActions, TimelineActions } from '~src/store/timeline/constants';

const DEFAULT_STATE: ITimelineClipState = {
    list: [],
    hoverClipId: null,
    previewClipId: null,
    editClipId: null,
    activeClipId: null,
    exportClipId: null,
};

const clipReducer = (state: ITimelineClipState = DEFAULT_STATE, { type, payload }): ITimelineClipState => {
    switch (type) {
        case ClipActions.ADD_NEW_CLIP: {
            const { clip } = payload;
            return {
                ...state,
                list: [clip, ...state.list],
            };
        }
        case ClipActions.ADD_CLIP_IN_MARKER: {
            const { id, renderClipInTime, exportClipInTime } = payload;
            if (id) {
                return {
                    ...state,
                    list: state.list.map((clip) => {
                        if (clip.id === id) {
                            // adjust out markers as well
                            if (exportClipInTime > clip.exportClipOutTime) {
                                return {
                                    ...clip,
                                    renderClipInTime,
                                    exportClipInTime,
                                    renderClipOutTime: renderClipInTime,
                                    exportClipOutTime: exportClipInTime,
                                };
                            }
                            return {
                                ...clip,
                                renderClipInTime,
                                exportClipInTime,
                            };
                        }
                        return clip;
                    }),
                };
            } else if (state.list.length) {
                const newState = [...state.list];
                const clip = newState.shift();
                return {
                    ...state,
                    list: [{ ...clip, ...payload, id: clip.id }, ...newState],
                };
            }
            return state;
        }
        case ClipActions.ADD_CLIP_OUT_MARKER_BY_ID: {
            const { id, renderClipOutTime, exportClipOutTime } = payload;
            return {
                ...state,
                list: state.list.map((clip) => {
                    if (clip.id === id) {
                        // adjust out markers as well
                        if (exportClipOutTime < clip.exportClipInTime) {
                            return {
                                ...clip,
                                renderClipOutTime,
                                exportClipOutTime,
                                renderClipInTime: renderClipOutTime,
                                exportClipInTime: exportClipOutTime,
                            };
                        }
                        return {
                            ...clip,
                            renderClipOutTime,
                            exportClipOutTime,
                        };
                    }
                    return clip;
                }),
            };
        }
        case ClipActions.REMOVE_CLIP: {
            const { id } = payload;
            return {
                ...state,
                list: state.list.filter((clip) => clip.id !== id),
            };
        }
        case ClipActions.SET_FOCUS_CLIP: {
            const { id } = payload;
            return {
                ...state,
                hoverClipId: id,
            };
        }
        case ClipActions.SET_PREVIEW_CLIP: {
            const { id } = payload;
            return {
                ...state,
                previewClipId: id,
                // if the clip is previewing, make it active
                activeClipId: id,
            };
        }
        case ClipActions.SET_EDIT_CLIP: {
            const { id } = payload;
            return {
                ...state,
                editClipId: id,
            };
        }
        case ClipActions.SET_ACTIVE_CLIP: {
            const { id } = payload;
            return {
                ...state,
                activeClipId: id,
            };
        }
        case ClipActions.SET_EXPORT_CLIP: {
            const { id } = payload;
            return {
                ...state,
                exportClipId: id,
            };
        }
        case ClipActions.SET_TITLE: {
            const { id, title } = payload;
            return {
                ...state,
                list: state.list.map((clip) => {
                    if (clip.id === id) {
                        return { ...clip, title };
                    }
                    return clip;
                }),
            };
        }
        case ClipActions.SET_THUMBNAIL: {
            const { id, thumbnailId } = payload;
            return {
                ...state,
                list: state.list.map((clip) => {
                    if (clip.id === id) {
                        return { ...clip, thumbnailId };
                    }
                    return clip;
                }),
            };
        }
        case ClipActions.REMOVE_THUMBNAIL_BY_THUMBNAIL_ID: {
            const { thumbnailId } = payload;
            return {
                ...state,
                list: state.list.map((clip) => {
                    if (clip.thumbnailId === thumbnailId) {
                        return { ...clip, thumbnailId: null };
                    }
                    return clip;
                }),
            };
        }
        case PlayerActions.LEVEL_LOADED_SHIFT:
            return {
                ...state,
                list: state.list.map((clip) => {
                    const inTime = clip.renderClipInTime;
                    const outTime = clip.renderClipOutTime;
                    return {
                        ...clip,
                        renderClipInTime: inTime !== null ? inTime - payload : inTime,
                        renderClipOutTime: outTime !== null ? outTime - payload : outTime,
                    };
                }),
            };
        case TimelineActions.RESET:
            return DEFAULT_STATE;
        default:
            return state;
    }
};

export { clipReducer };
