import { createStore, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from './root.reducer';
import { rootSaga } from './root.saga';

const configureStore = (): Store => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware];
    const composeEnhancers = composeWithDevTools({});
    const enhancer = composeEnhancers(applyMiddleware(...middlewares));

    const store: Store = createStore(rootReducer, {}, enhancer);

    sagaMiddleware.run(rootSaga);
    return store;
};

export { configureStore };
