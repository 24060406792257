import * as React from 'react';

export const InfoCircleIconLight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" {...props}>
            <path
                d="M9.992 7.927V5.862h2.016v2.065H9.992zM11 19.21a7.992 7.992 0 004.13-1.13 7.962 7.962 0 002.95-2.95A7.992 7.992 0 0019.211 11a7.992 7.992 0 00-1.13-4.13 7.962 7.962 0 00-2.95-2.95A7.992 7.992 0 0011 2.789a7.992 7.992 0 00-4.13 1.13 7.962 7.962 0 00-2.95 2.95A7.992 7.992 0 002.789 11c0 1.475.377 2.852 1.13 4.13a7.962 7.962 0 002.95 2.95A7.992 7.992 0 0011 19.211zM11 .773c1.868 0 3.598.467 5.187 1.401a9.693 9.693 0 013.639 3.639A10.058 10.058 0 0121.227 11c0 1.868-.467 3.598-1.401 5.187a9.693 9.693 0 01-3.639 3.639A10.058 10.058 0 0111 21.227c-1.868 0-3.598-.467-5.187-1.401a9.877 9.877 0 01-3.639-3.663A10.013 10.013 0 01.773 11c0-1.852.467-3.573 1.401-5.163a10.065 10.065 0 013.663-3.663A10.013 10.013 0 0111 .773zM9.992 16.138V9.992h2.016v6.146H9.992z"
                fill="#84929B"
                fillRule="nonzero"
            />
        </svg>
    );
};
