import * as moment from 'moment';
import * as React from 'react';

import { CardDescription } from '~components/Card/CardDescription';
import { CardPreviewLink } from '~components/Card/CardPreviewLink';
import { CardThumbnail } from '~components/Card/CardThumbnail';
import { CardTitle } from '~components/Card/CardTitle';
import { getAssetUrl, getThumbnailUrl } from '~components/Card/utilities';
import { SmartAnchor } from '~components/SmartAnchor';
import { IEvent } from '~services/live/types';
import { joinStrings } from '~services/utilities';
import { ICardSettings } from '~src/types';

import { CardSubTitle } from './CardSubTitle';

interface ILiveCardProps {
    settings: ICardSettings;
    card: IEvent;
    onPreviewClick: (canCreateClips: boolean, externalUrl: string) => (event: React.MouseEvent) => void;
}

const EDITOR_LIVE_VIDEO_URL = '/editor/live/{assetId}';

const LiveCard: React.FunctionComponent<ILiveCardProps & React.HTMLAttributes<HTMLDivElement>> = ({ settings, card, onPreviewClick }) => {
    const cardClasses = joinStrings(['card', settings.layout ? `card--${settings.layout}` : 'card--vertical']);
    const thumbnailSrc = getThumbnailUrl(card.thumbnail, settings);
    return (
        <div className={cardClasses} data-test-id="liveCard">
            <SmartAnchor to={getAssetUrl(EDITOR_LIVE_VIDEO_URL, card.id)} className="card-content">
                <CardThumbnail
                    thumbnailSrc={thumbnailSrc}
                    thumbnailHeight={settings.height}
                    thumbnailWidth={settings.width}
                    dataTestId="liveCard-thumbnail"
                >
                    <div className="card__tag-wrapper">
                        <div className="card__tag card__tag--live">LIVE</div>
                    </div>
                </CardThumbnail>
                <CardDescription>
                    <CardTitle dataTestId="liveCard-title">{card.title}</CardTitle>
                    <CardSubTitle>Started {moment(card.startDate).fromNow()}</CardSubTitle>
                </CardDescription>
            </SmartAnchor>
            <ul className="inline-list card__actions">
                <li className="list-item">
                    <CardPreviewLink
                        onClick={onPreviewClick(true, null)}
                        tooltipText="Preview live event"
                        dataTestId="liveCard-previewLink"
                    />
                </li>
            </ul>
        </div>
    );
};

export { LiveCard };
