import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageHeader } from '~components/BrowseHeader';
import { PlayerModal } from '~components/PlayerModal';
import { TSearchTerm } from '~components/Root/types';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { BrowseSection } from '~pages/Browse/components/BrowseSection';
import { SearchSection } from '~pages/Browse/components/SearchSection';
import { getSearchTermState } from '~src/store/search/search.selectors';
import { useShallowEqualSelector } from '~src/views/hooks';
import { Page } from '~src/views/layout/Page';

import './index.scss';

interface IProps {
    searchTerm: TSearchTerm;
}

const PREVIEW_MODAL_DEFAULT_STATE = {
    isModal: false,
    assetId: null,
    assetType: null,
    canCreateClip: false,
    externalUrl: null,
};

const BrowsePageComponent = ({ searchTerm }: IProps) => {
    const isSearchMode = searchTerm.trim() !== '';
    const title = isSearchMode ? 'Search' : 'Browse';
    const subTitle = isSearchMode ? 'Search for recently created and videos' : 'Pick a video to get started';

    const [previewModalState, setPreviewModalState] = React.useState(PREVIEW_MODAL_DEFAULT_STATE);

    const onOpenPreviewModal = (assetId: number, assetType: EAssetType, canCreateClip: boolean, externalUrl: string) => {
        setPreviewModalState({ isModal: true, assetId, assetType, canCreateClip, externalUrl });
    };

    const onCloseModal = (): void => {
        setPreviewModalState(PREVIEW_MODAL_DEFAULT_STATE);
    };

    return (
        <Page showFooter={true}>
            <div className="browse-page">
                <PageHeader title={title} subTitle={subTitle} />

                <div className="mt-4">
                    <div className="browse-section">
                        {!searchTerm && <BrowseSection onOpenPreviewModal={onOpenPreviewModal} />}

                        {searchTerm && <SearchSection onOpenPreviewModal={onOpenPreviewModal} searchTerm={searchTerm} />}
                    </div>

                    {previewModalState.isModal && (
                        <PlayerModal
                            assetId={previewModalState.assetId}
                            assetType={previewModalState.assetType}
                            canCreateClip={previewModalState.canCreateClip}
                            externalUrl={previewModalState.externalUrl}
                            close={onCloseModal}
                        />
                    )}
                </div>
            </div>
        </Page>
    );
};

const BrowsePage: React.FC<RouteComponentProps> = () => {
    const searchTerm = useShallowEqualSelector(getSearchTermState);
    return <BrowsePageComponent searchTerm={searchTerm} />;
};

export { BrowsePage };
