import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import TimesThinIcon from '~components/Icons/TimesThinIcon';
import LoadingSpinner from '~components/LoadingSpinner';
import Modal from '~components/Modal';
import { ICreateRealmConfiguration } from '~services/realms/types';
import { createRealm } from '~src/store/realm/realm.actions';
import { getCreatingState } from '~src/store/realm/realm.selectors';

import './index.scss';

interface IProps {
    onCancel: VoidFunction;
    onCreate: VoidFunction;
}

interface IFormFields {
    [key: string]: IFormField;
}

interface IFormField {
    label: string;
    required?: boolean;
    requiredIf?: string;
    type?: string;
    group?: string;
    placeholder?: string;
}

const formFields: IFormFields = {
    realmName: {
        label: 'Realm Name',
        required: true,
        placeholder: 'e.g. dce.sandbox',
    },
    dgeCredentialsOperatorId: {
        group: 'DGE',
        label: 'DGE operator id',
        type: 'number',
        required: true,
    },
    dgeCredentialsOperatorSecret: {
        group: 'DGE',
        label: 'DGE operator secret',
        required: true,
    },
    dgeCredentialsOperatorSupplementarySecret: {
        group: 'DGE',
        label: 'DGE operator supplementary secret',
        required: true,
    },
    dveCredentialsCustomerId: {
        group: 'DVE',
        label: 'DVE customer id',
        type: 'number',
        required: true,
    },
    dveCredentialsCustomerSecret: {
        group: 'DVE',
        label: 'DVE customer secret',
        required: true,
    },
    dveCredentialsSpockSecret: {
        group: 'DVE',
        label: 'DVE user API secret',
        required: true,
    },
    shieldInfoShieldOvp: {
        group: 'Dice Shield',
        label: 'Dice Shield OVP',
        requiredIf: 'shieldInfoShieldClientId',
    },
    shieldInfoShieldClientId: {
        group: 'Dice Shield',
        label: 'Dice Shield Client Id',
        requiredIf: 'shieldInfoShieldOvp',
    },
};

export const CreateRealmModal: React.FunctionComponent<IProps> = (props) => {
    const { onCancel, onCreate } = props;

    const dispatch = useDispatch();

    const realmIsCreating = useSelector(getCreatingState);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // prevent any global shortcuts from being triggered
        e.stopPropagation();
    };

    const [formFieldValues, setFormFieldValues] = React.useState<ICreateRealmConfiguration>({
        realmName: '',
        dgeCredentialsOperatorId: 0,
        dgeCredentialsOperatorSecret: '',
        dgeCredentialsOperatorSupplementarySecret: '',
        dveCredentialsCustomerId: 0,
        dveCredentialsCustomerSecret: '',
        dveCredentialsSpockSecret: '',
        shieldInfoShieldOvp: '',
        shieldInfoShieldClientId: '',
    });

    const [formFieldErrors, setFormFieldErrors] = React.useState({});

    const handleFieldChange = (fieldId: string) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            let value: string | number = e.target.value;
            if (formFields[fieldId].type === 'number') {
                value = Number.parseInt(value, 10) || 0;
            }
            setFormFieldValues((prev: ICreateRealmConfiguration) => {
                return {
                    ...prev,
                    [fieldId]: value,
                };
            });
        };
    };

    const validate = () => {
        const fieldIds = Object.keys(formFieldValues);
        const errors = {};
        let hasError = false;
        for (const fieldId of fieldIds) {
            if (!isRequired(fieldId)) {
                continue;
            }
            if (!formFieldValues[fieldId]) {
                errors[fieldId] = `${formFields[fieldId].label} is required.`;
                hasError = true;
            }
        }

        setFormFieldErrors(errors);
        return !hasError;
    };

    const isRequired = (fieldId: string) => {
        return formFields[fieldId].required || (formFields[fieldId].requiredIf && formFieldValues[formFields[fieldId].requiredIf]);
    };

    const handleCreateRealm = () => {
        if (!validate()) {
            return;
        }
        dispatch(createRealm(formFieldValues, onCreate));
    };

    const $component = (
        <Modal.Main hasOverlay={true} className="create-realm-modal">
            <div className="create-realm-modal__title">Create New Realm</div>
            <button className="create-realm-modal__close-btn" onClick={onCancel}>
                <TimesThinIcon />
            </button>
            <Modal.Body className="create-realm-modal__body">
                {realmIsCreating && <LoadingSpinner />}

                <div className="create-realm-modal--form">
                    {Object.keys(formFields).map((fieldId: string, index, fields) => (
                        <div key={fieldId}>
                            {formFields[fieldId].group && formFields[fields[index]].group !== formFields[fields[index - 1]].group && (
                                <h2 className="form-field-group">{formFields[fields[index]].group}</h2>
                            )}
                            <div className="form-field-row">
                                <label htmlFor={fieldId}>
                                    {formFields[fieldId].label}
                                    {isRequired(fieldId) && '*'}
                                </label>
                                <div>
                                    <input
                                        id={fieldId}
                                        onChange={handleFieldChange(fieldId)}
                                        value={formFieldValues[fieldId]}
                                        onKeyDown={handleKeyDown}
                                        required={isRequired(fieldId)}
                                        placeholder={formFields[fieldId].placeholder}
                                    />
                                    {formFieldErrors[fieldId] && (
                                        <div className="create-realm-modal--form--error">{formFieldErrors[fieldId]}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="create-realm-modal__action">
                        <button
                            className="create-realm-modal__action-btn"
                            type="button"
                            onClick={handleCreateRealm}
                            disabled={realmIsCreating}
                        >
                            Create New Realm
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal.Main>
    );

    return ReactDOM.createPortal($component, document.getElementById('modal'));
};
