import { LocationDescriptorObject, Path } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { IDataTestId } from '~src/views/types';

interface IProps {
    to?: Path | LocationDescriptorObject;
    onClick?: (event: React.MouseEvent) => void;
    disabled?: boolean;
}

const isRouteString = (to: Path | LocationDescriptorObject): to is Path => {
    return typeof to === 'string';
};

const isExternal = (to) => {
    const regEx = new RegExp('^(http|https)://', 'i');
    return regEx.test(to);
};

const SmartAnchor: React.FunctionComponent<
    IProps & React.HTMLAttributes<HTMLAnchorElement | HTMLDivElement | HTMLButtonElement> & IDataTestId
> = ({ to, onClick, className, children, disabled, dataTestId, ...rest }) => {
    if (disabled) {
        return (
            <div className={className} data-test-id={dataTestId} {...rest}>
                {children}
            </div>
        );
    } else if (to && isRouteString(to) && isExternal(to)) {
        return (
            <a href={to} target="_blank" className={className} data-test-id={dataTestId} {...rest}>
                {children}
            </a>
        );
    } else if (onClick) {
        return (
            <button className={className} onClick={onClick} data-test-id={dataTestId} {...rest}>
                {children}
            </button>
        );
    }
    return (
        <Link to={to} className={className} data-test-id={dataTestId} {...rest}>
            {children}
        </Link>
    );
};

export { SmartAnchor };
