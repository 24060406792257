import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import LocationIcon from '~components/Icons/LocationIcon';
import LocationSlashIcon from '~components/Icons/LocationSlashIcon';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { joinStrings } from '~services/utilities';
import { EZoomLevel } from '~services/zoomLevelService';
import { setIsTrackingPlayhead } from '~src/store/timeline/toggle/toggle.actions';
import { getTimelineToggleState } from '~src/store/timeline/toggle/toggle.selectors';
import { ITimelineToggleState } from '~src/store/timeline/toggle/types';
import { useShallowEqualSelector } from '~src/views/hooks';

interface IContainerProps {
    zoomLevel: EZoomLevel;
}
interface IProps {
    isTrackingPlayhead: ITimelineToggleState['isTrackingPlayhead'];
    zoomLevel: EZoomLevel;
    dispatch: Dispatch;
}

const PlayHeadTrackingButtonComponent: React.FunctionComponent<IProps> = ({ isTrackingPlayhead, zoomLevel, dispatch }) => {
    const togglePlayheadTrackingHandler = () => {
        dispatch(setIsTrackingPlayhead(!isTrackingPlayhead));
    };

    const disabled = zoomLevel === EZoomLevel.DEFAULT;

    const message = `${isTrackingPlayhead ? 'Stop' : 'Start'} tracking playhead`;
    const className = joinStrings(['timeline-controls__btn', isTrackingPlayhead && !disabled && 'timeline-controls__btn--active']);

    return (
        <Tooltip message={!disabled && message} position={TooltipPositions.TOP}>
            <button className={className} type="button" onClick={togglePlayheadTrackingHandler} disabled={disabled}>
                {isTrackingPlayhead && !disabled ? (
                    <LocationIcon fill="#ffffff" width="15" height="15" />
                ) : (
                    <LocationSlashIcon fill="#ffffff" width="15" height="15" />
                )}
            </button>
        </Tooltip>
    );
};

const PlayHeadTrackingMemo: React.NamedExoticComponent<IProps> = React.memo(PlayHeadTrackingButtonComponent);

const PlayHeadTrackingButton: React.FunctionComponent<IContainerProps> = ({ zoomLevel }) => {
    const { isTrackingPlayhead } = useShallowEqualSelector<ITimelineToggleState>(getTimelineToggleState);
    const dispatch = useDispatch();
    return <PlayHeadTrackingMemo isTrackingPlayhead={isTrackingPlayhead} zoomLevel={zoomLevel} dispatch={dispatch} />;
};

export { PlayHeadTrackingButton };
