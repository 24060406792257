import * as React from 'react';

import { useTimeout } from '.';
import { useClickOff } from './useClickOff';
import { useMoveElementIntoView } from './useMoveElementIntoView';

export interface IUseMenuReturn<T> {
    menuRef: React.MutableRefObject<T>;
    onMouseLeave: VoidFunction;
    onMouseEnter: VoidFunction;
    onClickHandler: (event: React.MouseEvent<Element, MouseEvent>) => void;
    isVisible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MENU_HIDE_TIMEOUT = 300;

export const useDropdownMenu = <T extends HTMLElement>(): IUseMenuReturn<T> => {
    const menuRef = React.useRef<T>(null);
    const [isVisible, setVisibility] = React.useState<boolean>(false);
    const { setTimer, clearTimer } = useTimeout(MENU_HIDE_TIMEOUT);

    const onOutsideClick = React.useCallback(() => {
        if (isVisible) {
            setVisibility(false);
        }
    }, [isVisible, setVisibility]);

    useMoveElementIntoView(isVisible, menuRef.current);

    useClickOff(menuRef, onOutsideClick);

    const onMouseLeave = () => {
        setTimer(() => setVisibility(false));
    };

    const onMouseEnter = () => {
        clearTimer();
    };

    const onClickHandler = (event: React.MouseEvent) => {
        if (menuRef.current?.contains(event.target as HTMLElement)) {
            return;
        }
        event.stopPropagation();
        setVisibility((prevState) => !prevState);
    };

    return { menuRef, onMouseLeave, onMouseEnter, onClickHandler, isVisible, setVisibility };
};
