import * as React from 'react';
import { useSelector } from 'react-redux';

import { CalendarPreview } from '~components/ChannelManager/PreviewSchedule/CalendarPreview';
import { PreviewScheduleModal } from '~components/ChannelManager/PreviewSchedule/PreviewScheduleModal';
import { useControls } from '~containers/ChannelManagerContainer/ChannelManagerContext';
import { StorageKeys } from '~services/storage/constants';
import { joinStrings } from '~services/utilities';
import { getPreviewSchedulePreviousSettings, getPreviewSchedulePaneVisibility } from '~src/store/channelManager/channelManager.selectors';
import { PreviewSchedulePaneVisibility } from '~src/store/channelManager/constants';
import { useLocalStorage } from '~src/views/hooks/useLocalStorage';

import './index.scss';

export const PreviewSchedule = () => {
    const previewSchedulePaneVisibility = useSelector(getPreviewSchedulePaneVisibility);
    const previewSchedulePreviousSettings = useSelector(getPreviewSchedulePreviousSettings);
    const { onClosePreviewSchedule, onFullScreenPreviewSchedule } = useControls();
    const [showUSDateTimeFormat] = useLocalStorage(StorageKeys.SHOW_US_DATE_TIME_FORMAT, false);

    return (
        <PreviewScheduleModal
            isActive={previewSchedulePaneVisibility !== PreviewSchedulePaneVisibility.OFF}
            modalWrapperClassName={joinStrings([
                'preview-schedule-modal',
                previewSchedulePaneVisibility === PreviewSchedulePaneVisibility.ON && 'preview-schedule-modal--full',
                previewSchedulePaneVisibility === PreviewSchedulePaneVisibility.SNEEK && 'preview-schedule-modal--sneek',
            ])}
        >
            <CalendarPreview
                onClosePreviewSchedule={onClosePreviewSchedule}
                onFullScreenPreviewSchedule={onFullScreenPreviewSchedule}
                showUSDateTimeFormat={showUSDateTimeFormat}
                previousSettings={previewSchedulePreviousSettings}
            />
        </PreviewScheduleModal>
    );
};
