import * as React from 'react';

import LoadingSpinner from '~components/LoadingSpinner';
import Modal from '~components/Modal';
import { SaveAction } from '~containers/EditorPageContainer/types';

interface IProps {
    saveAction: SaveAction;
    exportCount: number;
}

const getLoadingTitle = (saveAction: SaveAction, exportCount: number) => {
    if (saveAction === SaveAction.CREATE) {
        return `Creating ${exportCount > 1 ? 'clips' : 'clip'}`;
    }
    return 'Updating clip';
};

const Loading: React.FunctionComponent<IProps> = ({ saveAction, exportCount }) => (
    <Modal.Body justify="center" className="export-modal__loading">
        <LoadingSpinner />
        <Modal.Title>{getLoadingTitle(saveAction, exportCount)}</Modal.Title>
        <p>Please wait...</p>
    </Modal.Body>
);

export { Loading };
