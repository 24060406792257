import * as debounce from 'lodash.debounce';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { defaultCellRangeRenderer, Grid, GridCellRangeProps, Index, ScrollParams } from 'react-virtualized';
import { Dispatch } from 'redux';

import { ClipsRail } from '~components/Editor/ClipsRail';
import { Seekbar } from '~components/Editor/Seekbar';
import { ThumbnailRail } from '~components/Editor/ThumbnailRail';
import { TimeRange } from '~components/Editor/TimeRange';
import { PlayHead } from '~components/Editor/Timeline/Playhead';
import { TimelinePreview } from '~components/Editor/Timeline/TimelinePreview';
import { TimelineSegment } from '~components/Editor/Timeline/TimelineSegment';
import { FeatureToggle } from '~components/FeatureToggle';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { valuesToPercentage } from '~services/utilities';
import {
    EZoomLevel,
    getTimeLineSettingFromZoomLevel,
    getZoomLevelConfig,
    isDefaultZoomLevel,
    IZoomLevelConfig,
} from '~services/zoomLevelService';
import { setIsTrackingPlayhead } from '~src/store/timeline/toggle/toggle.actions';
import { getTimelineToggleState } from '~src/store/timeline/toggle/toggle.selectors';
import { ITimelineToggleState } from '~src/store/timeline/toggle/types';
import { Seconds } from '~src/types';
import { useShallowEqualSelector } from '~src/views/hooks';
import { FeatureToggleKey } from '~src/views/types';

import './_timeline.scss';

interface IContainerProps {
    zoomLevel: EZoomLevel;
    currentTime: Seconds;
    assetType: EAssetType;
    rawTimelineStart: Seconds;
    rawTimelineEnd: Seconds;
    hlsStreamUrl: string;
    isLive: boolean;
    setTimelineTime: (timeSeconds: number) => void;
}

interface IProps extends IContainerProps {
    isTrackingPlayhead: boolean;
    dispatch: Dispatch;
}

const TIMELINE_OFFSET = 60; // 30 * 2 layout padding
const TIMELINE_OFFSET_X_POSITION = 30;

const TimelineComponent: React.FC<IProps> = ({
    zoomLevel,
    currentTime,
    assetType,
    rawTimelineStart,
    rawTimelineEnd,
    hlsStreamUrl,
    isLive,
    setTimelineTime,
    isTrackingPlayhead,
    dispatch,
}) => {
    const gridRef = React.useRef<Grid>(null);
    const [gridScrollState, setGridScrollState] = React.useState({ clientWidth: 0, scrollLeft: 0, scrollWidth: 0 });
    const [isPlayHeadAnimating, setIsPlayHeadAnimating] = React.useState<boolean>(true);

    const streamDuration: Seconds = rawTimelineEnd - rawTimelineStart;

    const playHeadPosition: number = isLive
        ? valuesToPercentage(currentTime, streamDuration) + 100
        : valuesToPercentage(currentTime, streamDuration);

    const isDefault = isDefaultZoomLevel(zoomLevel);

    const { mainTickCount, subTickCount, minorTickCount, timeInterval }: IZoomLevelConfig = getZoomLevelConfig(zoomLevel);

    const { width: bodyWidth } = document.body.getBoundingClientRect();
    const timelineWindowWidth = bodyWidth - TIMELINE_OFFSET;

    const { columnCount, remainder, columnWidth, finalItemWidth } = getTimeLineSettingFromZoomLevel(
        streamDuration,
        zoomLevel,
        timelineWindowWidth
    );

    const scrollWidth = remainder === 0 ? columnWidth * columnCount : columnWidth * (columnCount - 1) + finalItemWidth;

    const handleShouldAnimateChange = (requestPlayheadAnimation: boolean) => {
        if (requestPlayheadAnimation && !isPlayHeadAnimating) {
            setIsPlayHeadAnimating(true);
        } else if (!requestPlayheadAnimation && isPlayHeadAnimating) {
            setIsPlayHeadAnimating(false);
        }
    };

    const getTickLabel = (columnIndex: number): number => {
        if (isLive) {
            if (isDefault) {
                return (streamDuration / mainTickCount) * (columnIndex - columnCount);
            } else if (columnIndex === 0) {
                return streamDuration;
            } else {
                return timeInterval * columnIndex - timeInterval * columnCount;
            }
        } else if (isDefault) {
            return (streamDuration / mainTickCount) * columnIndex;
        } else if (columnIndex !== columnCount) {
            return timeInterval * columnIndex;
        } else {
            return streamDuration;
        }
    };

    const cellRenderer = ({ columnIndex, style, key }): React.ReactNode => {
        const label = getTickLabel(columnIndex);
        let finalLabel = null;

        if (columnIndex === columnCount - 1) {
            finalLabel = getTickLabel(columnIndex + 1);
        }

        return (
            <TimelineSegment
                key={key}
                style={style}
                label={label}
                columnIndex={columnIndex}
                columnWidth={columnWidth}
                isLive={isLive}
                finalLabel={finalLabel}
                minorTickCount={minorTickCount}
                subTickCount={subTickCount}
            />
        );
    };

    const timelineOnClickHandler = ({ clientX, currentTarget }: React.MouseEvent) => {
        const { width, left } = currentTarget.getBoundingClientRect();
        const zeroClientX = clientX - TIMELINE_OFFSET_X_POSITION;
        const zeroLeft = Math.abs(left - TIMELINE_OFFSET_X_POSITION);
        const offsetXToPercent = (zeroLeft + zeroClientX) / width;
        const positiveSeekTime = offsetXToPercent * streamDuration;
        const seekTime = !isLive ? positiveSeekTime : positiveSeekTime - streamDuration;
        setTimelineTime(seekTime);
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        // persist recycling of event in pool
        event.persist();
        // current target is getting cleaned up so cache it
        delayedCallback(event, event.currentTarget);
    };

    const delayedCallback = debounce((event: React.DragEvent<HTMLDivElement>, currentTarget: HTMLDivElement) => {
        // rebind cached current target
        event.currentTarget = currentTarget;
        timelineOnClickHandler(event);
    }, 5);

    const getGridScrollLeftPosition = (): number => {
        if (isTrackingPlayhead) {
            if (scrollWidth > timelineWindowWidth) {
                const halfTimelineWidth = timelineWindowWidth / 2;
                const scrollOffsetLeft = playHeadPosition * (scrollWidth / 100);
                if (scrollOffsetLeft > halfTimelineWidth && scrollOffsetLeft < scrollWidth - halfTimelineWidth) {
                    handleShouldAnimateChange(false);
                    return scrollOffsetLeft - halfTimelineWidth;
                } else if (scrollOffsetLeft >= scrollWidth - halfTimelineWidth) {
                    handleShouldAnimateChange(true);
                    return scrollWidth - timelineWindowWidth;
                }
            }
            handleShouldAnimateChange(true);
            return 0;
        }
        handleShouldAnimateChange(true);
        return null;
    };

    const cellRangeRenderer = (cellProps: GridCellRangeProps): React.ReactNode[] => {
        const children = defaultCellRangeRenderer(cellProps);
        const $component = (
            <div key="timeline" className="timeline-overlay-wrapper" onClick={timelineOnClickHandler} onDragOver={onDragOver}>
                <PlayHead position={playHeadPosition} time={currentTime} animated={isPlayHeadAnimating} />
                <FeatureToggle featureName={FeatureToggleKey.TimelinePreview}>
                    <TimelinePreview hlsStreamUrl={hlsStreamUrl} isLive={isLive} duration={streamDuration} />
                </FeatureToggle>
                <ClipsRail timelineStartTime={rawTimelineStart} duration={streamDuration} assetType={assetType} isLive={isLive} />
                <ThumbnailRail
                    timelineStartTime={rawTimelineStart}
                    duration={streamDuration}
                    assetType={assetType}
                    isLive={isLive}
                    timelineWidth={scrollWidth}
                />
            </div>
        );
        children.push($component);
        return children;
    };

    const handleMouseWheel = () => {
        if (isTrackingPlayhead) {
            const action = setIsTrackingPlayhead(false);
            dispatch(action);
        }
    };

    const handleOnScroll = (scrollProps: ScrollParams) => {
        setGridScrollState({
            clientWidth: scrollProps.clientWidth,
            scrollLeft: scrollProps.scrollLeft,
            scrollWidth: scrollProps.scrollWidth,
        });
    };

    const handleColumnWidth = ({ index }: Index): number => {
        const nonZeroIndex = index + 1;
        if ((!isLive && nonZeroIndex === columnCount && remainder !== 0) || (isLive && index === 0 && remainder !== 0)) {
            return finalItemWidth;
        }
        return columnWidth;
    };

    const gridScrollLeft = getGridScrollLeftPosition();

    if (gridRef.current) {
        gridRef.current.scrollToPosition({ scrollLeft: gridScrollLeft, scrollTop: 0 });
        gridRef.current.measureAllCells();
        gridRef.current.recomputeGridSize();
    }

    return (
        <div id="timeline-container">
            <section className="timeline-section pb-0" onWheel={handleMouseWheel}>
                <Grid
                    ref={(instance) => (gridRef.current = instance)}
                    className="timeline-v2"
                    rowCount={1}
                    rowHeight={140}
                    height={140}
                    columnCount={columnCount}
                    onScroll={handleOnScroll}
                    columnWidth={handleColumnWidth}
                    cellRangeRenderer={cellRangeRenderer}
                    cellRenderer={cellRenderer}
                    width={timelineWindowWidth}
                    overscanColumnCount={10}
                />
            </section>

            <section className="timeline-section">
                <div className="flex-container flex-container--align-center">
                    <Seekbar
                        clientWidth={gridScrollState.clientWidth}
                        scrollLeft={gridScrollState.scrollLeft}
                        scrollWidth={gridScrollState.scrollWidth}
                        playheadPosition={playHeadPosition}
                        timelineOnClickHandler={timelineOnClickHandler}
                    />
                    <TimeRange currentTime={currentTime} duration={streamDuration} />
                </div>
            </section>
        </div>
    );
};

const TimelineMemo: React.NamedExoticComponent<IProps> = React.memo(TimelineComponent);

const Timeline: React.FunctionComponent<IContainerProps> = ({
    zoomLevel,
    currentTime,
    assetType,
    rawTimelineStart,
    rawTimelineEnd,
    hlsStreamUrl,
    isLive,
    setTimelineTime,
}) => {
    const { isTrackingPlayhead } = useShallowEqualSelector<ITimelineToggleState>(getTimelineToggleState);
    const dispatch = useDispatch();
    return (
        <TimelineMemo
            zoomLevel={zoomLevel}
            currentTime={currentTime}
            assetType={assetType}
            rawTimelineStart={rawTimelineStart}
            rawTimelineEnd={rawTimelineEnd}
            hlsStreamUrl={hlsStreamUrl}
            isLive={isLive}
            setTimelineTime={setTimelineTime}
            isTrackingPlayhead={isTrackingPlayhead}
            dispatch={dispatch}
        />
    );
};

export { Timeline };
