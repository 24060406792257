import { IChannelAdminStreamingConfigMuxer } from '~services/channels/types';

import { ReduceActions } from './channelManager.actions';
import { isCurrentStepContentSearch } from './channelManager.utilities';
import {
    ChannelManagerActions,
    CHANNEL_MANAGER_INITIAL_STATE,
    POD_MANAGEMENT_INITIAL_STATE,
    PreviewSchedulePaneVisibility,
} from './constants';
import { ChangeAction, IChannelManagerState } from './types';

export const channelManagerReducer = (
    state: IChannelManagerState = CHANNEL_MANAGER_INITIAL_STATE,
    action: ReduceActions
): IChannelManagerState => {
    switch (action.type) {
        case ChannelManagerActions.RESET_PAGE: {
            return { ...CHANNEL_MANAGER_INITIAL_STATE };
        }
        case ChannelManagerActions.PAGE_LOADED: {
            return {
                ...CHANNEL_MANAGER_INITIAL_STATE,
                hasPageLoaded: true,
                channelDetails: {
                    ...action.payload.channelDetails,
                },
                channelAdminDetails: {
                    dgeEventId: null,
                    streamingConfigs: null,
                    muxers: null,
                    isLoadingMuxers: false,
                    isSynchronisingChannelStreamingConfigs: false,
                    isProvisioningMuxer: false,
                    muxerActionRequiresConfirmation: false,
                    lastMuxerAction: null,
                },
                muxerVersions: {
                    ...action.payload.muxerVersions,
                },
                contentPods: [...action.payload.contentPods],
                lastAction: ChangeAction.LOAD,
                pendingPublishPodNumbers: [],
                featureFlags: { ...state.featureFlags },
            };
        }
        case ChannelManagerActions.CHANNEL_ADMIN_DETAILS_UPDATED: {
            return {
                ...state,
                channelAdminDetails: {
                    ...action.payload.channelAdminDetails,
                },
            };
        }
        case ChannelManagerActions.FEATURE_FLAGS_LOADED: {
            return {
                ...state,
                featureFlags: {
                    ...action.payload.featureFlags,
                },
            };
        }
        case ChannelManagerActions.MUXER_UPDATED: {
            const updatedMuxer = action.payload.muxer;
            const newMuxers = [...state.channelAdminDetails.muxers];
            const muxerToUpdate = newMuxers.findIndex((muxer: IChannelAdminStreamingConfigMuxer) => muxer.muxerId === updatedMuxer.muxerId);

            if (muxerToUpdate === -1) {
                newMuxers.unshift(updatedMuxer);
            } else {
                newMuxers.splice(muxerToUpdate, 1, updatedMuxer);
            }

            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    muxers: newMuxers,
                },
            };
        }
        case ChannelManagerActions.CONTENT_PODS_UPDATED: {
            const { lastChangeDetails, contentPods, pendingPublishPodNumbers } = action.payload;
            return {
                ...state,
                contentPods: [...contentPods],
                lastEdited: lastChangeDetails.lastEdited,
                lastAction: lastChangeDetails.lastAction,
                pendingPublishPodNumbers: [...pendingPublishPodNumbers],
            };
        }
        case ChannelManagerActions.TOTAL_CONTENT_SIZE_CHANGED: {
            return {
                ...state,
                channelDetails: {
                    ...state.channelDetails,
                    timeRemaining: action.payload.timeRemaining,
                    queuedContentPods: action.payload.queuedContentPods,
                },
            };
        }
        case ChannelManagerActions.CONTENT_RANGE_DETAILS_CHANGED: {
            return {
                ...state,
                channelDetails: {
                    ...state.channelDetails,
                    editableRange: { ...action.payload.editableRange },
                    scrollableDateTimeRange: { ...action.payload.scrollableDateTimeRange },
                },
            };
        }
        case ChannelManagerActions.RESET_LAST_EDITED_CHANGES: {
            return {
                ...state,
                lastEdited: null,
                lastAction: null,
            };
        }
        case ChannelManagerActions.POD_MANAGEMENT_MODAL_OPENED: {
            return {
                ...state,
                podManagement: {
                    ...state.podManagement,
                    isActive: true,
                    currentStep: { ...action.payload.currentStep },
                    prevStep: null,
                    insertPosition: action.payload.insertPosition,
                },
            };
        }
        case ChannelManagerActions.POD_MANAGEMENT_MODAL_CLOSED: {
            return {
                ...state,
                podManagement: {
                    ...POD_MANAGEMENT_INITIAL_STATE,
                },
            };
        }
        case ChannelManagerActions.POD_MANAGEMENT_MODAL_VIEW_CHANGED: {
            return {
                ...state,
                podManagement: {
                    ...state.podManagement,
                    currentStep: { ...action.payload.currentStep },
                    prevStep: { ...action.payload.prevStep },
                },
            };
        }
        case ChannelManagerActions.SELECTED_CONTENT_LIST_UPDATED: {
            if (isCurrentStepContentSearch(state.podManagement.currentStep)) {
                const updatedSelectedContentList = [
                    ...state.podManagement.currentStep.selectedContentList,
                    { ...action.payload.displayContent },
                ];

                return {
                    ...state,
                    podManagement: {
                        ...state.podManagement,
                        currentStep: {
                            ...state.podManagement.currentStep,
                            selectedContentList: updatedSelectedContentList,
                        },
                    },
                };
            }
            return state;
        }
        case ChannelManagerActions.REMOVE_SELECTED_CONTENT_LIST_ITEM: {
            if (isCurrentStepContentSearch(state.podManagement.currentStep)) {
                const updatedSelectedContentList = [...state.podManagement.currentStep.selectedContentList];
                updatedSelectedContentList.splice(action.payload.position, 1);

                return {
                    ...state,
                    podManagement: {
                        ...state.podManagement,
                        currentStep: {
                            ...state.podManagement.currentStep,
                            selectedContentList: updatedSelectedContentList,
                        },
                    },
                };
            }
            return state;
        }
        case ChannelManagerActions.SORT_SELECTED_CONTENT_LIST_ITEM: {
            if (isCurrentStepContentSearch(state.podManagement.currentStep)) {
                const { from, to } = action.payload;
                const updatedSelectedContentList = [...state.podManagement.currentStep.selectedContentList];
                const [itemToMove] = updatedSelectedContentList.splice(from, 1);

                updatedSelectedContentList.splice(to, 0, itemToMove);

                return {
                    ...state,
                    podManagement: {
                        ...state.podManagement,
                        currentStep: {
                            ...state.podManagement.currentStep,
                            selectedContentList: updatedSelectedContentList,
                        },
                    },
                };
            }
            return state;
        }
        case ChannelManagerActions.CONTENT_BY_ID_MAP_UPDATED: {
            const updatedContentByIdMap = { ...state.podManagement.contentByIdMap };
            updatedContentByIdMap[action.payload.contentId] = {
                content: { ...action.payload.content },
                status: action.payload.status,
            };
            return {
                ...state,
                podManagement: {
                    ...state.podManagement,
                    contentByIdMap: updatedContentByIdMap,
                },
            };
        }
        case ChannelManagerActions.SUCCESSFUL_MUXER_ACTION: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    muxerActionRequiresConfirmation: false,
                    lastMuxerAction: null,
                },
            };
        }
        case ChannelManagerActions.CANCEL_MUXER_ACTION: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    muxerActionRequiresConfirmation: false,
                    lastMuxerAction: null,
                },
            };
        }
        case ChannelManagerActions.MUXER_ACTION_REQUIRES_CONFIRMATION: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    muxerActionRequiresConfirmation: true,
                    lastMuxerAction: action.payload,
                },
            };
        }
        case ChannelManagerActions.STARTED_SYNCHRONISING_CHANNEL_STREAMING_CONFIGS: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    isSynchronisingChannelStreamingConfigs: true,
                },
            };
        }
        case ChannelManagerActions.SUCCESSFULLY_SYNCHRONISED_CHANNEL_STREAMING_CONFIGS: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    isSynchronisingChannelStreamingConfigs: false,
                },
            };
        }
        case ChannelManagerActions.ERROR_SYNCHRONISING_CHANNEL_STREAMING_CONFIGS: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    isSynchronisingChannelStreamingConfigs: false,
                },
            };
        }
        case ChannelManagerActions.STARTED_REFRESHING_CHANNEL_ADMIN_DETAILS: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    isLoadingMuxers: true,
                },
            };
        }
        case ChannelManagerActions.SUCCESSFULLY_REFRESHED_CHANNEL_ADMIN_DETAILS: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    isLoadingMuxers: false,
                },
            };
        }
        case ChannelManagerActions.ERROR_REFRESHING_CHANNEL_ADMIN_DETAILS: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    isLoadingMuxers: false,
                },
            };
        }
        case ChannelManagerActions.STARTED_PROVISIONING_MUXER: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    isProvisioningMuxer: true,
                },
            };
        }
        case ChannelManagerActions.SUCCESSFULLY_PROVISIONED_MUXER: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    isProvisioningMuxer: false,
                },
            };
        }
        case ChannelManagerActions.ERROR_PROVISIONING_MUXER: {
            return {
                ...state,
                channelAdminDetails: {
                    ...state.channelAdminDetails,
                    isProvisioningMuxer: false,
                },
            };
        }
        case ChannelManagerActions.STARTED_PUBLISHING_CHANGES: {
            return {
                ...state,
                isPublishing: true,
            };
        }
        case ChannelManagerActions.SUCCESSFULLY_PUBLISHED_CHANGES: {
            return {
                ...state,
                isPublishing: false,
                pendingPublishPodNumbers: [],
            };
        }
        case ChannelManagerActions.ERROR_PUBLISHING_CHANGES: {
            return {
                ...state,
                isPublishing: false,
            };
        }
        case ChannelManagerActions.CONFIRMATION_NEEDED_TO_OVERWRITE: {
            return {
                ...state,
                showConfirmOverwriteModal: true,
            };
        }
        case ChannelManagerActions.OVERWRITE_CANCELLED: {
            return {
                ...state,
                isPublishing: false,
                showConfirmOverwriteModal: false,
            };
        }
        case ChannelManagerActions.OVERWRITE_CONFIRMED: {
            return {
                ...state,
                showConfirmOverwriteModal: false,
            };
        }
        case ChannelManagerActions.SET_PREVIEW_SETTINGS: {
            return {
                ...state,
                previewSchedulePanePreviousSettings: action.payload.settings,
            };
        }
        case ChannelManagerActions.TOGGLE_BATCH_ACTION_MODE: {
            return {
                ...state,
                batchAction: {
                    mode: action.payload.mode === state.batchAction.mode ? null : action.payload.mode,
                    selectedPodUuids: [],
                },
            };
        }
        case ChannelManagerActions.DEACTIVATE_BATCH_ACTION_MODE: {
            return {
                ...state,
                batchAction: {
                    mode: null,
                    selectedPodUuids: [],
                },
            };
        }
        case ChannelManagerActions.TOGGLE_BATCH_ACTION_POD_SELECTION: {
            const selectedPodIndex = state.batchAction.selectedPodUuids.indexOf(action.payload.uuid);
            const newSelectedPodUuids = [...state.batchAction.selectedPodUuids];

            if (selectedPodIndex === -1) {
                newSelectedPodUuids.push(action.payload.uuid);
            } else {
                newSelectedPodUuids.splice(selectedPodIndex, 1);
            }

            return {
                ...state,
                batchAction: {
                    ...state.batchAction,
                    selectedPodUuids: newSelectedPodUuids,
                },
            };
        }
        case ChannelManagerActions.SNEEK_PREVIEW_SCHEDULE: {
            return {
                ...state,
                previewSchedulePaneVisibility: PreviewSchedulePaneVisibility.SNEEK,
            };
        }
        case ChannelManagerActions.FULL_SCREEN_PREVIEW_SCHEDULE: {
            return {
                ...state,
                previewSchedulePaneVisibility: PreviewSchedulePaneVisibility.ON,
            };
        }
        case ChannelManagerActions.CLOSE_PREVIEW_SCHEDULE: {
            return {
                ...state,
                previewSchedulePaneVisibility: PreviewSchedulePaneVisibility.OFF,
            };
        }
        case ChannelManagerActions.SCROLL_TO_CONTENT_POD: {
            return {
                ...state,
                contentPodScrollState: action.payload,
            };
        }
        case ChannelManagerActions.CLEAR_CHANNEL_DELETE_REQUIRES_CONFIRMATION: {
            return {
                ...state,
                channelIsDeleting: false,
                channelDeleteRequiresConfirmation: false,
            };
        }
        case ChannelManagerActions.CHANNEL_DELETE_REQUIRES_CONFIRMATION: {
            return {
                ...state,
                channelDeleteRequiresConfirmation: true,
                channelIsDeleting: false,
            };
        }
        case ChannelManagerActions.CHANNEL_OPENED_ACTIVE_MODAL: {
            return {
                ...state,
                hasActiveModal: true,
            };
        }
        case ChannelManagerActions.CHANNEL_CLOSED_ACTIVE_MODAL: {
            return {
                ...state,
                hasActiveModal: false,
            };
        }
        default: {
            return state;
        }
    }
};
