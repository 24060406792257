import * as React from 'react';

import { ClipCard } from '~components/Card/ClipCard';
import { LiveCard } from '~components/Card/LiveCard';
import { VodCard } from '~components/Card/VodCard';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { IClip } from '~services/clip/types';
import { IEvent } from '~services/live/types';
import { IVod } from '~services/vod/types';
import { ICardSettings, IContentType } from '~src/types';

type OnPreviewClick = (assetId: number, assetType: EAssetType, canCreateClips: boolean, externalUrl: string) => void;

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    assetType: Exclude<EAssetType, EAssetType.ARCHIVE_VOD>;
    card: IContentType;
    settings: ICardSettings;
    onPreviewClick: OnPreviewClick;
}

const handleOnPreviewOnClick = (assetType: EAssetType, assetId: IContentType['id'], onPreviewClick: OnPreviewClick) => (
    canCreateClips: boolean,
    externalUrl: string
) => (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onPreviewClick(assetId, assetType, canCreateClips, externalUrl);
};

const CardComponent: React.FunctionComponent<IProps> = ({ assetType, card, settings, onPreviewClick, className }) => {
    const handleOnPreviewClick = handleOnPreviewOnClick(assetType, card.id, onPreviewClick);
    switch (assetType) {
        case EAssetType.LIVE:
            return <LiveCard className={className} card={card as IEvent} settings={settings} onPreviewClick={handleOnPreviewClick} />;
        case EAssetType.CLIP:
            return <ClipCard className={className} card={card as IClip} settings={settings} onPreviewClick={handleOnPreviewClick} />;
        case EAssetType.VOD:
            return <VodCard className={className} card={card as IVod} settings={settings} onPreviewClick={handleOnPreviewClick} />;
        default:
            return null;
    }
};

const CardMemo: React.NamedExoticComponent<IProps> = React.memo(CardComponent);

const Card: React.FunctionComponent<IProps> = ({ assetType, card, settings, onPreviewClick, className }) => {
    return <CardMemo assetType={assetType} card={card} settings={settings} onPreviewClick={onPreviewClick} className={className} />;
};

export { Card, OnPreviewClick };
