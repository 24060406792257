import { useState, useEffect } from 'react';

import { storageProvider } from '~services/storage';

/**
 * from: https://usehooks.com/useLocalStorage/
 * @param key string
 * @param initialValue any
 *
 */

export const useLocalStorage = <T>(key: string, initialValue: T): [T, (value: T | ((val: T) => T)) => void] => {
    const [storedValue, setStoredValue] = useState<T>(initialValue);

    useEffect(() => {
        (async () => {
            try {
                // Get from local storage by key
                const value = await storageProvider.get(key);
                // Parse stored json or if none return initialValue
                setStoredValue(value ? JSON.parse(value) : initialValue);
            } catch (error) {
                console.log(error);
                setStoredValue(initialValue);
            }
        })();
    }, [storageProvider]);

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            storageProvider.set(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
};
