import { SortOrder } from '~services/channels/types';

export enum SortFields {
    TITLE = 'title',
    CREATED_AT = 'createdAt',
    SIMILARITY = 'similarity',
}

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SORT_ORDER = SortOrder.DESC;
export const DEFAULT_SORT_FIELD = SortFields.CREATED_AT;
export const DEFAULT_SEARCH_SORT_ORDER = SortOrder.DESC;
export const DEFAULT_SEARCH_SORT_FIELD = SortFields.SIMILARITY;

export const TITLE_SEARCH_FIELD = 'title';

export enum ChannelEndpoints {
    CHANNELS = '/channels',
    CHANNELS_SEARCH = '/channels/search',
    CHANNELS_ADMIN = '/admin/channels',
    MUXER_VERSIONS = '/admin/muxer-versions',
    VIDEOS = '/videos',
    IMAGES = '/images',
}

export enum ApiFetchStates {
    LOADING,
    LOADED,
    NO_CONTENT,
}

export enum ChannelType {
    ALL = 'ALL',
    LIVE = 'LIVE',
    FUTURE = 'FUTURE',
}
