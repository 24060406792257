enum PaginationActions {
    ADD_SAGA = 'pagination/ADD_SAGA',
    ADD = 'pagination/ADD',
    REMOVE = 'pagination/REMOVE',
    GET_NEXT_PAGE_SAGA = 'pagination/GET_NEXT_PAGE_SAGA',
    ADD_RESULTS = 'pagination/ADD_RESULTS',
    UPDATE_RESULT = 'pagination/UPDATE_RESULT ',
}

export { PaginationActions };
