import { put, select, takeEvery } from 'redux-saga/effects';

import { Console } from '~services/console';
import { PaginationActions } from '~src/store/pagination/constants';
import { getPaginationState } from '~src/store/pagination/pagination.selectors';

const DEFAULT_PAGER_STATE = {
    results: [],
    hasMoreResults: true,
    lastSeen: null,
    status: 'loading',
};

const DEFAULT_ALGOLIA_PAGER_STATE = {
    results: [],
    page: 0,
    nbPages: 0,
};

function* handleGetNextPage(action) {
    const { stateKey, pageService } = action.payload;
    const { lastSeen: stateLastSeen } = yield select(getPaginationState(stateKey));
    try {
        const { results, hasMoreResults, lastSeen } = yield pageService(stateLastSeen);

        yield put({
            type: PaginationActions.ADD_RESULTS,
            payload: {
                stateKey,
                results,
                hasMoreResults,
                lastSeen,
            },
        });
    } catch (error) {
        // todo: process error
        Console.warn(error);
    }
}

function* handleAddPagination(action) {
    const { stateKey, type } = action.payload;

    const defaultState = type === 'Algolia' ? DEFAULT_ALGOLIA_PAGER_STATE : DEFAULT_PAGER_STATE;

    yield put({
        type: PaginationActions.ADD,
        payload: {
            stateKey,
            defaultState,
        },
    });

    yield handleGetNextPage(action);
}

export const paginationSagas = [
    takeEvery(PaginationActions.ADD_SAGA, handleAddPagination),
    takeEvery(PaginationActions.GET_NEXT_PAGE_SAGA, handleGetNextPage),
];
