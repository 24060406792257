import * as React from 'react';

import { joinStrings } from '~services/utilities';

import { IScrollPositionState } from './types';

interface IProps {
    isChannelLive: boolean;
    scrollPositions: IScrollPositionState;
    scrollFirst: VoidFunction;
    scrollPrev: VoidFunction;
    scrollNext: VoidFunction;
    scrollLast: VoidFunction;
}

export const ScrollButtons: React.FC<IProps> = React.memo((props) => {
    const scrollLeftDisabledButtonClassName = joinStrings([props.scrollPositions.atLeftEnd && 'scroller--disabled-button']);
    const scrollRightDisabledButtonClassName = joinStrings([props.scrollPositions.atRightEnd && 'scroller--disabled-button']);

    return (
        <div className="scroller__control-buttons">
            <button className={joinStrings(['scroller--lg-button', scrollLeftDisabledButtonClassName])} onClick={props.scrollFirst}>
                |&lt;&lt; {props.isChannelLive ? 'LIVE' : 'START'}
            </button>
            <button className={joinStrings(['scroller--sm-button', scrollLeftDisabledButtonClassName])} onClick={props.scrollPrev}>
                &lt;&lt;
            </button>
            <button className={joinStrings(['scroller--sm-button', scrollRightDisabledButtonClassName])} onClick={props.scrollNext}>
                &gt;&gt;
            </button>
            <button className={joinStrings(['scroller--lg-button', scrollRightDisabledButtonClassName])} onClick={props.scrollLast}>
                END &gt;&gt;|
            </button>
        </div>
    );
});

ScrollButtons.displayName = 'ScrollButtons';
