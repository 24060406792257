import * as React from 'react';

import CloseIcon from '~components/Icons/CloseIcon';
import LoadingSpinner from '~components/LoadingSpinner';
import { joinStrings } from '~services/utilities';
import { KeyCode } from '~src/views/types';

import './index.scss';

enum TextJustify {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
}

interface ITileProps {
    justify?: TextJustify;
}

interface IModalProps {
    onClose?: (event) => void;
    onScroll?: (event: React.MouseEvent<HTMLDivElement>) => void;
    hasOverlay?: boolean;
    className?: string;
    isLoading?: boolean;
    closeWithEscape?: boolean;
    style?: object;
}

const Main: React.FunctionComponent<IModalProps> = (props) => {
    const { onClose, hasOverlay, className, isLoading, style, closeWithEscape = false, children } = props;

    // @ts-ignore - not all code paths return a value
    React.useEffect(() => {
        if (closeWithEscape) {
            window.addEventListener('keydown', keydownHandler);
            return () => {
                window.removeEventListener('keydown', keydownHandler);
            };
        }
    }, []);

    const keydownHandler = (event: KeyboardEvent) => {
        event.stopPropagation();
        if (event.keyCode === KeyCode.ESCAPE) {
            onClose(event);
        }
    };

    const classNames = joinStrings(['modal', className]);
    const onCloseCallback = (e: React.SyntheticEvent<HTMLButtonElement | HTMLDivElement>) => {
        if (onClose) {
            onClose(e);
        }
    };
    return (
        <React.Fragment>
            {hasOverlay ? <div className="overlay" onClick={onCloseCallback} /> : null}
            {isLoading && (
                <div className="modal__loading-spinner">
                    <LoadingSpinner />
                </div>
            )}
            {!isLoading && (
                <div style={style} className={classNames} onScroll={props.onScroll}>
                    {onClose && <ModalCloseBtn handleClick={onClose} />}
                    {children}
                </div>
            )}
        </React.Fragment>
    );
};

const Title: React.FunctionComponent<ITileProps> = ({ justify, children }) => {
    const classNames = joinStrings(['modal__title', justify === TextJustify.CENTER && `modal__title--${TextJustify.CENTER}`]);
    return (
        <div className={classNames} data-test-id="modalTitleComponent-titleText">
            {children}
        </div>
    );
};

const Body = (props) => {
    const classNames = ['modal__body', ...(props.justify === 'center' ? ['modal__body--center'] : []), props.className];
    return <div className={classNames.join(' ')}>{props.children}</div>;
};

const ModalCloseBtn = ({ handleClick }) => (
    <button className="modal__close-btn" onClick={handleClick}>
        <CloseIcon />
    </button>
);

export default {
    Body,
    Main,
    Title,
};

export { TextJustify };
