import * as React from 'react';

import { CalendarCardPreview } from '~components/ChannelManager/PreviewSchedule/CalendarCardPreview';
import { GroupedTabs } from '~components/ChannelManager/PreviewSchedule/GroupedTabs';
import { ClockIcon } from '~components/Icons/ClockIcon';
import { getDurationLabel } from '~services/utilities/time';

import { IGroupPlot } from '../types';
import './index.scss';

export const CalendarGroupCard: React.FC<IGroupPlot & { showUSDateTimeFormat: boolean; timezone?: string }> = (props) => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

    React.useEffect(() => {
        setSelectedIndex(0);
    }, [props.items]);

    const selectContentPod = (index: number) => {
        // wrap this in a brief timeout, to allow the global click off handler to fire first
        // This also makes the preview card hide, then re-appear with the new content, which looks better
        setTimeout(() => {
            setSelectedIndex(index);
            setShowPreview(true);
        }, 100);
    };

    const [showPreview, setShowPreview] = React.useState(false);

    const handleClick = () => {
        setShowPreview(true);
    };

    const handleClosePreview = () => {
        setShowPreview(false);
    };

    return (
        <div className="preview-schedule__plot" style={{ height: props.totalHeight }}>
            <div className="preview-schedule__plot-inner" onClick={handleClick}>
                <div className="grouped-pod">
                    <span className="grouped-pod__duration">
                        <ClockIcon />
                        {getDurationLabel(props.showUSDateTimeFormat, props.groupStartTime, props.totalDuration, props.timezone)}
                    </span>
                    <span className="grouped-pod__details">{props.items.length} Pods</span>
                </div>
                <GroupedTabs
                    contentPods={props.items}
                    showUSDateTimeFormat={props.showUSDateTimeFormat}
                    timezone={props.timezone}
                    selected={selectedIndex}
                    onSelect={selectContentPod}
                />
                {showPreview && (
                    <CalendarCardPreview
                        contentPod={props.items[selectedIndex]}
                        closePreview={handleClosePreview}
                        timezone={props.timezone}
                    />
                )}
            </div>
        </div>
    );
};
