import * as React from 'react';

import { joinStrings } from '~services/utilities';

import './index.scss';

/**
 * From angular DVE refactored to React
 */

interface IProps {
    count: number;
    perPage: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
}

interface IPageItem {
    text: string;
    class: string;
    link?: boolean;
    id: string;
}

const ITEMS_PER_PAGE = 10;
const PAGES_TO_SHOW = 5;
const PAGES_BEFORE_AFTER = Math.floor(PAGES_TO_SHOW / 2);

// Get ellipsis page from template
const getEllipsisPage = (id: string): IPageItem => ({
    text: '...',
    class: 'page--item-ellipsis',
    id,
});

// Get normal page from template
const getPage = (current: number, position: number): IPageItem => ({
    text: position.toString(),
    class: position === current ? 'page--item-current' : '',
    link: true,
    id: `page_${position}`,
});

// Get all normal pages
const getMiddlePages = (startPage: number, current: number, endPage: number): IPageItem[] => {
    const length = endPage - startPage + 1;
    return Array.from({ length }, (_, i) => getPage(current, startPage + i));
};

// Find where the pagination should start
const getFirstDisplayedPage = (page: number, total: number): number => {
    const after = total - page;
    const before = after < PAGES_BEFORE_AFTER ? 2 * PAGES_BEFORE_AFTER - after : PAGES_BEFORE_AFTER;
    const first = total <= PAGES_TO_SHOW ? 1 : page - before;

    return Math.max(1, first);
};

export const ListPagination: React.FC<IProps> = (props) => {
    const { count = 0, perPage = ITEMS_PER_PAGE, currentPage, setCurrentPage } = props;
    const totalPages = Math.ceil(count / perPage);

    const [pages, setPages] = React.useState<IPageItem[]>([]);

    React.useEffect(() => {
        buildPagination();
    }, [currentPage, count]);

    if (totalPages < 2) {
        return null;
    }

    const previousPageClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const nextPageClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const changePageClick = (pagedItem: IPageItem) => () => {
        const pageNumber = parseInt(pagedItem.text, 10);
        setCurrentPage(pageNumber);
    };

    function buildPagination() {
        let pageItems = [];

        const start = getFirstDisplayedPage(currentPage, totalPages);
        const end = Math.min(start + PAGES_TO_SHOW - 1, totalPages);

        if (start > 1) {
            pageItems.push(getEllipsisPage('before'));
        }

        pageItems = pageItems.concat(getMiddlePages(start, currentPage, end));

        if (end < totalPages) {
            pageItems.push(getEllipsisPage('after'));
        }
        setPages(pageItems);
    }

    const prevPageClassName = joinStrings(['page--item page--item-prev', currentPage === 1 && 'disabled']);
    const nextPageClassName = joinStrings(['page--item page--item-next', currentPage === totalPages && 'disabled']);

    return (
        <ul className="pagination">
            <li className={prevPageClassName}>
                {currentPage > 1 && (
                    <a className="page--item--link" onClick={previousPageClick}>
                        Previous
                    </a>
                )}
                {currentPage === 1 && <span className="page--item--link">Previous</span>}
            </li>
            {pages.map((pagedItem, index) => {
                return (
                    <li className={joinStrings(['page--item', pagedItem.class])} key={index}>
                        {pagedItem.link && (
                            <a className="page--item--link" onClick={changePageClick(pagedItem)}>
                                {pagedItem.text}
                            </a>
                        )}
                        {!pagedItem.link && <span className="page--item--link">{pagedItem.text}</span>}
                    </li>
                );
            })}

            <li className={nextPageClassName}>
                {currentPage < totalPages && (
                    <a className="page--item--link" onClick={nextPageClick}>
                        Next
                    </a>
                )}
                {currentPage === totalPages && <span className="page--item--link">Next</span>}
            </li>
        </ul>
    );
};
