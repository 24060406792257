import * as React from 'react';

import { ThumbnailList } from '~components/Editor/ThumbnailList';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { Base64ImageUri } from '~src/types';

interface IProps {
    thumbnails: ITimelineThumbnail[];
    selectThumbnail: (thumbnailId: ITimelineThumbnail['id'], imageData: Base64ImageUri) => void;
}

const ThumbnailPickerComponent: React.FunctionComponent<IProps> = ({ thumbnails, selectThumbnail }) => {
    return <ThumbnailList thumbnails={thumbnails} selectThumbnail={selectThumbnail} />;
};

const ThumbnailPicker = React.memo(ThumbnailPickerComponent);

export { ThumbnailPicker };
