import { RouteProps } from 'react-router-dom';

import { ICurrentUserResponse } from '~services/profile';
import { ISettingsSettings, TAlgoliaSettings } from '~services/settings';
import { FeatureDictionary } from '~src/types';

export type TSearchTerm = string;

export enum PageTheme {
    DARK = 'DARK',
    LIGHT = 'LIGHT',
    DARK_BLUE = 'DARK_BLUE',
}
export interface IRootContext {
    authorised: boolean;
    setAuthorised: (authorised: boolean, currentUser?: ICurrentUserResponse) => void;
    isDebugMode: boolean;
    algoliaSettings: TAlgoliaSettings;
    settings: ISettingsSettings;
    features: FeatureDictionary;
}

export type StudioRoute = RouteProps & {
    theme: PageTheme;
    title: string;
    withAuthHeader: boolean;
};
