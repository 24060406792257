import * as moment from 'moment';
import * as React from 'react';

import { TimeFilter } from '~components/ChannelManager/PreviewSchedule/TimeFilter';
import { DateRangePicker } from '~components/DateRangePicker';
import { ChevronRight } from '~components/Icons/ChevronRight';
import { joinStrings } from '~services/utilities';

import { IStartEndDates } from '../types';
import './index.scss';

const DROPDOWN_DATE_DISPLAY_FORMAT = 'D MMM YYYY';

export const getHumanisedDateString = (dateRange: IStartEndDates, format = DROPDOWN_DATE_DISPLAY_FORMAT): string => {
    if (dateRange) {
        const formattedStart = moment(dateRange.startDate).format(format);
        const formattedEnd = moment(dateRange.endDate).format(format);
        return `${formattedStart} - ${formattedEnd}`;
    }
    return '';
};

export interface IDropdownOption<T> {
    label: string;
    value: T;
    isDateRangePicker?: boolean;
}

interface IProps<T extends string> {
    uniqueId: string;
    options: Array<IDropdownOption<T>>;
    selected: T;
    className?: string;
    onSelected: (type: T, dateRange?: IStartEndDates) => void;
    onNext: VoidFunction;
    onNextDay: VoidFunction;
    onPrevious: VoidFunction;
    onPreviousDay: VoidFunction;
    selectedStartDate: moment.Moment;
    selectedEndDate: moment.Moment;
    minCustomDateRange?: moment.Moment;
    maxCustomDateRange?: moment.Moment;
    timeFilter: { start: string; end: string };
    timeIntervals: string[];
    onTimeChange: (timeFilter: { start: string; end: string }) => void;
}

export const DateFilter = <T extends string>(props: IProps<T>) => {
    const {
        selectedStartDate,
        selectedEndDate,
        minCustomDateRange,
        maxCustomDateRange,
        uniqueId,
        onNext,
        onNextDay,
        onPrevious,
        onPreviousDay,
        timeIntervals,
        timeFilter,
        onTimeChange,
    } = props;
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const transitionTimer = React.useRef<number>(null);

    React.useEffect(() => {
        const onClickOut = ({ target }: MouseEvent) => {
            if (!dropdownRef.current.contains(target as HTMLElement)) {
                close();
            }
        };
        document.addEventListener('mouseup', onClickOut);

        return () => {
            document.removeEventListener('mouseup', onClickOut);
            clearTimeout(transitionTimer.current);
            transitionTimer.current = null;
        };
    }, []);

    const close = () => {
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option: IDropdownOption<T>) => {
        props.onSelected(option.value, { startDate: selectedStartDate, endDate: selectedEndDate });
    };

    const selectDateRange = (optionValue: T) => (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
        props.onSelected(optionValue, { startDate: moment(startDate), endDate: moment(endDate) });
    };

    const containerId = `date-filter__${uniqueId}`;

    const datePickerOptions = React.useMemo(() => {
        return {
            autoApply: true,
            parentEl: `#${containerId}`,
            startDate: selectedStartDate?.toDate(),
            endDate: selectedEndDate?.toDate(),
            minDate: minCustomDateRange?.toDate(),
            maxDate: maxCustomDateRange?.toDate(),
            maxSpan: {
                days: 14,
            },
        };
    }, [containerId, maxCustomDateRange, minCustomDateRange, selectedStartDate, selectedEndDate]);

    const getOption = (option: IDropdownOption<T>) => {
        const onClick = () => selectOption(option);
        const isSelected = option.value === props.selected;
        const optionClassName = joinStrings(['dropdown-option', isSelected && 'selected']);
        let $component: JSX.Element;

        if (!option.isDateRangePicker) {
            $component = (
                <React.Fragment>
                    <span>{option.label}</span>
                </React.Fragment>
            );
        } else {
            $component = (
                <DateRangePicker options={datePickerOptions} onChange={selectDateRange(option.value)} inlinePicker={true}>
                    <span>{option.label}</span>
                </DateRangePicker>
            );
        }

        return (
            <li key={option.value} onClick={onClick}>
                <a className={optionClassName}>{$component}</a>
            </li>
        );
    };

    const className = joinStrings(['date-filter', props.className]);
    const containerClassName = joinStrings(['custom-dropdown', isOpen && 'active']);

    const handleStartTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onTimeChange({ start: e.target.value, end: timeFilter.end });
    };

    const handleEndTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onTimeChange({ start: timeFilter.start, end: e.target.value });
    };

    return (
        <div className={className} ref={dropdownRef}>
            <div className={containerClassName}>
                <div className="dropdown-selector double-previous" onClick={onPrevious}>
                    <ChevronRight className="dropdown-selector-previous" width={20} />
                    <ChevronRight className="dropdown-selector-previous" width={20} />
                </div>
                <div className="dropdown-selector" onClick={onPreviousDay}>
                    <ChevronRight className="dropdown-selector-previous" width={20} />
                </div>
                <div className="dropdown-value" onClick={toggleDropdown}>
                    {getHumanisedDateString({ startDate: selectedStartDate, endDate: selectedEndDate })}
                    <ChevronRight className="dropdown-icon" width={20} />
                </div>
                <div className="dropdown-selector" onClick={onNextDay}>
                    <ChevronRight className="dropdown-selector-next" width={20} />
                </div>
                <div className="dropdown-selector double-next" onClick={onNext}>
                    <ChevronRight className="dropdown-selector-next" width={20} />
                    <ChevronRight className="dropdown-selector-next" width={20} />
                </div>
                <div className="dropdown calendar-view">
                    <div className="dropdown-view-wrapper">
                        <div className="dropdown-view-title">
                            {getHumanisedDateString({ startDate: selectedStartDate, endDate: selectedEndDate })}
                        </div>
                        <div className="dropdown-view-contents">
                            <ul className="dropdown-options">{props.options.map(getOption)}</ul>
                            <div className="datepicker-container">
                                <div id={containerId} />
                            </div>
                        </div>
                        <div className="dropdown-view-footer">
                            From
                            <TimeFilter timeIntervals={timeIntervals} timeFilter={timeFilter.start} setTimeFilter={handleStartTimeChange} />
                            To
                            <TimeFilter timeIntervals={timeIntervals} timeFilter={timeFilter.end} setTimeFilter={handleEndTimeChange} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
