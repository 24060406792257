import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { FeedbackModal } from '~components/FeedbackModal';
import LoadingSpinner from '~components/LoadingSpinner';
import '~components/PageAnimation/index.scss';
import { PageRoutes } from '~components/Root/constants';
import { PageTheme } from '~components/Root/types';
import { VersionPanel } from '~components/VersionPanel';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { BrowsePage } from '~pages/Browse';
import { EditorPage } from '~pages/Editor';
import { authenticationService } from '~services/authentication';
import { ClipUpdateService } from '~services/clipUpdate';
import { Page } from '~src/routes/Page';
import { paginationUpdateResult } from '~src/store/pagination/pagination.actions';
import { searchUpdateResult } from '~src/store/search/search.actions';
import { FullScreen } from '~src/views/layout/FullScreen';

const LiveEditor: React.FC<RouteComponentProps> = () => <EditorPage assetType={EAssetType.LIVE} />;
const ClipEditor: React.FC<RouteComponentProps> = () => <EditorPage assetType={EAssetType.CLIP} />;
const VodEditor: React.FC<RouteComponentProps> = () => <EditorPage assetType={EAssetType.VOD} />;

const EDITOR_ROUTES = [
    {
        exact: true,
        title: '',
        path: '/editor',
        component: BrowsePage,
    },
    {
        exact: true,
        title: '',
        path: PageRoutes.EDITOR_LIVE,
        component: LiveEditor,
    },
    {
        exact: true,
        title: '',
        path: PageRoutes.EDITOR_CLIP,
        component: ClipEditor,
    },
    {
        exact: true,
        title: '',
        path: PageRoutes.EDITOR_VOD,
        component: VodEditor,
    },
];

class EditorRouteComponent extends React.PureComponent<{ dispatch: Dispatch; appLoaded: boolean }> {
    private clipUpdateService: ClipUpdateService = null;

    private async registerClipUpdateService() {
        const realm = await authenticationService.getRealm();

        this.clipUpdateService = new ClipUpdateService({ realm });
        this.clipUpdateService.subscribe((result) => {
            this.props.dispatch(paginationUpdateResult(['clip', 'recent-clip'], result));
            this.props.dispatch(searchUpdateResult(['clip'], result));
        });
    }

    public async componentDidMount() {
        this.registerClipUpdateService();
    }

    public componentWillUnmount() {
        this.clipUpdateService.unsubscribe();
    }

    public render() {
        const { appLoaded } = this.props;

        return (
            <FullScreen>
                {!appLoaded && (
                    <div className="spinner-wrapper">
                        <LoadingSpinner />
                    </div>
                )}

                <Switch>
                    {EDITOR_ROUTES.map((route, i) => (
                        <EditorTemplate {...route} key={i} />
                    ))}
                </Switch>

                <VersionPanel />

                <FeedbackModal />
            </FullScreen>
        );
    }
}

const EditorTemplate: React.FC<typeof EDITOR_ROUTES[0]> = (props) => {
    /* tslint:disable:jsx-no-lambda */
    return (
        <Route
            path={props.path}
            exact={props.exact}
            render={(route) => {
                return (
                    <Page title={props.title} theme={PageTheme.DARK}>
                        <props.component {...route} />
                    </Page>
                );
            }}
        />
    );
};

const EditorRoute: React.FC<{ appLoaded: boolean }> = (props) => {
    const dispatch = useDispatch();
    return <EditorRouteComponent dispatch={dispatch} {...props} />;
};

export default EditorRoute;
