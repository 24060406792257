import * as React from 'react';

import { Button, ButtonTheme } from '~components/Button';
import { ClipTitleField } from '~components/Editor/EditClip/ClipTitleField';
import { EditClipThumbnailPreview } from '~components/Editor/EditClip/EditClipThumbnailPreview';
import { ThumbnailPicker } from '~components/Editor/EditClip/ThumbnailPicker';
import { ClipPicker } from '~components/Editor/ExportClip/Components/ClipPicker';
import { CombineClipsNotification } from '~components/Editor/ExportClip/Components/CombineClipNotification';
import { Checkbox } from '~components/Form/Checkbox';
import Modal, { TextJustify } from '~components/Modal';
import { SaveAction } from '~containers/EditorPageContainer/types';
import { ClipUtilities } from '~src/store/timeline/clip/clip.utilities';
import { ClipsUtilities } from '~src/store/timeline/clip/clips.utilities';
import { IExportClipWithMeta, ITimelineClip } from '~src/store/timeline/clip/types';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { Base64ImageUri } from '~src/types';

enum EditStep {
    CLIP_META = 'meta',
    THUMBNAIL_SELECTION = 'thumbnail',
}

interface IComponentProps {
    clips: IExportClipWithMeta[];
    thumbnails: ITimelineThumbnail[];
    saveAction: SaveAction;
    back: VoidFunction;
    addCombinedClip: (combinedClip: IExportClipWithMeta) => void;
    canCreateCombinedClip: boolean;
}

const ClipCombiner: React.FunctionComponent<IComponentProps> = (props) => {
    const { clips, thumbnails, saveAction, back, addCombinedClip, canCreateCombinedClip } = props;

    const [clip, setClip] = React.useState(() => ClipUtilities.buildExportClipWithMeta());
    const [thumbnailId, setThumbnailId] = React.useState<ITimelineThumbnail['id']>(null);

    const [editStep, setEditStep] = React.useState<EditStep>(EditStep.CLIP_META);

    const [clipIds, setSelectedClipIds] = React.useState<Array<ITimelineClip['id']>>(() => {
        return canCreateCombinedClip ? ClipsUtilities.getIds(clips) : [];
    });

    const isCreateMode = saveAction === SaveAction.CREATE;

    const isAllSelected = clips.length === clipIds.length;

    const setThumbnail = (selectedThumbnailId: ITimelineThumbnail['id'], thumbnail: Base64ImageUri) => {
        setClip(ClipUtilities.setThumbnail(thumbnail, clip));
        setThumbnailId(selectedThumbnailId);
        setEditStep(EditStep.CLIP_META);
    };

    const setTitle = (title: string) => {
        setClip(ClipUtilities.setTitle(title, clip));
    };

    const handleSelectAll = () => {
        const newClipIds = isAllSelected ? [] : ClipsUtilities.getIds(clips);
        setSelectedClipIds(newClipIds);
    };

    const handleSelectClip = (clipId: ITimelineClip['id']) => {
        const newSelectedClipIds: Array<ITimelineClip['id']> = [...clipIds];

        const index = clipIds.indexOf(clipId);
        if (index === -1) {
            newSelectedClipIds.push(clipId);
        } else {
            newSelectedClipIds.splice(index, 1);
        }

        setSelectedClipIds(newSelectedClipIds);
    };

    const getUpdateMetaStep = () => {
        const hasTitle = clip.meta.title.trim().length;
        const isAddClipDisabled = !hasTitle || !hasSelectedClips();
        const isMetaView = editStep === EditStep.CLIP_META;

        return (
            <form onSubmit={handleSetCombinedClips} autoComplete="off">
                <div className="form-group">
                    <div className="form-group__control-wrapper">
                        <label className="form-group__label no-transition">
                            Select clips <small>(minimum of {isCreateMode ? '2 clips' : '1 clip'})</small>
                        </label>
                    </div>

                    <label className="form-group__label no-transition" htmlFor="selectAll">
                        <Checkbox
                            className="form-group__control"
                            id="selectAll"
                            name="selectAll"
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            data-test-id="exportForm-exportClipSelectAllCheckbox"
                            label="Select all"
                        />
                    </label>

                    <ClipPicker renderSingleList={!isCreateMode} clips={clips} clipIds={clipIds} selectClip={handleSelectClip} />
                </div>

                {isCreateMode && (
                    <React.Fragment>
                        <div className="form-group">
                            <div className="form-group__control-wrapper">
                                <label className="form-group__label no-transition">Select thumbnail</label>
                            </div>
                            <EditClipThumbnailPreview thumbnailId={thumbnailId} thumbnails={thumbnails} toggleEditStep={toggleEditStep} />
                        </div>

                        <ClipTitleField setTitle={setTitle} title={clip.meta.title} />
                    </React.Fragment>
                )}

                <div className="button-group mt-4 text-center">
                    {isMetaView && (
                        <Button theme={ButtonTheme.BLUE} disabled={isAddClipDisabled}>
                            {submitButtonText}
                        </Button>
                    )}
                    {back && (
                        <Button theme={ButtonTheme.BLACK} type="button" onClick={back}>
                            Back
                        </Button>
                    )}
                </div>
            </form>
        );
    };

    // thumbnail selection step of clip editing
    const getThumbnailSelectionStep = () => {
        return (
            <React.Fragment>
                <div className="form-group__control-wrapper">
                    <label className="form-group__label no-transition">Select thumbnail</label>
                </div>

                <ThumbnailPicker thumbnails={thumbnails} selectThumbnail={setThumbnail} />

                <div className="button-group mt-4 text-center">
                    <Button theme={ButtonTheme.BLACK} type="button" onClick={toggleEditStep}>
                        Back
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    const toggleEditStep = () => {
        if (editStep === EditStep.CLIP_META) {
            setEditStep(EditStep.THUMBNAIL_SELECTION);
        } else {
            setEditStep(EditStep.CLIP_META);
        }
    };

    const getEditStepView = (currentEditStep: EditStep): JSX.Element => {
        switch (currentEditStep) {
            case EditStep.CLIP_META:
                return getUpdateMetaStep();
            case EditStep.THUMBNAIL_SELECTION:
                return getThumbnailSelectionStep();
        }
    };

    const hasSelectedClips = () => {
        const selectedCount = clipIds.length;

        if (isCreateMode && selectedCount >= 2) {
            return true;
        } else if (!isCreateMode && !canCreateCombinedClip && selectedCount === 1) {
            return true;
        } else if (!isCreateMode && canCreateCombinedClip && selectedCount >= 1) {
            return true;
        }
        return false;
    };

    const handleSetCombinedClips = (event: React.FormEvent) => {
        event.preventDefault();

        const sourceClips = clipIds.map((clipId) => {
            return clips.find((sourceClip) => sourceClip.meta.id === clipId);
        });

        const combinedClip = ClipUtilities.combineClipComponents(clip, sourceClips);

        addCombinedClip(combinedClip);
    };

    const sectionTitle = isCreateMode ? 'Create a combined clip' : 'Overwrite Clip';
    const sectionDescription = isCreateMode
        ? 'Select the clips you want to combine into a single asset.'
        : 'Select the clip you want to overwrite, alternatively if multiple clips are selected they will be combined into a single asset.';
    const submitButtonText = isCreateMode ? 'Add combined clip' : 'Confirm overwrite';

    return (
        <React.Fragment>
            <Modal.Title justify={TextJustify.CENTER}>{sectionTitle}</Modal.Title>
            <Modal.Body className="export-modal__clip-combiner">
                <div className="export-modal__step-description">
                    <p>{sectionDescription}</p>
                </div>

                {!canCreateCombinedClip && <CombineClipsNotification />}

                {getEditStepView(editStep)}
            </Modal.Body>
        </React.Fragment>
    );
};

export { ClipCombiner };
