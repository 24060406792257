import { simpleFetchApi } from '~services/http';
import { IStreamUrl } from '~src/store/editor/stream/types';

interface IStreamingProtocol {
    url?: string;
    drm?: {
        jwtToken: string;
        url: string;
    };
}

interface IDvePlaybackResponse {
    dash: IStreamingProtocol[];
    hls: IStreamingProtocol[];
}

interface IStreamingProtocolDict {
    hlsStreamDRM: IStreamingProtocol['drm'];
    dashStreamDRM: IStreamingProtocol['drm'];
    hlsStreamUrl: IStreamingProtocol['url'];
    dashStreamUrl: IStreamingProtocol['url'];
    streamUrls: IStreamUrl[];
}

const getHlsStreamUrl = (playBackResponse: IDvePlaybackResponse): IStreamingProtocol['url'] => {
    const [protocol = {}] = playBackResponse.hls;
    return protocol.url;
};

const getDashStreamUrl = (playBackResponse: IDvePlaybackResponse): IStreamingProtocol['url'] => {
    const [protocol = {}] = playBackResponse.dash;
    return protocol.url;
};

const getHlsStreamDRM = (playBackResponse: IDvePlaybackResponse): IStreamingProtocol['drm'] => {
    const [protocol = {}] = playBackResponse.hls;
    return protocol.drm;
};

const getDashStreamDRM = (playBackResponse: IDvePlaybackResponse): IStreamingProtocol['drm'] => {
    const [protocol = {}] = playBackResponse.dash;
    return protocol.drm;
};

const getDvePlaybackUrls = async (playbackUrl: string): Promise<IStreamingProtocolDict> => {
    const response: IDvePlaybackResponse = await simpleFetchApi(playbackUrl);

    const playbackUrls = {
        hlsStreamDRM: getHlsStreamDRM(response),
        hlsStreamUrl: getHlsStreamUrl(response),
        dashStreamDRM: getDashStreamDRM(response),
        dashStreamUrl: getDashStreamUrl(response),
    };

    const streamUrls = [];

    if (playbackUrls.hlsStreamUrl) {
        streamUrls.push({
            type: 'HLS',
            url: playbackUrls.hlsStreamUrl,
            drm: playbackUrls.hlsStreamDRM,
        });
    }

    if (playbackUrls.dashStreamUrl) {
        streamUrls.push({
            type: 'DASH',
            url: playbackUrls.dashStreamUrl,
            drm: playbackUrls.dashStreamDRM,
        });
    }

    return {
        ...playbackUrls,
        streamUrls,
    };
};

export { getDvePlaybackUrls, IStreamingProtocolDict };
