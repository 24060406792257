import { ICreateRealmConfiguration } from '~services/realms/types';

import { createAction } from '../actions';
import { RealmActions } from './constants';

// Saga actions

export const createRealm = (realmConfiguration: ICreateRealmConfiguration, callback: VoidFunction) =>
    createAction(RealmActions.CREATE_REALM, { realmConfiguration, callback });

// Reduce actions

export const startedCreatingRealm = () => createAction(RealmActions.STARTED_CREATING_REALM);

export const successfullyCreatedRealm = () => createAction(RealmActions.SUCCESSFULLY_CREATED_REALM);

export const errorCreatingRealm = () => createAction(RealmActions.ERROR_CREATING_REALM);

export type ReduceActions = ReturnType<
    typeof createRealm | typeof startedCreatingRealm | typeof successfullyCreatedRealm | typeof errorCreatingRealm
>;
