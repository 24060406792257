import { all, call, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { PageTheme } from '~components/Root/types';
import * as service from '~services/channels';
import {
    IChannelAdminStreamingConfigMuxer,
    IChannelDetails,
    IContentPod,
    IGetResponseBase,
    IMuxerVersions,
    IPublishChangesPayload,
    IRawContentPod,
    IVllContent,
} from '~services/channels/types';
import { EServiceStatus } from '~services/http/enums';

import { EToastType } from '../toast/constants';
import { addToast } from '../toast/toast.actions';
import {
    batchActionConfirmed,
    featureFlagsLoaded,
    changePodManagementModalView,
    channelAdminDetailsUpdated,
    channelDeleteRequiresConfirmation,
    channelSettingsChanged,
    checkMuxerStatus,
    clearDeleteChannelRequiresConfirmation,
    closePodManagementModal,
    confirmationNeededToOverwrite,
    contentByIdMapUpdated,
    contentRangeDetailsUpdated,
    contentSelectedToAddToList,
    contentSelectedToPreview,
    deactivateBatchActionMode,
    deleteChannel,
    editPod,
    errorProvisioningMuxer,
    errorPublishingChanges,
    errorRefreshingMuxers,
    muxerAction,
    muxerActionRequiresConfirmation,
    muxerUpdated,
    openPodManagementModal,
    pageLoad,
    pageLoaded,
    podMoved,
    podRemoved,
    podRemovedUndo,
    podsUpdated,
    podUpdated,
    provisionMuxer,
    refreshMuxers,
    selectedContentListUpdated,
    setChannelDgeEventId,
    startedDeletingChannel,
    startedProvisioningMuxer,
    startedPublishingChanges,
    startedRefreshingMuxers,
    startedSynchronisingChannelStreamingConfigs,
    successfullyProvisionedMuxer,
    successfullyPublishedChanges,
    successfullyRefreshedMuxers,
    successfullySynchronisedChannelStreamingConfigs,
    successfulMuxerAction,
    totalContentSizeUpdated,
} from './channelManager.actions';
import * as selectors from './channelManager.selectors';
import {
    adjustContentPodPositions,
    copyPasteContentPodsToPosition,
    createNewContentPod,
    deleteContentPods,
    getFormattedContentPodsToPublish,
    getPodOptions,
    getPodSelectorInitialState,
    isCurrentStepContentSearch,
    isCurrentStepEditMode,
    moveContentPodsToPosition,
    parseRawContentPods,
    recalculateAdBreaksWithUpdatedTargetAdvertDuration,
} from './channelManager.utilities';
import {
    BatchActionMode,
    CHANNEL_DELETE_FORCE_REQUIRED,
    CHANNEL_DELETE_SUCCESS_NOTIFICATION,
    ChannelManagerActions,
    GENERAL_ERROR_NOTIFICATION,
    MISSING_DGE_EVENT_STATUS_CODE,
    MUXER_ACTION_FORCE_REQUIRED,
    MUXER_ACTION_SUCCESS_NOTIFICATION,
    OUTDATED_TIMELINE_STATUS_CODE,
    PodManagementStep,
    PodSelectorView,
    PROVISION_MUXER_SUCCESS_NOTIFICATION,
    PUBLISH_SUCCESS_NOTIFICATION,
    REFRESH_CHANNEL_ADMIN_DETAILS_SUCCESS_NOTIFICATION,
    SET_CHANNEL_DGE_ID_ERROR,
    SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_ERROR_NOTIFICATION,
    SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_SUCCESS_NOTIFICATION,
    TOAST_TIMEOUT,
} from './constants';
import { IChannelManagerState, ChangeAction, ICuratedPodData, UUID } from './types';

const GENERIC_ERROR_TOAST_ACTION = addToast(
    GENERAL_ERROR_NOTIFICATION.title,
    GENERAL_ERROR_NOTIFICATION.message,
    EToastType.DANGER,
    TOAST_TIMEOUT,
    null,
    PageTheme.LIGHT
);

const PUBLISH_TOAST_ACTION = addToast(
    PUBLISH_SUCCESS_NOTIFICATION.title,
    PUBLISH_SUCCESS_NOTIFICATION.message,
    EToastType.SUCCESS,
    TOAST_TIMEOUT,
    null,
    PageTheme.LIGHT
);

const PROVISION_MUXER_ACTION = addToast(
    PROVISION_MUXER_SUCCESS_NOTIFICATION.title,
    PROVISION_MUXER_SUCCESS_NOTIFICATION.message,
    EToastType.SUCCESS,
    TOAST_TIMEOUT,
    null,
    PageTheme.LIGHT
);

const REFRESH_MUXERS_ACTION = addToast(
    REFRESH_CHANNEL_ADMIN_DETAILS_SUCCESS_NOTIFICATION.title,
    REFRESH_CHANNEL_ADMIN_DETAILS_SUCCESS_NOTIFICATION.message,
    EToastType.SUCCESS,
    TOAST_TIMEOUT,
    null,
    PageTheme.LIGHT
);

const MUXER_ACTION_SUCCESS_ACTION = (message: string) => {
    return addToast(MUXER_ACTION_SUCCESS_NOTIFICATION.title, message, EToastType.SUCCESS, TOAST_TIMEOUT, null, PageTheme.LIGHT);
};

const DELETE_CHANNEL_SUCCESS_ACTION = (message: string) => {
    return addToast(CHANNEL_DELETE_SUCCESS_NOTIFICATION.title, message, EToastType.SUCCESS, TOAST_TIMEOUT, null, PageTheme.LIGHT);
};

const MUXER_ACTION_ERROR_ACTION = (message: string) => {
    return addToast(MUXER_ACTION_SUCCESS_NOTIFICATION.title, message, EToastType.DANGER, TOAST_TIMEOUT, null, PageTheme.LIGHT);
};

const CHANNEL_DELETE_ERROR_ACTION = (message: string) => {
    return addToast(MUXER_ACTION_SUCCESS_NOTIFICATION.title, message, EToastType.DANGER, TOAST_TIMEOUT, null, PageTheme.LIGHT);
};

const SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_ACTION = addToast(
    SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_SUCCESS_NOTIFICATION.title,
    SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_SUCCESS_NOTIFICATION.message,
    EToastType.SUCCESS,
    TOAST_TIMEOUT,
    null,
    PageTheme.LIGHT
);

const CHANNEL_MISSING_DGE_EVENT_ACTION = addToast(
    SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_ERROR_NOTIFICATION.title,
    SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_ERROR_NOTIFICATION.message,
    EToastType.DANGER,
    TOAST_TIMEOUT,
    null,
    PageTheme.LIGHT
);

function* handleLoadFeatureFlags() {
    try {
        const featureFlags = {};
        yield put(featureFlagsLoaded(featureFlags));
    } catch (e) {
        console.warn(e);
        yield put(GENERIC_ERROR_TOAST_ACTION);
    }
}

function* handlePageLoad(action: ReturnType<typeof pageLoad>) {
    const { channelId } = action.payload;

    try {
        const [channelDetails, muxerVersions, { results: rawContentPods }]: [
            IChannelDetails,
            IMuxerVersions,
            IGetResponseBase<IRawContentPod>
        ] = yield all([
            call(service.getChannelDetails, channelId),
            call(service.getMuxerVersions),
            call(service.getChannelContent, channelId),
        ]);

        const firstContentPod = rawContentPods?.[0];
        const firstContentPodStartTime = firstContentPod?.startTime ?? channelDetails.channelStartTime;
        const firstContentPodNumber = firstContentPod?.podNumber ?? 1;
        const { contentPods, editableRange, scrollableDateTimeRange } = parseRawContentPods(rawContentPods);

        yield put(
            pageLoaded(
                contentPods,
                {
                    ...channelDetails,
                    editableRange,
                    scrollableDateTimeRange,
                    firstContentPodStartTime,
                    firstContentPodNumber,
                },
                muxerVersions
            )
        );
    } catch (e) {
        console.warn(e);
        yield put(GENERIC_ERROR_TOAST_ACTION);
    }
}

function* handlePodMoved(action: ReturnType<typeof podMoved>) {
    const {
        payload: { from, to, changeAction },
    } = action;

    const contentPods: IChannelManagerState['contentPods'] = yield select(selectors.getContentPodsState);

    const updatedContentPods = [...contentPods];
    const contentPodToMoveFromIndex = updatedContentPods.findIndex(({ podNumber }) => podNumber === from);
    const contentPodToMoveToIndex = updatedContentPods.findIndex(({ podNumber }) => podNumber === to);
    const contentPodToMove = updatedContentPods[contentPodToMoveFromIndex];

    const hasMoved = contentPodToMove.originalPodNumber !== updatedContentPods[contentPodToMoveToIndex].podNumber;

    updatedContentPods.splice(contentPodToMoveFromIndex, 1);
    updatedContentPods.splice(contentPodToMoveToIndex, 0, { ...contentPodToMove, hasMoved });
    const lastEditedUuid = contentPodToMove.uuid;

    yield call(adjustContentPodsAndUpdateTimeline, updatedContentPods, changeAction, lastEditedUuid);
}

function* handleBatchActionConfirmed(action: ReturnType<typeof batchActionConfirmed>) {
    const { positionToAdd } = action.payload;

    const contentPods: IChannelManagerState['contentPods'] = yield select(selectors.getContentPodsState);
    const { selectedPodUuids, mode }: IChannelManagerState['batchAction'] = yield select(selectors.getBatchActionState);

    switch (mode) {
        case BatchActionMode.COPY_PASTE:
            yield call(handleBatchCopyPasteAction, contentPods, selectedPodUuids, positionToAdd);
            break;
        case BatchActionMode.MULTIPLE_MOVE:
            yield call(handleBatchMultipleMoveAction, contentPods, selectedPodUuids, positionToAdd);
            break;
        case BatchActionMode.MULTIPLE_DELETE:
            yield call(handleBatchMultipleDeleteAction, contentPods, selectedPodUuids);
            break;
    }
}

function* handleBatchCopyPasteAction(contentPods: IContentPod[], selectedPodUuids: UUID[], positionToCopy: number) {
    const { contentPods: updatedContentPods, lastEditedUuid } = copyPasteContentPodsToPosition(
        contentPods,
        selectedPodUuids,
        positionToCopy
    );

    yield call(adjustContentPodsAndUpdateTimeline, updatedContentPods, ChangeAction.ADD_TO_POSITION, lastEditedUuid);
}

function* handleBatchMultipleMoveAction(contentPods: IContentPod[], selectedPodUuids: UUID[], positionToMove: number) {
    const { contentPods: updatedContentPods, lastEditedUuid } = moveContentPodsToPosition(contentPods, selectedPodUuids, positionToMove);

    yield call(adjustContentPodsAndUpdateTimeline, updatedContentPods, ChangeAction.ADD_TO_POSITION, lastEditedUuid);
    yield put(deactivateBatchActionMode());
}

function* handleBatchMultipleDeleteAction(contentPods: IContentPod[], selectedPodUuids: UUID[]) {
    const updatedContentPods = deleteContentPods(contentPods, selectedPodUuids);

    yield call(adjustContentPodsAndUpdateTimeline, updatedContentPods, ChangeAction.REMOVE, null);
    yield put(deactivateBatchActionMode());
}

function* handlePodRemoved(action: ReturnType<typeof podRemoved>) {
    const {
        payload: { uuid },
    } = action;

    const contentPods: IChannelManagerState['contentPods'] = yield select(selectors.getContentPodsState);

    const updatedContentPods = contentPods.reduce((acc, pod) => {
        if (pod.uuid === uuid) {
            if (!pod.isNew) {
                const updatedPod = {
                    ...pod,
                    isRemoved: true,
                };
                acc.push(updatedPod);
            }
        } else {
            acc.push(pod);
        }
        return acc;
    }, []);

    yield call(adjustContentPodsAndUpdateTimeline, updatedContentPods, ChangeAction.REMOVE, null);
}

function* handlePodRemovedUndo(action: ReturnType<typeof podRemovedUndo>) {
    const {
        payload: { uuid },
    } = action;

    const contentPods: IChannelManagerState['contentPods'] = yield select(selectors.getContentPodsState);

    const updatedContentPods = contentPods.map((pod) => {
        if (pod.uuid === uuid) {
            return {
                ...pod,
                isRemoved: false,
            };
        }
        return pod;
    });

    yield call(adjustContentPodsAndUpdateTimeline, updatedContentPods, ChangeAction.REMOVE_UNDO, uuid);
}

function* handlePodUpdated(action: ReturnType<typeof podUpdated>) {
    const { pod: selectedPod, content } = action.payload;

    const contentPods: IChannelManagerState['contentPods'] = yield select(selectors.getContentPodsState);
    const { currentStep }: IChannelManagerState['podManagement'] = yield select(selectors.getPodManagementState);

    if (isCurrentStepEditMode(currentStep)) {
        const updatedContentPods = contentPods.map((contentPod) => {
            if (contentPod.uuid === currentStep.uuid) {
                return {
                    ...contentPod,
                    duration: selectedPod.duration,
                    adBreaks: [...selectedPod.adBreaks],
                    content: { ...content },
                    isEdited: true,
                };
            }
            return contentPod;
        });

        yield call(adjustContentPodsAndUpdateTimeline, updatedContentPods, ChangeAction.UPDATE, currentStep.uuid);
        yield put(closePodManagementModal());
    }
}

function* adjustContentPodsAndUpdateTimeline(
    updatedContentPods: IContentPod[],
    lastAction: IChannelManagerState['lastAction'],
    lastEdited: IChannelManagerState['lastEdited']
) {
    const { firstContentPodStartTime }: IChannelManagerState['channelDetails'] = yield select(selectors.getChannelDetailsState);

    const {
        contentPods: adjustedContentPods,
        editableRange,
        scrollableDateTimeRange,
        pendingPublishPodNumbers,
        totalContent,
        totalDuration,
    } = adjustContentPodPositions(updatedContentPods, firstContentPodStartTime);

    yield put(
        podsUpdated(
            adjustedContentPods,
            {
                lastAction,
                lastEdited,
            },
            pendingPublishPodNumbers
        )
    );
    // Not always necessary to update total size, but for now keep it simple and update every time timeline is updated
    yield put(totalContentSizeUpdated(totalDuration, totalContent));
    yield put(contentRangeDetailsUpdated(editableRange, scrollableDateTimeRange));
}

function* handleEditPod(action: ReturnType<typeof editPod>) {
    const {
        payload: { uuid },
    } = action;

    const contentPods: IChannelManagerState['contentPods'] = yield select(selectors.getContentPodsState);
    const podToEdit = contentPods.find((pod) => pod.uuid === uuid);
    const podData: ICuratedPodData = yield getPodData(podToEdit.content.dveVideoId);

    if (podData) {
        yield put(openPodManagementModal(getPodSelectorInitialState(podData, uuid, podToEdit.duration)));
    } else {
        yield put(GENERIC_ERROR_TOAST_ACTION);
    }
}

function* handleMultipleContentSelected() {
    const { currentStep, contentByIdMap, insertPosition }: IChannelManagerState['podManagement'] = yield select(
        selectors.getPodManagementState
    );

    if (isCurrentStepContentSearch(currentStep)) {
        const { targetAdvertDuration, targetAdvertPercentage }: IChannelManagerState['channelDetails'] = yield select(
            selectors.getChannelDetailsState
        );
        const contentPods: IChannelManagerState['contentPods'] = yield select(selectors.getContentPodsState);

        const selectedContentPods = [];

        for (const selectedContent of currentStep.selectedContentList) {
            const { content } = contentByIdMap[selectedContent.dveVideoId];
            const { defaultPod } = getPodOptions(content, targetAdvertDuration, targetAdvertPercentage);
            selectedContentPods.push(createNewContentPod(defaultPod, content));
        }

        const firstInsertedItem = selectedContentPods[0];

        const insertAt = insertPosition === null || insertPosition === undefined ? contentPods.length : insertPosition;

        const updatedContentPods = [...contentPods.slice(0, insertAt), ...selectedContentPods, ...contentPods.slice(insertAt)];

        yield call(adjustContentPodsAndUpdateTimeline, updatedContentPods, ChangeAction.ADD_TO_POSITION, firstInsertedItem.uuid);
        yield put(closePodManagementModal());
    }
}

function* handleMultiplePodAddedToAddToList(action: ReturnType<typeof contentSelectedToAddToList>) {
    const {
        payload: { content: baseContent },
    } = action;

    yield put(selectedContentListUpdated(baseContent));

    const hasContentDetails = yield call(hasContentDetailsInCache, baseContent.dveVideoId);

    if (!hasContentDetails) {
        // update store asynchronously. Add button will be disabled if there is a running api call
        yield fork(getContentDetailsAndUpdateCache, baseContent.dveVideoId);
    }
}

function* handleMultiplePodAddedToPreview(action: ReturnType<typeof contentSelectedToPreview>) {
    const {
        payload: { content, searchResults, searchQuery },
    } = action;

    const { currentStep } = yield select(selectors.getPodManagementState);
    const podData: ICuratedPodData = yield getPodData(content.dveVideoId);

    if (podData) {
        yield put(
            changePodManagementModalView(
                { step: PodManagementStep.POD_SELECTOR, kind: PodSelectorView.PREVIEW, podData, selectedPod: podData.defaultPod.duration },
                { ...currentStep, searchResults, searchQuery }
            )
        );
    } else {
        yield put(GENERIC_ERROR_TOAST_ACTION);
    }
}

function* getPodData(contentId: number) {
    try {
        const { contentByIdMap }: IChannelManagerState['podManagement'] = yield select(selectors.getPodManagementState);
        let content: IVllContent;

        const hasContentDetails = yield call(hasContentDetailsInCache, contentId);

        if (hasContentDetails) {
            content = contentByIdMap[contentId].content;
        } else {
            // Wait for data if not already in the cache
            content = yield call(getContentDetailsAndUpdateCache, contentId);
        }

        if (content) {
            const { targetAdvertDuration, targetAdvertPercentage }: IChannelManagerState['channelDetails'] = yield select(
                selectors.getChannelDetailsState
            );
            return getPodOptions(content, targetAdvertDuration, targetAdvertPercentage);
        }
        return null;
    } catch (e) {
        console.warn(e);
        return null;
    }
}

function* hasContentDetailsInCache(contentId: number) {
    const { contentByIdMap }: IChannelManagerState['podManagement'] = yield select(selectors.getPodManagementState);

    if (contentByIdMap[contentId]?.status !== EServiceStatus.LOADED) {
        // could return promise if already loading, but keeping it simple for now and fetch again
        return false;
    }

    return true;
}

function* getContentDetailsAndUpdateCache(contentId: number) {
    try {
        yield put(contentByIdMapUpdated(contentId, EServiceStatus.LOADING));
        const content: IVllContent = yield call(service.getVideoById, contentId);
        yield put(contentByIdMapUpdated(contentId, EServiceStatus.LOADED, content));

        return content;
    } catch (e) {
        console.warn(e);
        yield put(contentByIdMapUpdated(contentId, EServiceStatus.ERROR));
        console.warn(`Failed to fetch content id: ${contentId}`);
        return null;
    }
}

function* handlePublishContent() {
    const { timelineVersion, firstContentPodNumber, channelId }: IChannelManagerState['channelDetails'] = yield select(
        selectors.getChannelDetailsState
    );
    const contentPods: IChannelManagerState['contentPods'] = yield select(selectors.getContentPodsState);
    const contentPodsToPublish = getFormattedContentPodsToPublish(contentPods);
    const publishPayload: IPublishChangesPayload = {
        channelId,
        timelineVersion,
        firstContentPodNumber,
        contentPods: contentPodsToPublish,
    };

    try {
        yield put(startedPublishingChanges());
        yield call(service.publishChannelContent, publishPayload);
        yield all([put(PUBLISH_TOAST_ACTION), put(successfullyPublishedChanges()), put(pageLoad(channelId))]);
    } catch (e) {
        console.warn(e);
        if (e.status === OUTDATED_TIMELINE_STATUS_CODE) {
            yield put(confirmationNeededToOverwrite());

            const { confirmed } = yield race({
                confirmed: take(ChannelManagerActions.OVERWRITE_CONFIRMED),
                cancelled: take(ChannelManagerActions.OVERWRITE_CANCELLED),
            });

            if (confirmed) {
                yield call(service.publishChannelContent, publishPayload, true);
                yield all([put(PUBLISH_TOAST_ACTION), put(successfullyPublishedChanges()), put(pageLoad(channelId))]);
            }
        } else {
            yield put(GENERIC_ERROR_TOAST_ACTION);
            yield put(errorPublishingChanges());
        }
    }
}

function* handleSynchroniseChannelStreamingConfigs() {
    const { channelId }: IChannelManagerState['channelDetails'] = yield select(selectors.getChannelDetailsState);

    try {
        yield put(startedSynchronisingChannelStreamingConfigs());
        yield call(service.synchroniseChannelStreamingConfigs, channelId);
        const channelAdminDetails = yield call(service.getChannelAdminDetails, channelId);
        yield all([
            put(channelAdminDetailsUpdated(channelAdminDetails)),
            put(SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_ACTION),
            put(successfullySynchronisedChannelStreamingConfigs()),
        ]);
    } catch (e) {
        console.warn(e);
        if (e.status === MISSING_DGE_EVENT_STATUS_CODE) {
            yield put(CHANNEL_MISSING_DGE_EVENT_ACTION);
        } else {
            yield put(GENERIC_ERROR_TOAST_ACTION);
        }
        yield put(errorPublishingChanges());
    }
}

function* handleProvisionMuxer(action: ReturnType<typeof provisionMuxer>) {
    const { channelId }: IChannelManagerState['channelDetails'] = yield select(selectors.getChannelDetailsState);
    const { muxerConfiguration } = action.payload;
    try {
        yield put(startedProvisioningMuxer());
        const newMuxer = yield call(service.provisionMuxer, { channelId, muxerConfiguration });
        yield all([put(PROVISION_MUXER_ACTION), put(muxerUpdated(newMuxer)), put(successfullyProvisionedMuxer())]);
    } catch (e) {
        console.warn(e);
        yield put(errorProvisioningMuxer());
    }
}

function* handleSetChannelDgeEventId(action: ReturnType<typeof setChannelDgeEventId>) {
    const { channelId }: IChannelManagerState['channelDetails'] = yield select(selectors.getChannelDetailsState);
    const { dgeEventId } = action.payload;
    try {
        yield put(startedRefreshingMuxers());
        const channelAdminDetails = yield call(service.setChannelDgeEventId, { channelId, dgeEventId });
        yield all([put(channelAdminDetailsUpdated(channelAdminDetails)), put(successfullyRefreshedMuxers())]);
    } catch (e) {
        console.warn(e);
        if (e.status === SET_CHANNEL_DGE_ID_ERROR) {
            const responseBody = yield e.json();
            yield put(MUXER_ACTION_ERROR_ACTION(responseBody.message));
        }
        yield put(errorRefreshingMuxers());
    }
}

function* handleRefreshMuxers(action: ReturnType<typeof refreshMuxers>) {
    const { channelId }: IChannelManagerState['channelDetails'] = yield select(selectors.getChannelDetailsState);
    const { hideSuccessNotification } = action.payload;

    try {
        yield put(startedRefreshingMuxers());
        const channelAdminDetails = yield call(service.getChannelAdminDetails, channelId);
        yield all([put(channelAdminDetailsUpdated(channelAdminDetails)), put(successfullyRefreshedMuxers())]);

        if (!hideSuccessNotification) {
            yield put(REFRESH_MUXERS_ACTION);
        }
    } catch (e) {
        console.warn(e);
        yield put(errorRefreshingMuxers());
    }
}

function* handleMuxerAction(action: ReturnType<typeof muxerAction>) {
    const { channelId }: IChannelManagerState['channelDetails'] = yield select(selectors.getChannelDetailsState);

    let actionMessage = 'Unknown Error';

    try {
        const muxerPayload = {
            channelId,
            muxerId: action.payload.muxerId,
            streamingConfigId: action.payload.streamingConfigId,
            action: action.payload.action,
            forceAction: action.payload.forceAction,
        };

        const actionMessageResponse = yield call(service.muxerAction, muxerPayload);
        actionMessage = actionMessageResponse.message;
        yield all([
            put(MUXER_ACTION_SUCCESS_ACTION(actionMessage)),
            put(successfulMuxerAction()),
            put(checkMuxerStatus(action.payload.muxerId)),
        ]);
    } catch (e) {
        console.warn(e);
        if (e.status === MUXER_ACTION_FORCE_REQUIRED) {
            yield put(muxerActionRequiresConfirmation(action.payload));
        } else {
            yield put(MUXER_ACTION_ERROR_ACTION(actionMessage));
        }
    }
}

function* handleCheckMuxerStatusAction(action: ReturnType<typeof muxerAction>) {
    const { channelId }: IChannelManagerState['channelDetails'] = yield select(selectors.getChannelDetailsState);

    try {
        const muxerStatus: IChannelAdminStreamingConfigMuxer = yield call(service.getMuxerStatus, {
            channelId,
            muxerId: action.payload.muxerId,
        });

        let statusMessage = 'Muxer status updated';
        if (muxerStatus.status === 'PROVISIONING') {
            statusMessage = 'Muxer is still provisioning';
        }

        yield all([put(MUXER_ACTION_SUCCESS_ACTION(statusMessage)), put(muxerUpdated(muxerStatus)), put(successfulMuxerAction())]);
    } catch (e) {
        console.warn(e);
        yield put(GENERIC_ERROR_TOAST_ACTION);
    }
}

function* handleDeleteChannel(action: ReturnType<typeof deleteChannel>) {
    let actionMessage = 'Unknown Error';
    yield put(startedDeletingChannel());

    try {
        const actionMessageResponse = yield call(service.deleteChannel, {
            channelId: action.payload.channelId,
            forceDelete: action.payload.forceDelete,
        });
        actionMessage = actionMessageResponse.message;
        yield all([put(DELETE_CHANNEL_SUCCESS_ACTION(actionMessage)), put(clearDeleteChannelRequiresConfirmation())]);
        action.payload.callback();
    } catch (e) {
        console.warn(e);
        if (e.status === CHANNEL_DELETE_FORCE_REQUIRED) {
            yield put(channelDeleteRequiresConfirmation());
        } else {
            yield put(CHANNEL_DELETE_ERROR_ACTION(actionMessage));
        }
    }
}

function* handleChannelSettingsChanged(action: ReturnType<typeof channelSettingsChanged>) {
    const { settings } = action.payload;
    const contentPods: IChannelManagerState['contentPods'] = yield select(selectors.getContentPodsState);
    const channelDetails: IChannelManagerState['channelDetails'] = yield select(selectors.getChannelDetailsState);
    const { channelId, timelineVersion, firstContentPodNumber, targetAdvertPercentage } = channelDetails;

    try {
        yield put(startedPublishingChanges());

        if (contentPods.length && channelDetails.targetAdvertDuration !== settings.targetAdvertDuration) {
            // only need to update timeline if there are existing content pods
            // and the target advert duration changed
            const { contentPods: adjustedContentPods } = adjustContentPodPositions(
                recalculateAdBreaksWithUpdatedTargetAdvertDuration(settings.targetAdvertDuration, targetAdvertPercentage, contentPods),
                channelDetails.firstContentPodStartTime
            );
            const contentPodsToPublish = getFormattedContentPodsToPublish(adjustedContentPods);
            const publishPayload: IPublishChangesPayload = {
                channelId,
                timelineVersion,
                firstContentPodNumber,
                contentPods: contentPodsToPublish,
            };

            yield call(service.publishChannelContent, publishPayload);
        }
        yield call(service.updateChannelDetails, {
            ...channelDetails,
            targetAdvertDuration: settings.targetAdvertDuration,
            title: settings.title,
            logo: settings.logo,
            enableBackupStream: settings.enableBackupStream,
        });

        yield all([
            put(pageLoad(channelId)),
            put(addToast('Channel Settings Updated', '', EToastType.SUCCESS, TOAST_TIMEOUT, null, PageTheme.LIGHT)),
            put(successfullyPublishedChanges()),
        ]);
    } catch (e) {
        console.warn(e);
        const titleMessage =
            e?.status === OUTDATED_TIMELINE_STATUS_CODE
                ? 'A newer version of the timeline exists, please refresh the page to continue'
                : 'Something happened, please try again';

        yield all([
            put(addToast(titleMessage, '', EToastType.DANGER, TOAST_TIMEOUT, null, PageTheme.LIGHT)),
            put(errorPublishingChanges()),
        ]);
    }
}

export const channelManagerSagas = [
    takeEvery(ChannelManagerActions.INIT_PAGE_SAGA, handlePageLoad),
    takeEvery(ChannelManagerActions.POD_MOVED_SAGA, handlePodMoved),
    takeEvery(ChannelManagerActions.POD_REMOVED_SAGA, handlePodRemoved),
    takeEvery(ChannelManagerActions.POD_REMOVED_UNDO_SAGA, handlePodRemovedUndo),
    takeEvery(ChannelManagerActions.POD_UPDATED_SAGA, handlePodUpdated),
    takeEvery(ChannelManagerActions.EDIT_POD_SAGA, handleEditPod),
    takeEvery(ChannelManagerActions.MULTIPLE_POD_ADDED_SAGA, handleMultipleContentSelected),
    takeEvery(ChannelManagerActions.CONTENT_SELECTED_TO_ADD_TO_LIST_SAGA, handleMultiplePodAddedToAddToList),
    takeEvery(ChannelManagerActions.CONTENT_SELECTED_TO_PREVIEW_SAGA, handleMultiplePodAddedToPreview),
    takeLatest(ChannelManagerActions.PUBLISH_CONTENT_SAGA, handlePublishContent),
    takeLatest(ChannelManagerActions.SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_SAGA, handleSynchroniseChannelStreamingConfigs),
    takeLatest(ChannelManagerActions.PROVISION_MUXER, handleProvisionMuxer),
    takeLatest(ChannelManagerActions.SET_CHANNEL_DGE_EVENT_ID, handleSetChannelDgeEventId),
    takeLatest(ChannelManagerActions.REFRESH_CHANNEL_ADMIN_DETAILS, handleRefreshMuxers),
    takeLatest(ChannelManagerActions.DELETE_CHANNEL, handleDeleteChannel),
    takeLatest(ChannelManagerActions.MUXER_ACTION, handleMuxerAction),
    takeLatest(ChannelManagerActions.CHECK_MUXER_STATUS_ACTION, handleCheckMuxerStatusAction),
    takeLatest(ChannelManagerActions.BATCH_ACTION_CONFIRMED_SAGA, handleBatchActionConfirmed),
    takeLatest(ChannelManagerActions.CHANNEL_SETTINGS_CHANGED_SAGA, handleChannelSettingsChanged),
    takeLatest(ChannelManagerActions.FEATURE_FLAG_SAGA, handleLoadFeatureFlags),
];
