import * as React from 'react';
import { ListChildComponentProps, areEqual } from 'react-window';

import { SortableWrapper } from '../PodCard/SortableWrapper';
import { itemIsContentPod } from './VirtualisedList';
import { ListItemPaddingLeft } from './constants';
import { VirtualisedListItems } from './types';

interface IListItemProps extends ListChildComponentProps {
    data: {
        items: VirtualisedListItems;
        isInBatchActionMode: boolean;
        showUSDateTimeFormat: boolean;
    };
}

export const VirtualisedListItem: React.FC<IListItemProps> = React.memo((props): JSX.Element => {
    const { index, style, data } = props;
    const item = data.items[index];

    const prev = data.items?.[index - 1] ?? null;
    const next = data.items?.[index + 1] ?? null;

    if (itemIsContentPod(item)) {
        const extendedStyles: React.CSSProperties = {
            ...style,
            paddingLeft: index === 0 ? ListItemPaddingLeft.FIRST : ListItemPaddingLeft.REST,
        };

        return (
            <SortableWrapper
                index={index}
                itemIndex={index}
                style={extendedStyles}
                disabled={data.isInBatchActionMode || !item.isEditable || item.isRemoved}
                item={item}
                showUSDateTimeFormat={data.showUSDateTimeFormat}
                prev={prev}
                next={next}
            />
        );
    }

    return (
        <div style={style} className="channel-manager__pods-endpoint">
            <div>End of scheduled programming</div>
        </div>
    );
}, areEqual);

VirtualisedListItem.displayName = 'VirtualisedListItem';
