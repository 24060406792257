import { all } from 'redux-saga/effects';

import { channelManagerSagas } from '~src/store/channelManager/channelManager.saga';
import { exportSagas } from '~src/store/editor/export/export.saga';
import { publishSagas } from '~src/store/editor/publish/publish.saga';
import { streamSagas } from '~src/store/editor/stream/stream.saga';
import { feedbackSagas } from '~src/store/feedback/feedback.saga';
import { paginationSagas } from '~src/store/pagination/pagination.saga';
import { realmSagas } from '~src/store/realm/realm.saga';
import { searchSagas } from '~src/store/search/search.saga';
import { clipSagas } from '~src/store/timeline/clip/clip.saga';
import { thumbnailSagas } from '~src/store/timeline/thumbnail/thumbnail.saga';

function* rootSaga() {
    return yield all([
        ...feedbackSagas,
        ...clipSagas,
        ...thumbnailSagas,
        ...publishSagas,
        ...exportSagas,
        ...streamSagas,
        ...paginationSagas,
        ...searchSagas,
        ...channelManagerSagas,
        ...realmSagas,
    ]);
}

export { rootSaga };
