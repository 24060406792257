import * as React from 'react';

import useFirstMount from './useFirstMount';

/**
 * Use Effect callback which only runs after first mount
 * @param effect use effect callback to be run
 * @param deps dependencies to watch for
 */
const useUpdateEffect: typeof React.useEffect = (effect, deps) => {
    const isFirstMount = useFirstMount();

    React.useEffect(() => {
        if (!isFirstMount) {
            return effect();
        }
    }, deps);
};

export default useUpdateEffect;
