import * as React from 'react';

import { joinStrings } from '~services/utilities';

interface ILinkMenuItem {
    title: string;
    callback: (event: React.MouseEvent<HTMLButtonElement>) => void;
    titleIcon?: JSX.Element;
    className?: string;
    testId?: string;
}

const LinkMenuItem: React.FunctionComponent<ILinkMenuItem> = ({ title, titleIcon, callback, className, testId = '' }) => {
    const classNames = joinStrings(['link-menu__menu-item', className]);

    return (
        <li className={classNames} data-test-id={testId}>
            <button type="button" onClick={callback}>
                {titleIcon && <span>{titleIcon}</span>}
                {title}
            </button>
        </li>
    );
};

export { LinkMenuItem, ILinkMenuItem };
