import * as React from 'react';

import { NavLogo } from '~components/NavLogo';
import { SearchField } from '~components/SearchField';
import { PageTitle } from '~src/views/layout/PageTitle';

import './index.scss';

interface IProps {
    title: string;
    subTitle: string;
}

const PageHeader: React.NamedExoticComponent<IProps> = React.memo(({ title, subTitle }) => (
    <div className="page__header">
        <PageTitle title={title} subTitle={subTitle} icon={<NavLogo />} />
        <SearchField />
    </div>
));

export { PageHeader };
