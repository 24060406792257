import * as React from 'react';

import { joinStrings } from '~services/utilities';

import './index.scss';

interface IProps {
    maxLength: number;
    count: number;
}

const WARNING_PERCENT = 80;
const ERROR_PERCENT = 90;

const CharacterRange: React.NamedExoticComponent<IProps> = React.memo(({ maxLength, count }: IProps) => {
    const consumptionPercent = 100 * (count / maxLength);
    const isWarning = consumptionPercent >= WARNING_PERCENT;
    const isDanger = consumptionPercent >= ERROR_PERCENT;
    const className = joinStrings(['character-range', isWarning && 'character-range--warning', isDanger && 'character-range--danger']);
    return (
        <div className={className}>
            {count} / {maxLength}
        </div>
    );
});

export { CharacterRange };
