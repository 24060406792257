import * as moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CalendarCard } from '~components/ChannelManager/PreviewSchedule/CalendarCard';
import { CalendarGroupCard } from '~components/ChannelManager/PreviewSchedule/CalendarGroupCard';
import { TimezoneFilter } from '~components/ChannelManager/PreviewSchedule/TimezoneFilter';
import { ZoomFilter } from '~components/ChannelManager/PreviewSchedule/ZoomFilter';
import { CloseLightIcon } from '~components/Icons/CloseLightIcon';
import { StorageKeys } from '~services/storage/constants';
import { joinStrings } from '~services/utilities';
import { getLocalisedFormattedTime } from '~services/utilities/time';
import { getChannelDetailsState, getContentPodsState } from '~src/store/channelManager/channelManager.selectors';
import { Seconds } from '~src/types';
import { useLocalStorage } from '~src/views/hooks/useLocalStorage';

import { DateFilter } from '../DateFilter';
import {
    DATE_FILTER_DROPDOWN_OPTIONS,
    DateFilterTypes,
    DAY_COLUMN_WIDTH,
    DEFAULT_DATE_FILTER,
    DEFAULT_TIME_ZONE,
    DEFAULT_ZOOM_SETTING,
    PlotTypes,
} from '../constants';
import { ICalendarPreviewSettings, IGroupPlot, IStartEndDates, ITimeFilter, Plot } from '../types';
import { calculateDateRange, getDateRange, getIntervals, getPlottedContentPodsByDayValue, groupContentPodsByDayValue } from '../utilities';
import './index.scss';

interface ICalendarPreviewProps {
    onClosePreviewSchedule: (ICalendarPreviewSettings) => void;
    previousSettings?: ICalendarPreviewSettings;
    onFullScreenPreviewSchedule: VoidFunction;
    showUSDateTimeFormat: boolean;
}

export const CalendarPreview: React.FC<ICalendarPreviewProps> = (props) => {
    const contentPods = useSelector(getContentPodsState);
    const { isLive, channelLogoUrl, title } = useSelector(getChannelDetailsState);

    const [storedTimeZoneSetting, setStoredTimeZoneSetting] = useLocalStorage<string>(StorageKeys.CALENDAR_TIME_ZONE, DEFAULT_TIME_ZONE);
    const [storedZoomSetting, setStoredZoomSetting] = useLocalStorage<number>(StorageKeys.CALENDAR_ZOOM, DEFAULT_ZOOM_SETTING);

    const contentStartTime = moment(contentPods[0]?.startTime || new Date());

    const [customDateRange, setCustomDateRange] = React.useState<IStartEndDates>(() => {
        if (props.previousSettings?.customDateRange) {
            return props.previousSettings.customDateRange;
        }

        return calculateDateRange(DEFAULT_DATE_FILTER, null, contentStartTime);
    });

    const [timezoneSetting, setTimezoneSetting] = React.useState<string>(DEFAULT_TIME_ZONE);
    const [zoomSetting, setZoomSetting] = React.useState<Seconds>(DEFAULT_ZOOM_SETTING);
    const [dateFilter, setDateFilter] = React.useState<DateFilterTypes>(() => {
        if (props.previousSettings?.dateFilter) {
            return props.previousSettings.dateFilter;
        }
        return DEFAULT_DATE_FILTER;
    });

    const [timeIntervals, setTimeIntervals] = React.useState<string[]>(getIntervals(zoomSetting, props.showUSDateTimeFormat));
    const [timeFilter, setTimeFilter] = React.useState<ITimeFilter>(() => {
        if (props.previousSettings?.timeFilter) {
            return props.previousSettings.timeFilter;
        }

        return {
            start: timeIntervals[0],
            end: timeIntervals.slice(-1).pop(),
        };
    });

    useEffect(() => {
        setTimezoneSetting(storedTimeZoneSetting);
    }, [storedTimeZoneSetting]);

    useEffect(() => {
        setZoomSetting(storedZoomSetting);
        setTimeIntervals(getIntervals(storedZoomSetting, props.showUSDateTimeFormat));
    }, [storedZoomSetting]);

    const dayColumns = getDateRange(customDateRange, timezoneSetting);
    const contentPodsByDayValue = groupContentPodsByDayValue(contentPods, timezoneSetting);
    const plottedContentsByDayValue = getPlottedContentPodsByDayValue(contentPodsByDayValue, dayColumns, zoomSetting, timezoneSetting);

    const handleZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        setZoom(value);
    };

    const handleZoomReset = () => {
        setZoom(DEFAULT_ZOOM_SETTING);
    };

    const setZoom = (zoom: number) => {
        setStoredZoomSetting(zoom);
        setZoomSetting(zoom);
        setTimeIntervals(getIntervals(zoom, props.showUSDateTimeFormat));
    };

    const handleDateFilterUpdate = (newDateFilter: DateFilterTypes, newDateRange?: IStartEndDates) => {
        setDateFilter(newDateFilter);
        setCustomDateRange(calculateDateRange(newDateFilter, newDateRange, contentStartTime));
    };

    const handleMouseUp = (e: React.SyntheticEvent<HTMLInputElement>) => e.currentTarget.blur();

    const handleSetTimeZone = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTimeZone(e.target.value);
    };

    const handleTimeZoneReset = () => {
        setTimeZone(DEFAULT_TIME_ZONE);
    };

    const setTimeZone = (timezone: string) => {
        setStoredTimeZoneSetting(timezone);
        setTimezoneSetting(timezone);
    };

    const handleTimeChange = (filter: { start: string; end: string }) => {
        setTimeFilter(filter);
    };

    const filteredTimeIntervals = timeIntervals.slice(timeIntervals.indexOf(timeFilter.start), timeIntervals.indexOf(timeFilter.end));

    const handleDateFilterNext = () => {
        setDateFilter(DateFilterTypes.CUSTOM);
        setCustomDateRange({
            startDate: moment(customDateRange.startDate).add(7, 'day'),
            endDate: moment(customDateRange.endDate).add(7, 'day'),
        });
    };

    const handleDateFilterPrevious = () => {
        setDateFilter(DateFilterTypes.CUSTOM);
        setCustomDateRange({
            startDate: moment(customDateRange.startDate).subtract(7, 'day'),
            endDate: moment(customDateRange.endDate).subtract(7, 'day'),
        });
    };

    const handleDateFilterNextDay = () => {
        setDateFilter(DateFilterTypes.CUSTOM);
        setCustomDateRange({
            startDate: moment(customDateRange.startDate).add(1, 'day'),
            endDate: moment(customDateRange.endDate).add(1, 'day'),
        });
    };

    const handleDateFilterPreviousDay = () => {
        setDateFilter(DateFilterTypes.CUSTOM);
        setDateFilter(DateFilterTypes.CUSTOM);
        setCustomDateRange({
            startDate: moment(customDateRange.startDate).subtract(1, 'day'),
            endDate: moment(customDateRange.endDate).subtract(1, 'day'),
        });
    };

    const handleClose = () => {
        props.onClosePreviewSchedule({
            dateFilter,
            customDateRange,
            timeFilter,
        });
    };

    const minCalendarWidth = dayColumns.length * DAY_COLUMN_WIDTH;

    return (
        <div className="calendar-preview">
            <div className="calendar-preview__header">
                <div className="calendar-preview__channel-details">
                    {channelLogoUrl && <img src={channelLogoUrl} width={44} alt="Channel Logo" />}
                    {title}
                </div>

                <div className="calendar-preview__action">
                    <DateFilter
                        uniqueId="dateFilter"
                        options={DATE_FILTER_DROPDOWN_OPTIONS}
                        onSelected={handleDateFilterUpdate}
                        onTimeChange={handleTimeChange}
                        onNext={handleDateFilterNext}
                        onNextDay={handleDateFilterNextDay}
                        onPrevious={handleDateFilterPrevious}
                        onPreviousDay={handleDateFilterPreviousDay}
                        selected={dateFilter}
                        selectedStartDate={customDateRange?.startDate}
                        selectedEndDate={customDateRange?.endDate}
                        className="calendar-preview__date-filter"
                        timeIntervals={timeIntervals}
                        timeFilter={timeFilter}
                    />
                    <ZoomFilter zoomSetting={zoomSetting} onChange={handleZoomChange} onMouseUp={handleMouseUp} onReset={handleZoomReset} />
                    <TimezoneFilter timezoneSetting={timezoneSetting} setTimezone={handleSetTimeZone} onReset={handleTimeZoneReset} />
                </div>
                <button onClick={handleClose}>
                    <CloseLightIcon width={44} height={44} />
                </button>
                {/* <button onClick={props.onFullScreenPreviewSchedule}>
                    <FullScreenIcon width={20} />
                </button> */}
            </div>
            <div className="calendar-preview__body" style={{ minWidth: minCalendarWidth }}>
                <div className="calendar-preview__day-row">
                    {dayColumns.map((dayValue, dayIndex) => {
                        const isToday = moment(dayValue).isSame(new Date(), 'day');
                        const dayClass = joinStrings(['calendar-preview__day', isToday && 'today']);

                        return (
                            <div className={dayClass} key={dayIndex}>
                                <span className="day-name">{moment(dayValue).tz(timezoneSetting).format('ddd')}</span>
                                <span className="day-number">{moment(dayValue).tz(timezoneSetting).date()}</span>
                            </div>
                        );
                    })}
                </div>

                <div className="calendar-preview__time-column">
                    {filteredTimeIntervals.map((hour) => (
                        <div className="calendar-preview__time" key={hour}>
                            <div className="calendar-preview__time__time_subdivision" />
                            <div className="calendar-preview__time-text">{hour}</div>
                        </div>
                    ))}
                </div>

                <div className="calendar-preview__plots-wrapper">
                    {dayColumns.map((dayValue, dayIndex) => {
                        const plottedContentPodsForDay = plottedContentsByDayValue[dayValue] || [];

                        let isFirstOfSelectedDates = true;
                        let showContentStartTime = true;

                        const [startHour, startMinute] = timeFilter.start.split(':');
                        const [endHour, endMinute] = timeFilter.end.split(':');
                        const startOfDayValue = moment(dayValue)
                            .tz(timezoneSetting)
                            .hour(parseInt(startHour, 10))
                            .minute(parseInt(startMinute, 10));
                        const endOfDayValue = moment(dayValue)
                            .tz(timezoneSetting)
                            .hour(parseInt(endHour, 10))
                            .minute(parseInt(endMinute, 10));

                        return (
                            <div className="calendar-preview__plot-column" key={dayValue}>
                                {plottedContentPodsForDay.map((plot, contentPodIndex) => {
                                    const [firstContentPod] = plot.items;
                                    let fillerPlot: JSX.Element = null;

                                    const startTime = moment(firstContentPod.startTime);

                                    const duration = isGroupPlot(plot) ? plot.totalDuration : plot.duration;
                                    const endTime = moment(startTime).add(duration, 'seconds');

                                    if (startTime < startOfDayValue) {
                                        showContentStartTime = false;
                                    }

                                    if (endTime < startOfDayValue || startTime > endOfDayValue) {
                                        return <div key={contentPodIndex} />;
                                    }

                                    if (showContentStartTime) {
                                        isFirstOfSelectedDates = false;
                                        showContentStartTime = false;
                                        const differenceFromStartOfDayInSeconds =
                                            (moment(firstContentPod.startTime).valueOf() - startOfDayValue.valueOf()) / 1000;

                                        const offsetFromTop = (differenceFromStartOfDayInSeconds / zoomSetting) * 100;

                                        let fillerText = 'LIVE';

                                        if (!isLive) {
                                            const startTimeLabel = getLocalisedFormattedTime(
                                                firstContentPod.startTime,
                                                props.showUSDateTimeFormat,
                                                '',
                                                timezoneSetting
                                            );
                                            fillerText = `Content starts at ${startTimeLabel}`;
                                        }

                                        fillerPlot = (
                                            <div className="calendar-preview__plot-filler" style={{ height: offsetFromTop }}>
                                                <div className={`start-indicator start-indicator--${isLive ? 'live' : 'upcoming'}`}>
                                                    <span className="start-indicator__text">{fillerText}</span>
                                                </div>
                                            </div>
                                        );
                                    }

                                    let contentOffsetFromTop = 0;
                                    if (isFirstOfSelectedDates) {
                                        isFirstOfSelectedDates = false;

                                        const differenceFromStartOfDayInSeconds =
                                            (moment(firstContentPod.startTime).valueOf() - startOfDayValue.valueOf()) / 1000;

                                        contentOffsetFromTop = (differenceFromStartOfDayInSeconds / zoomSetting) * 100;
                                    }

                                    return (
                                        <div key={contentPodIndex} style={{ marginTop: contentOffsetFromTop }}>
                                            {fillerPlot}
                                            {isGroupPlot(plot) ? (
                                                <CalendarGroupCard
                                                    {...plot}
                                                    showUSDateTimeFormat={props.showUSDateTimeFormat}
                                                    timezone={timezoneSetting}
                                                />
                                            ) : (
                                                <CalendarCard
                                                    contentPod={firstContentPod}
                                                    height={plot.height}
                                                    key={contentPodIndex}
                                                    showUSDateTimeFormat={props.showUSDateTimeFormat}
                                                    timezone={timezoneSetting}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const isGroupPlot = (plot: Plot): plot is IGroupPlot => {
    return plot.type === PlotTypes.GROUP;
};
