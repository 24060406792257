import * as React from 'react';
import { Redirect } from 'react-router';

import { ChromeIcon } from '~components/Icons/ChromeIcon';
import { PageRoutes } from '~components/Root/constants';
import { SmartAnchor } from '~components/SmartAnchor';
import { isSafeBrowser } from '~services/utilities';
import { Page } from '~src/views/layout/Page';
import { PageTitle } from '~src/views/layout/PageTitle';

import './index.scss';

const ChromeOnly: React.FunctionComponent = () => {
    const $icon = <ChromeIcon className="chrome-only-page__icon" height="120" width="120" />;

    const callToActionList = [
        // tslint:disable-next-line:jsx-key // key not required as the 'SmartAnchor' is has a wrapper with a key
        <SmartAnchor className="button button--secondary" to="https://www.google.com/chrome/">
            Download Google Chrome
        </SmartAnchor>,
    ];

    return isSafeBrowser() ? (
        <Redirect to={PageRoutes.LANDING_PAGE} />
    ) : (
        <Page showFooter={true} verticalCenter={true}>
            <div className="chrome-only">
                <PageTitle
                    title="Studio only works in Google Chrome"
                    subTitle="More browsers coming soon."
                    icon={$icon}
                    callToActionList={callToActionList}
                />
            </div>
        </Page>
    );
};

export { ChromeOnly };
