import * as React from 'react';

import { InfoCircleIconLight } from '~components/Icons/InfoCircleIconLight';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { IVllContentBase } from '~services/channels/types';
import { getTimeLabel, joinStrings } from '~services/utilities';
import { TimeFormats } from '~src/types';

import './index.scss';

interface IProps extends IVllContentBase {
    showTags?: boolean;
}

const TooltipIcon = <InfoCircleIconLight width={20} height={20} />;

export const ContentCardPreview: React.FC<IProps> = (props) => {
    const [hasImageLoaded, setHasImageLoaded] = React.useState(false);
    const { title, description, duration, thumbnailUrl, playlists = [], episodeNumber, seasonNumber } = props;

    const handleOnImageLoad = () => {
        setHasImageLoaded(true);
    };

    const playlistsNameInfo = playlists.map(({ playlistId, title: playlistTitle }) => <div key={playlistId}>{playlistTitle}</div>);

    const contentCardClassName = joinStrings(['content-card', hasImageLoaded && 'content-card--is-loaded']);
    const seasonEpisodeArray: string[] = [];

    if (seasonNumber) {
        seasonEpisodeArray.push(`S${seasonNumber}`);
    }
    if (episodeNumber) {
        seasonEpisodeArray.push(`E${episodeNumber}`);
    }

    return (
        <div className={contentCardClassName}>
            <div className="content-card__thumbnail">
                <img src={thumbnailUrl} alt={title} onLoad={handleOnImageLoad} width={160} height={90} />
                <div className="content-card__duration">{getTimeLabel(duration, TimeFormats.HHmmss)}</div>
            </div>
            <div className="content-card__meta">
                <div className="content-card__title">
                    <span>{title}</span>
                </div>
                <div className="content-card__description">{description}</div>
                <div className="content-card__bottom-tags">
                    {(!!seasonNumber || !!episodeNumber) && (
                        <div className="content-card__bottom-tag">
                            <span className="content-card__season-episode-icon" />
                            {seasonEpisodeArray.join(' : ')}
                        </div>
                    )}
                    {!!playlists.length && (
                        <div className="content-card__bottom-tag">
                            <span className="content-card__playlists-icon" />
                            {playlists.length} Playlists
                            <Tooltip message={playlistsNameInfo} position={TooltipPositions.TOP}>
                                {TooltipIcon}
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
            {props.children}
        </div>
    );
};
