import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import Modal from '~components/Modal';
import { joinStrings } from '~services/utilities';

import { TRANSITION_TIMEOUT } from '../constants';
import './index.scss';

interface IPreviewScheduleModalProps {
    isActive: boolean;
    modalWrapperClassName: string;
}

export const PreviewScheduleModal: React.FC<IPreviewScheduleModalProps> = (props) => {
    return (
        <CSSTransition in={props.isActive} timeout={TRANSITION_TIMEOUT} classNames="preview-schedule-modal" unmountOnExit={true}>
            <div className={joinStrings([props.modalWrapperClassName])}>
                <Modal.Main closeWithEscape={false} hasOverlay={true}>
                    <Modal.Body justify="left">{props.children}</Modal.Body>
                </Modal.Main>
            </div>
        </CSSTransition>
    );
};
