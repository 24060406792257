import { Subscriber } from '@dicetechnology/dice-unity/lib/services/Subscriptions';

import { IClip } from '~services/clip/types';

interface ISubscribeEvent {
    message: IClip;
}

enum Environment {
    development = 'dev',
    qa = 'qa',
    staging = 'stag',
    production = 'prod',
}

class ClipUpdateService {
    private static baseChannelString = '{{realm}}_{{environment}}_studio_clip_update';
    private subscriber: Subscriber<ISubscribeEvent> = null;
    private readonly channel: string;

    constructor({ realm }) {
        const { STD__PUBNUB_SUB_KEY: subscribeKey, NODE_ENV: environment } = process.env;

        if (subscribeKey) {
            this.subscriber = new Subscriber<ISubscribeEvent>({
                pubnubConfig: {
                    subscribeKey,
                },
            });
        }

        if (realm && environment) {
            const environmentString = Environment[environment];
            this.channel = ClipUpdateService.baseChannelString.replace('{{realm}}', realm).replace('{{environment}}', environmentString);
        }
    }

    public subscribe(callback) {
        if (this.channel) {
            this.subscriber.subscribe({
                channel: this.channel,
                handler: (event: ISubscribeEvent) => {
                    callback(event.message);
                },
            });
        }
    }

    public unsubscribe() {
        if (this.channel) {
            this.subscriber.unsubscribe({ channel: this.channel });
            this.subscriber = null;
        }
    }
}

export { ClipUpdateService };
