import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { LongArrowIcon } from '~components/Icons/LongArrowIcon';
import Modal, { TextJustify } from '~components/Modal';
import { joinStrings } from '~services/utilities';
import { closePodManagementModal, changePodManagementModalView } from '~src/store/channelManager/channelManager.actions';
import { getPodManagementState } from '~src/store/channelManager/channelManager.selectors';
import { isCurrentStepContentSearch, isCurrentStepPodSelector } from '~src/store/channelManager/channelManager.utilities';
import { PodManagementStep, PodSelectorView } from '~src/store/channelManager/constants';

import { ContentSearch } from '../ContentSearch';
import { PodSelector } from '../PodSelector';

interface IPodManagementModalProps {
    title: string;
    isActive: boolean;
    canGoBack: boolean;
    modalWrapperClassName: string;
    onClose: VoidFunction;
    onBack: VoidFunction;
    onScroll?: VoidFunction;
}

export interface IRefWithOnScroll {
    onScroll: VoidFunction;
}

const TRANSITION_TIMEOUT = 1000;

const PodManagementModalComponent: React.FC<IPodManagementModalProps> = (props) => {
    return (
        <CSSTransition in={props.isActive} timeout={TRANSITION_TIMEOUT} classNames="pod-management-modal" unmountOnExit={true}>
            <div className={joinStrings(['channel-manager__pod-management-modal', props.modalWrapperClassName])}>
                <Modal.Main
                    closeWithEscape={true}
                    hasOverlay={true}
                    onClose={props.onClose}
                    onScroll={props.onScroll}
                    className="channel-manager__modal channel-manager__modal--align-right"
                >
                    <Modal.Title justify={TextJustify.CENTER}>
                        {props.canGoBack && (
                            <button className="channel-manager__add-content-back-button" onClick={props.onBack}>
                                <LongArrowIcon />
                            </button>
                        )}
                        {props.title}
                    </Modal.Title>
                    <Modal.Body justify="left">{props.children}</Modal.Body>
                </Modal.Main>
            </div>
        </CSSTransition>
    );
};

const getPodPreviewSelectorModalTitle = (kind: PodSelectorView): string => {
    switch (kind) {
        case PodSelectorView.ADD:
            return 'Add content to pod';
        case PodSelectorView.EDIT:
            return 'Manage pod';
        case PodSelectorView.PREVIEW:
            return 'Preview pod';
        default:
            return '';
    }
};

export const PodManagementModal: React.FC = () => {
    const viewRef = React.useRef<IRefWithOnScroll>(null);
    const dispatch = useDispatch();
    const { isActive, currentStep, prevStep } = useSelector(getPodManagementState);

    const handleOnBack = () => {
        dispatch(changePodManagementModalView({ ...prevStep, step: PodManagementStep.CONTENT_SELECTOR, onBack: true }, null));
    };

    const handleOnClose = () => {
        dispatch(closePodManagementModal());
    };

    const handleOnScroll = () => {
        if (viewRef.current?.onScroll) {
            viewRef.current.onScroll();
        }
    };

    let $component: React.ReactElement;
    let modalTitle = '';
    let modalViewClassName = '';

    if (isCurrentStepContentSearch(currentStep)) {
        modalViewClassName = 'pod-management-modal__content-search';
        modalTitle = 'Search for content';
        $component = <ContentSearch ref={viewRef} />;
    } else if (isCurrentStepPodSelector(currentStep)) {
        modalViewClassName = 'pod-management-modal__pod-selector';
        modalTitle = getPodPreviewSelectorModalTitle(currentStep.kind);
        $component = <PodSelector />;
    }

    return (
        <PodManagementModalComponent
            isActive={isActive}
            title={modalTitle}
            canGoBack={!!prevStep}
            modalWrapperClassName={modalViewClassName}
            onClose={handleOnClose}
            onBack={handleOnBack}
            onScroll={handleOnScroll}
        >
            {$component}
        </PodManagementModalComponent>
    );
};
