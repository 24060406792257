import * as React from 'react';

import { IAdBreak } from '~services/channels/types';
import { convertSecondsToHoursMinutesLabel } from '~services/utilities';
import { Seconds } from '~src/types';

export const TimelineTotalPercentageBar: React.FC<{ contentDuration: Seconds; podDuration: Seconds; adBreaks: IAdBreak[] }> = ({
    contentDuration,
    podDuration,
    adBreaks = [],
}) => {
    const contentDurationPercentage = (contentDuration / podDuration) * 100;
    const adsDurationPercentage = ((podDuration - contentDuration) / podDuration) * 100;

    const adBreakStatus = `${adBreaks.length} Ad Breaks`;
    const contentDurationStatus = `Content Duration: ${convertSecondsToHoursMinutesLabel(contentDuration)}`;
    const adsDurationStatus = `Ads Duration: ${convertSecondsToHoursMinutesLabel(podDuration - contentDuration)}`;
    const contentDurationSummary = `${contentDurationStatus}\n${adsDurationStatus}\n${adBreakStatus}`;

    return (
        <div className="pod-card__timeline-bar" title={contentDurationSummary}>
            <div className="pod-card__timeline-bar__content" style={{ width: `${contentDurationPercentage}%` }} />
            <div className="pod-card__timeline-bar__ads" style={{ width: `${adsDurationPercentage}%` }} />
        </div>
    );
};
