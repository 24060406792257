import * as moment from 'moment-timezone';

import { IContentPod } from '~services/channels/types';
import { Meridiem, SECONDS_IN_MINUTE } from '~services/utilities/time/constants';
import { getStartOfDayValue } from '~src/store/channelManager/channelManager.utilities';
import { Seconds } from '~src/types';

import { DateFilterTypes, DAY_VALUE, INTERVAL_HEIGHT_PX, PlotTypes } from './constants';
import {
    ContentPodsByDayValue,
    IDateRangeBoundaries,
    IGroupPlot,
    ISinglePlot,
    IStartEndDates,
    Plot,
    PlottedContentPodsByDayValue,
} from './types';

const convertSecondsToHeight = (value: Seconds, zoomSetting: Seconds) => (value / zoomSetting) * INTERVAL_HEIGHT_PX;

export const getIntervals = (zoomSetting: Seconds, showUSDateTimeFormat: boolean): string[] => {
    const timeIntervals: string[] = [];
    const interval = zoomSetting / SECONDS_IN_MINUTE; // minutes interval
    let runningStartTime = 0;

    for (let i = 0; runningStartTime <= 24 * SECONDS_IN_MINUTE; i++) {
        const hh = Math.floor(runningStartTime / SECONDS_IN_MINUTE);
        const mmPart = ('0' + (runningStartTime % SECONDS_IN_MINUTE)).slice(-2);
        const hhPart = ('0' + (showUSDateTimeFormat ? (hh === 12 || hh === 0 ? 12 : hh % 12) : hh)).slice(-2);
        const meridiemPart = showUSDateTimeFormat ? `${hh < 12 ? Meridiem.AM : Meridiem.PM}` : '';

        timeIntervals.push(`${hhPart}:${mmPart}${meridiemPart}`);

        runningStartTime += interval;
    }

    return timeIntervals;
};

export const groupContentPodsByDayValue = (contentPods: IContentPod[], timezone?: string) => {
    return contentPods.reduce<ContentPodsByDayValue>((acc, contentPod) => {
        const startDay = getStartOfDayValue(contentPod.startTime, timezone);
        const endDay = getStartOfDayValue(moment(contentPod.startTime).add(contentPod.duration, 'seconds'), timezone);

        if (!acc[startDay]) {
            acc[startDay] = [];
        }

        acc[startDay].push(contentPod);

        if (startDay !== endDay) {
            if (!acc[endDay]) {
                acc[endDay] = [];
            }
            acc[endDay].push(contentPod);
        }

        return acc;
    }, {});
};

export const getAllDayKeys = (contentPodsByDayValue: ContentPodsByDayValue): number[] =>
    Object.keys(contentPodsByDayValue).map(Number).sort();

export const getDateRangeBoundaries = (allDays: number[]): IDateRangeBoundaries => {
    return {
        minDate: moment(allDays[0]),
        maxDate: moment(allDays[allDays.length - 1]),
    };
};

export const getDateRange = (dateRange: IStartEndDates, timezone: string): number[] => {
    const dates = [];

    let i = 0;
    let nextDate = null;

    // Start and End dates will be set in the local user's time zone
    // We can set the day/month/year directly, so they will not be changed by timezone conversions
    const convertedStartDate = moment().tz(timezone).set({
        year: dateRange.startDate.year(),
        month: dateRange.startDate.month(),
        date: dateRange.startDate.date(),
    });

    const convertedEndDate = moment().tz(timezone).set({
        year: dateRange.endDate.year(),
        month: dateRange.endDate.month(),
        date: dateRange.endDate.date(),
    });

    do {
        nextDate = moment(convertedStartDate).add(i, 'day');
        dates.push(getStartOfDayValue(nextDate, timezone));
        i++;
    } while (nextDate < convertedEndDate);

    return dates;
};

export const calculateDateRange = (dateFilter: DateFilterTypes, dateRange: IStartEndDates, startDate: moment.Moment): IStartEndDates => {
    switch (dateFilter) {
        case DateFilterTypes.CUSTOM:
            return dateRange;
        case DateFilterTypes.NEXT_SEVEN:
            return {
                startDate,
                endDate: moment(startDate).add(6, 'day'),
            };
        case DateFilterTypes.WEEKEND:
            return {
                startDate: moment(startDate).isoWeekday(6),
                endDate: moment(startDate).isoWeekday(7),
            };
        case DateFilterTypes.WEEK:
            return {
                startDate: moment(startDate).day(0),
                endDate: moment(startDate).day(6),
            };
        default:
            return {
                startDate,
                endDate: startDate,
            };
    }
};

export const getDaysToShow = (
    selectedDateFilter: DateFilterTypes,
    allDays: number[],
    selectedCustomDateRange: IStartEndDates,
    timezone?: string
): number[] => {
    if (selectedDateFilter === DateFilterTypes.CUSTOM) {
        return [...allDays].slice(
            allDays.indexOf(selectedCustomDateRange.startDate.valueOf()),
            allDays.indexOf(getStartOfDayValue(selectedCustomDateRange.endDate, timezone)) + 1
        );
    }
    return [...allDays].slice(0, DAY_VALUE[selectedDateFilter]);
};

export const getPlottedContentPodsByDayValue = (
    contentPodsByDayValue: ContentPodsByDayValue,
    daysToShow: number[],
    zoomSetting: Seconds,
    timezone: string
): PlottedContentPodsByDayValue => {
    let overflowingPlot: Plot;
    const MIN_HEIGHT = 80;
    const HEIGHT_TO_ACHIEVE = 120;
    const plottedContentPodsByDays: PlottedContentPodsByDayValue = {};
    const defaultRunningGroup: IGroupPlot = {
        type: PlotTypes.GROUP,
        totalHeight: 0,
        totalDuration: 0,
        items: [],
        groupStartTime: null,
        height: 0,
    };

    for (const dayString of daysToShow) {
        const contentPodsForDay = contentPodsByDayValue[dayString];

        if (!contentPodsForDay) {
            plottedContentPodsByDays[dayString] = [];
            continue;
        }

        const plottedPods: Plot[] = [];

        if (overflowingPlot) {
            plottedPods.push(overflowingPlot);
            overflowingPlot = null;
        }

        let isGrouping = false;
        let runningGroup = { ...defaultRunningGroup, items: [] };

        for (const [i, contentPod] of contentPodsForDay.entries()) {
            const plotHeight = convertSecondsToHeight(contentPod.duration, zoomSetting);
            const isLast = i === contentPodsForDay.length - 1;

            if (isGrouping) {
                runningGroup.totalDuration += contentPod.duration;
                runningGroup.totalHeight += plotHeight;
                runningGroup.items.push(contentPod);

                if (runningGroup.totalHeight > HEIGHT_TO_ACHIEVE || isLast) {
                    isGrouping = false;
                    plottedPods.push(runningGroup);
                }
            } else if (plotHeight < MIN_HEIGHT && !isLast) {
                // below threshold, start grouping content pods until we reach the minimum height
                isGrouping = true;
                runningGroup = { ...defaultRunningGroup, items: [] };
                runningGroup.totalDuration += contentPod.duration;
                runningGroup.totalHeight += plotHeight;
                runningGroup.groupStartTime = contentPod.startTime;
                runningGroup.items.push(contentPod);
            } else {
                const singlePlot: ISinglePlot = {
                    type: PlotTypes.SINGLE,
                    items: [contentPod],
                    height: plotHeight,
                    duration: contentPod.duration,
                };

                plottedPods.push(singlePlot);
            }
        }

        plottedContentPodsByDays[dayString] = plottedPods;
    }

    return plottedContentPodsByDays;
};

export const getAllTimeZones = () => {
    const allTimeZones = moment.tz.names();

    let availableTimeZones = [];

    const timeZoneLookup = {};

    for (const timeZone of allTimeZones) {
        const label = 'UTC ' + moment.tz(timeZone).format('Z z');

        const offsetName = moment.tz(timeZone).format('z');
        if (offsetName[0] === '-' || offsetName[0] === '+') {
            continue;
        }

        if (timeZoneLookup[label]) {
            continue;
        }

        timeZoneLookup[label] = true;

        availableTimeZones.push({
            value: timeZone,
            label,
        });
    }

    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    availableTimeZones = availableTimeZones.sort((a, b) => {
        // Because of how natural sorting works, negative timezones need to be sorted in reverse
        if (a.label.indexOf('-') !== -1 && b.label.indexOf('-') !== -1) {
            return collator.compare(b.label, a.label);
        }
        return collator.compare(a.label, b.label);
    });

    return availableTimeZones;
};
