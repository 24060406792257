import { IClientClipCreate } from '~services/clip/types';
import { httpV2, httpV3, httpV4, simpleFetchApi } from '~services/http';
import { EventEndpoints } from '~services/live/constants';
import { IEvent, IDgeStreamMetaResponse } from '~services/live/types';
import { parsePagingRequest } from '~services/utilities';
import { IStreamUrl } from '~src/store/editor/stream/types';
import { IParsedPagingResponse } from '~src/types';

import { extractDgeStreams } from './utils/extract-dge-streams';

interface ILiveEventResponse {
    parsedData: {
        id: number;
        nonDrmUrl: string;
        drmUrl: string;
        title: string;
        tournamentTitle: string;
        thumbnail: string;
        startDate: number;
    };
}

const getLiveEvents = (size: number) => async (lastSeen: number = null): Promise<IParsedPagingResponse<IEvent>> => {
    const url = EventEndpoints.GET;
    return parsePagingRequest(httpV2, url, size, lastSeen);
};

const getLiveEventStudioMeta = async (eventId: string): Promise<ILiveEventResponse['parsedData']> => {
    const { parsedData }: ILiveEventResponse = await httpV3.get(`${EventEndpoints.GET}/${eventId}`);
    return parsedData;
};

const getDgeStreamUrls = async (typeUrl: string, drm: boolean): Promise<IStreamUrl[]> => {
    if (!typeUrl) {
        return [];
    }

    const dgeResult: IDgeStreamMetaResponse = await simpleFetchApi(typeUrl);

    const hlsUrls = extractDgeStreams(dgeResult.hls, 'HLS', drm);
    const dashUrls = extractDgeStreams(dgeResult.dash, 'DASH', drm);

    return [...hlsUrls, ...dashUrls];
};

const getDgeStreamMeta = async (nonDrmUrl: string, drmUrl: string): Promise<IStreamUrl[]> => {
    const [nonDrmUrls, drmUrls] = await Promise.all([await getDgeStreamUrls(nonDrmUrl, false), await getDgeStreamUrls(drmUrl, true)]);

    return [...nonDrmUrls, ...drmUrls];
};

const createClipFromEvent = (eventId: number, clip: IClientClipCreate): Promise<void> => {
    const endpoint = EventEndpoints.CREATE.replace('{id}', eventId.toString());
    return httpV4.post(endpoint, clip);
};

const getClipsOfEvent = (size: number, eventId: number) => async (lastSeen: number = null): Promise<IParsedPagingResponse<IEvent>> => {
    const endpoint = EventEndpoints.GET_CLIPS_BY_ID.replace('{id}', eventId.toString());
    return parsePagingRequest(httpV2, endpoint, size, lastSeen);
};

export { getLiveEvents, getLiveEventStudioMeta, getDgeStreamMeta, createClipFromEvent, getClipsOfEvent };
