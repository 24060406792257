import { ICalendarPreviewSettings } from '~components/ChannelManager/PreviewSchedule/types';
import {
    IChannelDetails,
    IContentPod,
    IVllContent,
    IContentPodBase,
    IVllContentBase,
    IChannelAdminDetails,
    IMuxerVersions,
    MuxerAction,
} from '~services/channels/types';
import { EServiceStatus } from '~services/http/enums';
import { Seconds } from '~src/types';

import { PodManagementStep, PodSelectorView, BatchActionMode, PreviewSchedulePaneVisibility } from './constants';

export type UUID = string;

export interface IChannelManagerState {
    hasPageLoaded: boolean;
    isPublishing: boolean;
    batchAction: IBatchAction;
    showConfirmOverwriteModal: boolean;
    contentPods?: IContentPod[];
    channelDetails: IChannelDetailsState;
    channelAdminDetails: IChannelAdminDetailsState;
    muxerVersions: IMuxerVersions;
    pendingPublishPodNumbers: number[];
    hasActiveModal: boolean;
    podManagement: {
        insertPosition: number;
        isActive: boolean;
        contentByIdMap: ContentByIdMap;
        currentStep: IContentSelectorStep | IPodSelectorStep;
        prevStep?: IContentSelectorStep;
    };
    lastEdited: UUID;
    lastAction: ChangeAction;
    previewSchedulePaneVisibility: PreviewSchedulePaneVisibility;
    previewSchedulePanePreviousSettings?: ICalendarPreviewSettings;
    contentPodScrollState: IContentPod;
    channelIsDeleting: boolean;
    channelDeleteRequiresConfirmation: boolean;
    featureFlags: Record<string, string>;
}

export interface IBatchAction {
    mode: BatchActionMode | null;
    selectedPodUuids: UUID[];
}

export type ContentByIdMap = Record<
    number,
    {
        status: EServiceStatus;
        content: IVllContent;
    }
>;

export interface IContentSelectorStep {
    step: PodManagementStep.CONTENT_SELECTOR;
    searchQuery?: string;
    searchResults?: IVllContentBase[];
    selectedContentList?: IVllContentBase[];
    onBack?: boolean;
}

export interface IPodSelectorStep {
    step: PodManagementStep.POD_SELECTOR;
    kind: PodSelectorView;
    podData: ICuratedPodData;
    uuid?: UUID;
    selectedPod?: Seconds;
}

export interface IPodSelectedPayload {
    pod: IContentPodBase;
    content: IVllContent;
}

export interface IContentSelectedPayload {
    searchQuery: string;
    searchResults: IVllContentBase[];
    content: IVllContentBase;
}

export interface IChannelDetailsState extends IChannelDetails {
    firstContentPodStartTime?: string;
    firstContentPodNumber?: number;
    editableRange?: IEditableRange;
    scrollableDateTimeRange: IScrollableDateTimeRange;
}

export interface IMuxerAction {
    muxerId: number;
    streamingConfigId: number;
    action: MuxerAction;
    forceAction: boolean;
}

export interface IChannelAdminDetailsState extends IChannelAdminDetails {
    isSynchronisingChannelStreamingConfigs: boolean;
    isProvisioningMuxer: boolean;
    isLoadingMuxers: boolean;
    muxerActionRequiresConfirmation: boolean;
    lastMuxerAction: IMuxerAction;
}

export interface IEditableRange {
    first: number;
    last: number;
}

export interface IScrollableDateTimeRange {
    start: string;
    end: string;
}

export interface ICuratedPodData {
    defaultPod: IContentPodBase;
    extendedPodOptions: IContentPodBase[]; // Currently not used. Populate to give extra options in the UI
    content: IVllContent;
}

export interface IChannelSettings {
    targetAdvertDuration: Seconds;
    title: string;
    logo: IChannelDetails['logo'];
    enableBackupStream: boolean;
}

export enum ChangeAction {
    LOAD = 'LOAD',
    ADD_TO_POSITION = 'ADD_TO_POSITION',
    REMOVE = 'REMOVE',
    REMOVE_UNDO = 'REMOVE_UNDO',
    UPDATE = 'UPDATE',
    MOVE_BY_DRAG = 'MOVE_BY_DRAG',
    MOVE_BY_POSITION = 'MOVE_BY_POSITION',
}
