import * as React from 'react';

import { LiveButton } from '~components/Editor/LiveButton';
import { PlayHeadTrackingButton } from '~components/Editor/PlayHeadTrackingButton';
import { ThumbnailsButton } from '~components/Editor/ThumbnailsButton';
import ZoomSlider from '~components/Editor/ZoomSlider';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { EZoomLevel } from '~services/zoomLevelService';

interface IProps {
    assetType: EAssetType;
    zoomLevel: EZoomLevel;
}

const EditorTimelineControlsComponent: React.FunctionComponent<IProps> = ({ assetType, zoomLevel }) => (
    <div className="timeline-controls">
        <ul className="inline-list">
            {assetType === EAssetType.LIVE && (
                <li className="list-item">
                    <LiveButton />
                </li>
            )}
            <li className="list-item">
                <ThumbnailsButton />
            </li>
            <li className="list-item">
                <PlayHeadTrackingButton zoomLevel={zoomLevel} />
            </li>
        </ul>
        <ZoomSlider />
    </div>
);

const EditorTimelineControls: React.NamedExoticComponent<IProps> = React.memo(EditorTimelineControlsComponent);

export { EditorTimelineControls };
