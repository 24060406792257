import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { ClipCombiner } from '~components/Editor/ExportClip/Components/ClipCombiner';
import { ClipSelection } from '~components/Editor/ExportClip/Components/ClipSelection';
import { Complete } from '~components/Editor/ExportClip/Components/Complete';
import { ConfirmCreate } from '~components/Editor/ExportClip/Components/ConfirmCreate';
import { ConfirmOverwrite } from '~components/Editor/ExportClip/Components/ConfirmOverwrite';
import { Loading } from '~components/Editor/ExportClip/Components/Loading';
import { SaveSelection } from '~components/Editor/ExportClip/Components/SaveSelection';
import Modal from '~components/Modal';
import { RootContext } from '~components/Root/context';
import { EAssetType, ExportStep, SaveAction } from '~containers/EditorPageContainer/types';
import { resetExportStep, setExportStep } from '~src/store/editor/export/export.actions';
import { getExportState } from '~src/store/editor/export/export.selectors';
import { publishClip } from '~src/store/editor/publish/publish.actions';
import { getExportableClips } from '~src/store/timeline/clip/clip.selectors';
import { ClipUtilities } from '~src/store/timeline/clip/clip.utilities';
import { ClipsUtilities } from '~src/store/timeline/clip/clips.utilities';
import { IExportClipWithMeta, ITimelineClip } from '~src/store/timeline/clip/types';
import { getThumbnails } from '~src/store/timeline/thumbnail/thumbnail.selectors';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { useShallowEqualSelector } from '~src/views/hooks';
import { KeyCode } from '~src/views/types';

import './index.scss';

interface IContainerProps {
    loadPDT: number;
    assetType: EAssetType;
    isOpen: boolean;
    reset: VoidFunction;
}

interface IComponentProps extends Omit<IContainerProps, 'isOpen'> {
    exportStep: ExportStep;
    saveAction: SaveAction;
    errorCount: number;
    totalClips: number;
    dispatch: Dispatch;
    clips: ITimelineClip[];
    thumbnails: ITimelineThumbnail[];
    minimumClipSeparation: number;
}

const getDefaultCreateState = (clips: ITimelineClip[]): Array<ITimelineClip['id']> => {
    return clips.map(({ id }) => id);
};

const ExportClipComponent: React.FunctionComponent<IComponentProps> = (props) => {
    const {
        exportStep,
        errorCount,
        totalClips,
        dispatch,
        reset,
        clips,
        thumbnails,
        saveAction,
        minimumClipSeparation,
        assetType,
        // todo: move to IEditorStreamState
        loadPDT,
    } = props;

    // reformat clip shape priming for export,
    const { singleClips, canCreateCombinedClip } = React.useMemo<{
        singleClips: IExportClipWithMeta[];
        canCreateCombinedClip: boolean;
    }>(() => {
        const exportClips = clips.map((clip) => {
            const { id, title, thumbnailId, exportClipInTime, exportClipOutTime } = clip;
            const { imageData: thumbnail = null } = thumbnails.find((thumb) => thumb.id === thumbnailId) || {};
            const payload = { id, title, exportClipInTime, exportClipOutTime, thumbnail, loadPDT };

            return ClipUtilities.buildExportClipWithMeta(payload);
        });

        return {
            singleClips: ClipsUtilities.sortClipsByOffset(exportClips),
            canCreateCombinedClip: ClipsUtilities.canCombineClips(exportClips, minimumClipSeparation),
        };
    }, []);

    React.useEffect(() => {
        const formState = saveAction === SaveAction.CREATE ? getDefaultCreateState(clips) : [];
        // reset checkbox state between save actions
        setUserSelectedClipIds(formState);
        // reset clips list
        setSelectableClips(singleClips);
    }, [saveAction]);

    React.useEffect(() => {
        if (exportStep === null && assetType === EAssetType.LIVE && selectableClips.length === 1) {
            setSelectedExportClips(selectableClips);
            const action = setExportStep(ExportStep.CONFIRM_CREATE, SaveAction.CREATE);
            dispatch(action);
        } else if (exportStep === null && assetType === EAssetType.LIVE) {
            const action = setExportStep(ExportStep.CLIP_SELECTION, SaveAction.CREATE);
            dispatch(action);
        } else if (exportStep === null) {
            const action = setExportStep(ExportStep.SAVE_SELECTION);
            dispatch(action);
        }
    }, []);

    React.useEffect(() => {
        window.addEventListener('keydown', escapeKeyHandler);
        return () => {
            window.removeEventListener('keydown', escapeKeyHandler);
        };
    }, [exportStep]);

    // list of single and combined clips used to drive ClipPicker view
    const [selectableClips, setSelectableClips] = React.useState<IExportClipWithMeta[]>(singleClips);

    // list of single and combined clipIds used to generate list of export clip objects
    const [userSelectedClipIds, setUserSelectedClipIds] = React.useState<string[]>([]);

    /**
     * Clip selection for export
     */

    const handleSelectClip = (fieldId: string) => {
        // handle selectAll toggle
        if (fieldId === 'all') {
            if (selectableClips.length === userSelectedClipIds.length) {
                // all clips are selected, deselect all clips
                setUserSelectedClipIds([]);
            } else {
                // partial clip selection, select all clips
                const clipIds = ClipsUtilities.getIds(selectableClips);

                setUserSelectedClipIds(clipIds);
            }
            return;
        }

        const index = userSelectedClipIds.indexOf(fieldId);
        const newSelectedClipIds: Array<ITimelineClip['id']> = [...userSelectedClipIds];

        if (index === -1) {
            newSelectedClipIds.push(fieldId);
        } else {
            newSelectedClipIds.splice(index, 1);
        }

        setUserSelectedClipIds(newSelectedClipIds);
    };

    // actual list of export clips sent to saga
    const [selectedExportClips, setSelectedExportClips] = React.useState<IExportClipWithMeta[]>([]);

    const escapeKeyHandler = (event: KeyboardEvent) => {
        event.stopPropagation();
        if (event.keyCode === KeyCode.ESCAPE && exportStep !== ExportStep.LOADING) {
            reset();
        }
    };

    const submitHandler = () => {
        const action = publishClip(selectedExportClips, saveAction);
        dispatch(action);
    };

    const setSaveActionHandler = (selectedSaveAction: SaveAction) => {
        if (selectableClips.length === 1) {
            setSelectedExportClips(selectableClips);

            // jump to confirmation if there is only a single clip selected
            const exportConfirmStep = selectedSaveAction === SaveAction.CREATE ? ExportStep.CONFIRM_CREATE : ExportStep.CONFIRM_OVERWRITE;
            const action = setExportStep(exportConfirmStep, selectedSaveAction);
            dispatch(action);
        } else if (selectedSaveAction === SaveAction.OVERWRITE) {
            // jump to combining a clip for overwrite
            const action = setExportStep(ExportStep.COMBINE_CLIP, selectedSaveAction);
            dispatch(action);
        } else {
            const action = setExportStep(ExportStep.CLIP_SELECTION, selectedSaveAction);
            dispatch(action);
        }
    };

    const selectExportClips = () => {
        const exportClips = userSelectedClipIds.map((clipId) => {
            return ClipsUtilities.findById(selectableClips, clipId);
        });

        setSelectedExportClips(exportClips);

        const exportConfirmStep = saveAction === SaveAction.CREATE ? ExportStep.CONFIRM_CREATE : ExportStep.CONFIRM_OVERWRITE;
        const action = setExportStep(exportConfirmStep);
        dispatch(action);
    };

    const handleModalMainOnClose = () => {
        if (exportStep === ExportStep.LOADING || exportStep === ExportStep.COMPLETE) {
            return null;
        }

        return reset;
    };

    const handleProcessBackButton = () => {
        // dont show back button for shortcut export start point
        if (
            (assetType === EAssetType.LIVE && exportStep === ExportStep.CONFIRM_CREATE && selectableClips.length === 1) ||
            (assetType === EAssetType.LIVE && exportStep === ExportStep.CLIP_SELECTION)
        ) {
            return null;
        }
        return () => {
            const isConfirmStep = exportStep === ExportStep.CONFIRM_CREATE || exportStep === ExportStep.CONFIRM_OVERWRITE;
            if ((isConfirmStep && selectableClips.length === 1) || exportStep === ExportStep.CLIP_SELECTION) {
                setSelectedExportClips(null);
                const action = resetExportStep();
                dispatch(action);
            } else if (exportStep === ExportStep.COMBINE_CLIP && saveAction === SaveAction.CREATE) {
                const action = setExportStep(ExportStep.CLIP_SELECTION);
                dispatch(action);
            } else if (exportStep === ExportStep.COMBINE_CLIP && saveAction === SaveAction.OVERWRITE) {
                const action = setExportStep(ExportStep.SAVE_SELECTION);
                dispatch(action);
            } else if (exportStep === ExportStep.CONFIRM_CREATE) {
                const action = setExportStep(ExportStep.CLIP_SELECTION);
                dispatch(action);
            } else if (exportStep === ExportStep.CONFIRM_OVERWRITE) {
                const action = setExportStep(ExportStep.COMBINE_CLIP);
                dispatch(action);
            }
        };
    };

    const handleAddCombinedClip = (clip: IExportClipWithMeta) => {
        if (saveAction === SaveAction.CREATE) {
            setSelectableClips([...selectableClips, clip]);
            // make new combined clip selected by default
            setUserSelectedClipIds([...userSelectedClipIds, clip.meta.id]);
        } else {
            setSelectedExportClips([clip]);
        }

        const nextStep = saveAction === SaveAction.CREATE ? ExportStep.CLIP_SELECTION : ExportStep.CONFIRM_OVERWRITE;
        const action = setExportStep(nextStep);
        dispatch(action);
    };

    const handleRemoveCombinedClip = (combinedClipId: string) => {
        const newSelectedClipIds: Array<ITimelineClip['id']> = [...userSelectedClipIds];
        const index = userSelectedClipIds.indexOf(combinedClipId);

        if (index > -1) {
            newSelectedClipIds.splice(index, 1);
            setUserSelectedClipIds(newSelectedClipIds);
        }

        const newSelectableClips = selectableClips.filter((clip) => clip.meta.id !== combinedClipId);
        setSelectableClips(newSelectableClips);
    };

    const getExportStep = (): JSX.Element => {
        switch (exportStep) {
            case ExportStep.SAVE_SELECTION:
                const isSingularClip = selectableClips.length === 1;
                return <SaveSelection setSaveAction={setSaveActionHandler} isSingularClip={isSingularClip} />;
            case ExportStep.CLIP_SELECTION:
                return (
                    <ClipSelection
                        clips={selectableClips}
                        submit={selectExportClips}
                        saveAction={saveAction}
                        back={handleProcessBackButton()}
                        userSelectedClipIds={userSelectedClipIds}
                        selectClip={handleSelectClip}
                        exportStep={exportStep}
                        canCreateCombinedClip={canCreateCombinedClip}
                        removeCombinedClip={handleRemoveCombinedClip}
                    />
                );
            case ExportStep.COMBINE_CLIP:
                return (
                    <ClipCombiner
                        clips={singleClips}
                        thumbnails={thumbnails}
                        saveAction={saveAction}
                        addCombinedClip={handleAddCombinedClip}
                        back={handleProcessBackButton()}
                        canCreateCombinedClip={canCreateCombinedClip}
                    />
                );
            case ExportStep.CONFIRM_CREATE:
                return <ConfirmCreate clips={selectedExportClips} submit={submitHandler} back={handleProcessBackButton()} />;
            case ExportStep.CONFIRM_OVERWRITE:
                const [overwriteClip] = selectedExportClips;
                return <ConfirmOverwrite clip={overwriteClip} submit={submitHandler} back={handleProcessBackButton()} />;
            case ExportStep.LOADING:
                return <Loading exportCount={selectedExportClips.length} saveAction={saveAction} />;
            case ExportStep.COMPLETE:
                return <Complete saveAction={saveAction} totalClips={totalClips} errorCount={errorCount} close={reset} />;
            default:
                return null;
        }
    };

    const $Component = (
        <Modal.Main hasOverlay={true} className="export-modal" onClose={handleModalMainOnClose()}>
            {getExportStep()}
        </Modal.Main>
    );

    return ReactDOM.createPortal($Component, document.getElementById('modal'));
};

const ExportClip: React.FunctionComponent<IContainerProps> = (props) => {
    const { loadPDT, assetType, reset, isOpen } = props;
    const { step, errorCount, totalClips, saveAction } = useShallowEqualSelector(getExportState);
    const dispatch = useDispatch();

    const {
        settings: { minimumClipDuration, minimumClipSeparation },
    } = React.useContext(RootContext);

    const clips = useShallowEqualSelector(getExportableClips(minimumClipDuration));

    const thumbnails = useShallowEqualSelector(getThumbnails);

    if (!isOpen) {
        return null;
    }

    return (
        <ExportClipComponent
            loadPDT={loadPDT}
            assetType={assetType}
            exportStep={step}
            saveAction={saveAction}
            errorCount={errorCount}
            totalClips={totalClips}
            dispatch={dispatch}
            reset={reset}
            clips={clips}
            thumbnails={thumbnails}
            minimumClipSeparation={minimumClipSeparation}
        />
    );
};

export { ExportClip };
