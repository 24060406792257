import { ClipActions } from '~src/store/timeline/clip/constants';
import { ITimelineClip } from '~src/store/timeline/clip/types';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { Seconds } from '~src/types';

const addClipInMarker = (renderClipInTime: Seconds, exportClipInTime: Seconds) => ({
    type: ClipActions.ADD_CLIP_IN_MARKER_SAGA,
    payload: {
        renderClipInTime,
        exportClipInTime,
    },
});

const addClipOutMarker = (renderClipOutTime: Seconds, exportClipOutTime: Seconds) => ({
    type: ClipActions.ADD_CLIP_OUT_MARKER_SAGA,
    payload: {
        renderClipOutTime,
        exportClipOutTime,
    },
});

const removeClip = (id: ITimelineClip['id']) => ({
    type: ClipActions.REMOVE_CLIP_SAGA,
    payload: {
        id,
    },
});

const setFocusClip = (id: ITimelineClip['id']) => ({
    type: ClipActions.SET_FOCUS_CLIP,
    payload: {
        id,
    },
});

const setEditClip = (id: ITimelineClip['id']) => ({
    type: ClipActions.SET_EDIT_CLIP,
    payload: {
        id,
    },
});

const setActiveClip = (id: ITimelineClip['id']) => ({
    type: ClipActions.SET_ACTIVE_CLIP,
    payload: {
        id,
    },
});

const setExportClip = (id: ITimelineClip['id']) => ({
    type: ClipActions.SET_EXPORT_CLIP,
    payload: {
        id,
    },
});

const setPreviewClip = (id?: ITimelineClip['id']) => ({
    type: ClipActions.SET_PREVIEW_CLIP_SAGA,
    payload: {
        id,
    },
});

const setClipTitle = (id: ITimelineClip['id'], title: ITimelineClip['title']) => ({
    type: ClipActions.SET_TITLE_SAGA,
    payload: {
        id,
        title,
    },
});

const setClipThumbnail = (id: ITimelineClip['id'], thumbnailId: ITimelineThumbnail['id']) => ({
    type: ClipActions.SET_THUMBNAIL_SAGA,
    payload: {
        id,
        thumbnailId,
    },
});

export {
    addClipInMarker,
    addClipOutMarker,
    removeClip,
    setFocusClip,
    setEditClip,
    setPreviewClip,
    setClipTitle,
    setActiveClip,
    setExportClip,
    setClipThumbnail,
};
