import debounce from 'lodash.debounce';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { ClipItem } from '~components/Editor/ClipsPanel/ClipItem';
import { InlineNotification } from '~components/InlineNotification';
import { RootContext } from '~components/Root/context';
import { EditorContext } from '~containers/EditorPageContainer/EditorContext';
import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { EditorModes } from '~containers/EditorPageContainer/types';
import { ISettingsSettings } from '~services/settings';
import { removeClip, setActiveClip, setEditClip, setExportClip, setFocusClip, setPreviewClip } from '~src/store/timeline/clip/clip.actions';
import { isClipValid } from '~src/store/timeline/clip/clip.utilities';
import { ITimelineClip, Seconds } from '~src/store/timeline/clip/types';
import { ThumbnailsUtilities } from '~src/store/timeline/thumbnail/thumbnails.utilities';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { IStudioState } from '~src/store/types';
import { useShallowEqualSelector } from '~src/views/hooks';

import './index.scss';

interface IProps {
    clips: ITimelineClip[];
    thumbnails: ITimelineThumbnail[];
    previewClipId: ITimelineClip['id'];
    activeClipId: ITimelineClip['id'];
    hoverClipId: ITimelineClip['id'];
    minimumClipDuration: ISettingsSettings['minimumClipDuration'];
    dispatch: Dispatch;
    loop: (start: Seconds, end: Seconds) => void;
    seekToWallClock: (time: Seconds) => void;
    stopLoop: VoidFunction;
    toggleEditorMode: (editorMode: EditorModes) => void;
}

const ClipsPanelComponent: React.FunctionComponent<IProps> = ({
    clips,
    previewClipId,
    dispatch,
    activeClipId,
    hoverClipId,
    loop,
    seekToWallClock,
    stopLoop,
    thumbnails,
    toggleEditorMode,
    minimumClipDuration,
}) => {
    if (!clips.length) {
        return <InlineNotification message="No clips currently added" />;
    }

    const setClipFocusDispatchHandler = (payload) => {
        const action = setFocusClip(payload);
        dispatch(action);
    };

    const debounceSetClipFocus = debounce(setClipFocusDispatchHandler, 250);

    const setClipFocusHandler = (type: string, id: ITimelineClip['id']) => {
        const payload = type === 'mouseenter' ? id : null;
        debounceSetClipFocus(payload);
    };

    const removeClipHandler = (id: ITimelineClip['id']) => {
        if (id === previewClipId) {
            stopLoop();
        }
        const action = removeClip(id);
        dispatch(action);
    };

    const editClipHandler = (id: ITimelineClip['id']) => {
        const action = setEditClip(id);
        dispatch(action);
    };

    const activeClipHandler = (id: ITimelineClip['id']) => {
        const action = setActiveClip(id);
        dispatch(action);
    };

    const exportSingleClipHandler = (id: ITimelineClip['id']) => {
        const action = setExportClip(id);
        dispatch(action);
        toggleEditorMode(EditorModes.EXPORT);
    };

    const previewClip = (id, start, end) => {
        const action = setPreviewClip(id);
        dispatch(action);
        loop(start, end);
    };

    const stopReviewClip = () => {
        const action = setPreviewClip(null);
        dispatch(action);
        stopLoop();
    };

    const getClipItem = (clip: ITimelineClip): JSX.Element => {
        const { id, title, exportClipInTime, exportClipOutTime, thumbnailId } = clip;
        const clipDuration: number = exportClipOutTime - exportClipInTime;

        const isPreviewing = id === previewClipId;
        const isActive = id === activeClipId;
        const isHovered = id === hoverClipId;
        const { valid, message } = isClipValid(clip, minimumClipDuration);

        const thumbnail = ThumbnailsUtilities.findById(thumbnailId, thumbnails);

        return (
            <ClipItem
                key={id}
                id={id}
                title={title}
                thumbnail={thumbnail}
                exportClipInTime={exportClipInTime}
                exportClipOutTime={exportClipOutTime}
                isPreviewing={isPreviewing}
                isActive={isActive}
                isHovered={isHovered}
                isValid={valid}
                isInvalidMessage={message}
                clipDuration={clipDuration}
                setClipFocus={setClipFocusHandler}
                removeClip={removeClipHandler}
                editClip={editClipHandler}
                setActiveClip={activeClipHandler}
                previewClip={previewClip}
                stopPreviewClip={stopReviewClip}
                exportSingleClip={exportSingleClipHandler}
                seekToClip={seekToWallClock}
            />
        );
    };

    return <div className="clip-list">{clips.map(getClipItem)}</div>;
};

const ClipsPanelMemo: React.NamedExoticComponent<IProps> = React.memo(ClipsPanelComponent);

const getClipState = (state) => {
    const { list: clips, previewClipId, activeClipId, hoverClipId } = state.timeline.clips;
    return {
        clips,
        previewClipId,
        activeClipId,
        hoverClipId,
    };
};

const getThumbnailState = (state: IStudioState): ITimelineThumbnail[] => {
    return state.timeline.thumbnails.list;
};

const ClipsPanel: React.FunctionComponent = () => {
    const { clips, previewClipId, activeClipId, hoverClipId } = useShallowEqualSelector(getClipState);
    const thumbnails = useShallowEqualSelector(getThumbnailState);
    const dispatch = useDispatch();
    const { loop, stopLoop, seekToWallClock } = React.useContext(VideoContext);
    const { toggleEditorMode } = React.useContext(EditorContext);
    const {
        settings: { minimumClipDuration },
    } = React.useContext(RootContext);
    return (
        <ClipsPanelMemo
            clips={clips}
            thumbnails={thumbnails}
            previewClipId={previewClipId}
            activeClipId={activeClipId}
            hoverClipId={hoverClipId}
            minimumClipDuration={minimumClipDuration}
            dispatch={dispatch}
            loop={loop}
            seekToWallClock={seekToWallClock}
            stopLoop={stopLoop}
            toggleEditorMode={toggleEditorMode}
        />
    );
};

export { ClipsPanel };
