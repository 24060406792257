import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { Button, ButtonTheme } from '~components/Button';
import { ClipPicker } from '~components/Editor/ExportClip/Components/ClipPicker';
import { CombineClipsNotification } from '~components/Editor/ExportClip/Components/CombineClipNotification';
import { Checkbox } from '~components/Form/Checkbox';
import { FilePlusIcon } from '~components/Icons/FilePlusIcon';
import Modal, { TextJustify } from '~components/Modal';
import { ExportStep, SaveAction } from '~containers/EditorPageContainer/types';
import { joinStrings } from '~services/utilities';
import { setExportStep } from '~src/store/editor/export/export.actions';
import { getStreamState } from '~src/store/editor/stream/stream.selectors';
import { IStreamUrl, IEditorStreamState } from '~src/store/editor/stream/types';
import { IExportClipWithMeta } from '~src/store/timeline/clip/types';

interface IContainerProps {
    clips: IExportClipWithMeta[];
    saveAction: SaveAction;
    back: VoidFunction;
    submit: VoidFunction;
    userSelectedClipIds: string[];
    exportStep: ExportStep;
    canCreateCombinedClip: boolean;
    selectClip: (clipId: string) => void;
    removeCombinedClip: (clipId: string) => void;
}

interface IComponentProps extends IContainerProps {
    dispatch: Dispatch;
}

const ClipSelectionComponent: React.FunctionComponent<IComponentProps> = (props) => {
    const { clips, submit, canCreateCombinedClip, userSelectedClipIds, selectClip, removeCombinedClip, saveAction, back, dispatch } = props;

    const submitHandler = async (event?: React.SyntheticEvent<HTMLFormElement | MouseEvent>) => {
        if (event) {
            event.preventDefault();
        }
        submit();
    };

    const isSubmitDisabled = (): boolean => {
        return !Object.values(userSelectedClipIds).some((value) => value);
    };

    const handleSelectAll = (formId: string) => () => {
        selectClip(formId);
    };

    const showAddCombinedClipView = () => {
        const action = setExportStep(ExportStep.COMBINE_CLIP);
        dispatch(action);
    };

    const isAllSelected = userSelectedClipIds.length === clips.length;

    const selectionCardClass = joinStrings(['clip-picker__item greyscale', !canCreateCombinedClip && 'greyscale--disabled']);

    let showDrmWarning = false;

    const streamState: IEditorStreamState = useSelector(getStreamState);

    if (canCreateCombinedClip) {
        showDrmWarning = streamState.streamUrls.some((stream: IStreamUrl) => stream.drm);
    }

    return (
        <React.Fragment>
            <Modal.Title justify={TextJustify.CENTER}>Create New Clips</Modal.Title>
            <Modal.Body className="export-modal__clip-selection">
                <div className="export-modal__step-description">
                    <p>
                        Select the single clips you want to export, additionally you can select multiple clips to create a new combined
                        asset.
                    </p>
                </div>

                {!canCreateCombinedClip && <CombineClipsNotification />}

                <form onSubmit={submitHandler} autoComplete="off">
                    <label className="export-clip__selector form-group__label no-transition" htmlFor="selectAll">
                        <Checkbox
                            className="form-group__control"
                            id="selectAll"
                            name="selectAll"
                            checked={isAllSelected}
                            onChange={handleSelectAll('all')}
                            data-test-id="exportForm-exportClipSelectAllCheckbox"
                            label="Select all"
                        />
                    </label>

                    <ClipPicker
                        clips={clips}
                        clipIds={userSelectedClipIds}
                        selectClip={selectClip}
                        removeClip={removeCombinedClip}
                        renderSingleList={true}
                    >
                        {saveAction === SaveAction.CREATE && (
                            <div className={selectionCardClass}>
                                <div className="selection-card">
                                    <div className="selection-card__icon">
                                        <FilePlusIcon height="50" width="50" />
                                    </div>
                                    <div className="selection-card__content">
                                        <h3>New Combined Clip</h3>
                                        <p>Create a new combined clip</p>
                                        {showDrmWarning && (
                                            <div className="selection-card__content__warning">
                                                Temporary assets for combined clips will be non-DRM. Permanent assets will follow the
                                                customer’s preset for DRM.
                                            </div>
                                        )}
                                    </div>
                                    <Button theme={ButtonTheme.BLACK} className="selection-card__button" onClick={showAddCombinedClipView}>
                                        Create Combined Clip
                                    </Button>
                                </div>
                            </div>
                        )}
                    </ClipPicker>

                    <div className="button-group mt-4 text-center">
                        <Button theme={ButtonTheme.BLUE} disabled={isSubmitDisabled()}>
                            Confirm Selection
                        </Button>
                        {back && (
                            <Button theme={ButtonTheme.BLACK} type="button" onClick={back}>
                                Back
                            </Button>
                        )}
                    </div>
                </form>
            </Modal.Body>
        </React.Fragment>
    );
};

const ClipSelectionMemo: React.NamedExoticComponent<IComponentProps> = React.memo(ClipSelectionComponent);

const ClipSelection: React.FC<IContainerProps> = (props) => {
    const {
        clips,
        userSelectedClipIds,
        selectClip,
        saveAction,
        back,
        submit,
        exportStep,
        canCreateCombinedClip,
        removeCombinedClip,
    } = props;

    const dispatch = useDispatch();

    return (
        <ClipSelectionMemo
            clips={clips}
            userSelectedClipIds={userSelectedClipIds}
            selectClip={selectClip}
            canCreateCombinedClip={canCreateCombinedClip}
            saveAction={saveAction}
            submit={submit}
            back={back}
            dispatch={dispatch}
            exportStep={exportStep}
            removeCombinedClip={removeCombinedClip}
        />
    );
};

export { ClipSelection };
