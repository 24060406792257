import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';

import { ResetPasswordPage, ResetPasswordFormState } from '@dicetechnology/dice-backoffice-ui-components';

import { PageRoutes } from '~components/Root/constants';
import { RootContext } from '~components/Root/context';
import { ApplicationVersion } from '~components/application-version';
import { RelativeContainer } from '~components/styled';
import { TOAST_TIMEOUT, TOAST_TEST_ID } from '~pages/constants';
import { authenticationService } from '~services/authentication';
import { Console } from '~services/console';
import { EToastType } from '~src/store/toast/constants';
import { addToast } from '~src/store/toast/toast.actions';

import { ERROR_TOAST_TITLE, ERROR_TOAST_MESSAGE, SUCCESS_TOAST_MESSAGE, SUCCESS_TOAST_TITLE, AUTHENTICATION_LINKS } from './constants';
import './index.scss';

interface ForgottenPasswordContainerProps extends RouteComponentProps<unknown, unknown, { from: string }> {}

const ForgottenPasswordContainer: React.FC<ForgottenPasswordContainerProps> = ({ location }) => {
    const { state = { from: '' } } = location;

    const { authorised } = React.useContext(RootContext);

    const dispatch = useDispatch();

    const handleOnSubmit = async ({ emailAddress, realm }: ResetPasswordFormState): Promise<void> => {
        try {
            await authenticationService.forgotPassword(emailAddress, realm);
            const action = addToast(SUCCESS_TOAST_TITLE, SUCCESS_TOAST_MESSAGE, EToastType.SUCCESS, TOAST_TIMEOUT, TOAST_TEST_ID);
            dispatch(action);
        } catch (e) {
            Console.warn("Processing 'User Login':", e);
            const action = addToast(ERROR_TOAST_TITLE, ERROR_TOAST_MESSAGE, EToastType.DANGER, TOAST_TIMEOUT, TOAST_TEST_ID);
            dispatch(action);
        }
    };

    if (authorised) {
        return <Redirect to={state.from || PageRoutes.LANDING_PAGE} />;
    }

    return (
        <RelativeContainer>
            <ResetPasswordPage onSubmit={handleOnSubmit} renderRealmField={true} authenticationLinks={AUTHENTICATION_LINKS} />
            <ApplicationVersion />
        </RelativeContainer>
    );
};

export const ForgottenPasswordWithRouter = withRouter<RouteComponentProps, React.FunctionComponent>(ForgottenPasswordContainer);
