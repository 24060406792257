import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { CreateRealmModal } from '~components/CreateRealmModal';
import { FeatureToggle } from '~components/FeatureToggle';
import { CustomSelect, ICustomSelectEvent } from '~components/Form/CustomSelect';
import { SettingsIcon } from '~components/Icons/SettingsIcon';
import UserSolidIcon from '~components/Icons/UserSolidIcon';
import { LinkListDropdownMenu } from '~components/LinkListDropdownMenu';
import { PageRoutes } from '~components/Root/constants';
import { SimpleDropdownMenu } from '~components/SimpleDropdownMenu';
import { SwitchToggle } from '~components/SwitchToggle';
import { authenticationService } from '~services/authentication';
import { Console } from '~services/console';
import { ProfileService } from '~services/profile';
import { StorageKeys } from '~services/storage/constants';
import { joinStrings } from '~services/utilities';
import { Realm } from '~src/types';
import { useLocalStorage } from '~src/views/hooks/useLocalStorage';
import { FeatureToggleKey } from '~src/views/types';

import './index.scss';
import './index.scss';

interface IState {
    username: string;
    realm: Realm;
    showAddRealmModal: boolean;
}

interface IProps {
    realms: string[];
}

enum TimeFormats {
    HOUR_12 = '12 Hour',
    HOUR_24 = '24 Hour',
}

class ProfileMenu extends React.PureComponent<IProps, IState> {
    public state: IState = {
        username: 'loading...',
        realm: null,
        showAddRealmModal: false,
    };

    public async componentDidMount() {
        const realm = await authenticationService.getRealm();
        const name = await this.getProfileName();

        this.setState({ username: name, realm });
    }

    private onShowAddRealmModal = () => {
        this.setState({ showAddRealmModal: true });
    };

    private onHideAddRealmModal = () => {
        this.setState({ showAddRealmModal: false });
    };

    private onRealmChange = (event: ICustomSelectEvent) => {
        authenticationService.switchRealm(event.value as string);
    };

    public render() {
        const { realm: activeRealm, username } = this.state;
        const { realms } = this.props;
        const canShowRealmSelector = realms && realms.length > 1;

        return (
            <section className="profile-menu-wrapper">
                <FeatureToggle featureName={FeatureToggleKey.AdminMultiRealm}>
                    {canShowRealmSelector && (
                        <>
                            <a onClick={this.onShowAddRealmModal} className="create-realm" title="Add Realm">
                                <span>+</span>
                            </a>
                            <CustomSelect
                                value={activeRealm}
                                options={realms}
                                onChange={this.onRealmChange}
                                className="realm-selector"
                                showFilter={true}
                            />
                        </>
                    )}
                </FeatureToggle>
                <h3 className="username" data-test-id="profileMenu-usernameText">
                    {username}
                </h3>
                <ProfileIconMenu />
                {this.state.showAddRealmModal && (
                    <CreateRealmModal onCancel={this.onHideAddRealmModal} onCreate={this.onHideAddRealmModal} />
                )}
            </section>
        );
    }

    private getProfileName = async (): Promise<string> => {
        try {
            const { name = {}, id } = await ProfileService.getProfile();
            const { fullName, preferredName } = name;
            return fullName || preferredName || id;
        } catch (e) {
            Console.warn("Processing 'Get Profile':", e);
            return '';
        }
    };
}

const ProfileIconMenu = () => {
    const [showUSDateTimeFormat, setShowUSDateTimeFormat] = useLocalStorage(StorageKeys.SHOW_US_DATE_TIME_FORMAT, false);
    const history = useHistory();

    const onLogoutClick = () => {
        history.push(PageRoutes.LOGOUT);
    };

    const accountMenuLinkList = React.useMemo(() => [{ title: 'Logout', callback: onLogoutClick, testId: 'profileMenu-logoutButton' }], []);

    const onTimeFormatSettingsChange = () => {
        setShowUSDateTimeFormat(!showUSDateTimeFormat);
    };

    return (
        <>
            <LinkListDropdownMenu
                menuItems={accountMenuLinkList}
                triggerChildren={<NavIcon icon={<UserSolidIcon />} iconClassName="user-icon" />}
                className="profile-menu"
            />

            <SimpleDropdownMenu
                triggerChildren={<NavIcon icon={<SettingsIcon />} iconClassName="settings-icon" />}
                className="profile-menu settings__time-format"
            >
                <div className="settings__time-format-label">Time format</div>
                <div className="settings__time-format-control">
                    <SwitchToggle
                        firstOption={TimeFormats.HOUR_12}
                        secondOption={TimeFormats.HOUR_24}
                        activeOption={showUSDateTimeFormat ? TimeFormats.HOUR_12 : TimeFormats.HOUR_24}
                        onToggleChange={onTimeFormatSettingsChange}
                    />
                </div>
            </SimpleDropdownMenu>
        </>
    );
};

const NavIcon: React.FC<{ iconClassName?: string; icon: React.ReactNode }> = (props) => {
    return (
        <div className="nav-icon-wrapper">
            <span className={joinStrings(['nav-icon', props.iconClassName])}>{props.icon}</span>
            <span className="nav-icon-arrow" />
        </div>
    );
};

export { ProfileMenu };
