import { SearchActions } from '~src/store/search/constants';
import { SearchTerm } from '~src/store/search/types';
import { ISearchableContentType, StateKey } from '~src/types';

const setSearchTerm = (searchTerm: SearchTerm) => ({
    type: SearchActions.SET_SEARCH_TERM,
    payload: {
        searchTerm,
    },
});

const searchAddBucket = (stateKey: StateKey) => ({
    type: SearchActions.ADD_BUCKET,
    payload: {
        stateKey,
    },
});

const searchRemoveBucket = (stateKey: StateKey) => ({
    type: SearchActions.REMOVE_BUCKET,
    payload: {
        stateKey,
    },
});

const searchGetNextPage = (
    stateKey: StateKey,
    pageService,
    searchTerm: SearchTerm,
    facetFilters: string[],
    indexName: string,
    hitsPerPage: number,
    callbackMiddleware: (algoliaResults: ISearchableContentType[]) => Promise<ISearchableContentType[]>
) => ({
    type: SearchActions.GET_NEXT_PAGE_SAGA,
    payload: {
        stateKey,
        pageService,
        searchTerm,
        facetFilters,
        indexName,
        hitsPerPage,
        callbackMiddleware,
    },
});

const searchProcessSearch = (
    stateKey: StateKey,
    pageService,
    searchTerm: SearchTerm,
    facetFilters: string[],
    indexName: string,
    hitsPerPage: number,
    callbackMiddleware: (algoliaResults: ISearchableContentType[]) => Promise<ISearchableContentType[]>
) => ({
    type: SearchActions.PROCESS_SEARCH_SAGA,
    payload: {
        stateKey,
        pageService,
        searchTerm,
        facetFilters,
        indexName,
        hitsPerPage,
        callbackMiddleware,
    },
});

const searchAdBucketResults = <T>(stateKey: StateKey, results: T[], nbHits: number, page: number, nbPages: number) => ({
    type: SearchActions.ADD_BUCKET_RESULTS,
    payload: {
        stateKey,
        results,
        nbHits,
        page,
        nbPages,
        status: 'loaded',
    },
});

const searchUpdateResult = <T>(stateKeys: StateKey[], result: T) => ({
    type: SearchActions.UPDATE_RESULT,
    payload: {
        stateKeys,
        result,
    },
});

export {
    setSearchTerm,
    searchAddBucket,
    searchRemoveBucket,
    searchGetNextPage,
    searchAdBucketResults,
    searchProcessSearch,
    searchUpdateResult,
};
