import debounce from 'lodash.debounce';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { ClipRange } from '~components/Editor/ClipsRail/ClipRange';
import { RootContext } from '~components/Root/context';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { ISettingsSettings } from '~services/settings';
import { joinStrings, valuesToPercentage } from '~services/utilities';
import { setActiveClip, setFocusClip } from '~src/store/timeline/clip/clip.actions';
import { isClipValid } from '~src/store/timeline/clip/clip.utilities';
import { ITimelineClip, Seconds } from '~src/store/timeline/clip/types';
import { useShallowEqualSelector } from '~src/views/hooks';

import './index.scss';

interface IParentProps {
    timelineStartTime: Seconds;
    duration: Seconds;
    assetType: EAssetType;
    isLive: boolean;
}

interface IClipsRailProps extends IParentProps {
    clips: ITimelineClip[];
    previewClipId: ITimelineClip['id'];
    hoverClipId: ITimelineClip['id'];
    activeClipId: ITimelineClip['id'];
    minimumClipDuration: ISettingsSettings['minimumClipDuration'];
    dispatch: Dispatch;
}

const getClipRenderTimes = (assetType: EAssetType, isLive: boolean, clip: ITimelineClip) => {
    const { renderClipInTime, renderClipOutTime, exportClipInTime, exportClipOutTime } = clip;
    // event starts off as a live event, but transitions into vod mode
    if (assetType === EAssetType.LIVE && !isLive) {
        return {
            renderClipInTime: exportClipInTime,
            renderClipOutTime: exportClipOutTime,
        };
    }

    return {
        renderClipInTime,
        renderClipOutTime,
    };
};

const ClipsRailComponent: React.FunctionComponent<IClipsRailProps> = ({
    timelineStartTime,
    duration,
    clips,
    previewClipId,
    hoverClipId,
    activeClipId,
    assetType,
    isLive,
    minimumClipDuration,
    dispatch,
}) => {
    const className = joinStrings([
        'clips-rail',
        previewClipId && 'clips-rail--preview-focus',
        activeClipId && 'clips-rail--active-focus',
        hoverClipId && !activeClipId && 'clips-rail--hover-focus',
    ]);

    const activeClipHandler = (id: ITimelineClip['id']) => {
        const action = setActiveClip(id);
        dispatch(action);
    };

    const setClipFocusDispatchHandler = (payload) => {
        const action = setFocusClip(payload);
        dispatch(action);
    };

    const debounceSetClipFocus = debounce(setClipFocusDispatchHandler, 250);

    const setClipFocusHandler = (type: string, id: ITimelineClip['id']) => {
        const payload = type === 'mouseenter' ? id : null;
        debounceSetClipFocus(payload);
    };

    return (
        <div className={className}>
            {clips.map((clip) => {
                const { id, title, exportClipInTime, exportClipOutTime } = clip;
                const { renderClipInTime, renderClipOutTime } = getClipRenderTimes(assetType, isLive, clip);

                const renderInPosition = valuesToPercentage(renderClipInTime - timelineStartTime, duration);
                const renderOutPosition =
                    renderClipOutTime !== null ? valuesToPercentage(renderClipOutTime - timelineStartTime, duration) : renderClipOutTime;

                const clipDuration = exportClipOutTime - exportClipInTime;
                const isPreviewing = id === previewClipId;
                const isActive = id === activeClipId;
                const isHovered = id === hoverClipId;
                const { valid, message } = isClipValid(clip, minimumClipDuration);

                return (
                    <ClipRange
                        key={id}
                        id={id}
                        title={title}
                        isPreviewing={isPreviewing}
                        isActive={isActive}
                        isHovered={isHovered}
                        isValid={valid}
                        isInvalidMessage={message}
                        renderInPosition={renderInPosition}
                        renderOutPosition={renderOutPosition}
                        clipDuration={clipDuration}
                        setActiveClip={activeClipHandler}
                        setClipFocus={setClipFocusHandler}
                    />
                );
            })}
        </div>
    );
};

const ClipsRailMemo: React.NamedExoticComponent<IClipsRailProps> = React.memo(ClipsRailComponent);

const getClipState = (state) => {
    const { list: clips, previewClipId, hoverClipId, activeClipId } = state.timeline.clips;
    return {
        clips,
        previewClipId,
        hoverClipId,
        activeClipId,
    };
};

const ClipsRail: React.FunctionComponent<IParentProps> = ({ timelineStartTime, duration, assetType, isLive }) => {
    const {
        settings: { minimumClipDuration },
    } = React.useContext(RootContext);

    const { clips, previewClipId, hoverClipId, activeClipId } = useShallowEqualSelector(getClipState);
    const dispatch = useDispatch();

    return (
        <ClipsRailMemo
            timelineStartTime={timelineStartTime}
            duration={duration}
            clips={clips}
            previewClipId={previewClipId}
            hoverClipId={hoverClipId}
            activeClipId={activeClipId}
            assetType={assetType}
            isLive={isLive}
            minimumClipDuration={minimumClipDuration}
            dispatch={dispatch}
        />
    );
};

export { ClipsRail };
