import * as React from 'react';

import { Button, ButtonTheme } from '~components/Button';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { V4Uid } from '~src/types';

interface IComponentProps {
    thumbnailId: V4Uid;
    thumbnails: ITimelineThumbnail[];
    toggleEditStep: VoidFunction;
}

const getThumbnailDataUri = (thumbnailId: ITimelineThumbnail['id'], thumbnails: ITimelineThumbnail[]): ITimelineThumbnail['imageData'] => {
    if (!thumbnailId) {
        return null;
    }
    const { imageData = null } = thumbnails.find((thumbnail) => thumbnail.id === thumbnailId) || {};
    return imageData;
};

const EditClipThumbnailPreview: React.FunctionComponent<IComponentProps> = ({ thumbnailId, thumbnails, toggleEditStep }) => {
    const thumbnailImageData: ITimelineThumbnail['imageData'] = getThumbnailDataUri(thumbnailId, thumbnails);

    const getThumbnailPlaceholder = () => {
        return (
            <div className="edit-clip__thumbnail-placeholder">
                <Button
                    type="button"
                    className="edit-clip__select-thumbnail"
                    theme={ButtonTheme.BLACK}
                    onClick={toggleEditStep}
                    disabled={!thumbnails.length}
                >
                    Select thumbnail
                </Button>
            </div>
        );
    };

    const getThumbnail = () => {
        return (
            <div className="edit-clip__thumbnail-wrapper">
                <img src={thumbnailImageData} />
                <Button type="button" theme={ButtonTheme.BLACK} onClick={toggleEditStep} className="edit-clip__select-thumbnail">
                    Select thumbnail
                </Button>
            </div>
        );
    };

    return <div className="edit-clip__thumbnail">{!thumbnailId ? getThumbnailPlaceholder() : getThumbnail()}</div>;
};

export { EditClipThumbnailPreview };
