import { call, put, take } from 'redux-saga/effects';

import { EAssetType } from '~containers/EditorPageContainer/types';
import { getStudioClipMeta, IClipStudio } from '~services/clip';
import { getDvePlaybackUrls, IStreamingProtocolDict } from '~services/dve';
import { getDgeStreamMeta, getLiveEventStudioMeta } from '~services/live';
import { getStudioVodMeta, IVodStudio } from '~services/vod';
import { addFeedback } from '~src/store/feedback/feedback.actions';

import { StreamActions } from './constants';
import { processStreamFailure, processStreamSuccess } from './stream.actions';

function* processEventAsset(assetId: number) {
    try {
        const { nonDrmUrl, drmUrl, title, thumbnail, streamingConfigId } = yield getLiveEventStudioMeta(assetId.toString());

        const streamUrls = yield getDgeStreamMeta(nonDrmUrl, drmUrl);

        const url = streamUrls[0].url;

        yield put(
            processStreamSuccess({
                assetType: EAssetType.LIVE,
                assetId,
                title,
                thumbnail,
                hlsStreamUrl: url,
                streamUrls,
                streamingConfigId,
            })
        );
    } catch (error) {
        const { status = '', messages = [], requestId = '' } = yield error.clone().json();
        let feedback;

        if (status === 404) {
            feedback = {
                title: 'Event ended',
                message: `<p>The event is no longer available</p>`,
                canDismiss: true,
            };
        } else {
            feedback = {
                title: `ERROR ${status}`,
                message: `<p>
                        ${messages.join(' ')} <br /> RID: ${requestId}
                    </p>`,
                canDismiss: true,
            };
        }

        yield put(addFeedback(feedback));
        yield put(processStreamFailure());
    }
}

function* processClipAsset(assetId: number) {
    try {
        const { title, duration, thumbnail, streamUrl, createdAt, updatedAt }: IClipStudio = yield getStudioClipMeta(assetId);
        const { hlsStreamUrl, dashStreamUrl, hlsStreamDRM, dashStreamDRM, streamUrls }: IStreamingProtocolDict = yield getDvePlaybackUrls(
            streamUrl
        );
        yield put(
            processStreamSuccess({
                assetType: EAssetType.CLIP,
                assetId,
                title,
                thumbnail,
                hlsStreamUrl,
                dashStreamUrl,
                hlsStreamDRM,
                dashStreamDRM,
                streamUrls,
                duration,
                createdAt,
                updatedAt,
            })
        );
    } catch (e) {
        yield put(processStreamFailure());
    }
}

function* processVodAsset(assetId: number) {
    try {
        const { title, duration, thumbnail, streamUrl }: IVodStudio = yield getStudioVodMeta(assetId);
        const { hlsStreamUrl, dashStreamUrl, hlsStreamDRM, dashStreamDRM, streamUrls }: IStreamingProtocolDict = yield getDvePlaybackUrls(
            streamUrl
        );
        yield put(
            processStreamSuccess({
                assetType: EAssetType.VOD,
                assetId,
                title,
                thumbnail,
                hlsStreamUrl,
                dashStreamUrl,
                hlsStreamDRM,
                dashStreamDRM,
                streamUrls,
                duration,
            })
        );
    } catch (e) {
        yield put(processStreamFailure());
    }
}

function* handleProcessStream(action) {
    const { id, assetType } = action.payload;

    if (assetType === EAssetType.LIVE) {
        yield call(processEventAsset, id);
    } else if (assetType === EAssetType.CLIP) {
        yield call(processClipAsset, id);
    } else if (assetType === EAssetType.VOD) {
        yield call(processVodAsset, id);
    }
}

function* watchStreamSaga() {
    while (true) {
        const action = yield take(StreamActions.PROCESS_STREAM_SAGA);
        yield call(handleProcessStream, action);
    }
}

export const streamSagas = [watchStreamSaga()];
