import * as moment from 'moment';
import * as React from 'react';
import { ChangeEvent, useEffect } from 'react';
import * as ReactDOM from 'react-dom';

import TimesThinIcon from '~components/Icons/TimesThinIcon';
import { LinkListDropdownMenu } from '~components/LinkListDropdownMenu';
import { ILinkMenuItem } from '~components/LinkListDropdownMenu/MenuItem';
import LoadingSpinner from '~components/LoadingSpinner';
import Modal from '~components/Modal';
import { IChannelManagerContext, IMuxerConfiguration } from '~containers/ChannelManagerContainer/types';
import {
    IChannelAdminStreamingConfig,
    IChannelAdminStreamingConfigMuxer,
    IMuxerRegion,
    IMuxerVersion,
    IMuxerVersions,
    MuxerAction,
} from '~services/channels/types';
import { joinStrings } from '~services/utilities';
import { IChannelAdminDetailsState } from '~src/store/channelManager/types';

import './index.scss';

enum TabTypes {
    STREAMING_CONFIGS = 'STREAMING_CONFIGS',
    MUXERS = 'MUXERS',
    PROVISION_MUXER = 'PROVISION_MUXER',
}

interface IProps {
    channelLogoUrl: string;
    channelTitle: string;
    onCancel: (event: React.MouseEvent) => void;
    channelAdminDetails: IChannelAdminDetailsState;
    onSynchroniseChannelStreamingConfigs: IChannelManagerContext['onSynchroniseChannelStreamingConfigs'];
    onProvisionMuxer: IChannelManagerContext['onProvisionMuxer'];
    onMuxerAction: IChannelManagerContext['onMuxerAction'];
    onSetChannelDgeEventId: IChannelManagerContext['onSetChannelDgeEventId'];
    onCancelMuxerAction: IChannelManagerContext['onCancelMuxerAction'];
    onRefreshChannelAdminDetails: IChannelManagerContext['onRefreshChannelAdminDetails'];
    onCheckMuxerStatus: IChannelManagerContext['onCheckMuxerStatus'];
    muxerVersions: IMuxerVersions;
}

const isDrmStream = (streamingConfig: IChannelAdminStreamingConfig) => {
    return streamingConfig.protocol === 'HLS_DRM' || streamingConfig.protocol === 'DASH_DRM';
};

export const ChannelAdminViewModal: React.FunctionComponent<IProps> = (props) => {
    const {
        channelAdminDetails,
        onSynchroniseChannelStreamingConfigs,
        muxerVersions,
        onProvisionMuxer,
        onRefreshChannelAdminDetails,
        onCheckMuxerStatus,
        onMuxerAction,
        onSetChannelDgeEventId,
        onCancelMuxerAction,
    } = props;

    const { muxerActionRequiresConfirmation, lastMuxerAction, isProvisioningMuxer, isLoadingMuxers } = props.channelAdminDetails;

    const [confirmStopMuxer, setConfirmStopMuxer] = React.useState<boolean>(false);
    const [muxerToStop, setMuxerToStop] = React.useState<IChannelAdminStreamingConfigMuxer>(null);

    const showStopMuxerConfirmation = (muxerConfig: IChannelAdminStreamingConfigMuxer) => {
        setMuxerToStop(muxerConfig);
        setConfirmStopMuxer(true);
    };

    const handleStopMuxer = () => {
        stopMuxer(muxerToStop);
        setConfirmStopMuxer(false);
        setMuxerToStop(null);
    };

    const handleCancelStopMuxer = () => {
        setConfirmStopMuxer(false);
        setMuxerToStop(null);
    };

    const [newMuxerValue, setNewMuxerValues] = React.useState<IMuxerConfiguration>({
        muxerVersion: '',
        streamingConfigId: 0,
        region: '',
        availability: '',
    });
    const [channelTitleConfirmation, setChannelTitleConfirmation] = React.useState<string>('');
    const [dgeEventId, setDgeEventId] = React.useState<string>('');

    const handleSetDgeEventId = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDgeEventId(e.target.value);
    };

    useEffect(() => {
        setChannelTitleConfirmation('');
    }, [muxerActionRequiresConfirmation]);

    useEffect(() => {
        if (!channelAdminDetails?.streamingConfigs?.length) {
            return;
        }
        setMuxerStreamingConfigId(channelAdminDetails.streamingConfigs[0].streamingConfigId);
        setMuxerRegion(muxerVersions.regions[0].region);
    }, [channelAdminDetails?.streamingConfigs, muxerVersions?.regions]);

    useEffect(() => {
        if (!channelAdminDetails.muxers) {
            onRefreshChannelAdminDetails(true);
        }
    }, [channelAdminDetails.muxers]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // prevent any global shortcuts from being triggered
        e.stopPropagation();
    };

    const handleSetChannelNameConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChannelTitleConfirmation(e.target.value);
    };

    const handleProvisionMuxer = () => {
        onProvisionMuxer(newMuxerValue);
        setTabType(TabTypes.MUXERS);
    };

    const [selectedMuxerRegion, setSelectedMuxerRegion] = React.useState<IMuxerRegion>(null);

    const handleSelectMuxerRegion = (e: ChangeEvent<HTMLSelectElement>) => {
        setMuxerRegion(e.target.value);
    };

    const setMuxerRegion = (newRegion: string) => {
        const newSelectedMuxerRegion = muxerVersions.regions.find((region: IMuxerRegion) => region.region === newRegion);
        setSelectedMuxerRegion(newSelectedMuxerRegion);
        setNewMuxerValues((prevMuxerConfiguration: IMuxerConfiguration) => {
            return {
                ...prevMuxerConfiguration,
                region: newRegion,
                muxerVersion: newSelectedMuxerRegion.defaultVersion,
                availability: newSelectedMuxerRegion.defaultAvailabilityZone,
            };
        });
    };

    const handleSelectMuxerStreamingConfigId = (e: ChangeEvent<HTMLSelectElement>) => {
        setMuxerStreamingConfigId(parseInt(e.target.value, 10));
    };

    const setMuxerStreamingConfigId = (newStreamingConfigId: number) => {
        setNewMuxerValues((prevMuxerConfiguration: IMuxerConfiguration) => {
            return {
                ...prevMuxerConfiguration,
                streamingConfigId: newStreamingConfigId,
            };
        });
    };

    const setMuxerVersion = (e: ChangeEvent<HTMLSelectElement>) => {
        setNewMuxerValues((prevMuxerConfiguration: IMuxerConfiguration) => {
            return {
                ...prevMuxerConfiguration,
                muxerVersion: e.target.value,
            };
        });
    };

    const setMuxerAvailabilityZone = (e: ChangeEvent<HTMLSelectElement>) => {
        setNewMuxerValues((prevMuxerConfiguration: IMuxerConfiguration) => {
            return {
                ...prevMuxerConfiguration,
                availability: e.target.value,
            };
        });
    };

    const [tabType, setTabType] = React.useState<TabTypes>(TabTypes.STREAMING_CONFIGS);

    const handleTabClick = (selected: TabTypes) => () => {
        setTabType(selected);
    };

    const getActiveTabClassName = (tabToRender: TabTypes): string => joinStrings([tabToRender === tabType && 'active-tab']);

    const handleCancelMuxerActionConfirmation = () => {
        onCancelMuxerAction();
    };

    const handleSetChannelDgeEventId = () => {
        onSetChannelDgeEventId(dgeEventId);
    };

    const handleConfirmMuxerActionConfirmation = () => {
        onMuxerAction(lastMuxerAction.muxerId, lastMuxerAction.streamingConfigId, lastMuxerAction.action, true);
    };

    const stopMuxer = (muxerConfig: IChannelAdminStreamingConfigMuxer) => {
        onMuxerAction(muxerConfig.muxerId, muxerConfig.streamingConfigs[0].streamingConfigId, MuxerAction.STOP);
    };

    const terminateMuxer = (muxerConfig: IChannelAdminStreamingConfigMuxer) => {
        onMuxerAction(muxerConfig.muxerId, muxerConfig.streamingConfigs[0].streamingConfigId, MuxerAction.TERMINATE);
    };

    const makeMuxerLive = (muxerConfig: IChannelAdminStreamingConfigMuxer) => {
        onMuxerAction(muxerConfig.muxerId, muxerConfig.streamingConfigs[0].streamingConfigId, MuxerAction.GO_LIVE);
    };

    const checkMuxerStatus = (muxerConfig: IChannelAdminStreamingConfigMuxer) => {
        onCheckMuxerStatus(muxerConfig.muxerId);
    };

    const refreshMuxers = () => {
        onRefreshChannelAdminDetails();
    };

    const muxerOptions = (muxerConfig: IChannelAdminStreamingConfigMuxer) => {
        let menuOptions: ILinkMenuItem[] = [];

        switch (muxerConfig.status) {
            case 'LIVE':
                menuOptions = [{ title: 'STOP', callback: () => showStopMuxerConfirmation(muxerConfig) }];
                break;
            case 'READY':
            case 'STOPPED':
                menuOptions = [
                    { title: 'GO LIVE', callback: () => makeMuxerLive(muxerConfig) },
                    { title: 'TERMINATE', callback: () => terminateMuxer(muxerConfig) },
                ];
                break;
            case 'PROVISIONING':
                menuOptions = [
                    { title: 'CHECK STATUS', callback: () => checkMuxerStatus(muxerConfig) },
                    { title: 'TERMINATE', callback: () => terminateMuxer(muxerConfig) },
                ];
                break;
            case 'TERMINATED':
            default:
                return <></>;
        }

        return <LinkListDropdownMenu menuItems={menuOptions} className="channel-details__edit-menu" />;
    };

    const getModalContent = () => {
        if (!channelAdminDetails.muxers) {
            return (
                <Modal.Body className="channel-manager__channel-admin-view-modal__body">
                    <LoadingSpinner />
                </Modal.Body>
            );
        }

        if (channelAdminDetails && !channelAdminDetails.dgeEventId) {
            return (
                <>
                    <Modal.Body className="channel-manager__channel-admin-view-modal__body">
                        {isLoadingMuxers && <LoadingSpinner />}

                        <div className="form-field-row">
                            <label>DGE Event ID</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-field-row__input"
                                    value={dgeEventId}
                                    onChange={handleSetDgeEventId}
                                    onKeyDown={handleKeyDown}
                                />
                                <p className="form-field-row__helptext">This channel has no DGE event ID, please add one.</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="channel-manager__channel-admin-view-modal__footer">
                        <button
                            className="channel-manager__channel-admin-view-modal__action-btn"
                            type="button"
                            onClick={handleSetChannelDgeEventId}
                        >
                            Set DGE Event ID
                        </button>
                    </div>
                </>
            );
        }

        if (confirmStopMuxer) {
            return (
                <>
                    <Modal.Body className="channel-manager__channel-admin-view-modal__body">
                        <div className="channel-manager__channel-admin-view-modal__confirm-action">
                            <h3>Are you sure you want to stop Muxer {muxerToStop.muxerId}?</h3>
                        </div>
                    </Modal.Body>
                    <div className="channel-manager__channel-admin-view-modal__footer--center">
                        <button
                            className="channel-manager__channel-admin-view-modal__action-btn"
                            type="button"
                            onClick={handleCancelStopMuxer}
                        >
                            Cancel
                        </button>
                        <button className="channel-manager__channel-admin-view-modal__action-btn" type="button" onClick={handleStopMuxer}>
                            Stop Muxer
                        </button>
                    </div>
                </>
            );
        }

        if (muxerActionRequiresConfirmation) {
            return (
                <>
                    <Modal.Body className="channel-manager__channel-admin-view-modal__body">
                        <div className="channel-manager__channel-admin-view-modal__confirm-action">
                            <h3>
                                Action <span className="highlighted">{lastMuxerAction.action.replace(/_/g, ' ')}</span> could not be be
                                performed for <span className="highlighted">Muxer {lastMuxerAction.muxerId}</span>.
                            </h3>
                            <div>Please type the following name of this channel to perform this action anyway.</div>
                            <div>
                                <span className="highlighted">{props.channelTitle}</span>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="form-field-row__input"
                                    value={channelTitleConfirmation}
                                    onChange={handleSetChannelNameConfirmation}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="channel-manager__channel-admin-view-modal__footer--center">
                        <button
                            className="channel-manager__channel-admin-view-modal__action-btn"
                            type="button"
                            onClick={handleCancelMuxerActionConfirmation}
                        >
                            Cancel
                        </button>
                        <button
                            className="channel-manager__channel-admin-view-modal__action-btn"
                            type="button"
                            onClick={handleConfirmMuxerActionConfirmation}
                            disabled={channelTitleConfirmation !== props.channelTitle}
                        >
                            Force Action
                        </button>
                    </div>
                </>
            );
        }

        return (
            <>
                <ul className="content-page__tab-section">
                    <li>
                        <button
                            className={getActiveTabClassName(TabTypes.STREAMING_CONFIGS)}
                            onClick={handleTabClick(TabTypes.STREAMING_CONFIGS)}
                        >
                            Streaming Configs
                        </button>
                    </li>
                    <li>
                        <button className={getActiveTabClassName(TabTypes.MUXERS)} onClick={handleTabClick(TabTypes.MUXERS)}>
                            Muxers
                        </button>
                    </li>
                    <li>
                        <button
                            className={getActiveTabClassName(TabTypes.PROVISION_MUXER)}
                            onClick={handleTabClick(TabTypes.PROVISION_MUXER)}
                        >
                            Provision New Muxer
                        </button>
                    </li>
                </ul>

                {!!channelAdminDetails.muxers && tabType === TabTypes.STREAMING_CONFIGS && (
                    <>
                        <Modal.Body className="channel-manager__channel-admin-view-modal__body">
                            {isLoadingMuxers && <LoadingSpinner />}
                            <div className="form-field-row">DGE Event ID - {channelAdminDetails.dgeEventId}</div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>DRM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {channelAdminDetails.streamingConfigs.map((streamingConfig: IChannelAdminStreamingConfig) => (
                                        <tr key={streamingConfig.dgeStreamingConfigId}>
                                            <td>Stream {streamingConfig.dgeStreamingConfigId}</td>
                                            <td>{isDrmStream(streamingConfig) ? 'DRM' : 'Non DRM'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <div className="channel-manager__channel-admin-view-modal__footer">
                            <div className="form-field-row">
                                <button
                                    className="channel-manager__channel-admin-view-modal__action-btn"
                                    type="button"
                                    onClick={onSynchroniseChannelStreamingConfigs}
                                >
                                    Synchronise with DGE
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {!!channelAdminDetails.muxers && tabType === TabTypes.MUXERS && (
                    <Modal.Body className="channel-manager__channel-admin-view-modal__body">
                        {(isLoadingMuxers || isProvisioningMuxer) && <LoadingSpinner />}
                        <table>
                            <thead>
                                <tr>
                                    <th>Muxer ID</th>
                                    <th>Muxer Version</th>
                                    <th>AWS Region</th>
                                    <th>Muxer Status</th>
                                    <th>DGE Streaming Config ID</th>
                                    <th>Launch Date</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {channelAdminDetails.muxers.map((muxerConfig: IChannelAdminStreamingConfigMuxer) => (
                                    <tr key={muxerConfig.muxerId}>
                                        <td>{muxerConfig.muxerId}</td>
                                        <td>{muxerConfig.muxerVersion}</td>
                                        <td>{muxerConfig.region}</td>
                                        <td>
                                            <span className={`status-dot ${muxerConfig.status.toLowerCase()}`} />
                                            {muxerConfig.status}
                                        </td>
                                        <td>
                                            {muxerConfig.streamingConfigs.map((streamingConfig: IChannelAdminStreamingConfig) => (
                                                <div key={streamingConfig.dgeStreamingConfigId}>{streamingConfig.dgeStreamingConfigId}</div>
                                            ))}
                                        </td>
                                        <td>
                                            <time dateTime={muxerConfig.launchedAt}>
                                                {moment(muxerConfig.launchedAt).format('DD-MM-YYYY - hh:mm:ss')}
                                            </time>
                                        </td>
                                        <td>{muxerOptions(muxerConfig)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                )}

                {!!channelAdminDetails.muxers && tabType === TabTypes.PROVISION_MUXER && (
                    <>
                        <Modal.Body className="channel-manager__channel-admin-view-modal__body">
                            {isProvisioningMuxer && <LoadingSpinner />}
                            <div className="form-field-row">
                                <label>DGE Streaming Config</label>
                                <select
                                    className="form-field-row__input"
                                    value={newMuxerValue.streamingConfigId}
                                    onChange={handleSelectMuxerStreamingConfigId}
                                >
                                    {channelAdminDetails.streamingConfigs.map((streamingConfig: IChannelAdminStreamingConfig) => (
                                        <option key={streamingConfig.streamingConfigId} value={streamingConfig.streamingConfigId}>
                                            Stream {streamingConfig.dgeStreamingConfigId}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-field-row">
                                <label>Region</label>
                                <select className="form-field-row__input" value={newMuxerValue.region} onChange={handleSelectMuxerRegion}>
                                    {muxerVersions.regions.map((region: IMuxerRegion) => (
                                        <option key={region.region} value={region.region}>
                                            {region.region}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-field-row">
                                <label>Muxer Version</label>
                                <select
                                    className="form-field-row__input"
                                    value={newMuxerValue.muxerVersion}
                                    onChange={setMuxerVersion}
                                    disabled={!selectedMuxerRegion}
                                >
                                    {selectedMuxerRegion &&
                                        selectedMuxerRegion.versions.map((version: IMuxerVersion) => (
                                            <option key={version.muxerVersion} value={version.muxerVersion}>
                                                {version.muxerVersion} - {version.description}{' '}
                                                {version.muxerVersion === selectedMuxerRegion.defaultVersion && '(Recommended)'}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="form-field-row">
                                <label>Availability Zone</label>
                                <select
                                    className="form-field-row__input"
                                    value={newMuxerValue.availability}
                                    onChange={setMuxerAvailabilityZone}
                                    disabled={!selectedMuxerRegion}
                                >
                                    {selectedMuxerRegion &&
                                        selectedMuxerRegion.availabilityZones.map((availabilityZone: string) => (
                                            <option key={availabilityZone} value={availabilityZone}>
                                                {availabilityZone}{' '}
                                                {availabilityZone === selectedMuxerRegion.defaultAvailabilityZone && '(Recommended)'}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </Modal.Body>

                        <div className="channel-manager__channel-admin-view-modal__footer">
                            <div className="form-field-row">
                                <button
                                    className="channel-manager__channel-admin-view-modal__action-btn"
                                    type="button"
                                    onClick={handleProvisionMuxer}
                                    disabled={!selectedMuxerRegion}
                                >
                                    Provision Muxer
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    const $modal = (
        <Modal.Main hasOverlay={true} className="channel-manager__modal channel-manager__channel-admin-view-modal">
            <div className="channel-manager__channel-admin-view-modal__title">
                <img src={props.channelLogoUrl} className="channel-manager__channel-admin-view-modal__logo" />
                {props.channelTitle} - Admin Settings
                <button
                    className="channel-manager__channel-admin-view-modal__action-btn"
                    type="button"
                    onClick={refreshMuxers}
                    disabled={isLoadingMuxers}
                >
                    Refresh
                </button>
            </div>
            <button className="channel-manager__channel-admin-view-modal__close-btn" onClick={props.onCancel}>
                <TimesThinIcon />
            </button>
            {getModalContent()}
        </Modal.Main>
    );

    return ReactDOM.createPortal($modal, document.getElementById('modal'));
};
