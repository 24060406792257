import * as React from 'react';

import { ThumbnailContent } from '~components/Editor/ThumbnailRail/ThumbnailContent';
import { PhotoVideoIcon } from '~components/Icons/PhotoVideoIcon';
import Modal from '~components/Modal';
import { joinStrings } from '~services/utilities';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';

import './index.scss';

interface IThumbnailMarkerProps {
    renderPosition: ITimelineThumbnail['renderThumbnailTime'];
    imageData: ITimelineThumbnail['imageData'];
    isActive: boolean;
    timelineWidth: number;
}

const THUMB_MODAL_SAFE_ZONE = 90;
const TIMELINE_OFFSET = 60;

const ThumbnailMarker: React.NamedExoticComponent<IThumbnailMarkerProps> = React.memo((props) => {
    const { renderPosition, imageData, isActive, timelineWidth } = props;

    const markerStyle = {
        left: `${renderPosition}%`,
    };

    const innerWidthAdjusted = timelineWidth - TIMELINE_OFFSET;
    const pixelPosition = innerWidthAdjusted * (renderPosition / 100);

    let left = '50%';

    if (pixelPosition < THUMB_MODAL_SAFE_ZONE) {
        left = `${THUMB_MODAL_SAFE_ZONE - pixelPosition}px`;
    } else if (pixelPosition > innerWidthAdjusted - THUMB_MODAL_SAFE_ZONE) {
        left = `${innerWidthAdjusted - THUMB_MODAL_SAFE_ZONE - pixelPosition}px`;
    }

    const modalStyles = {
        left,
    };

    const className = joinStrings(['thumbnail__marker', isActive && 'thumbnail__marker--active']);

    return (
        <div className={className} style={markerStyle}>
            <div className="thumbnail__icon">
                <PhotoVideoIcon fill="#ffffff" width="14" height="14" />
            </div>
            <div className="thumbnail__modal-wrapper" style={modalStyles}>
                <Modal.Main hasOverlay={false} className="thumbnail__modal">
                    <Modal.Body>
                        <ThumbnailContent imageData={imageData} />
                    </Modal.Body>
                </Modal.Main>
            </div>
        </div>
    );
});

export { ThumbnailMarker };
