import { ListItems } from '~components/ChannelManager/PodScroller/constants';
import { Seconds } from '~src/types';

export interface IChannelDetails {
    channelId: number;
    title: string;
    currentlyLiveContent?: {
        title: string;
        duration: Seconds;
    };
    timeRemaining: number;
    queuedContentPods: number;
    channelStartTime: string;
    isLive: boolean;
    channelLogoUrl: string;
    targetAdvertDuration: Seconds;
    enableBackupStream?: boolean;
    dgeEventId: number;
    timelineVersion: number;
    targetAdvertPercentage: number;
    presetIds: unknown[];
    contentPodInterval: number;
    logo?: {
        fileId: number;
        url: string;
    };
}

export interface IChannelAdminStreamingConfigPublishingPoint {
    destinationHost: string;
    streamId: string;
    adaptiveGroup: string;
    streamName: string;
    renditionFolderPrefix: string; // deprecated
    videoRenditionFolderTemplate: string;
    audioRenditionFolderTemplate: string;
}

export interface IChannelAdminStreamingConfig {
    streamingConfigId: number;
    dgeStreamingConfigId: number;
    protocol: 'HLS' | 'DASH' | 'HLS_DRM' | 'DASH_DRM';
    primaryPublishingPoint: IChannelAdminStreamingConfigPublishingPoint;
    backupPublishingPoint: IChannelAdminStreamingConfigPublishingPoint;
}

export interface IChannelAdminStreamingConfigMuxer {
    muxerId: number;
    region: string;
    availabilityZone: string;
    ipAddress: string;
    muxerVersion: string;
    status: 'PROVISIONING' | 'READY' | 'LIVE' | 'TERMINATED' | 'STOPPED';
    streamingConfigs: IChannelAdminStreamingConfig[];
    launchedAt: string;
}

export interface IMuxerActionResponse {
    message: string;
}

export interface IDeleteChannelResponse {
    message: string;
}

export enum MuxerAction {
    GO_LIVE = 'GO_LIVE',
    STOP = 'STOP',
    TERMINATE = 'TERMINATE',
}

export interface IChannelAdminDetails {
    dgeEventId: number;
    streamingConfigs: IChannelAdminStreamingConfig[];
    muxers: IChannelAdminStreamingConfigMuxer[];
}

export interface IMuxerVersion {
    muxerVersion: string;
    description: string;
}

export interface IMuxerRegion {
    region: string;
    defaultVersion: string;
    defaultAvailabilityZone: string;
    availabilityZones: string[];
    versions: IMuxerVersion[];
}

export interface IMuxerVersions {
    regions: IMuxerRegion[];
}

export interface ISynchroniseChannelStreamingConfigs {
    streamingConfigs: IChannelAdminStreamingConfig[];
}

export interface ICuePoint {
    cuePoint: Seconds;
}

export interface IAdBreak extends ICuePoint {
    duration: Seconds;
}

export interface IContentPodBase {
    adBreaks: IAdBreak[];
    duration: Seconds;
}

export interface IContentPodBaseWithContent extends IContentPodBase {
    content: IVllContent;
}

export interface IRawContentPod extends IContentPodBaseWithContent {
    contentPodId: number;
    podNumber: number;
    editable: boolean;
    startTime: string;
}

export interface IContentPod extends IContentPodBaseWithContent {
    kind: ListItems.CONTENT_POD;
    contentPodId?: number;
    uuid: string;
    isLive: boolean;
    isFirstOfDay: boolean;
    isLastOfDay: boolean;
    isFirstEditable: boolean;
    isLastEditable: boolean;
    isNew: boolean;
    isEdited: boolean;
    isRemoved: boolean;
    isEditable: boolean;
    hasMoved: boolean;
    originalPodNumber: number;
    podNumber: number;
    startTime: string;
}

export interface IVllContentBase {
    dveVideoId: number;
    title: string;
    description: string;
    thumbnailUrl: string;
    duration: Seconds;
    externalUrl?: string;
    playlists?: IPlaylist[];
    seasonNumber?: number;
    episodeNumber?: number;
}

export interface IVllContent extends IVllContentBase {
    adBreaks: ICuePoint[];
}

export interface IPlaylist {
    playlistId: number;
    createdAt: string;
    description: string;
    title: string;
}

export interface IPublishContentPod extends IContentPodBase {
    startTime: string;
    contentPodId?: number;
    dveVideoId?: number;
}

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

interface IDefaultQueryParams {
    page?: number;
    size?: number;
    sortField?: string;
    sortOrder?: SortOrder;
}

export interface ITitleSearchQueryParams extends IDefaultQueryParams {
    title?: string;
}

export interface IGetChannelsPayload extends IDefaultQueryParams {
    isLive: boolean;
}

export interface IGetVideosPayload extends ITitleSearchQueryParams {}

export interface IGetResponseBase<T> {
    results: T[];
    total: number;
}

export interface IPublishChangesPayload {
    channelId: number;
    timelineVersion: number;
    firstContentPodNumber: number;
    contentPods: IPublishContentPod[];
}

export interface IPermanentFileResponse {
    permFileId: number;
    permFileUrl: string;
}
