import { toWords } from 'number-to-words';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { CopyPasteIcon } from '~components/Icons/CopyPasteIcon';
import { MultipleMoveIcon } from '~components/Icons/MultipleMoveIcon';
import { TrashBoldIcon } from '~components/Icons/TrashBoldIcon';
import LoadingSpinner, { LoadingSpinnerSize } from '~components/LoadingSpinner';
import { useControls } from '~containers/ChannelManagerContainer/ChannelManagerContext';
import { IChannelManagerContext } from '~containers/ChannelManagerContainer/types';
import { convertArrayToObject, joinStrings } from '~services/utilities';
import {
    getBatchActionState,
    getChannelDetailsState,
    getContentPodsState,
    getPendingPublishPodNumbersState,
    getPublishingState,
    getShowConfirmationOverwriteModalState,
} from '~src/store/channelManager/channelManager.selectors';
import { BatchActionMode } from '~src/store/channelManager/constants';
import { IBatchAction, IEditableRange } from '~src/store/channelManager/types';

import { ConfirmModal } from './ConfirmModal';
import './index.scss';

enum ConfirmModalClassNames {
    BODY = 'channel-manager__modal channel-manager__confirm-modal',
    CONFIRM = 'channel-manager__confirm-modal__confirm-btn',
    CLOSE = 'channel-manager__confirm-modal__close-btn',
}

interface IProps {
    showConfirmationOverwriteModal: boolean;
    isPublishing: boolean;
    batchActionMode: IBatchAction['mode'];
    batchActionSelectedPodUuids: IBatchAction['selectedPodUuids'];
    batchActionselectedPodNumbers: number[];
    pendingPublishPodNumbers: number[];
    lastEditablePodNumber: IEditableRange['last'];
    onPublish: IChannelManagerContext['onPublish'];
    onDiscard: IChannelManagerContext['onDiscard'];
    onConfirmOverwrite: IChannelManagerContext['onConfirmOverwrite'];
    onCancelOverwrite: IChannelManagerContext['onCancelOverwrite'];
    onCopyPasteBatchActionConfirmed: IChannelManagerContext['onCopyPasteBatchActionConfirmed'];
    onMultipleMoveBatchActionConfirmed: IChannelManagerContext['onMultipleMoveBatchActionConfirmed'];
    onMultipleDeleteBatchActionConfirmed: IChannelManagerContext['onMultipleDeleteBatchActionConfirmed'];
    onDeactivateBatchActionMode: IChannelManagerContext['onDeactivateBatchActionMode'];
}

const ControlsSectionComponent: React.FC<IProps> = (props) => {
    const [showDiscardConfirm, setShowDiscardConfirm] = React.useState<boolean>(false);
    const submitBtnRef = React.useRef<HTMLButtonElement>(null);
    const countOfPendingPublishPodNumbers = props.pendingPublishPodNumbers.length;
    const countOfBatchActionSelectedPodUuids = props.batchActionSelectedPodUuids.length;
    const pendingPublishPodNumbersPluralAddon = countOfPendingPublishPodNumbers > 1 ? 's' : '';
    const countOfBatchActionSelectedPodsPluralAddon = countOfBatchActionSelectedPodUuids > 1 ? 's' : '';

    const isInBatchActionMode = !!props.batchActionMode;
    const isInCopyPasteMode = props.batchActionMode === BatchActionMode.COPY_PASTE;
    const isInMultipleMoveMode = props.batchActionMode === BatchActionMode.MULTIPLE_MOVE;
    const isInMultipleDeleteMode = props.batchActionMode === BatchActionMode.MULTIPLE_DELETE;

    React.useEffect(() => {
        if (!props.isPublishing) {
            submitBtnRef.current?.removeAttribute('disabled');
        }
    }, [props.isPublishing]);

    const handleDiscardModalAnswer = (hasConfirmed: boolean) => () => {
        setShowDiscardConfirm(false);

        if (hasConfirmed) {
            props.onDiscard();
        }
    };

    const handlePublishDiscard = () => {
        setShowDiscardConfirm(true);
    };

    const handleConfirmOverwriteModalAnswer = (hasConfirmed: boolean) => () => {
        if (hasConfirmed) {
            props.onConfirmOverwrite();
        } else {
            props.onCancelOverwrite();
        }
    };

    const handlePublish = () => {
        submitBtnRef.current?.setAttribute('disabled', 'disabled');
        props.onPublish();
    };

    const handleCopyPastePods = (position: number) => () => {
        props.onCopyPasteBatchActionConfirmed(position);
    };

    const handleMultipleMovePods = (position: number) => () => {
        props.onMultipleMoveBatchActionConfirmed(position);
    };

    const getPublishMessage = () => {
        return props.isPublishing ? (
            <React.Fragment>
                Publishing Changes
                <LoadingSpinner size={LoadingSpinnerSize.SMALL} />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <span className="pending-action__number-to-word">{toWords(countOfPendingPublishPodNumbers)}</span> unsaved change
                {pendingPublishPodNumbersPluralAddon} - Item{pendingPublishPodNumbersPluralAddon}
                <span className="pending-action__updated-items">{props.pendingPublishPodNumbers.join(', ')}</span>
            </React.Fragment>
        );
    };

    const endListener = React.useCallback(
        (node: HTMLElement, done: VoidFunction) => node.addEventListener('transitionend', done, false),
        []
    );

    const getTransitionKey = (): string => {
        if (countOfBatchActionSelectedPodUuids) {
            return 'Pending batch action changes';
        } else if (!isInBatchActionMode && countOfPendingPublishPodNumbers) {
            return 'Pending publish changes';
        }
        return null;
    };

    const getModeIconAndText = (): JSX.Element => {
        switch (props.batchActionMode) {
            case BatchActionMode.COPY_PASTE:
                return (
                    <>
                        <CopyPasteIcon />
                        COPY AND PASTE MODE
                    </>
                );
            case BatchActionMode.MULTIPLE_MOVE:
                return (
                    <>
                        <MultipleMoveIcon />
                        MOVE MODE
                    </>
                );
            case BatchActionMode.MULTIPLE_DELETE:
                return (
                    <>
                        <TrashBoldIcon />
                        DELETE MODE
                    </>
                );
        }
    };

    return (
        <section className="controls-section">
            {showDiscardConfirm && (
                <ConfirmModal
                    className={ConfirmModalClassNames.BODY}
                    message="Are you sure you want to discard your changes?"
                    confirmButtonTitle="Yes, Discard them"
                    confirmButtonClassName={ConfirmModalClassNames.CONFIRM}
                    closeButtonClassName={ConfirmModalClassNames.CLOSE}
                    onCancel={handleDiscardModalAnswer(false)}
                    onConfirm={handleDiscardModalAnswer(true)}
                />
            )}
            {props.showConfirmationOverwriteModal && (
                <ConfirmModal
                    className={ConfirmModalClassNames.BODY}
                    message="A newer version of the timeline exists, do you want to overwrite the latest version with your changes?"
                    confirmButtonTitle="Yes"
                    confirmButtonClassName={ConfirmModalClassNames.CONFIRM}
                    closeButtonClassName={ConfirmModalClassNames.CLOSE}
                    onCancel={handleConfirmOverwriteModalAnswer(false)}
                    onConfirm={handleConfirmOverwriteModalAnswer(true)}
                />
            )}
            <div className="controls-section__mode-section">
                <CSSTransition in={isInBatchActionMode} timeout={500} classNames="copy-paste--transition" unmountOnExit={true}>
                    <div className="controls-section__copy-paste-text">{getModeIconAndText()}</div>
                </CSSTransition>
            </div>

            <SwitchTransition>
                <CSSTransition addEndListener={endListener} classNames="pending-action--transition" key={getTransitionKey()}>
                    <div>
                        {!!countOfPendingPublishPodNumbers && !isInBatchActionMode && (
                            <PendingAction
                                primaryButtonTitle="Publish"
                                secondaryButtonTitle="Discard"
                                primaryButtonRef={submitBtnRef}
                                shouldDisableSubmitButton={props.isPublishing}
                                onPrimaryClick={handlePublish}
                                onSecondaryClick={handlePublishDiscard}
                            >
                                {getPublishMessage()}
                            </PendingAction>
                        )}
                        {!!countOfBatchActionSelectedPodUuids && isInMultipleDeleteMode && (
                            <PendingAction
                                primaryButtonTitle="Remove"
                                primaryButtonClassName="pending-action__button--remove"
                                secondaryButtonTitle="Cancel"
                                primaryButtonRef={submitBtnRef}
                                onPrimaryClick={props.onMultipleDeleteBatchActionConfirmed}
                                onSecondaryClick={props.onDeactivateBatchActionMode}
                            >
                                Remove item{countOfBatchActionSelectedPodsPluralAddon}
                                <span className="pending-action__updated-items">{props.batchActionselectedPodNumbers.join(', ')}</span>
                                &nbsp;from the timeline
                            </PendingAction>
                        )}
                        {!!countOfBatchActionSelectedPodUuids && isInCopyPasteMode && (
                            <PendingAction
                                primaryButtonTitle="Paste to end"
                                secondaryButtonTitle="Done"
                                primaryButtonRef={submitBtnRef}
                                onPrimaryClick={handleCopyPastePods(props.lastEditablePodNumber)}
                                onSecondaryClick={props.onDeactivateBatchActionMode}
                            >
                                Copy item{countOfBatchActionSelectedPodsPluralAddon}
                                <span className="pending-action__updated-items">{props.batchActionselectedPodNumbers.join(', ')}</span>
                                &nbsp;to the end of the timeline
                            </PendingAction>
                        )}
                        {!!countOfBatchActionSelectedPodUuids && isInMultipleMoveMode && (
                            <PendingAction
                                primaryButtonTitle="Move to end"
                                secondaryButtonTitle="Cancel"
                                primaryButtonRef={submitBtnRef}
                                onPrimaryClick={handleMultipleMovePods(props.lastEditablePodNumber)}
                                onSecondaryClick={props.onDeactivateBatchActionMode}
                            >
                                Move item{countOfBatchActionSelectedPodsPluralAddon}
                                <span className="pending-action__updated-items">{props.batchActionselectedPodNumbers.join(', ')}</span>
                                &nbsp;to the end of the timeline
                            </PendingAction>
                        )}
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </section>
    );
};

const PendingAction: React.FC<{
    onPrimaryClick: (...args: any[]) => void;
    onSecondaryClick: VoidFunction;
    primaryButtonTitle: string;
    primaryButtonClassName?: string;
    primaryButtonRef: React.RefObject<HTMLButtonElement>;
    secondaryButtonTitle: string;
    secondaryButtonClassName?: string;
    shouldDisableSubmitButton?: boolean;
}> = (props) => {
    return (
        <div className="controls-section__pending-action-section">
            <div className="pending-action">{props.children}</div>
            <button
                className={joinStrings(['pending-action__button pending-action__button--submit', props.primaryButtonClassName])}
                onClick={props.onPrimaryClick}
            >
                {props.primaryButtonTitle}
            </button>
            <button
                className={joinStrings(['pending-action__button pending-action__button--discard', props.secondaryButtonClassName])}
                onClick={props.onSecondaryClick}
                disabled={props.shouldDisableSubmitButton}
            >
                {props.secondaryButtonTitle}
            </button>
        </div>
    );
};

export const ControlsSection: React.FC = () => {
    const {
        onPublish,
        onDiscard,
        onConfirmOverwrite,
        onCancelOverwrite,
        onDeactivateBatchActionMode,
        onCopyPasteBatchActionConfirmed,
        onMultipleMoveBatchActionConfirmed,
        onMultipleDeleteBatchActionConfirmed,
    } = useControls();
    const contentPods = useSelector(getContentPodsState);
    const pendingPublishPodNumbers = useSelector(getPendingPublishPodNumbersState);
    const { editableRange } = useSelector(getChannelDetailsState);
    const showConfirmationOverwriteModal = useSelector(getShowConfirmationOverwriteModalState);
    const isPublishing = useSelector(getPublishingState);
    const { mode, selectedPodUuids } = useSelector(getBatchActionState);

    const selectedPodNumbers = React.useMemo(() => {
        const contentPodsByUuid = convertArrayToObject(contentPods, 'uuid');
        return selectedPodUuids.map((uuid) => contentPodsByUuid[uuid].podNumber);
    }, [contentPods, selectedPodUuids]);

    return (
        <ControlsSectionComponent
            batchActionMode={mode}
            batchActionSelectedPodUuids={selectedPodUuids}
            batchActionselectedPodNumbers={selectedPodNumbers}
            pendingPublishPodNumbers={pendingPublishPodNumbers}
            isPublishing={isPublishing}
            showConfirmationOverwriteModal={showConfirmationOverwriteModal}
            lastEditablePodNumber={editableRange.last}
            onPublish={onPublish}
            onDiscard={onDiscard}
            onConfirmOverwrite={onConfirmOverwrite}
            onCancelOverwrite={onCancelOverwrite}
            onDeactivateBatchActionMode={onDeactivateBatchActionMode}
            onCopyPasteBatchActionConfirmed={onCopyPasteBatchActionConfirmed}
            onMultipleMoveBatchActionConfirmed={onMultipleMoveBatchActionConfirmed}
            onMultipleDeleteBatchActionConfirmed={onMultipleDeleteBatchActionConfirmed}
        />
    );
};
