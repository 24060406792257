import { GetLoginRequestPayload } from './types';

export const getLoginRequestPayload: GetLoginRequestPayload = (loginFormState, mfaChallenge, mfaAnswer) => {
    const loginPayload = {
        id: loginFormState.emailAddress,
        secret: loginFormState.password,
    };

    if (!mfaAnswer && !mfaChallenge) {
        return loginPayload;
    }

    return {
        ...loginPayload,
        mfa: {
            id: mfaChallenge.id,
            secrets: [
                {
                    type: mfaChallenge.types[0].type,
                    value: mfaAnswer.pin,
                },
            ],
        },
    };
};
