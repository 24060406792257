import * as React from 'react';

import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';

interface IProps {
    id: ITimelineThumbnail['id'];
    imageData: ITimelineThumbnail['imageData'];
    selectThumbnail: (id: ITimelineThumbnail['id'], imageData: ITimelineThumbnail['imageData']) => void;
}

const ThumbnailItemComponent: React.FunctionComponent<IProps> = ({ id, imageData, selectThumbnail }) => {
    const onClickHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        selectThumbnail(id, imageData);
    };

    return (
        <div className="thumbnail-item" onClick={onClickHandler}>
            <div className="thumbnail-item__thumbnail">
                <img src={imageData} alt="Thumbnail" />
            </div>
        </div>
    );
};

const ThumbnailItem: React.NamedExoticComponent<IProps> = React.memo(ThumbnailItemComponent);

export { ThumbnailItem };
