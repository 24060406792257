import * as React from 'react';

import { FilmIcon } from '~components/Icons/FilmIcon';
import { SmartAnchor } from '~components/SmartAnchor';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { IDataTestId } from '~src/views/types';

interface IProps {
    onClick: (event: React.MouseEvent) => void;
    tooltipText: string;
}

const CardPreviewLink: React.FunctionComponent<IProps & IDataTestId> = ({ onClick, tooltipText, dataTestId }) => {
    return (
        <SmartAnchor onClick={onClick} dataTestId={dataTestId}>
            <Tooltip message={tooltipText} position={TooltipPositions.TOP}>
                <FilmIcon />
            </Tooltip>
        </SmartAnchor>
    );
};

export { CardPreviewLink };
