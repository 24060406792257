import * as React from 'react';

import { InlineNotification } from '~components/InlineNotification';
import LoadingSpinner from '~components/LoadingSpinner';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { IDataTestId } from '~src/views/types';

import './index.scss';

interface IGridProps extends IDataTestId {}

const Grid: React.FunctionComponent<IGridProps> = ({ children, dataTestId }) => {
    return (
        <div className="card-grid" data-test-id={dataTestId}>
            {children}
        </div>
    );
};

interface ITitleProps extends IDataTestId {}

const Title: React.FunctionComponent<ITitleProps> = ({ children, dataTestId }) => {
    return (
        <div className="card-grid__title" data-test-id={dataTestId}>
            {children}
        </div>
    );
};

interface IResultsProps extends IDataTestId {
    type: EAssetType;
}

const Results: React.FunctionComponent<IResultsProps> = ({ children, type }) => {
    return <div className={`card-grid__cards card-type__${type.toLowerCase()}`}>{children}</div>;
};

const Message: React.FunctionComponent<{ title?: string; message?: string }> = ({ title, message }) => (
    <div className="card-grid__message">
        <InlineNotification notificationTitle={title} message={message} />
    </div>
);

const Loading: React.FunctionComponent = () => (
    <div className="card-grid__loading">
        <LoadingSpinner />
    </div>
);

export const CardGrid = {
    Grid,
    Title,
    Results,
    Message,
    Loading,
};
