import debounce from 'lodash.debounce';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortStart } from 'react-sortable-hoc';
import { CSSTransition } from 'react-transition-group';

import { RegularSearchIcon } from '~components/Icons/RegularSearchIcon';
import { LoadingSpinner } from '~components/LoadingSpinner';
import { getListOfVideos } from '~services/channels';
import { ApiFetchStates } from '~services/channels/constants';
import { IVllContentBase } from '~services/channels/types';
import {
    multiplePodAdded,
    contentSelectedToPreview,
    contentSelectedToAddToList,
    removeSelectedContentListItem,
    sortSelectedContentListItem,
} from '~src/store/channelManager/channelManager.actions';
import { getChannelDetailsState, getPodManagementState } from '~src/store/channelManager/channelManager.selectors';
import { isCurrentStepContentSearch } from '~src/store/channelManager/channelManager.utilities';
import { ContentByIdMap, IContentSelectorStep } from '~src/store/channelManager/types';
import useDebounce from '~src/views/hooks/useDebounce';
import useFirstMount from '~src/views/hooks/useFirstMount';

import { IRefWithOnScroll } from '../PodManagementModal';
import { SearchResult } from './SearchResult';
import { SortableSelectedContentList } from './SelectedContentList';
import './index.scss';

const SEARCH_CHANGE_DEBOUNCE = 700;
const SEARCH_SCROLL_DEBOUNCE = 100;
const SEARCH_PAGE_SIZE = 20;
const FIRST_PAGE = 1;
const LOADER_BUFFER = 250;

const isInfiniteLoaderInView = (element: HTMLElement): boolean => {
    const rect = element.getBoundingClientRect();
    return rect.bottom <= LOADER_BUFFER + window.innerHeight;
};

export const ContentSearch = React.forwardRef<IRefWithOnScroll>((props, ref) => {
    const { currentStep, contentByIdMap } = useSelector(getPodManagementState);
    const { editableRange } = useSelector(getChannelDetailsState);
    const { channelId } = useSelector(getChannelDetailsState);

    if (isCurrentStepContentSearch(currentStep)) {
        return (
            <ContentSearchComponent
                {...currentStep}
                channelId={channelId}
                contentByIdMap={contentByIdMap}
                lastEditablePodNumber={editableRange.last}
                ref={ref}
            />
        );
    }

    return null;
});

interface IProps extends IContentSelectorStep {
    channelId: number;
    lastEditablePodNumber: number;
    contentByIdMap: ContentByIdMap;
}

export const ContentSearchComponent = React.forwardRef<IRefWithOnScroll, IProps>((props, ref) => {
    const {
        searchQuery: searchQueryFromStore,
        searchResults: searchResultsFromStore,
        selectedContentList,
        channelId,
        contentByIdMap,
        lastEditablePodNumber,
    } = props;

    const abortController = React.useRef<AbortController>(null);
    const sortableSelectedContentListRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const infiniteLoaderRef = React.useRef<HTMLDivElement>(null);
    const totalPages = React.useRef<number>(0);
    const totalResults = React.useRef<number>(0);
    const [currentPage, setCurrentPage] = React.useState<number>(FIRST_PAGE);
    const [searchQuery, setSearchQuery] = React.useState<string>(searchQueryFromStore);
    const [searchResults, setSearchResults] = React.useState<IVllContentBase[]>(searchResultsFromStore);
    const [contentState, setContentState] = React.useState<ApiFetchStates>(() =>
        props.onBack ? ApiFetchStates.LOADED : ApiFetchStates.LOADING
    );

    const dispatch = useDispatch();
    const isFirstMount = useFirstMount();

    const handleOnScroll = React.useCallback(() => {
        if (infiniteLoaderRef.current) {
            const isVisible = isInfiniteLoaderInView(infiniteLoaderRef.current);

            if (isVisible && contentState !== ApiFetchStates.LOADING) {
                setCurrentPage((prevPage) => ++prevPage);
            }
        }
    }, [contentState]);

    const debouncedHandleOnScroll = debounce(handleOnScroll, SEARCH_SCROLL_DEBOUNCE);
    const debouncedSearchQuery = useDebounce(searchQuery, SEARCH_CHANGE_DEBOUNCE);

    React.useImperativeHandle(
        ref,
        () => ({
            // Adapter to make modal component scroll
            onScroll: debouncedHandleOnScroll,
        }),
        [debouncedHandleOnScroll]
    );

    React.useEffect(() => {
        if (isFirstMount && props.onBack) {
            return;
        }

        const getSearchResults = async (newSearchQuery: string, page: number) => {
            try {
                abortController.current = new AbortController();
                const { results, total } = await getListOfVideos(
                    channelId,
                    {
                        title: newSearchQuery,
                        page,
                        size: SEARCH_PAGE_SIZE,
                    },
                    abortController.current.signal
                );

                totalPages.current = Math.ceil(total / SEARCH_PAGE_SIZE);
                totalResults.current = total;

                if (results.length) {
                    setSearchResults((prevResults) => {
                        return page === FIRST_PAGE ? results : [...prevResults, ...results];
                    });
                    setContentState(ApiFetchStates.LOADED);
                    return;
                }

                setContentState(ApiFetchStates.NO_CONTENT);
            } catch (e) {
                console.error(`Search error ${e}`);
            }
        };

        getSearchResults(debouncedSearchQuery, currentPage);
        // Don't need to watch isFirstMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery, currentPage, props.onBack]);

    React.useEffect(handleLoopFocus, []);

    function handleLoopFocus() {
        inputRef.current.focus();
    }

    const handleOnSearchChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        // abort currently running fetch request
        abortController.current?.abort();
        setContentState(ApiFetchStates.LOADING);
        setSearchResults([]);
        setCurrentPage(FIRST_PAGE);
        setSearchQuery(value);
    };

    const handleOnMultipleContentSelected = () => {
        dispatch(multiplePodAdded());
    };

    const handleOnSelectToAdd = (content: IVllContentBase) => () => {
        dispatch(contentSelectedToAddToList(content));
    };

    const handleOnSelectToPreview = (content: IVllContentBase) => () => {
        dispatch(contentSelectedToPreview({ content, searchResults, searchQuery }));
    };

    const handleOnSortEndSelectedContentListItem = ({ oldIndex, newIndex }) => {
        dispatch(sortSelectedContentListItem(oldIndex, newIndex));
    };

    const handleOnRemoveSelectedContentListItem = (position: number) => {
        dispatch(removeSelectedContentListItem(position));
    };

    const showInfiniteLoader = () => {
        const hasMorePages = totalPages.current !== 0 && totalPages.current > currentPage;
        return hasMorePages;
    };

    // Fixes issue with text wrapping when dragged
    const setSortableElementNodeWidthCorrectly = ({ node }: SortStart) => node.getBoundingClientRect();

    const hasSearchQuery = !!searchQuery;

    return (
        <div className="content-search" onScroll={handleOnScroll}>
            <div className="content-search__input-wrapper">
                <RegularSearchIcon />
                <input type="text" value={searchQuery} onChange={handleOnSearchChange} ref={inputRef} tabIndex={1} />
                {!!totalResults.current && (
                    <span className="content-search__results-text">
                        {totalResults.current} Result{totalResults.current > 1 && 's'}
                    </span>
                )}
            </div>
            {contentState === ApiFetchStates.LOADING && (
                <section className="content-page__no-content text-center">
                    <LoadingSpinner />
                    Loading Videos
                </section>
            )}
            {contentState === ApiFetchStates.NO_CONTENT && hasSearchQuery && (
                <div className="content-page__no-content text-center">No content found</div>
            )}
            {!!searchResults.length && (
                <div className="content-search__results-wrapper">
                    {searchResults.map((content) => (
                        <SearchResult
                            key={content.dveVideoId}
                            content={content}
                            onSelect={handleOnSelectToAdd(content)}
                            onPreview={handleOnSelectToPreview(content)}
                        />
                    ))}
                    {/* Loop focus back to top */}
                    <div tabIndex={1} onFocus={handleLoopFocus} />
                </div>
            )}
            {showInfiniteLoader() && <div ref={infiniteLoaderRef} />}
            <CSSTransition
                in={!!selectedContentList.length}
                timeout={500}
                classNames="content-search__selected-content-list"
                unmountOnExit={true}
            >
                <SortableSelectedContentList
                    axis="xy"
                    distance={1}
                    getHelperDimensions={setSortableElementNodeWidthCorrectly}
                    helperContainer={sortableSelectedContentListRef.current}
                    containerRef={sortableSelectedContentListRef}
                    list={selectedContentList}
                    contentByIdMap={contentByIdMap}
                    lastEditablePodNumber={lastEditablePodNumber}
                    lockToContainerEdges={true}
                    onSortEnd={handleOnSortEndSelectedContentListItem}
                    onAddSelectedContentListToTimeline={handleOnMultipleContentSelected}
                    onRemoveSelectedContentListItem={handleOnRemoveSelectedContentListItem}
                />
            </CSSTransition>
        </div>
    );
});
