import { ITimelineClip } from '~src/store/timeline/clip/types';
import { ITimelineThumbnailState } from '~src/store/timeline/thumbnail/types';
import { IStudioState } from '~src/store/types';

const getClipIdPendingThumbnail = (state: IStudioState): ITimelineClip['id'] => {
    return state.timeline.thumbnails.clipIdPendingThumbnail;
};

const getThumbnailState = (state: IStudioState): ITimelineThumbnailState => {
    return state.timeline.thumbnails;
};

const getThumbnails = (state: IStudioState): ITimelineThumbnailState['list'] => {
    return state.timeline.thumbnails.list;
};

export { getThumbnailState, getClipIdPendingThumbnail, getThumbnails };
