import { API_KEY, AUTH_TOKEN_KEY, REALM_KEY, REFRESH_TOKEN_KEY } from '@dicetechnology/dice-unity/lib/services/Http/constants';
import { IDiceFetch, IHttpResponse } from '@dicetechnology/dice-unity/src/services/Http/types';

import { BASE_HEADERS } from '~services/constants';
import { authHttp } from '~services/http';
import { USER_REALM_KEY } from '~services/http/constants';
import { ProfileService } from '~services/profile';
import { storageProvider } from '~services/storage';
import { Realm, Role } from '~src/types';

import { AuthEndpoints as Endpoints } from './enums';
import {
    AuthenticationServiceReturn,
    DecodeToken,
    ForgotPassword,
    GetRealm,
    GetRole,
    JwtPayload,
    Login,
    LoginResponse,
    Logout,
    SetPassword,
    SwitchRealm,
    ValidatateAppStorage,
} from './types';
import { isResetPasswordResponse } from './utils/is-reset-password-response';

const AuthenticationService = (): AuthenticationServiceReturn => {
    let cachedRealm: Realm = null;

    const login: Login = async (payload, realm) => {
        const options = {
            headers: {
                ...BASE_HEADERS,
                Realm: realm,
            },
            useAuth: false,
        };

        const { parsedData } = await authHttp.post<IHttpResponse<LoginResponse>>(Endpoints.LOGIN, payload, options);

        if (isResetPasswordResponse(parsedData)) {
            return parsedData;
        }

        const { authorisationToken, refreshToken } = parsedData;

        const fetchOptions: IDiceFetch = {
            headers: {
                ...BASE_HEADERS,
                Authorization: `Bearer ${authorisationToken}`,
                Realm: realm,
            },
            useAuth: false,
        };

        const currentUser = await ProfileService.getCurrentUser(fetchOptions);

        await storageProvider.set(API_KEY, '0d3754d4-138e-4907-96a3-e7914f1c5bb7');
        await storageProvider.set(AUTH_TOKEN_KEY, authorisationToken);
        await storageProvider.set(REFRESH_TOKEN_KEY, refreshToken);
        await storageProvider.set(REALM_KEY, realm);
        await storageProvider.set(USER_REALM_KEY, realm);

        cachedRealm = realm;

        return { currentUser };
    };

    const validatateAppStorage: ValidatateAppStorage = async () => {
        const userRealm = await storageProvider.get(USER_REALM_KEY);
        const realm = await storageProvider.get(REALM_KEY);

        if (realm && !userRealm) {
            logout();
        }

        if (!cachedRealm) {
            cachedRealm = realm;
        }
    };

    const getRealm: GetRealm = async () => {
        if (!cachedRealm) {
            cachedRealm = await storageProvider.get(REALM_KEY);
        }

        return cachedRealm;
    };

    const logout: Logout = async () => {
        await storageProvider.remove(AUTH_TOKEN_KEY);
        await storageProvider.remove(REFRESH_TOKEN_KEY);
        await storageProvider.remove(REALM_KEY);
        await storageProvider.remove(USER_REALM_KEY);

        cachedRealm = null;

        return Promise.resolve();
    };

    const forgotPassword: ForgotPassword = (email, realm) => {
        const payload = { id: email };
        const options = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Realm: realm,
                'x-api-key': process.env.STD__X_API_KEY,
            },
            useAuth: false,
        };

        return authHttp.post(Endpoints.UPDATE_PASSWORD, payload, options);
    };

    const setPassword: SetPassword = (secret, token) => {
        return authHttp.put(Endpoints.UPDATE_PASSWORD, { token, secret }, { useAuth: false });
    };

    const switchRealm: SwitchRealm = async (realm) => {
        await storageProvider.set('realm', realm);
        location.href = '/';
    };

    const getRole: GetRole = async () => {
        const authToken = await storageProvider.get(AUTH_TOKEN_KEY);
        const { role } = decodeToken(authToken);
        return Role[role] || Role.GUEST;
    };

    const decodeToken: DecodeToken = (authToken) => {
        const token = authToken;
        const jwtObj = atob(token.split('.')[1].replace('-', '+').replace('_', '/'));
        const { aud, rol } = JSON.parse(jwtObj);

        const jwtPayload: JwtPayload = {
            audience: aud,
            role: rol,
        };

        return jwtPayload;
    };

    return {
        login,
        validatateAppStorage,
        getRealm,
        logout,
        forgotPassword,
        setPassword,
        switchRealm,
        getRole,
        decodeToken,
    };
};

export const authenticationService = AuthenticationService();
