import { getAllTimeZones } from '~components/ChannelManager/PreviewSchedule/utilities';

export enum ZoomSettingBoundary {
    MIN = 900,
    MAX = 7200,
}

export enum DateFilterTypes {
    WEEK = 'WEEK',
    WEEKEND = 'WEEKEND',
    NEXT_SEVEN = 'NEXT_SEVEN',
    CUSTOM = 'CUSTOM',
}

export enum PlotTypes {
    SINGLE = 'SINGLE',
    GROUP = 'GROUP',
}

export const DAY_VALUE = {
    [DateFilterTypes.WEEKEND]: 2,
    [DateFilterTypes.WEEK]: 7,
    [DateFilterTypes.NEXT_SEVEN]: 7,
};

export const DATE_FILTER_DROPDOWN_OPTIONS = [
    {
        label: 'This Week',
        value: DateFilterTypes.WEEK,
    },
    {
        label: 'This Weekend',
        value: DateFilterTypes.WEEKEND,
    },
    {
        label: 'Next Seven Days',
        value: DateFilterTypes.NEXT_SEVEN,
    },
    {
        label: 'Custom Range',
        value: DateFilterTypes.CUSTOM,
        isDateRangePicker: true,
    },
];

export const TRANSITION_TIMEOUT = 1000;

export const DEFAULT_ZOOM_SETTING = 900;

export const ZOOM_SETTING_INTERVAL = 900;

export const ZOOM_SETTING_DEBOUNCE = 10;

export const INTERVAL_HEIGHT_PX = 100;

export const DEFAULT_TIME_ZONE = 'Etc/UCT';

export const DEFAULT_DATE_FILTER = DateFilterTypes.NEXT_SEVEN;

export const AVAILABLE_TIME_ZONES = getAllTimeZones();

export const DAY_COLUMN_WIDTH = 160;
