import { IChannelManagerState } from './types';

enum ChannelManagerActions {
    INIT_PAGE_SAGA = 'cm/INIT_PAGE_SAGA',

    FEATURE_FLAG_SAGA = 'cm/FEATURE_FLAG_SAGA',

    CHANNEL_SETTINGS_CHANGED_SAGA = 'cm/CHANNEL_SETTINGS_CHANGED_SAGA',

    POD_MOVED_SAGA = 'cm/POD_MOVED_SAGA',
    POD_REMOVED_SAGA = 'cm/POD_REMOVED_SAGA',
    POD_REMOVED_UNDO_SAGA = 'cm/POD_REMOVED_UNDO_SAGA',
    POD_UPDATED_SAGA = 'cm/POD_UPDATED_SAGA',
    EDIT_POD_SAGA = 'cm/EDIT_POD_SAGA',
    CONTENT_SELECTED_TO_ADD_TO_LIST_SAGA = 'cm/CONTENT_SELECTED_TO_ADD_TO_LIST_SAGA',
    CONTENT_SELECTED_TO_PREVIEW_SAGA = 'cm/CONTENT_SELECTED_TO_PREVIEW_SAGA',
    MULTIPLE_POD_ADDED_SAGA = 'cm/MULTIPLE_POD_ADDED_SAGA',
    PUBLISH_CONTENT_SAGA = 'cm/PUBLISH_CONTENT_SAGA',
    SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_SAGA = 'cm/SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_SAGA',
    CHANNEL_ADMIN_DETAILS_UPDATED = 'cm/CHANNEL_ADMIN_DETAILS_UPDATED',
    MUXER_UPDATED = 'cm/MUXER_UPDATED',
    STARTED_PROVISIONING_MUXER = 'cm/STARTED_PROVISIONING_MUXER',
    SUCCESSFULLY_PROVISIONED_MUXER = 'cm/SUCCESSFULLY_PROVISIONED_MUXER',
    ERROR_PROVISIONING_MUXER = 'cm/ERROR_PROVISIONING_MUXER',
    STARTED_REFRESHING_CHANNEL_ADMIN_DETAILS = 'cm/STARTED_REFRESHING_CHANNEL_ADMIN_DETAILS',
    SUCCESSFULLY_REFRESHED_CHANNEL_ADMIN_DETAILS = 'cm/SUCCESSFULLY_REFRESHED_CHANNEL_ADMIN_DETAILS',
    ERROR_REFRESHING_CHANNEL_ADMIN_DETAILS = 'cm/ERROR_REFRESHING_CHANNEL_ADMIN_DETAILS',
    STARTED_MUXER_ACTION = 'cm/STARTED_MUXER_ACTION',
    MUXER_ACTION_REQUIRES_CONFIRMATION = 'cm/MUXER_ACTION_REQUIRES_CONFIRMATION',
    SUCCESSFUL_MUXER_ACTION = 'cm/SUCCESSFUL_MUXER_ACTION',
    PROVISION_MUXER = 'cm/PROVISION_MUXER',
    REFRESH_CHANNEL_ADMIN_DETAILS = 'cm/REFRESH_CHANNEL_ADMIN_DETAILS',
    CANCEL_MUXER_ACTION = 'cm/CANCEL_MUXER_ACTION',
    MUXER_ACTION = 'cm/MUXER_ACTION',
    SET_CHANNEL_DGE_EVENT_ID = 'cm/SET_CHANNEL_DGE_EVENT_ID',
    CHECK_MUXER_STATUS_ACTION = 'cm/CHECK_MUXER_STATUS_ACTION',
    BATCH_ACTION_CONFIRMED_SAGA = 'cm/BATCH_ACTION_CONFIRMED_SAGA',
    DELETE_CHANNEL = 'cm/DELETE_CHANNEL',
    CHANNEL_DELETE_REQUIRES_CONFIRMATION = 'cm/CHANNEL_DELETE_REQUIRES_CONFIRMATION',
    CLEAR_CHANNEL_DELETE_REQUIRES_CONFIRMATION = 'cm/CLEAR_CHANNEL_DELETE_REQUIRES_CONFIRMATION',
    STARTED_DELETING_CHANNEL = 'cm/STARTED_DELETING_CHANNEL',
    FINISHED_DELETING_CHANNEL = 'cm/FINISHED_DELETING_CHANNEL',
    CHANNEL_OPENED_ACTIVE_MODAL = 'cm/CHANNEL_OPENED_ACTIVE_MODAL',
    CHANNEL_CLOSED_ACTIVE_MODAL = 'cm/CHANNEL_CLOSED_ACTIVE_MODAL',

    FEATURE_FLAGS_LOADED = 'cm/FEATURE_FLAGS_LOADED',
    PAGE_LOADED = 'cm/PAGE_LOADED',
    RESET_PAGE = 'cm/RESET_PAGE',
    STARTED_PUBLISHING_CHANGES = 'cm/STARTED_PUBLISHING_CHANGES',
    SUCCESSFULLY_PUBLISHED_CHANGES = 'cm/SUCCESSFULLY_PUBLISHED_CHANGES',
    ERROR_PUBLISHING_CHANGES = 'cm/ERROR_PUBLISHING_CHANGES',

    STARTED_SYNCHRONISING_CHANNEL_STREAMING_CONFIGS = 'cm/SYNCHRONISING_CHANNEL_STREAMING_CONFIGS',
    SUCCESSFULLY_SYNCHRONISED_CHANNEL_STREAMING_CONFIGS = 'cm/SUCCESSFULLY_SYNCHRONISED_CHANNEL_STREAMING_CONFIGS',
    ERROR_SYNCHRONISING_CHANNEL_STREAMING_CONFIGS = 'cm/ERROR_SYNCHRONISING_CHANNEL_STREAMING_CONFIGS',

    POD_MANAGEMENT_MODAL_OPENED = 'cm/POD_MANAGEMENT_MODAL_OPENED',
    POD_MANAGEMENT_MODAL_CLOSED = 'cm/POD_MANAGEMENT_MODAL_CLOSED',
    POD_MANAGEMENT_MODAL_VIEW_CHANGED = 'cm/POD_MANAGEMENT_MODAL_VIEW_CHANGED',
    SELECTED_CONTENT_LIST_UPDATED = 'cm/SELECTED_CONTENT_LIST_UPDATED',
    CONTENT_BY_ID_MAP_UPDATED = 'cm/CONTENT_BY_ID_MAP_UPDATED',

    CONTENT_PODS_UPDATED = 'cm/CONTENT_PODS_UPDATED',
    TOTAL_CONTENT_SIZE_CHANGED = 'cm/TOTAL_CONTENT_SIZE_CHANGED',
    CONTENT_RANGE_DETAILS_CHANGED = 'cm/CONTENT_RANGE_DETAILS_CHANGED',
    RESET_LAST_EDITED_CHANGES = 'cm/RESET_LAST_EDITED_CHANGES',

    CONFIRMATION_NEEDED_TO_OVERWRITE = 'cm/CONFIRMATION_NEEDED_TO_OVERWRITE',
    OVERWRITE_CONFIRMED = 'cm/OVERWRITE_CONFIRMED',
    OVERWRITE_CANCELLED = 'cm/OVERWRITE_CANCELLED',

    TOGGLE_BATCH_ACTION_MODE = 'cm/TOGGLE_BATCH_ACTION_MODE',
    DEACTIVATE_BATCH_ACTION_MODE = 'cm/DEACTIVATE_BATCH_ACTION_MODE',
    TOGGLE_BATCH_ACTION_POD_SELECTION = 'cm/TOGGLE_BATCH_ACTION_POD_SELECTION',

    REMOVE_SELECTED_CONTENT_LIST_ITEM = 'cm/REMOVE_SELECTED_CONTENT_LIST_ITEM',
    SORT_SELECTED_CONTENT_LIST_ITEM = 'cm/SORT_SELECTED_CONTENT_LIST_ITEM',

    SNEEK_PREVIEW_SCHEDULE = 'cm/SNEEK_PREVIEW_SCHEDULE',
    FULL_SCREEN_PREVIEW_SCHEDULE = 'cm/FULL_SCREEN_PREVIEW_SCHEDULE',
    CLOSE_PREVIEW_SCHEDULE = 'cm/CLOSE_PREVIEW_SCHEDULE',
    SET_PREVIEW_SETTINGS = 'cm/SET_PREVIEW_SETTINGS',

    SCROLL_TO_CONTENT_POD = 'cm/SCROLL_TO_CONTENT_POD',
}

export enum PreviewSchedulePaneVisibility {
    ON = 'ON',
    OFF = 'OFF',
    SNEEK = 'SNEEK',
}

export enum PodManagementStep {
    CONTENT_SELECTOR = 'CONTENT_SELECTOR',
    POD_SELECTOR = 'POD_SELECTOR',
}

export enum PodSelectorView {
    ADD = 'ADD',
    EDIT = 'EDIT',
    PREVIEW = 'PREVIEW',
}

export enum BatchActionMode {
    MULTIPLE_MOVE = 'MULTIPLE_MOVE',
    MULTIPLE_DELETE = 'MULTIPLE_DELETE',
    COPY_PASTE = 'COPY_PASTE',
}

export const DEFAULT_CONTENT_POD_FLAGS = {
    isNew: false,
    isRemoved: false,
    isEdited: false,
    hasMoved: false,
};

export const POD_MANAGEMENT_INITIAL_STATE = {
    isActive: false,
    currentStep: null,
    prevStep: null,
    contentByIdMap: {},
    insertPosition: null,
};

export const CHANNEL_MANAGER_INITIAL_STATE: IChannelManagerState = {
    hasPageLoaded: false,
    showConfirmOverwriteModal: false,
    isPublishing: false,
    contentPods: [],
    channelDetails: null,
    muxerVersions: null,
    pendingPublishPodNumbers: [],
    hasActiveModal: false,
    podManagement: {
        ...POD_MANAGEMENT_INITIAL_STATE,
    },
    lastEdited: null,
    lastAction: null,
    batchAction: {
        mode: null,
        selectedPodUuids: [],
    },
    previewSchedulePaneVisibility: PreviewSchedulePaneVisibility.OFF,
    previewSchedulePanePreviousSettings: null,
    contentPodScrollState: null,
    channelAdminDetails: null,
    channelIsDeleting: false,
    channelDeleteRequiresConfirmation: false,
    featureFlags: {},
};

export const TOAST_TIMEOUT = 3000;

export const OUTDATED_TIMELINE_STATUS_CODE = 409;

export const PUBLISH_SUCCESS_NOTIFICATION = {
    title: null,
    message: 'Timeline Successfully Updated',
};

export const MISSING_DGE_EVENT_STATUS_CODE = 400;

export const REFRESH_CHANNEL_ADMIN_DETAILS_SUCCESS_NOTIFICATION = {
    title: null,
    message: 'Loaded Channel Admin Details',
};

export const PROVISION_MUXER_SUCCESS_NOTIFICATION = {
    title: null,
    message: 'Successfully Provisioned Muxer',
};

export const SET_CHANNEL_DGE_ID_ERROR = 422;

export const MUXER_ACTION_FORCE_REQUIRED = 400;

export const CHANNEL_DELETE_FORCE_REQUIRED = 422;

export const MUXER_ACTION_SUCCESS_NOTIFICATION = {
    title: null,
    message: 'Successfully Performed Muxer Action',
};

export const CHANNEL_DELETE_SUCCESS_NOTIFICATION = {
    title: null,
    message: 'Successfully Deleted Channel',
};

export const SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_SUCCESS_NOTIFICATION = {
    title: null,
    message: 'Channel Streaming Configs Successfully Synchronised',
};

export const SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_ERROR_NOTIFICATION = {
    title: null,
    message: 'Channel Does Not Have a DGE Event Associated',
};

export const GENERAL_ERROR_NOTIFICATION = {
    title: null,
    message: 'An error occurred, please try again.',
};

export const SEGMENT_DURATION_IN_SECONDS = 6;

export { ChannelManagerActions };
