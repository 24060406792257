import { REALM_KEY } from '@dicetechnology/dice-unity/lib/services/Http/constants';
import { LocalStorageProvider } from '@dicetechnology/dice-unity/lib/services/Storage/localStorage';

import { SessionStorageProvider } from '~services/storage/SessionStorage';

import { STORAGE_PREFIX } from './constants';

const localStorageProvider = new LocalStorageProvider(STORAGE_PREFIX);
const sessionStorageProvider = new SessionStorageProvider(STORAGE_PREFIX);

export const storageProvider = {
    get: async (key: string): Promise<string | null> => {
        if (key === REALM_KEY) {
            const sessionValue = await sessionStorageProvider.get(key);

            if (sessionValue) {
                return sessionValue;
            }
        }

        return localStorageProvider.get(key);
    },
    set: async (key: string, value: string): Promise<void> => {
        if (key === REALM_KEY) {
            const localValue = await localStorageProvider.get(key);

            if (!localValue) {
                await localStorageProvider.set(key, value);
            }

            return sessionStorageProvider.set(key, value);
        }

        return localStorageProvider.set(key, value);
    },
    remove: async (key: string): Promise<void> => {
        if (key === REALM_KEY) {
            await sessionStorageProvider.remove(key);
        }

        return localStorageProvider.remove(key);
    },
};
