import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';

class ThumbnailsUtilities {
    public static findById(id: ITimelineThumbnail['id'], thumbnails: ITimelineThumbnail[]): ITimelineThumbnail {
        return thumbnails.find((thumbnail) => thumbnail.id === id);
    }

    /* enforce static usage: prevent instances being created */
    private constructor() {
        /* noop */
    }
}

export { ThumbnailsUtilities };
