import * as React from 'react';

import { RootContext } from '~components/Root/context';
import { authenticationService } from '~services/authentication';
import { Console } from '~services/console';

const Logout = () => {
    const { setAuthorised } = React.useContext(RootContext);

    React.useEffect(() => {
        (async () => {
            try {
                await authenticationService.logout();
                setAuthorised(false);
            } catch (e) {
                Console.warn("Processing 'Logout'", e);
            }
        })();
    }, []);

    return null;
};

export { Logout };
