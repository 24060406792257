import { PaginationActions } from '~src/store/pagination/constants';
import { IPaginationBucketState, IPaginationState } from '~src/store/pagination/types';
import { IContentType, StateKey } from '~src/types';

const DEFAULT_STATE: IPaginationState = {};

const updatePaginationResult = (state: IPaginationState, key: StateKey, updatedResult: IContentType): IPaginationBucketState['results'] => {
    return state[key].results.map((result) => {
        if (result.id === updatedResult.id) {
            return updatedResult;
        }
        return result;
    });
};

const updatePaginationStates = (state: IPaginationState, payload): IPaginationState => {
    return payload.stateKeys.reduce((accumulator, key) => {
        if (state[key]) {
            return {
                ...accumulator,
                [key]: {
                    ...state[key],
                    results: updatePaginationResult(state, key, payload.result),
                },
            };
        }
        return accumulator;
    }, {});
};

const paginationReducer = (state: IPaginationState = DEFAULT_STATE, { type, payload }): IPaginationState => {
    switch (type) {
        case PaginationActions.ADD:
            return {
                ...state,
                [payload.stateKey]: payload.defaultState,
            };
        case PaginationActions.REMOVE:
            const stateKey = payload.stateKey;
            const { [stateKey]: value, ...newState } = state;
            return newState;
        case PaginationActions.ADD_RESULTS:
            if (!state[payload.stateKey]) {
                return state;
            }
            return {
                ...state,
                [payload.stateKey]: {
                    results: [...state[payload.stateKey].results, ...payload.results],
                    hasMoreResults: payload.hasMoreResults,
                    lastSeen: payload.lastSeen,
                    status: 'loaded',
                },
            };
        case PaginationActions.UPDATE_RESULT:
            return {
                ...state,
                ...updatePaginationStates(state, payload),
            };
        default:
            return state;
    }
};

export { paginationReducer };
