import { Seconds } from '~src/types';

interface IZoomLevelConfig {
    seconds: Seconds;
    label: string;
    mainTickCount: number;
    minorTickCount: number;
    subTickCount: number;
    timeInterval: Seconds;
}

interface IZoomLevelConfigDictionary {
    [key: string]: IZoomLevelConfig;
}

enum EZoomLevel {
    TEN_SECONDS = 'TEN_SECONDS',
    ONE_MINUTE = 'ONE_MINUTE',
    TEN_MINUTES = 'TEN_MINUTES',
    ONE_HOUR = 'ONE_HOUR',
    DEFAULT = 'DEFAULT',
}

enum ZoomNudge {
    IN = 1,
    OUT = -1,
}

const DefaultZoomLevel: IZoomLevelConfig = {
    seconds: 0,
    label: 'full stream',
    mainTickCount: 12,
    subTickCount: 5,
    minorTickCount: 5,
    timeInterval: 0,
};

const ZoomLevels: IZoomLevelConfigDictionary = {
    [EZoomLevel.ONE_HOUR]: {
        seconds: 3600,
        label: '1h',
        mainTickCount: 12,
        subTickCount: 5,
        minorTickCount: 6,
        timeInterval: 300,
    },
    [EZoomLevel.TEN_MINUTES]: {
        seconds: 600,
        label: '10m',
        mainTickCount: 10,
        subTickCount: 6,
        minorTickCount: 5,
        timeInterval: 60,
    },
    [EZoomLevel.ONE_MINUTE]: {
        seconds: 60,
        label: '1m',
        mainTickCount: 12,
        subTickCount: 5,
        minorTickCount: 5,
        timeInterval: 5,
    },
    [EZoomLevel.TEN_SECONDS]: {
        seconds: 10,
        label: '10s',
        mainTickCount: 10,
        subTickCount: 5,
        minorTickCount: 5,
        timeInterval: 1,
    },
};

const MIN_ZOOM_VALUE = 0;

const getZoomPointsFromDuration = (duration: number): number => {
    const allZoomPoints = Object.values(ZoomLevels).reverse();
    const zoomPoints = allZoomPoints.findIndex((timeScale) => timeScale.seconds > duration);
    return zoomPoints > -1 ? zoomPoints : allZoomPoints.length;
};

const getZoomLevelFromValue = (zoomStep: number, maxSteps: number): EZoomLevel => {
    if (maxSteps === 0) {
        return EZoomLevel.DEFAULT;
    }
    return Object.keys(ZoomLevels).reverse().slice(0, maxSteps).concat(EZoomLevel.DEFAULT).reverse()[zoomStep] as EZoomLevel;
};

const getZoomValueFromLevel = (zoomLevel: EZoomLevel, maxSteps: number): number => {
    if (maxSteps === 0 || zoomLevel === EZoomLevel.DEFAULT) {
        return 0;
    } else {
        return Object.keys(ZoomLevels).reverse().slice(0, maxSteps).concat(EZoomLevel.DEFAULT).reverse().indexOf(zoomLevel);
    }
};

const getNextZoomLevel = (zoomDirection: ZoomNudge, zoomLevel: EZoomLevel, duration: Seconds): EZoomLevel | undefined => {
    const maxSteps = getZoomPointsFromDuration(duration);
    const currentZoomLevel = getZoomValueFromLevel(zoomLevel, maxSteps);
    const newZoomLevel = currentZoomLevel + zoomDirection;

    if (newZoomLevel >= MIN_ZOOM_VALUE && newZoomLevel <= maxSteps) {
        return getZoomLevelFromValue(newZoomLevel, maxSteps);
    }

    return undefined;
};

const getZoomLevelConfig = (zoomLevel: EZoomLevel): IZoomLevelConfig => {
    return isDefaultZoomLevel(zoomLevel) ? DefaultZoomLevel : ZoomLevels[zoomLevel];
};

const isDefaultZoomLevel = (zoomLevel: EZoomLevel): boolean => {
    return zoomLevel === EZoomLevel.DEFAULT;
};

const getTimeLineSettingFromZoomLevel = (
    streamDuration: number,
    zoomLevel: EZoomLevel,
    timelineWindowWidth: number
): {
    remainder: number;
    columnWidth: number;
    columnCount: number;
    finalItemWidth: number;
} => {
    const positiveStreamDuration = Math.abs(streamDuration);
    const { timeInterval, mainTickCount } = getZoomLevelConfig(zoomLevel);
    const remainder = isDefaultZoomLevel(zoomLevel) ? 0 : positiveStreamDuration % timeInterval;
    const columnWidth = timelineWindowWidth / mainTickCount;
    const columnCount = isDefaultZoomLevel(zoomLevel) ? mainTickCount : Math.ceil(positiveStreamDuration / timeInterval);
    return {
        remainder,
        columnWidth,
        columnCount,
        finalItemWidth: remainder > 0 ? (remainder * columnWidth) / timeInterval : 0,
    };
};

export {
    ZoomLevels,
    DefaultZoomLevel,
    MIN_ZOOM_VALUE,
    getZoomPointsFromDuration,
    getZoomLevelFromValue,
    getZoomValueFromLevel,
    getNextZoomLevel,
    getTimeLineSettingFromZoomLevel,
    isDefaultZoomLevel,
    getZoomLevelConfig,
    EZoomLevel,
    ZoomNudge,
    IZoomLevelConfig,
    IZoomLevelConfigDictionary,
};
