import * as React from 'react';

import { IAdBreak } from '~services/channels/types';
import {
    convertSecondsToDurationLabel,
    convertSecondsToHoursMinutesLabel,
    joinStrings,
    convertSecondsToMomentTimeFormat,
} from '~services/utilities';
import { Seconds } from '~src/types';
import { KeyCode } from '~src/views/types';

import './index.scss';

interface IProps {
    isDefault?: boolean;
    isSelectable?: boolean;
    isSelected: boolean;
    contentDuration: Seconds;
    podDuration: Seconds;
    adBreaks: IAdBreak[];
    onSelect: VoidFunction;
}

export const PodDetails = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const { contentDuration, podDuration, adBreaks, isDefault, isSelected = false, isSelectable = true } = props;
    const adsDuration = podDuration - contentDuration;
    const adsDurationPercentage = (adsDuration / podDuration) * 100;
    const contentDurationPercentage = (contentDuration / podDuration) * 100;
    const podDetailsClassName = joinStrings([
        'pod-details',
        isDefault && 'pod-details--is-default',
        isSelected && 'pod-details--is-selected',
        isSelectable && 'pod-details--is-selectable',
        !adBreaks.length && 'pod-details--no-ad-breaks',
    ]);

    React.useEffect(() => {
        if (isDefault && typeof ref === 'object') {
            ref.current.focus();
        }
    }, [ref, isDefault]);

    const handleKeyPress = ({ keyCode }: React.KeyboardEvent) => {
        if (keyCode === KeyCode.ENTER) {
            props.onSelect();
        }
    };

    const eventProps = isSelectable ? { onClick: props.onSelect, onKeyDown: handleKeyPress } : {};

    return (
        <div className={podDetailsClassName} tabIndex={0} ref={ref} {...eventProps}>
            <div className="pod-details__duration">{convertSecondsToHoursMinutesLabel(podDuration)} pod</div>
            <div className="pod-details__timeline-bar">
                <div className="pod-details__timeline-bar__content" style={{ width: `${contentDurationPercentage}%` }}>
                    <span>Content {convertSecondsToMomentTimeFormat(contentDuration)}</span>
                </div>
                <div className="pod-details__timeline-bar__ads" style={{ width: `${adsDurationPercentage}%` }}>
                    {!!adBreaks.length && <span>Ads {convertSecondsToMomentTimeFormat(adsDuration)}</span>}
                </div>
            </div>
            {!!adBreaks.length && (
                <div className="pod-details__ad-breaks">
                    {adBreaks.length} x Ad Breaks at {convertSecondsToDurationLabel(adBreaks[0].duration)} each
                </div>
            )}
        </div>
    );
});

export const DefaultPodDetails = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return <PodDetails {...props} ref={ref} isDefault={true} />;
});
