import v4 from 'uuid/v4';

import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { Seconds } from '~src/types';

class ThumbnailUtilities {
    public static buildThumbnail(renderThumbnailTime: Seconds, exportThumbnailTime: Seconds): ITimelineThumbnail {
        return {
            id: v4(),
            renderThumbnailTime,
            exportThumbnailTime,
            imageData: null,
        };
    }

    /* enforce static usage: prevent instances being created */
    private constructor() {
        /* noop */
    }
}

export { ThumbnailUtilities };
