import * as React from 'react';

import placeholder from '~assets/images/no-thumbnail.jpg';
import { joinStrings } from '~services/utilities';
import { IDataTestId } from '~src/views/types';

interface IProps {
    thumbnailSrc: string;
    thumbnailHeight?: number;
    thumbnailWidth?: number;
}

const CardThumbnail: React.FunctionComponent<IProps & IDataTestId> = ({
    thumbnailSrc,
    thumbnailHeight,
    thumbnailWidth,
    children,
    dataTestId,
}) => {
    const imageRef = React.useRef<HTMLImageElement>(null);

    const handleOnError = () => {
        imageRef.current.src = placeholder;
    };

    const className = joinStrings(['card__thumbnail', !thumbnailHeight && !thumbnailWidth && 'card__thumbnail--auto']);

    return (
        <div className={className}>
            <img
                src={thumbnailSrc}
                height={thumbnailHeight}
                width={thumbnailWidth}
                alt="thumbnail"
                data-test-id={dataTestId}
                ref={imageRef}
                onError={handleOnError}
            />
            {children}
        </div>
    );
};

export { CardThumbnail };
