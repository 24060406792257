import * as React from 'react';

import { RootContext } from '~components/Root/context';
import { EditorContext } from '~containers/EditorPageContainer/EditorContext';
import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { EditorModes, PlayerKeys } from '~containers/EditorPageContainer/types';
import { EZoomLevel, ZoomNudge } from '~services/zoomLevelService';
import { ITimelineClip } from '~src/store/timeline/clip/types';
import { IStudioState } from '~src/store/types';
import { BooleanFunction, FeatureDictionary } from '~src/types';
import { useShallowEqualSelector } from '~src/views/hooks';
import { KeyCode } from '~src/views/types';

interface IContainerProps {
    addClipInMarker: VoidFunction;
    addClipOutMarker: VoidFunction;
    setPreviewMode: VoidFunction;
    resetActiveClipId: VoidFunction;
    resetPreviewClipId: VoidFunction;
    addThumbnail: VoidFunction;
}

interface IProps extends IContainerProps {
    nudge: (amount: number) => void;
    nudgeVolume: (player: PlayerKeys, amount: number) => void;
    togglePlay: (player: PlayerKeys) => void;
    nudgeAmount: number;
    editorMode: string;
    goLive: VoidFunction;
    toggleEditorMode: (editorMode: EditorModes) => void;
    canPreview: BooleanFunction;
    canExport: BooleanFunction;
    canZoom: (zoomLevel: EZoomLevel) => boolean;
    canResetTimeLineState: BooleanFunction;
    resetTimeLineState: VoidFunction;
    features: FeatureDictionary;

    // THUMBNAILS
    canAddThumbnail: BooleanFunction;
    addThumbnail: VoidFunction;

    // timeline zooming methods
    handleTimelineZoom: (zoomLevel: EZoomLevel) => void;
    handleTimelineZoomNudge: (zoomNudge: ZoomNudge) => void;
    canResetPreviewClip: boolean;
    canResetActiveClip: boolean;
}

const KeyboardControlsComponent: React.FunctionComponent<IProps> = (props) => {
    const {
        nudge,
        nudgeVolume,
        togglePlay,
        nudgeAmount,
        goLive,
        editorMode,
        toggleEditorMode,
        canPreview,
        canExport,
        canZoom,
        canResetTimeLineState,
        resetTimeLineState,
        setPreviewMode,
        // redux actions
        addClipInMarker,
        addClipOutMarker,
        canAddThumbnail,
        addThumbnail,
        // timeline zooming methods
        handleTimelineZoom,
        handleTimelineZoomNudge,
        canResetActiveClip,
        canResetPreviewClip,
        resetActiveClipId,
        resetPreviewClipId,
    } = props;

    const validateZoomRequest = (zoomLevel: EZoomLevel) => {
        if (canZoom(zoomLevel)) {
            handleTimelineZoom(zoomLevel);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        const { shiftKey, keyCode } = event;

        if (editorMode === EditorModes.EDIT || editorMode === EditorModes.PREVIEW) {
            switch (keyCode) {
                // toggle play / pause
                case KeyCode.SPACE:
                    // prevent scrolling of body
                    if (event.target === document.body) {
                        event.preventDefault();
                    }
                // fallthrough is deliberate
                case KeyCode.K:
                    togglePlay(PlayerKeys.EDITOR);
                    break;
                // add in marker
                case KeyCode.I:
                    if (editorMode === EditorModes.EDIT) {
                        addClipInMarker();
                    }
                    break;
                // add out marker
                case KeyCode.O:
                    if (editorMode === EditorModes.EDIT) {
                        addClipOutMarker();
                    }
                    break;
                // jump to live edge
                case KeyCode.L:
                    if (editorMode === EditorModes.EDIT) {
                        goLive();
                    }
                    break;
                // take thumbnail
                case KeyCode.T:
                    // only add thumbs nails if clip is present
                    if (canAddThumbnail()) {
                        addThumbnail();
                    }
                    break;
                // move into preview mode
                case KeyCode.P:
                    if (editorMode === EditorModes.EDIT && canPreview()) {
                        setPreviewMode();
                    }
                    break;
                // move into export mode
                case KeyCode.E:
                    if (canExport()) {
                        toggleEditorMode(EditorModes.EXPORT);
                    }
                    break;
                // reset timeline state
                case KeyCode.R:
                    if (canResetTimeLineState()) {
                        resetTimeLineState();
                    }
                    break;
                case KeyCode.ARROW_LEFT: {
                    const nudgeBy = shiftKey ? -nudgeAmount : -1;
                    nudge(nudgeBy);
                    break;
                }
                case KeyCode.ARROW_RIGHT: {
                    const nudgeBy = shiftKey ? nudgeAmount : 1;
                    nudge(nudgeBy);
                    break;
                }
                case KeyCode.ARROW_UP:
                    nudgeVolume(PlayerKeys.EDITOR, 0.05);
                    break;
                case KeyCode.ARROW_DOWN:
                    nudgeVolume(PlayerKeys.EDITOR, -0.05);
                    break;
                case KeyCode.DIGIT_1:
                    validateZoomRequest(EZoomLevel.TEN_SECONDS);
                    break;
                case KeyCode.DIGIT_2:
                    validateZoomRequest(EZoomLevel.ONE_MINUTE);
                    break;
                case KeyCode.DIGIT_3:
                    validateZoomRequest(EZoomLevel.TEN_MINUTES);
                    break;
                case KeyCode.DIGIT_4:
                    validateZoomRequest(EZoomLevel.ONE_HOUR);
                    break;
                case KeyCode.DIGIT_5:
                    handleTimelineZoom(EZoomLevel.DEFAULT);
                    break;
                // nudge timeline zoom out
                case KeyCode.MINUS:
                    handleTimelineZoomNudge(ZoomNudge.OUT);
                    break;
                // nudge timeline zoom in
                case KeyCode.EQUAL:
                    handleTimelineZoomNudge(ZoomNudge.IN);
                    break;
                case KeyCode.ESCAPE: {
                    if (canResetPreviewClip) {
                        resetPreviewClipId();
                    } else if (canResetActiveClip) {
                        resetActiveClipId();
                    }
                    break;
                }
            }
        }
    };

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [editorMode, canResetPreviewClip, canResetActiveClip]);

    return null;
};

const KeyboardControlsMemo: React.NamedExoticComponent<IProps> = React.memo(KeyboardControlsComponent);

const getThumbnailState = (state: IStudioState): { activeClipId: ITimelineClip['id']; previewClipId: ITimelineClip['id'] } => {
    const { activeClipId, previewClipId } = state.timeline.clips;
    return {
        activeClipId,
        previewClipId,
    };
};

const KeyboardControls: React.FunctionComponent<IContainerProps> = ({
    addClipInMarker,
    addClipOutMarker,
    setPreviewMode,
    resetActiveClipId,
    resetPreviewClipId,
    addThumbnail,
}) => {
    const { features } = React.useContext(RootContext);
    const {
        nudge,
        nudgeVolume,
        togglePlay,
        goLive,
        editorPlayer: { frameRate },
    } = React.useContext(VideoContext);

    const { activeClipId, previewClipId } = useShallowEqualSelector(getThumbnailState);

    const canResetPreviewClip = previewClipId !== null;
    const canResetActiveClip = activeClipId !== null;

    const {
        editorMode,
        handleTimelineZoom,
        toggleEditorMode,
        canPreview,
        canZoom,
        canResetTimeLineState,
        resetTimeLineState,
        canAddThumbnail,
        handleTimelineZoomNudge,
        canExport,
    } = React.useContext(EditorContext);

    return (
        <KeyboardControlsMemo
            canResetPreviewClip={canResetPreviewClip}
            canResetActiveClip={canResetActiveClip}
            resetActiveClipId={resetActiveClipId}
            resetPreviewClipId={resetPreviewClipId}
            nudge={nudge}
            nudgeVolume={nudgeVolume}
            togglePlay={togglePlay}
            nudgeAmount={1 / frameRate}
            goLive={goLive}
            editorMode={editorMode}
            toggleEditorMode={toggleEditorMode}
            canPreview={canPreview}
            canExport={canExport}
            canZoom={canZoom}
            canResetTimeLineState={canResetTimeLineState}
            resetTimeLineState={resetTimeLineState}
            features={features}
            // redux actions
            addClipInMarker={addClipInMarker}
            addClipOutMarker={addClipOutMarker}
            canAddThumbnail={canAddThumbnail}
            addThumbnail={addThumbnail}
            setPreviewMode={setPreviewMode}
            handleTimelineZoom={handleTimelineZoom}
            handleTimelineZoomNudge={handleTimelineZoomNudge}
        />
    );
};

export { KeyboardControls };
