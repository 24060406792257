import * as React from 'react';

import { InlineNotification } from '~components/InlineNotification';
import { RootContext } from '~components/Root/context';
import { Seconds } from '~src/types';

interface IContainerProps {
    minimumClipSeparation: Seconds;
}

const CombineClipsNotificationComponent: React.FunctionComponent<IContainerProps> = ({ minimumClipSeparation }) => (
    <InlineNotification
        className="export-modal__inline-notification"
        notificationTitle="Combining clips disabled"
        message={`There is a minimum clip separation of ${minimumClipSeparation} seconds to be able to combine clips together.`}
    />
);

const CombineClipsNotificationMemo: React.NamedExoticComponent<IContainerProps> = React.memo(CombineClipsNotificationComponent);

const CombineClipsNotification: React.FunctionComponent = () => {
    const {
        settings: { minimumClipSeparation },
    } = React.useContext(RootContext);

    return <CombineClipsNotificationMemo minimumClipSeparation={minimumClipSeparation} />;
};

export { CombineClipsNotification };
