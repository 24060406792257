import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@dicetechnology/dice-backoffice-ui-components';

import Root from '~components/Root';
import '~scss/index.scss';

import { configureStore } from './store';

if (window.chrome && !window.chrome.webstore) {
    // prevent react-virtualized poor detection feature flag initialising with IE scroll limit
    // https://github.com/bvaughn/react-virtualized/issues/1394
    // @ts-ignore
    window.chrome.webstore = {};
}

if (process.env.NODE_ENV !== 'production') {
    // tslint:disable-next-line:no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackHooks: true,
        trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
    });
}

ReactDOM.render(
    <Provider store={configureStore()}>
        <ThemeProvider>
            <Root />
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);
