import { httpStargateV1, httpStargateSpockV1 } from '~services/http';
import { IStringDictionary } from '~src/types';

import { ChannelEndpoints, DEFAULT_PAGE_SIZE, TITLE_SEARCH_FIELD, ChannelType, DEFAULT_SORT_ORDER, DEFAULT_SORT_FIELD } from './constants';
import {
    IGetChannelsPayload,
    IGetResponseBase,
    IChannelDetails,
    IRawContentPod,
    IGetVideosPayload,
    ITitleSearchQueryParams,
    IVllContent,
    IPublishChangesPayload,
    IChannelAdminDetails,
    ISynchroniseChannelStreamingConfigs,
    IMuxerVersions,
    IChannelAdminStreamingConfigMuxer,
    IMuxerActionResponse,
    IDeleteChannelResponse,
    IPermanentFileResponse,
} from './types';

const VLL_ENDPOINT = '/vll/v1';
const VLL_ENDPOINT_V2 = '/vll/v2';
const CHANNELS_URL = `${VLL_ENDPOINT}${ChannelEndpoints.CHANNELS}`;
const CHANNELS_URL_V2 = `${VLL_ENDPOINT_V2}${ChannelEndpoints.CHANNELS}`;
const CHANNELS_SEARCH_URL = `${VLL_ENDPOINT}${ChannelEndpoints.CHANNELS_SEARCH}`;
const CHANNELS_ADMIN_URL = `${VLL_ENDPOINT}${ChannelEndpoints.CHANNELS_ADMIN}`;
const MUXER_VERSIONS_URL = `${VLL_ENDPOINT}${ChannelEndpoints.MUXER_VERSIONS}`;
const VIDEOS_URL = `${VLL_ENDPOINT}${ChannelEndpoints.VIDEOS}`;
const IMAGES_URL = ChannelEndpoints.IMAGES;

const getDefaultQueryParams = (payload: ITitleSearchQueryParams): IStringDictionary => {
    const { title = '', page = 1, size = DEFAULT_PAGE_SIZE, sortOrder = DEFAULT_SORT_ORDER, sortField = DEFAULT_SORT_FIELD } = payload;

    return {
        size: size.toString(),
        page: (page - 1).toString(), // 0 indexed pages
        ...(title && { 'filter.field': TITLE_SEARCH_FIELD, 'filter.text': title }),
        ...(sortOrder && { 'sort.order': sortOrder }),
        ...(sortField && { 'sort.field': sortField }),
    };
};

export const getListOfChannels = async (payload: IGetChannelsPayload): Promise<IGetResponseBase<IChannelDetails>> => {
    const queryParams = {
        ...getDefaultQueryParams(payload),
        type: payload.isLive ? ChannelType.LIVE : ChannelType.FUTURE,
    };
    const {
        parsedData: { results, total },
    } = await httpStargateV1.get(CHANNELS_SEARCH_URL, { queryParams });
    return { results, total };
};

export const getChannelDetails = async (channelId: number): Promise<IChannelDetails> => {
    const { parsedData } = await httpStargateV1.get(`${CHANNELS_URL}/${channelId}`);
    return parsedData;
};

export const getChannelAdminDetails = async (channelId: number): Promise<IChannelAdminDetails> => {
    const { parsedData } = await httpStargateV1.get(`${CHANNELS_ADMIN_URL}/${channelId}`);
    return parsedData;
};

export const getMuxerStatus = async ({ channelId, muxerId }): Promise<IChannelAdminStreamingConfigMuxer> => {
    const { parsedData } = await httpStargateV1.get(`${CHANNELS_ADMIN_URL}/${channelId}/muxers/${muxerId}/healthcheck`);
    return parsedData;
};

export const getMuxerVersions = async (): Promise<IMuxerVersions> => {
    const { parsedData } = await httpStargateV1.get(`${MUXER_VERSIONS_URL}`);
    return parsedData;
};

export const provisionMuxer = async ({ channelId, muxerConfiguration }): Promise<IChannelAdminStreamingConfigMuxer> => {
    const { parsedData } = await httpStargateV1.post(`${CHANNELS_ADMIN_URL}/${channelId}/muxers`, {
        streamingConfigId: muxerConfiguration.streamingConfigId,
        awsRegion: muxerConfiguration.region,
        awsAvailabilityZone: muxerConfiguration.availability,
        muxerVersion: muxerConfiguration.muxerVersion,
    });
    return parsedData;
};

export const setChannelDgeEventId = async ({ channelId, dgeEventId }): Promise<IChannelAdminDetails> => {
    const { parsedData } = await httpStargateV1.put(`${CHANNELS_ADMIN_URL}/${channelId} `, {
        dgeEventId,
    });
    return parsedData;
};

export const muxerAction = async ({ channelId, muxerId, streamingConfigId, action, forceAction }): Promise<IMuxerActionResponse> => {
    const { parsedData } = await httpStargateV1.put(`${CHANNELS_ADMIN_URL}/${channelId}/muxers/${muxerId}?forceAction=${forceAction}`, {
        action,
        streamingConfigId,
    });
    return parsedData;
};

export const deleteChannel = async ({ channelId, forceDelete }): Promise<IDeleteChannelResponse> => {
    const { parsedData } = await httpStargateV1.delete(`${CHANNELS_URL}/${channelId}?forceDelete=${forceDelete}`);
    return parsedData;
};

export const synchroniseChannelStreamingConfigs = async (channelId: number): Promise<ISynchroniseChannelStreamingConfigs> => {
    const { parsedData } = await httpStargateV1.post(`${CHANNELS_ADMIN_URL}/${channelId}/sync-streaming-configs`, {});
    return parsedData;
};

export const updateChannelDetails = async ({
    channelId,
    title,
    channelStartTime,
    contentPodInterval,
    presetIds,
    targetAdvertPercentage,
    targetAdvertDuration,
    enableBackupStream,
    logo,
}: IChannelDetails): Promise<IChannelDetails> => {
    const { parsedData } = await httpStargateV1.put(`${CHANNELS_URL_V2}/${channelId}`, {
        title,
        channelStartTime,
        contentPodInterval,
        presetIds,
        targetAdvertPercentage,
        targetAdvertDuration,
        enableBackupStream,
        logoFileId: logo?.fileId,
    });
    return parsedData;
};

export const getChannelContent = async (channelId: number): Promise<IGetResponseBase<IRawContentPod>> => {
    const {
        parsedData: { results, total },
    } = await httpStargateV1.get(`${CHANNELS_URL}/${channelId}/content-pods`);
    return { results, total };
};

export const getListOfVideos = async (
    channelId: number,
    payload: IGetVideosPayload,
    signal: AbortSignal
): Promise<IGetResponseBase<IVllContent>> => {
    const queryParams = getDefaultQueryParams(payload);
    const {
        parsedData: { results, total },
    } = await httpStargateV1.get(`${CHANNELS_URL}/${channelId}/videos`, { queryParams, signal });
    return { results, total };
};

export const getVideoById = async (dveVideoId: number): Promise<IVllContent> => {
    const { parsedData } = await httpStargateV1.get(`${VIDEOS_URL}/${dveVideoId}`);
    return parsedData;
};

export const publishChannelContent = (payload: IPublishChangesPayload, overwrite = false): Promise<void> => {
    const { channelId, timelineVersion, firstContentPodNumber, contentPods } = payload;
    return httpStargateV1.post(`${CHANNELS_URL}/${channelId}/content-pods?overwrite=${overwrite}`, {
        timelineVersion,
        firstContentPodNumber,
        contentPods,
    });
};

export const makePermanentImage = async (tempFileId: number): Promise<IPermanentFileResponse> => {
    const { parsedData } = await httpStargateSpockV1.post(`${IMAGES_URL}/permanent`, {
        tempFileId,
    });
    return parsedData;
};
