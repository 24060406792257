import * as React from 'react';

import { ClockIcon } from '~components/Icons/ClockIcon';

import './index.scss';

interface IProps {
    timeFilter: string;
    className?: string;
    setTimeFilter: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    timeIntervals: string[];
}

export const TimeFilter = (props: IProps) => {
    const { timeFilter, setTimeFilter, className, timeIntervals } = props;

    return (
        <div className={className}>
            <label className="time-filter">
                <select value={timeFilter} onChange={setTimeFilter} className="time-filter__filter">
                    {timeIntervals.map((hour) => (
                        <option key={hour} value={hour}>
                            {hour}
                        </option>
                    ))}
                </select>
                <div className="time-filter__icon">
                    <ClockIcon height={24} width={30} />
                </div>
            </label>
        </div>
    );
};
