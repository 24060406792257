import * as React from 'react';

import { CharacterRange } from '~components/CharacterRange';
import { AutoFocusInput } from '~components/Form/TextInput';
import { required } from '~components/Form/utilities';
import { joinStrings } from '~services/utilities';

interface IComponentProps {
    title: string;
    setTitle: (title: string) => void;
}

const ClipTitleField: React.FunctionComponent<IComponentProps> = ({ title, setTitle }) => {
    const [hasFocus, setFocus] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<string[]>([]);

    // form and field functions
    const changeHandler = (event: React.SyntheticEvent<HTMLInputElement>) => {
        setTitle(event.currentTarget.value);
    };

    const keyDownHandler = (event: React.SyntheticEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    };

    const focusHandler = () => {
        setFocus(true);
    };

    const blurHandler = (event, errorMessages = []) => {
        setFocus(false);
        setErrors(errorMessages);
    };

    const formGroupTitleClass = joinStrings(['clip-title-field form-group', !!errors.length && !hasFocus && 'form-group--has-error']);

    return (
        <div className={formGroupTitleClass}>
            <div className="form-group__control-wrapper">
                <AutoFocusInput
                    className="form-group__control"
                    type="text"
                    id="clipTitle"
                    name="clipTitle"
                    value={title}
                    onChange={changeHandler}
                    onKeyDown={keyDownHandler}
                    onFocus={focusHandler}
                    customOnBlur={blurHandler}
                    validation={[required]}
                    maxLength={255}
                    autoComplete="off"
                />
                <label className="form-group__label no-transition" htmlFor="title">
                    Clip title
                </label>
                {hasFocus && <CharacterRange maxLength={255} count={title.toString().length} />}
            </div>
            <span className="form-group__helpblock">{errors[0] || ''}</span>
        </div>
    );
};

export { ClipTitleField };
