import * as React from 'react';

const TrashBoldIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width={16} height={18} viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="nonzero">
                <path d="M12.5 15.714H3.214c-.056 0-.357-.312-.357-.848V4.286h10v10.58c0 .536-.301.848-.357.848z" fill="#FFF" />
                <path
                    d="M12.5 17.143c.982 0 1.786-1.05 1.786-2.277V4.286h1.071c.201 0 .357-.157.357-.357v-.715c0-.2-.156-.357-.357-.357h-3.449l-.78-1.864C10.903.446 10.233 0 9.642 0H6.07c-.591 0-1.26.446-1.484.993l-.781 1.864H.357c-.2 0-.357.156-.357.357v.715c0 .2.156.357.357.357H1.43V14.91c0 1.227.803 2.232 1.785 2.232H12.5zM10.357 2.857h-5l.547-1.306a.376.376 0 01.19-.122h3.538a.43.43 0 01.19.122l.535 1.306zM12.5 15.714H3.214c-.056 0-.357-.312-.357-.848V4.286h10v10.58c0 .536-.301.848-.357.848zm-7.143-2.143c.201 0 .357-.156.357-.357V6.786c0-.201-.156-.357-.357-.357h-.714c-.201 0-.357.156-.357.357v6.428c0 .201.156.357.357.357h.714zm2.857 0c.201 0 .357-.156.357-.357V6.786c0-.201-.156-.357-.357-.357H7.5c-.2 0-.357.156-.357.357v6.428c0 .201.156.357.357.357h.714zm2.857 0c.201 0 .358-.156.358-.357V6.786c0-.201-.157-.357-.358-.357h-.714c-.2 0-.357.156-.357.357v6.428c0 .201.156.357.357.357h.714z"
                    fill="#4A5F6C"
                />
            </g>
        </svg>
    );
};

export { TrashBoldIcon };
