import * as React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { ChevronLeft } from '~components/Icons/ChevronLeft';
import { ExclamationTriangleIcon } from '~components/Icons/ExclamationTriangleIcon';
import { TimesIcon } from '~components/Icons/TimesIcon';
import { IVllContentBase } from '~services/channels/types';
import { EServiceStatus } from '~services/http/enums';
import { joinStrings } from '~services/utilities';
import { ContentByIdMap } from '~src/store/channelManager/types';

import './index.scss';

interface IProps {
    lastEditablePodNumber: number;
    list: IVllContentBase[];
    contentByIdMap: ContentByIdMap;
    containerRef: React.MutableRefObject<HTMLDivElement>;
    onAddSelectedContentListToTimeline: VoidFunction;
    onRemoveSelectedContentListItem: (position: number) => void;
}

const getPodPluralSuffix = (count: number): string => `${count} Pod${count > 1 ? 's' : ''}`;

export const SortableSelectedContentList = SortableContainer((props: IProps) => {
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    const handleOnToggle = () => {
        setIsExpanded((prevExpanded) => !prevExpanded);
    };

    const handleOnRemove = (position: number) => () => {
        props.onRemoveSelectedContentListItem(position);
    };

    const itemsWithError = React.useMemo(
        () => props.list.filter((item) => props.contentByIdMap[item.dveVideoId].status === EServiceStatus.ERROR),
        [props.contentByIdMap, props.list]
    );

    const hasErrors = !!itemsWithError.length;

    const hasLoadingStates = React.useMemo(
        () => props.list.some((item) => props.contentByIdMap[item.dveVideoId].status === EServiceStatus.LOADING),
        [props.contentByIdMap, props.list]
    );

    return (
        <div
            className={joinStrings(['selected-content-list', isExpanded && 'selected-content-list--is-expanded'])}
            ref={props.containerRef}
        >
            <div className="selected-content-list__info-wrapper">
                <div className="selected-content-list__info-chevron" onClick={handleOnToggle}>
                    <ChevronLeft width={15} />
                </div>
                <div className="selected-content-list__list-count">{getPodPluralSuffix(props.list.length)} selected</div>
                {hasErrors && (
                    <div className="selected-content-list__error">
                        <ExclamationTriangleIcon width={12} />
                        {getPodPluralSuffix(itemsWithError.length)} with errors
                    </div>
                )}
            </div>
            <ul className="selected-content-list__list">
                {props.list.map((item, i) => (
                    <SortableSelectedItem
                        key={`${item.dveVideoId}-${i}`}
                        hasError={props.contentByIdMap[item.dveVideoId].status === EServiceStatus.ERROR}
                        title={item.title}
                        onRemove={handleOnRemove(i)}
                        index={i}
                        itemIndex={i}
                        position={i + 1 + props.lastEditablePodNumber}
                    />
                ))}
            </ul>
            <button
                className="selected-content-list__add-button"
                onClick={props.onAddSelectedContentListToTimeline}
                disabled={hasLoadingStates || hasErrors}
            >
                Add {getPodPluralSuffix(props.list.length)} to timeline
            </button>
        </div>
    );
});

interface ISortsbleSelectedItemProps {
    position: number;
    itemIndex: number;
    title: string;
    hasError: boolean;
    onRemove: VoidFunction;
}

const SortableSelectedItem = SortableElement((props: ISortsbleSelectedItemProps) => {
    return (
        <li
            className={joinStrings(['selected-content-list__list-item', props.hasError && 'selected-content-list__list-item--has-error'])}
            key={props.itemIndex}
        >
            <span className="selected-content-list__item-position">{props.position}</span>
            <span className="selected-content-list__item-title">{props.title}</span>
            <span className="selected-content-list__remove-item" onClick={props.onRemove}>
                <TimesIcon />
            </span>
        </li>
    );
});
