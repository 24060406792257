import * as React from 'react';

import { CollapsePanel } from '~components/CollapsePanel';
import { ClipsPanel } from '~components/Editor/ClipsPanel';
import { PlayerControls } from '~components/Editor/PlayerControls';
import { ThumbnailsPanel } from '~components/Editor/ThumbnailPanel';
import { LiveBadge } from '~components/LiveBadge';
import { VideoConsumer } from '~containers/EditorPageContainer/VideoContext';
import { EAssetType, IVideoContext, PlayerKeys } from '~containers/EditorPageContainer/types';

import { RecentlyCreatedPanel } from './RecentlyCreatedPanel';

interface IEditorVideoPanelProps {
    hasPreviewPlayerLoaded: boolean;
    toggleMute: (playerKey: PlayerKeys) => void;
    thumbnail: string;
    isPreviewPlayerPaused: boolean;
    play: (playerKey: PlayerKeys) => void;
    pause: (playerKey: PlayerKeys) => void;
    atLiveEdge: boolean;
    assetType: EAssetType;
}

class EditorVideoPanel extends React.PureComponent<IEditorVideoPanelProps> {
    private play = () => {
        this.props.play(PlayerKeys.EDITOR);
    };

    private pause = () => {
        this.props.pause(PlayerKeys.EDITOR);
    };

    private toggleMute = (playerKey: PlayerKeys) => {
        this.props.toggleMute(playerKey);
    };

    public render() {
        const { hasPreviewPlayerLoaded, thumbnail, isPreviewPlayerPaused, atLiveEdge, assetType } = this.props;

        const previewPlaceholderPlayerCssStyles = [
            'video-placeholder-img',
            'embed-responsive-item',
            hasPreviewPlayerLoaded ? 'loaded' : '',
        ].join(' ');

        return (
            <div className="editor__video-panel">
                <section className="video-preview">
                    <div className="embed-responsive embed-responsive-16by9">
                        <img className={previewPlaceholderPlayerCssStyles} src={thumbnail} />
                        <div className="video-preview-player embed-responsive-item" id="editor-player" />
                        {atLiveEdge && !isPreviewPlayerPaused && <LiveBadge />}
                        <PlayerControls playerKey={PlayerKeys.EDITOR} play={this.play} pause={this.pause} toggleMute={this.toggleMute} />
                    </div>
                </section>

                <div className="video-sidepanel">
                    {assetType === EAssetType.LIVE && (
                        <CollapsePanel title="Live Window">
                            <div className="video">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <img className="video__thumbnail" src={thumbnail} />
                                    <div className="video-preview-player embed-responsive-item" id="live-preview" />
                                    <PlayerControls playerKey={PlayerKeys.PREVIEW} toggleMute={this.toggleMute} />
                                </div>
                            </div>
                        </CollapsePanel>
                    )}

                    <CollapsePanel title="Clips">
                        <ClipsPanel />
                    </CollapsePanel>
                    <CollapsePanel title="Thumbnails">
                        <ThumbnailsPanel />
                    </CollapsePanel>
                    <CollapsePanel title="Recently Created">
                        <RecentlyCreatedPanel />
                    </CollapsePanel>
                </div>
            </div>
        );
    }
}

export default () => (
    <VideoConsumer>
        {({
            thumbnail,
            toggleMute,
            play,
            pause,
            editorPlayer: { ready: previewPlayerLoaded, paused: isPreviewPlayerPaused, atLiveEdge },
            assetType,
        }: IVideoContext) => (
            <EditorVideoPanel
                toggleMute={toggleMute}
                hasPreviewPlayerLoaded={previewPlayerLoaded}
                isPreviewPlayerPaused={isPreviewPlayerPaused}
                thumbnail={thumbnail}
                play={play}
                pause={pause}
                atLiveEdge={atLiveEdge}
                assetType={assetType}
            />
        )}
    </VideoConsumer>
);
