import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ChannelDetails } from '~components/ChannelManager/ChannelDetails';
import { ControlsSection } from '~components/ChannelManager/ControlsSection';
import { PodManagementModal } from '~components/ChannelManager/PodManagementModal';
import { PodScroller } from '~components/ChannelManager/PodScroller';
import { PreviewSchedule } from '~components/ChannelManager/PreviewSchedule';
import { ICalendarPreviewSettings } from '~components/ChannelManager/PreviewSchedule/types';
import { IMuxerConfiguration } from '~containers/ChannelManagerContainer/types';
import { IChannelAdminStreamingConfig, IChannelAdminStreamingConfigMuxer, MuxerAction } from '~services/channels/types';
import {
    pageLoad,
    setFeatureFlags,
    podMoved,
    podRemoved,
    podRemovedUndo,
    openPodManagementModal,
    editPod,
    publishContent,
    resetPage,
    overwriteConfirmed,
    overwriteCancelled,
    batchActionConfirmed,
    channelSettingsChanged,
    toggleBatchActionPodSelection,
    deactivateBatchActionMode,
    toggleBatchActionMode,
    sneekPreviewSchedule,
    fullScreenPreviewSchedule,
    closePreviewSchedule,
    setPreviewSchedulePreviousSettings,
    synchroniseChannelStreamingConfigs,
    provisionMuxer,
    muxerAction,
    refreshMuxers,
    checkMuxerStatus,
    cancelMuxerAction,
    setChannelDgeEventId,
} from '~src/store/channelManager/channelManager.actions';
import {
    getPageLoadedState,
    getPodManagementState,
    getPendingPublishPodNumbersState,
    getChannelHasActiveModal,
} from '~src/store/channelManager/channelManager.selectors';
import { getContentSelectorStepInitialState } from '~src/store/channelManager/channelManager.utilities';
import { BatchActionMode } from '~src/store/channelManager/constants';
import { IChannelSettings, UUID, ChangeAction } from '~src/store/channelManager/types';
import { usePrompt } from '~src/views/hooks/usePrompt';
import { KeyCode } from '~src/views/types';

import { ControlsContext } from './ChannelManagerContext';

const ChannelManagerProvider: React.FC = (props) => {
    const { id } = useParams<{ id: string }>();
    const channelId = parseInt(id, 10);
    const dispatch = useDispatch();
    const hasLoaded = useSelector(getPageLoadedState);
    const { isActive: isAddModalActive } = useSelector(getPodManagementState);
    const hasActiveModal = useSelector(getChannelHasActiveModal);
    const pendingPublishPodNumbers = useSelector(getPendingPublishPodNumbersState);
    const needConfirmationBeforeUnload = !!pendingPublishPodNumbers.length;

    usePrompt(needConfirmationBeforeUnload);

    React.useEffect((): void | VoidFunction => {
        if (!isAddModalActive && !hasActiveModal) {
            const handleKeyDown = (e: KeyboardEvent) => {
                if (e.keyCode === KeyCode.P) {
                    e.preventDefault();
                    onAddContent();
                }
            };

            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isAddModalActive, hasActiveModal]);

    React.useEffect(() => {
        dispatch(pageLoad(channelId));

        return () => {
            dispatch(resetPage());
        };
    }, []);

    React.useEffect(() => {
        dispatch(setFeatureFlags());
    }, []);

    const onChannelSettingsChanged = (settings: IChannelSettings) => {
        dispatch(channelSettingsChanged(settings));
    };

    const onPodMove = (fromPosition: number, toPosition: number, changeAction: ChangeAction) => {
        dispatch(podMoved(fromPosition, toPosition, changeAction));
    };

    const onPodRemove = (uuid: UUID) => {
        dispatch(podRemoved(uuid));
    };

    const onPodRemoveUndo = (uuid: UUID) => {
        dispatch(podRemovedUndo(uuid));
    };

    const onAddContent = (insertPosition?: number) => {
        dispatch(openPodManagementModal(getContentSelectorStepInitialState(), insertPosition));
    };

    const onPodEdit = (uuid: UUID) => {
        dispatch(editPod(uuid));
    };

    const onPublish = () => {
        dispatch(publishContent());
    };

    const onSynchroniseChannelStreamingConfigs = () => {
        dispatch(synchroniseChannelStreamingConfigs());
    };

    const onProvisionMuxer = (muxerConfiguration: IMuxerConfiguration) => {
        dispatch(provisionMuxer(muxerConfiguration));
    };

    const onMuxerAction = (
        muxerId: IChannelAdminStreamingConfigMuxer['muxerId'],
        streamingConfigId: IChannelAdminStreamingConfig['streamingConfigId'],
        action: MuxerAction,
        forceAction: boolean = false
    ) => {
        dispatch(muxerAction(muxerId, streamingConfigId, action, forceAction));
    };

    const onSetChannelDgeEventId = (dgeEventId: string) => {
        dispatch(setChannelDgeEventId(dgeEventId));
    };

    const onRefreshChannelAdminDetails = (hideSuccessNotification: boolean = false) => {
        dispatch(refreshMuxers(hideSuccessNotification));
    };

    const onCancelMuxerAction = () => {
        dispatch(cancelMuxerAction());
    };

    const onCheckMuxerStatus = (muxerId: IChannelAdminStreamingConfigMuxer['muxerId']) => {
        dispatch(checkMuxerStatus(muxerId));
    };

    const onDiscard = () => {
        dispatch(pageLoad(channelId));
    };

    const onConfirmOverwrite = () => {
        dispatch(overwriteConfirmed());
    };

    const onCancelOverwrite = () => {
        dispatch(overwriteCancelled());
    };

    const onToggleBatchActionMode = (mode: BatchActionMode) => {
        dispatch(toggleBatchActionMode(mode));
    };

    const onDeactivateBatchActionMode = () => {
        dispatch(deactivateBatchActionMode());
    };

    const onToggleBatchActionPodSelection = (uuid: UUID) => {
        dispatch(toggleBatchActionPodSelection(uuid));
    };

    const onCopyPasteBatchActionConfirmed = (position: number) => {
        dispatch(batchActionConfirmed(position));
    };

    const onMultipleMoveBatchActionConfirmed = (position: number) => {
        dispatch(batchActionConfirmed(position));
    };

    const onMultipleDeleteBatchActionConfirmed = () => {
        dispatch(batchActionConfirmed());
    };

    const onSneekPreviewSchedule = () => dispatch(sneekPreviewSchedule());
    const onFullScreenPreviewSchedule = () => dispatch(fullScreenPreviewSchedule());
    const onClosePreviewSchedule = (settings: ICalendarPreviewSettings) => {
        dispatch(setPreviewSchedulePreviousSettings(settings));
        dispatch(closePreviewSchedule());
    };

    // Maybe we should get rid of this approach and directly dispatch from the component
    const controls = React.useMemo(
        () => ({
            onChannelSettingsChanged,
            onAddContent,
            onPodMove,
            onPodRemove,
            onPodRemoveUndo,
            onPodEdit,
            onPublish,
            onSynchroniseChannelStreamingConfigs,
            onProvisionMuxer,
            onMuxerAction,
            onSetChannelDgeEventId,
            onRefreshChannelAdminDetails,
            onCancelMuxerAction,
            onCheckMuxerStatus,
            onDiscard,
            onConfirmOverwrite,
            onCancelOverwrite,
            onToggleBatchActionMode,
            onDeactivateBatchActionMode,
            onToggleBatchActionPodSelection,
            onCopyPasteBatchActionConfirmed,
            onMultipleMoveBatchActionConfirmed,
            onMultipleDeleteBatchActionConfirmed,
            onSneekPreviewSchedule,
            onFullScreenPreviewSchedule,
            onClosePreviewSchedule,
        }),
        [dispatch]
    );

    if (hasLoaded) {
        return <ControlsContext.Provider value={controls}>{props.children}</ControlsContext.Provider>;
    }

    return null;
};

export const ChannelManagerContainer: React.FC = () => {
    return (
        <ChannelManagerProvider>
            <PodManagementModal />
            <ChannelDetails />
            <PodScroller />
            <PreviewSchedule />
            <ControlsSection />
        </ChannelManagerProvider>
    );
};
