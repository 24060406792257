import * as React from 'react';

const CalendarScheduleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width={22} height={21} viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.016 7v1.968H12V7h2.016zM9.984 7v1.968H8.016V7h1.968zM16 9.5c1.005 0 1.935.251 2.79.754a5.213 5.213 0 011.956 1.956c.503.855.754 1.785.754 2.79a5.409 5.409 0 01-.754 2.79 5.213 5.213 0 01-1.956 1.956A5.409 5.409 0 0116 20.5a5.409 5.409 0 01-2.79-.754 5.311 5.311 0 01-1.956-1.97 5.383 5.383 0 01-.67-1.806H9v-.003h1.583A5.587 5.587 0 0110.5 15c0-.996.251-1.921.754-2.776a5.413 5.413 0 011.97-1.97A5.385 5.385 0 0116 9.5zm0 1.5c-.719 0-1.39.184-2.012.55a3.879 3.879 0 00-1.437 1.438A3.893 3.893 0 0012 15c0 .333.04.656.118.968h3.866c.12 0 .238-.01.352-.028l.168-.036-.066.018c-.094.02-.19.035-.289.043L16 15.97h-3.881c.091.361.235.708.432 1.042.351.607.83 1.086 1.437 1.437.623.367 1.293.551 2.012.551.719 0 1.39-.184 2.012-.55a3.879 3.879 0 001.437-1.438c.367-.623.551-1.293.551-2.012 0-.719-.184-1.39-.55-2.012a3.879 3.879 0 00-1.438-1.437A3.893 3.893 0 0016 11zm.416 1.14v2.372l1.115.741.066-.08a2.01 2.01 0 01-.064.082l.512.341-.428.733-.797-.534a1.986 1.986 0 01-.242.09l-.074.019.163-.05a1.9 1.9 0 00.15-.06l-1.272-.854v-2.8h.871zM5.016 0v1.968h7.968V0H15v1.968h.984c.544 0 1.016.2 1.416.6.4.4.6.872.6 1.416V8h-1.5V4.5h-15v10H9v1.467l-6.984.001c-.56 0-1.036-.192-1.428-.576A1.877 1.877 0 010 14V3.984c0-.544.196-1.016.588-1.416.392-.4.868-.6 1.428-.6H3V0h2.016zM6 7v1.968H3.984V7H6z"
                fill="#001B2D"
                fillRule="evenodd"
            />
        </svg>
    );
};

export { CalendarScheduleIcon };
