import * as React from 'react';

import { CalendarCardPreview } from '~components/ChannelManager/PreviewSchedule/CalendarCardPreview';
import { GroupedCardUI } from '~components/ChannelManager/PreviewSchedule/GroupedCardUI';
import { ClockIcon } from '~components/Icons/ClockIcon';
import { IContentPod } from '~services/channels/types';
import { getDurationLabel } from '~services/utilities/time';

import './index.scss';

interface ICalendarCardProps {
    contentPod: IContentPod;
    height: number;
    showUSDateTimeFormat: boolean;
    timezone?: string;
}

export const CalendarCard: React.FC<ICalendarCardProps> = (props) => {
    const cardRef = React.useRef<HTMLDivElement>(null);
    const [showPreview, setShowPreview] = React.useState(false);
    const { contentPod, timezone } = props;

    const handleClick = () => {
        setShowPreview(!showPreview);
    };

    const handleClosePreview = () => {
        setShowPreview(false);
    };

    return (
        <div className="preview-schedule__plot" style={{ height: props.height }} ref={cardRef}>
            <div className="preview-schedule__plot-inner" onClick={handleClick}>
                <div className="grouped-pod">
                    <span className="grouped-pod__duration">
                        <ClockIcon />
                        {getDurationLabel(props.showUSDateTimeFormat, contentPod.startTime, contentPod.duration, props.timezone)}
                    </span>
                </div>
                <GroupedCardUI contentPod={contentPod} />
            </div>
            {showPreview && <CalendarCardPreview contentPod={contentPod} closePreview={handleClosePreview} timezone={timezone} />}
        </div>
    );
};
