import * as moment from 'moment-timezone';
import v4 from 'uuid/v4';

import { ListItems } from '~components/ChannelManager/PodScroller/constants';
import {
    IRawContentPod,
    IContentPod,
    IVllContent,
    IContentPodBase,
    IPublishContentPod,
    ICuePoint,
    IAdBreak,
} from '~services/channels/types';
import { IClientThumbnail } from '~services/clip/types';
import { Console } from '~services/console';
import { FileService } from '~services/file';
import { S3Service } from '~services/s3Service';
import { convertArrayToObject } from '~services/utilities';
import { getTimezoneConformedMoment } from '~services/utilities/time';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { Seconds } from '~src/types';

import { DEFAULT_CONTENT_POD_FLAGS, PodManagementStep, PodSelectorView, SEGMENT_DURATION_IN_SECONDS } from './constants';
import {
    IChannelManagerState,
    IContentSelectorStep,
    ICuratedPodData,
    IEditableRange,
    IPodSelectorStep,
    IScrollableDateTimeRange,
    UUID,
} from './types';

export const isCurrentStepContentSearch = (
    currentStep: IChannelManagerState['podManagement']['currentStep']
): currentStep is IContentSelectorStep => {
    return currentStep?.step === PodManagementStep.CONTENT_SELECTOR;
};

export const isCurrentStepPodSelector = (
    currentStep: IChannelManagerState['podManagement']['currentStep']
): currentStep is IPodSelectorStep => {
    return currentStep?.step === PodManagementStep.POD_SELECTOR;
};

export const isCurrentStepEditMode = (
    currentStep: IChannelManagerState['podManagement']['currentStep']
): currentStep is IPodSelectorStep => {
    return isCurrentStepPodSelector(currentStep) && currentStep.kind === PodSelectorView.EDIT;
};

export const convertRawContentPod = (
    { editable, podNumber, startTime, ...rest }: IRawContentPod,
    isFirstOfDay: boolean,
    isLastOfDay: boolean,
    isFirstEditable: boolean,
    isLastEditable: boolean,
    adjustedPodNumber: number
): IContentPod => {
    const momentStartTime = moment(startTime);

    return {
        ...DEFAULT_CONTENT_POD_FLAGS,
        ...rest,
        kind: ListItems.CONTENT_POD,
        uuid: v4(),
        isLive: momentStartTime.isBefore(),
        isFirstEditable,
        isLastEditable,
        isFirstOfDay,
        isLastOfDay,
        isEditable: editable,
        startTime: momentStartTime.toISOString(),
        podNumber: adjustedPodNumber,
        originalPodNumber: adjustedPodNumber,
    };
};

export const createNewContentPod = (pod: IContentPodBase, content: IVllContent): IContentPod => {
    return {
        ...DEFAULT_CONTENT_POD_FLAGS,
        ...pod,
        kind: ListItems.CONTENT_POD,
        content,
        uuid: v4(),
        isLive: false, // only check for isLive on page load
        isFirstOfDay: false,
        isLastOfDay: false,
        isFirstEditable: false,
        isLastEditable: false,
        isNew: true,
        isEditable: true,
        startTime: null,
        podNumber: null,
        originalPodNumber: null,
    };
};

export const getScrollableDateTimeRange = (contentPods: IContentPod[]): IScrollableDateTimeRange => {
    return {
        start: contentPods[0]?.startTime,
        end: contentPods[contentPods.length - 1]?.startTime,
    };
};

export const getStartOfDayValue = (startTime: moment.MomentInput, timezone?: string): number => {
    const dayInstance = getTimezoneConformedMoment(moment(startTime), timezone);
    return dayInstance.startOf('day').valueOf();
};

export const getEndOfDayValue = (startTime: moment.MomentInput): number => {
    return moment(startTime).endOf('day').valueOf();
};

export const parseRawContentPods = (
    rawContentPods: IRawContentPod[],
    timezone?: string
): { contentPods: IContentPod[]; editableRange: IEditableRange; scrollableDateTimeRange: IScrollableDateTimeRange } => {
    let firstEditablePodNumber = null;
    let lastEditablePodNumber = null;
    const days: Record<number, boolean> = {};

    const contentPods = rawContentPods.map((contentPod, index, array) => {
        // podNumber always starts at 1
        const podNumber = index + 1;
        let isFirstEditable = false;
        const isLastEditable = podNumber === rawContentPods.length;
        const day = getStartOfDayValue(contentPod.startTime, timezone);
        const nextPodDay = !isLastEditable ? getStartOfDayValue(array[podNumber].startTime, timezone) : day;
        const isFirstOfDay = !days[day];
        const isLastOfDay = day !== nextPodDay;

        if (isFirstOfDay) {
            days[day] = true;
        }

        if (!firstEditablePodNumber && contentPod.editable) {
            firstEditablePodNumber = podNumber;
            isFirstEditable = true;
        }

        if (isLastEditable) {
            lastEditablePodNumber = podNumber;
        }

        return convertRawContentPod(contentPod, isFirstOfDay, isLastOfDay, isFirstEditable, isLastEditable, podNumber);
    });

    return {
        contentPods,
        editableRange: {
            first: firstEditablePodNumber,
            last: lastEditablePodNumber,
        },
        scrollableDateTimeRange: getScrollableDateTimeRange(contentPods),
    };
};

export const adjustContentPodPositions = (
    contentPods: IContentPod[],
    firstContentStartTime: string,
    timezone?: string
): {
    contentPods: IContentPod[];
    pendingPublishPodNumbers: number[];
    editableRange: IEditableRange;
    scrollableDateTimeRange: IScrollableDateTimeRange;
    totalDuration: number;
    totalContent: number;
} => {
    let runningPodNumber = 1;
    let runningTotalContent = 0;
    let runningDuration = 0;
    let firstEditablePodNumber: number = null;
    let lastEditablePodNumber: number = null;
    let lastFoundEndOfDayIndex: number = null;
    const pendingPublishPodNumbers = new Set<number>();
    const days: Record<number, boolean> = {};

    const updatedContentPods: IContentPod[] = contentPods.reduce((acc, contentPod, i) => {
        let currentPodNumber: number = null;
        let currentStartTime: moment.Moment = null;
        const isLastEditable = i + 1 === contentPods.length;
        let isFirstEditable = false;
        let isFirstOfDay = false;

        if (!contentPod.isRemoved) {
            currentPodNumber = runningPodNumber;
            currentStartTime = moment(firstContentStartTime).clone().add(runningDuration, 'seconds');
            runningDuration = !runningDuration ? contentPod.duration : runningDuration + contentPod.duration;
            runningPodNumber++;
            runningTotalContent++;

            const day = getStartOfDayValue(currentStartTime, timezone);
            isFirstOfDay = !days[day];

            if (isFirstOfDay) {
                if (lastFoundEndOfDayIndex) {
                    acc[lastFoundEndOfDayIndex].isLastOfDay = true;
                }

                lastFoundEndOfDayIndex = null;
                days[day] = true;
            } else {
                lastFoundEndOfDayIndex = i;
            }
        }

        if (contentPod.isNew) {
            pendingPublishPodNumbers.add(currentPodNumber);
        } else if (contentPod.isRemoved || contentPod.hasMoved || contentPod.isEdited) {
            pendingPublishPodNumbers.add(contentPod.originalPodNumber);
        }

        if (!firstEditablePodNumber && contentPod.isEditable && !contentPod.isRemoved) {
            firstEditablePodNumber = currentPodNumber;
            isFirstEditable = true;
        }

        acc.push({
            ...contentPod,
            isFirstOfDay,
            isLastOfDay: false,
            isFirstEditable,
            isLastEditable,
            startTime: currentStartTime?.toISOString() ?? null,
            podNumber: currentPodNumber,
        });
        return acc;
    }, [] as IContentPod[]);

    // Find last editable item once the whole list has been processed
    // Cannot be done as part of the reduce
    for (let i = updatedContentPods.length - 1; i >= 0; i--) {
        const pod = updatedContentPods[i];

        if (pod.isEditable && !pod.isRemoved) {
            lastEditablePodNumber = pod.podNumber;
            break;
        }
    }

    return {
        contentPods: updatedContentPods,
        pendingPublishPodNumbers: [...pendingPublishPodNumbers].sort(),
        editableRange: {
            first: firstEditablePodNumber,
            last: lastEditablePodNumber,
        },
        scrollableDateTimeRange: getScrollableDateTimeRange(updatedContentPods),
        totalDuration: runningDuration,
        totalContent: runningTotalContent,
    };
};

export const getPodSuggestionsForContentWithCuePoints = (
    cuePoints: ICuePoint[],
    contentDuration: Seconds,
    targetAdvertDuration: Seconds
): IContentPodBase => {
    const podDuration = contentDuration + targetAdvertDuration * cuePoints.length;
    let adBreaks: IAdBreak[] = [];

    if (targetAdvertDuration > 0) {
        adBreaks = cuePoints.map((cuePoint) => ({
            ...cuePoint,
            duration: targetAdvertDuration,
        }));
    }

    return {
        duration: podDuration,
        adBreaks,
    };
};

export const getPodSuggestionsForContentWithoutCuePoints = (
    contentDuration: Seconds,
    targetAdvertDuration: Seconds,
    targetAdvertPercentage: number
): IContentPodBase => {
    if (!targetAdvertDuration) {
        return {
            duration: contentDuration,
            adBreaks: [],
        };
    }

    const podDurationWithTargetAdvertPercentageFill = contentDuration * (targetAdvertPercentage + 1);
    const numberOfAdverts = Math.ceil((podDurationWithTargetAdvertPercentageFill - contentDuration) / targetAdvertDuration) || 0;
    const podDuration = contentDuration + numberOfAdverts * targetAdvertDuration;
    const adBreaks: IAdBreak[] =
        targetAdvertDuration !== 0 ? getAdBreaksSpreadEvenlyAcrossContent(contentDuration, targetAdvertDuration, numberOfAdverts) : [];

    return {
        duration: podDuration,
        adBreaks,
    };
};

export const getAdBreaksSpreadEvenlyAcrossContent = (
    contentDuration: Seconds,
    targetAdvertDuration: Seconds,
    numberOfAdverts: number
): IAdBreak[] => {
    const finalAdCuePoint = contentDuration;
    return Array(numberOfAdverts)
        .fill(contentDuration / numberOfAdverts)
        .map((value, index) => ({
            cuePoint: getNumberWithThreeDecimals(finalAdCuePoint - index * value),
            duration: targetAdvertDuration,
        }));
};

export const getNumberWithThreeDecimals = (value: number): number => parseFloat(value.toFixed(3));

export const getFormattedContentPodsToPublish = (contentPods: IContentPod[]): IPublishContentPod[] => {
    return contentPods
        .filter((pod) => !pod.isRemoved)
        .map(({ isNew, adBreaks, duration, startTime, content }) => {
            const payload: IPublishContentPod = {
                adBreaks,
                duration,
                startTime,
            };
            payload.dveVideoId = content.dveVideoId;
            return payload;
        });
};

export const recalculateAdBreaksWithUpdatedTargetAdvertDuration = (
    targetAdvertDuration: Seconds,
    targetAdvertPercentage: number,
    contentPods: IContentPod[]
): IContentPod[] => {
    return contentPods.map((contentPod) => {
        if (contentPod.isEditable) {
            let podSuggestions = null;

            if (!!contentPod.adBreaks.length) {
                podSuggestions = getPodSuggestionsForContentWithCuePoints(
                    contentPod.adBreaks,
                    contentPod.content.duration,
                    targetAdvertDuration
                );
            } else {
                podSuggestions = getPodSuggestionsForContentWithoutCuePoints(
                    contentPod.content.duration,
                    targetAdvertDuration,
                    targetAdvertPercentage
                );
            }

            const { adBreaks, duration } = podSuggestions;

            return {
                ...contentPod,
                duration,
                adBreaks,
            };
        }
        return contentPod;
    });
};

export const adjustCuePoints = (cuePoints: ICuePoint[], contentDuration: Seconds): ICuePoint[] => {
    return cuePoints
        .reduceRight((acc, curr, i, array) => {
            const lastAddedCuePoint = acc[acc.length - 1]?.cuePoint ?? contentDuration;
            const isLast = i === array.length - 1;

            if (isLast && contentDuration - curr.cuePoint < SEGMENT_DURATION_IN_SECONDS) {
                // if last cuepoint is within last segment of the video, move it to the end of the video
                acc.push({
                    cuePoint: contentDuration,
                });
            } else if (lastAddedCuePoint - curr.cuePoint >= SEGMENT_DURATION_IN_SECONDS) {
                // only add cuepoints if there is a gap of 6s
                acc.push({
                    cuePoint: curr.cuePoint,
                });
            }
            return acc;
        }, [])
        .reverse();
};

export const moveContentPodsToPosition = (
    contentPods: IContentPod[],
    selectedPodUuids: UUID[],
    positionToMove: number
): { contentPods: IContentPod[]; lastEditedUuid: UUID } => {
    const contentPodsByUuid = convertArrayToObject(contentPods, 'uuid');
    const contentPodsToMove: IContentPod[] = [];
    const updatedContentPods = [...contentPods];

    for (const uuid of selectedPodUuids) {
        const contentPodToClone = contentPodsByUuid[uuid];
        contentPodsToMove.push({ ...contentPodToClone, uuid: v4(), hasMoved: true });
    }

    updatedContentPods.splice(positionToMove, 0, ...contentPodsToMove);

    // Remove old versions
    const contentPodsToReturn = updatedContentPods.filter((contentPod) => !selectedPodUuids.includes(contentPod.uuid));
    const lastEditedUuid = contentPodsToMove[contentPodsToMove.length - 1].uuid;

    return { contentPods: contentPodsToReturn, lastEditedUuid };
};

export const copyPasteContentPodsToPosition = (
    contentPods: IContentPod[],
    selectedPodUuids: UUID[],
    positionToCopy: number
): { contentPods: IContentPod[]; lastEditedUuid: UUID } => {
    const contentPodsByUuid = convertArrayToObject(contentPods, 'uuid');
    const updatedContentPods = [...contentPods];
    const contentPodsToCopy = selectedPodUuids.map((uuid) => {
        const { duration, adBreaks, content } = contentPodsByUuid[uuid];
        return createNewContentPod(
            {
                duration,
                adBreaks,
            },
            content
        );
    });

    updatedContentPods.splice(positionToCopy, 0, ...contentPodsToCopy);

    const lastEditedUuid = contentPodsToCopy[contentPodsToCopy.length - 1].uuid;

    return { contentPods: updatedContentPods, lastEditedUuid };
};

export const deleteContentPods = (contentPods: IContentPod[], selectedPodUuids: UUID[]): IContentPod[] => {
    return contentPods.reduce((acc, pod) => {
        if (selectedPodUuids.includes(pod.uuid)) {
            if (!pod.isNew) {
                const updatedPod = {
                    ...pod,
                    isRemoved: true,
                };
                acc.push(updatedPod);
            }
        } else {
            acc.push(pod);
        }
        return acc;
    }, []);
};

export const getContentSelectorStepInitialState = (): IContentSelectorStep => {
    return {
        step: PodManagementStep.CONTENT_SELECTOR,
        selectedContentList: [],
        searchResults: [],
        searchQuery: '',
    };
};

export const getPodSelectorInitialState = (podData: ICuratedPodData, uuid: UUID, selectedPod: Seconds): IPodSelectorStep => {
    return {
        step: PodManagementStep.POD_SELECTOR,
        kind: PodSelectorView.EDIT,
        podData,
        uuid,
        selectedPod,
    };
};

export const getPodOptions = (content: IVllContent, targetAdvertDuration: number, targetAdvertPercentage: number): ICuratedPodData => {
    const hasAdMarkers = !!content.adBreaks.length;
    let defaultPod: IContentPodBase;

    if (hasAdMarkers) {
        content.adBreaks = adjustCuePoints(content.adBreaks, content.duration);
        defaultPod = getPodSuggestionsForContentWithCuePoints(content.adBreaks, content.duration, targetAdvertDuration);
    } else {
        defaultPod = getPodSuggestionsForContentWithoutCuePoints(content.duration, targetAdvertDuration, targetAdvertPercentage);
    }

    return {
        defaultPod,
        extendedPodOptions: [], // Currently not used
        content,
    };
};

export const uploadThumbnail = (imageData: ITimelineThumbnail['imageData'], useSpock = false): Promise<IClientThumbnail> => {
    return new Promise(async (resolve) => {
        const file = FileService.dataURItoFile(imageData, 'test', 'jpg');
        const files: File[] = [file];
        const payload = S3Service.getTargetsPayload(files);
        const { keyId, region, targets } = await S3Service.getUploadTargets(payload, useSpock);
        const filesWithMeta = S3Service.getFilesWithMeta(files, targets);

        const uploadInstance = await S3Service.upload(filesWithMeta, keyId, region, filesWithMeta[0].data.bucketName, useSpock);
        uploadInstance
            // tslint:disable-next-line:no-empty
            .start(() => {})
            // tslint:disable-next-line:no-empty
            .progress(() => {})
            .success((resultFile, data) => resolve(data))
            // todo: investigate why this never fires in the result of network error
            .error((error) => {
                Console.warn("Processing 'Thumbnail Upload'", error);
                resolve(undefined);
            });
    });
};
