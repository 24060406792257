import { put, takeEvery, select } from 'redux-saga/effects';

import { FeedbackFactory, IFeedbackContent, IFeedbackItem } from '~components/FeedbackModal';

import { IStudioState } from '../types';
import { FeedbackActions } from './constants';

const getFeedbackList = (state: IStudioState): IFeedbackItem[] => state.feedback.list;

function* handleAddFeedback(action) {
    const { payload } = action;
    const feedbackList: IFeedbackItem[] = yield select(getFeedbackList);
    const typeExists = feedbackList.find((feedback) => feedback.type === payload.type);

    if (!typeExists) {
        const { title, message, canDismiss, type }: IFeedbackContent = payload;
        const feedbackItem: IFeedbackItem = FeedbackFactory({ title, message, canDismiss, type });
        yield put({ type: FeedbackActions.ADD, payload: feedbackItem });
    } else {
        // This acton doesn't update state, only for visibility
        yield put({ type: FeedbackActions.ADD_IGNORED });
    }
}

export const feedbackSagas = [takeEvery(FeedbackActions.ADD_SAGA, handleAddFeedback)];
