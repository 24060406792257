import { put, select, takeEvery } from 'redux-saga/effects';

import { Console } from '~services/console';
import { SearchProviders } from '~services/searchProviders/algolia';
import { SearchActions } from '~src/store/search/constants';
import { searchAdBucketResults } from '~src/store/search/search.actions';
import { getSearchBucketState } from '~src/store/search/search.selectors';
import { SearchTerm } from '~src/store/search/types';

enum AlgoliaError {
    ExpiredApiKey = '"validUntil" parameter expired (less than current date)',
}

function* updateServiceKey(action) {
    try {
        const { apiKey } = yield SearchProviders.getNewAlgoliaKey();
        const { pageService } = action.payload;
        pageService.client.apiKey = apiKey;
    } catch (error) {
        // todo: process error
        Console.warn(error);
    }
}

const buildQuery = (indexName: string, facetFilters: string[], hitsPerPage: number, page: number, query: SearchTerm) => [
    {
        indexName,
        params: {
            facetFilters,
            hitsPerPage,
            page,
        },
        query,
    },
];

const isValidUntilError = (error) => {
    return error.statusCode === 400 && error.message === AlgoliaError.ExpiredApiKey;
};

function* handlePerformSearchQuery(action) {
    const { stateKey, pageService, searchTerm, facetFilters, indexName, hitsPerPage, callbackMiddleware } = action.payload;

    try {
        const query = buildQuery(indexName, facetFilters, hitsPerPage, 0, searchTerm);
        const { results: algoliaResults, nbHits, page, nbPages } = yield pageService.search(query);
        const results = yield callbackMiddleware(algoliaResults);

        yield put(searchAdBucketResults(stateKey, results, nbHits, page, nbPages));
    } catch (error) {
        if (isValidUntilError(error)) {
            yield updateServiceKey(action);
            yield handlePerformSearchQuery(action);
            return;
        }
        // todo: process error
        Console.warn(error);
    }
}

function* handleGetNextPage(action) {
    const { stateKey, pageService, indexName, facetFilters, hitsPerPage, searchTerm, callbackMiddleware } = action.payload;

    try {
        const { page: stalePage, results: previousResults } = yield select(getSearchBucketState(stateKey));
        const nextPage = stalePage + 1;

        const query = buildQuery(indexName, facetFilters, hitsPerPage, nextPage, searchTerm);
        const { results: algoliaResults, nbHits, page, nbPages } = yield pageService.search(query);
        const results = yield callbackMiddleware(algoliaResults);
        const resultsCollection = [...previousResults, ...results];

        yield put(searchAdBucketResults(stateKey, resultsCollection, nbHits, page, nbPages));
    } catch (error) {
        if (isValidUntilError(error)) {
            yield updateServiceKey(action);
            yield handleGetNextPage(action);
            return;
        }
        // todo: process error
        Console.warn(error);
    }
}

export const searchSagas = [
    takeEvery(SearchActions.PROCESS_SEARCH_SAGA, handlePerformSearchQuery),
    takeEvery(SearchActions.GET_NEXT_PAGE_SAGA, handleGetNextPage),
];
