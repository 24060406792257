import * as React from 'react';

import { fullScreenService } from '~services/fullScreen';
import { KeyCode } from '~src/views/types';

import './index.scss';

const FullScreen: React.NamedExoticComponent<React.PropsWithChildren<{}>> = React.memo(({ children }) => {
    const element = document.getElementsByTagName('body')[0];

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            if (fullScreenService.isFullScreen()) {
                fullScreenService.exitFullscreen();
            }
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === KeyCode.F) {
            if (fullScreenService.isFullScreen()) {
                fullScreenService.exitFullscreen();
            } else {
                fullScreenService.enterFullscreen(element);
            }
        }
    };

    return <React.Fragment>{children}</React.Fragment>;
});

export { FullScreen };
