import * as React from 'react';

import { ProfileMenu } from '~components/ProfileMenu';

import './index.scss';

interface IProps {
    realms: string[];
}

const AuthorisedLayout: React.FunctionComponent<IProps> = ({ realms, children }) => (
    <React.Fragment>
        <aside className="fixed-header">
            <ProfileMenu realms={realms} />
        </aside>
        {children}
    </React.Fragment>
);

export { AuthorisedLayout };
