import * as React from 'react';

import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { ITimeRange } from '~containers/EditorPageContainer/types';

import './index.scss';

interface IProps {
    seekableStart: number;
    seekableEnd: number;
    bufferedTimeRanges: ITimeRange[];
    durationChangeDelta: number;
}

const BufferComponent: React.NamedExoticComponent<IProps> = React.memo(
    ({ bufferedTimeRanges, seekableStart, seekableEnd, durationChangeDelta }) => {
        const getBufferedRange = (bufferedSection, index) => {
            const duration = Math.abs(seekableStart - seekableEnd);
            const left = 100 * (Math.abs(bufferedSection.start - durationChangeDelta) / duration);
            const width = 100 * (Math.abs(bufferedSection.end - durationChangeDelta) / duration) - left;
            const style = {
                left: `${left}%`,
                width: `${width}%`,
            };
            return <div className="buffer__range" key={index} style={style} />;
        };

        return <div className="buffer">{bufferedTimeRanges.map(getBufferedRange)}</div>;
    }
);

const Buffer = () => {
    const {
        editorPlayer: {
            bufferedTimeRanges,
            seekable: { start: seekableStart, end: seekableEnd },
        },
        durationChangeDelta,
    } = React.useContext(VideoContext);

    return (
        <BufferComponent
            bufferedTimeRanges={bufferedTimeRanges}
            seekableStart={seekableStart}
            seekableEnd={seekableEnd}
            durationChangeDelta={durationChangeDelta}
        />
    );
};

export { Buffer };
