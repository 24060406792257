enum ToastAction {
    ADD = 'toast/ADD',
    DISMISS = 'toast/DISMISS',
}

enum EToastType {
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    INFO = 'info',
}

export { ToastAction, EToastType };
