import * as React from 'react';

import VideoSolidIcon from '~components/Icons/VideoSolidIcon';

import './index.scss';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {}

const LiveBadge: React.FunctionComponent<IProps> = (props) => {
    const className = ['live-badge', ...(props.className ? [props.className] : [])];

    return (
        <div className={className.join(' ')}>
            <VideoSolidIcon className="live-badge__icon" />
            <span className="live-badge__text">Live</span>
        </div>
    );
};

export { LiveBadge };
