import { REALM_INITIAL_STATE, RealmActions } from './constants';
import { ReduceActions } from './realm.actions';
import { IRealmState } from './types';

export const realmReducer = (state: IRealmState = REALM_INITIAL_STATE, action: ReduceActions): IRealmState => {
    switch (action.type) {
        case RealmActions.STARTED_CREATING_REALM: {
            return {
                ...state,
                isCreating: true,
            };
        }
        case RealmActions.ERROR_CREATING_REALM: {
            return {
                ...state,
                isCreating: false,
            };
        }
        case RealmActions.SUCCESSFULLY_CREATED_REALM: {
            return {
                ...state,
                isCreating: false,
            };
        }
        default: {
            return state;
        }
    }
};
