import * as Hls from 'hls.js';
import debounce from 'lodash.debounce';
import * as moment from 'moment';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';

import * as Doris from '@dicetechnology/doris';

import { EditClip } from '~components/Editor/EditClip';
import { EditorHeader } from '~components/Editor/EditorHeader';
import { EditorTimelineControls } from '~components/Editor/EditorTimelineControls';
import EditorVideoPanel from '~components/Editor/EditorVideoPanel';
import { ExportClip } from '~components/Editor/ExportClip';
import { HotKeys } from '~components/Editor/Hotkeys';
import { TimelineControls } from '~components/Editor/TimelineControls';
import { IFeedbackContent } from '~components/FeedbackModal';
import { PageRoutes } from '~components/Root/constants';
import { RootContext } from '~components/Root/context';
import { EditorProvider } from '~containers/EditorPageContainer/EditorContext';
import { VideoProvider } from '~containers/EditorPageContainer/VideoContext';
import { initialPlayerState } from '~containers/EditorPageContainer/constants';
import { TimelineContainerWrapper } from '~containers/TimelineContainer';
import { Console } from '~services/console';
import { ISettingsSettings } from '~services/settings';
import { EZoomLevel, getNextZoomLevel, ZoomLevels, ZoomNudge } from '~services/zoomLevelService';
import { resetEditor } from '~src/store/editor/editor.actions';
import { resetExportProcess } from '~src/store/editor/export/export.actions';
import { processStream, streamHasEnded } from '~src/store/editor/stream/stream.actions';
import { IEditorStreamState } from '~src/store/editor/stream/types';
import { addFeedback } from '~src/store/feedback/feedback.actions';
import { setPreviewClip } from '~src/store/timeline/clip/clip.actions';
import { ITimelineClip } from '~src/store/timeline/clip/types';
import { PlayerActions } from '~src/store/timeline/constants';
import { cancelThumbnail, updateThumbnailImageData } from '~src/store/timeline/thumbnail/thumbnail.actions';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { resetTimeline } from '~src/store/timeline/timeline.actions';
import { setIsTrackingPlayhead } from '~src/store/timeline/toggle/toggle.actions';
import { IStudioState } from '~src/store/types';
import { Seconds, TimeFormats } from '~src/types';
import { useShallowEqualSelector } from '~src/views/hooks';

import {
    EAssetType,
    EditorModes,
    IEditorContext,
    IEditorMethods,
    IEditorParams,
    IPlayerControls,
    IPlayerState,
    IVideoContext,
    PlayerKeys,
} from './types';

interface IRoute {
    id: string;
}

interface IEditorRouteProps extends RouteComponentProps<IRoute> {
    assetType: EAssetType;
}

interface IEditorProps extends IEditorRouteProps {
    isDebugMode: boolean;
    dispatch: Dispatch;
    clips: ITimelineClip[];
    activeClipId: ITimelineClip['id'];
    previewClipId: ITimelineClip['id'];
    thumbnails: ITimelineThumbnail[];
    assetId: IEditorStreamState['assetId'];
    hlsStreamUrl: IEditorStreamState['hlsStreamUrl'];
    hlsStreamDRM: IEditorStreamState['hlsStreamDRM'];
    dashStreamUrl: IEditorStreamState['dashStreamUrl'];
    dashStreamDRM: IEditorStreamState['dashStreamDRM'];
    title: IEditorStreamState['title'];
    thumbnail: IEditorStreamState['thumbnail'];
    hasEventEnded: IEditorStreamState['hasEventEnded'];
    assetType: IEditorStreamState['assetType'];
    minimumClipDuration: ISettingsSettings['minimumClipDuration'];
}

interface IEditorState {
    [PlayerKeys.EDITOR]: IPlayerState;
    editorProperties: IEditorParams;
    durationChangeDelta: number;
    isHotKeyModalOpen: boolean;
}

interface IPlayersDictionary {
    [PlayerKeys.EDITOR]?: Doris.IPlayer;
    [PlayerKeys.PREVIEW]?: Doris.IPlayer;
}

const DEFAULT_EDITOR_PROPERTIES: IEditorParams = {
    zoomLevel: EZoomLevel.DEFAULT,
    editorMode: EditorModes.EDIT,
};

class EditorPageContainer extends React.PureComponent<IEditorProps, IEditorState> {
    private players: IPlayersDictionary = {} as IPlayersDictionary;
    private pendingThumbnail: boolean = false;

    public state = {
        [PlayerKeys.EDITOR]: { ...initialPlayerState },
        [PlayerKeys.PREVIEW]: { ...initialPlayerState },
        editorProperties: DEFAULT_EDITOR_PROPERTIES,
        durationChangeDelta: null,
        isHotKeyModalOpen: false,
    };

    public componentDidMount() {
        const {
            match: {
                params: { id },
            },
            assetType,
            dispatch,
        } = this.props;

        dispatch(processStream(parseInt(id, 10), assetType));
    }

    public componentDidUpdate(prevProps: Readonly<IEditorProps>, prevState: Readonly<IEditorState>, snapshot?: any): void {
        if (this.props.hlsStreamUrl && this.props.hlsStreamUrl !== prevProps.hlsStreamUrl) {
            this.videoControls.mounted();
        }
    }

    public componentWillUnmount(): void {
        // notify user when a live event moves to vod mode, issuing a feedback item for the user to dismiss
        if (this.state[PlayerKeys.EDITOR] && this.state[PlayerKeys.EDITOR].live === false && this.props.assetType === EAssetType.LIVE) {
            const { title: eventTitle } = this.props;
            const feedback: IFeedbackContent = {
                title: 'Event ended',
                message: `<p>
                        The event '${eventTitle || ''}' ended at ${moment().format(TimeFormats.HHmm)}
                    </p>`,
                canDismiss: true,
            };

            this.props.dispatch(addFeedback(feedback));
        }

        if (this.editorContext.canResetTimeLineState()) {
            this.editorContext.resetTimeLineState();
        }
        this.videoControls.destroy();
        this.props.dispatch(resetEditor());
    }

    private checkIsLive = (isLive: boolean) => {
        const { assetType, dispatch } = this.props;
        if (!isLive && assetType === EAssetType.LIVE) {
            dispatch(streamHasEnded());
        }
    };

    private dorisTimeUpdate = () => {
        const {
            currentTime,
            el,
            el: { currentTime: absoluteTime },
            atLiveEdge,
            live,
            seekable,
        } = this.players[PlayerKeys.EDITOR];

        const {
            [PlayerKeys.EDITOR]: { loop },
        } = this.state;

        let updatedCurrentTime = currentTime;
        // Handle loop
        if (loop) {
            const { start, end } = loop;
            if (absoluteTime < start || absoluteTime >= end) {
                const difference = absoluteTime - start;
                el.currentTime = start;
                updatedCurrentTime = updatedCurrentTime - difference;
            }
        }

        // todo: seekable is a new object all the time, so causes continuous re-renders
        this.setPlayerState(PlayerKeys.EDITOR, {
            currentTime: updatedCurrentTime,
            atLiveEdge,
            live,
            seekable,
        });
    };

    private listenToPlayerEvents = () => {
        const editorPlayer = this.players[PlayerKeys.EDITOR];

        if (!editorPlayer) {
            return;
        }

        editorPlayer.once(Doris.Events.LOADED_METADATA, () => {
            const { live } = editorPlayer;
            this.checkIsLive(live);

            // only perform time updates when the player is state aware.
            editorPlayer.on(Doris.Events.TIME_UPDATE, this.dorisTimeUpdate);
        });
        editorPlayer.on(Doris.Events.PAUSE, () => {
            // Fixes issue where rendered frame is not always frame-accurate on pause.
            editorPlayer.el.currentTime = editorPlayer.el.currentTime;
        });
        editorPlayer.once(Hls.Events.LEVEL_LOADED, (e, { details }) => {
            if (details && details.fragments && details.fragments[0]) {
                this.setPlayerState(PlayerKeys.EDITOR, {
                    loadPDT: details.fragments[0].programDateTime,
                    frameRate: 25, // todo: will come from the doris api in future.
                });
            }
        });
        editorPlayer.on(Doris.Events.DURATION_CHANGE, () => {
            // re-adding some code used to adjust timeline assets
            const { currentTime, duration, atLiveEdge, live, seekable } = editorPlayer;

            let durationChange: Seconds = 0;
            let durationChangeDelta: Seconds = 0;

            this.setState((previousState) => {
                const { duration: staleDuration = 0 } = previousState[PlayerKeys.EDITOR];
                const { durationChangeDelta: staleDurationChangeDelta = 0 } = previousState;

                durationChange = staleDuration ? duration - staleDuration : staleDuration;
                durationChangeDelta = staleDurationChangeDelta + durationChange;

                return {
                    durationChangeDelta,
                };
            });

            this.setPlayerState(PlayerKeys.EDITOR, {
                currentTime,
                duration,
                atLiveEdge,
                live,
                seekable,
            });

            // updates clips and thumbnail shifts
            // todo: import action and dont use it inline
            this.props.dispatch({ type: PlayerActions.LEVEL_LOADED_SHIFT, payload: durationChange });
        });
        editorPlayer.on(Hls.Events.BUFFER_APPENDED, () => this.debounceBuffer(editorPlayer));
        editorPlayer.on(Doris.Events.CAN_PLAY_THROUGH, () => this.setPlayerState(PlayerKeys.EDITOR, { ready: true }));
        editorPlayer.on(Doris.Events.WAITING, () => this.setPlayerState(PlayerKeys.EDITOR, { waiting: true }));
        editorPlayer.on(Doris.Events.SEEKING, () => {
            // a user seek will cancel pending async processes
            // actively cancelling the thumbnail capture process on user seek
            if (this.pendingThumbnail) {
                this.pendingThumbnail = false;
                const action = cancelThumbnail();
                this.props.dispatch(action);
            }

            this.setPlayerState(PlayerKeys.EDITOR, { waiting: true });
        });
        editorPlayer.on(Doris.Events.SEEKED, () => {
            this.setPlayerState(PlayerKeys.EDITOR, { waiting: false });
            if (this.pendingThumbnail) {
                this.pendingThumbnail = false;
                this.editorContext.captureImageData();
            }
        });
        editorPlayer.on(Doris.Events.PLAYING, () => this.setPlayerState(PlayerKeys.EDITOR, { waiting: false }));
        editorPlayer.on(Doris.Events.PLAY, () => this.setPlayerState(PlayerKeys.EDITOR, { paused: false }));
        editorPlayer.on(Doris.Events.ENDED, () => this.setPlayerState(PlayerKeys.EDITOR, { paused: true }));
    };

    private debounceBuffer = debounce((editorPlayer) => {
        const bufferedTimeRanges = [];
        for (let i = 0; i < editorPlayer.buffered.length; i++) {
            const timeRange = {
                start: editorPlayer.buffered.start(i),
                end: editorPlayer.buffered.end(i),
            };
            bufferedTimeRanges.push(timeRange);
        }
        this.setPlayerState(PlayerKeys.EDITOR, { bufferedTimeRanges });
    }, 100);

    public editorContext: IEditorMethods = {
        handleTimelineZoom: (zoomLevel: EZoomLevel): void => {
            this.setState((previousState) => {
                return { editorProperties: { ...previousState.editorProperties, zoomLevel } };
            });
            this.props.dispatch(setIsTrackingPlayhead(true));
        },
        handleTimelineZoomNudge: (zoomNudge: ZoomNudge) => {
            const {
                seekable: { start, end },
            } = this.state[PlayerKeys.EDITOR];
            const {
                editorProperties: { zoomLevel },
            } = this.state;
            const duration: Seconds = Math.abs(start - end) || 0;

            const nextZoomLevel = getNextZoomLevel(zoomNudge, zoomLevel, duration);

            if (nextZoomLevel) {
                this.editorContext.handleTimelineZoom(nextZoomLevel);
            }
        },
        toggleEditorMode: (editorMode: EditorModes): void => {
            this.setState((previousState) => {
                return { editorProperties: { ...previousState.editorProperties, editorMode } };
            });
        },
        resetTimeLineState: () => {
            this.editorContext.toggleEditorMode(EditorModes.EDIT);

            if (this.state[PlayerKeys.EDITOR].loop !== undefined) {
                this.videoControls.stopLoop();
            }

            const action = resetTimeline();
            this.props.dispatch(action);
        },
        canTogglePreview: (): boolean => {
            return this.props.previewClipId !== null || this.editorContext.canPreview();
        },
        canPreview: (): boolean => {
            const { clips, activeClipId } = this.props;
            if (clips.length === 1) {
                /**
                 * when there is a single clip, check to see if the `exportClipOutTime` has a value
                 */
                const [clip] = clips;
                return clip.exportClipOutTime !== null;
            } else if (activeClipId !== null) {
                /**
                 * when there is an active clip, check to see if the `exportClipOutTime` has a value
                 */
                const clip = clips.find((c) => c.id === activeClipId);
                return clip.exportClipOutTime !== null;
            }
            return false;
        },
        canExport: () => {
            const { clips, minimumClipDuration } = this.props;
            return clips.some(({ exportClipInTime, exportClipOutTime }) => {
                return exportClipOutTime !== null && Math.abs(exportClipInTime - exportClipOutTime) > minimumClipDuration;
            });
        },
        isPreviewing: (): boolean => {
            return this.props.previewClipId !== null;
        },
        canResetTimeLineState: (): boolean => {
            const { clips, thumbnails } = this.props;
            return !!clips.length || !!thumbnails.length;
        },
        canZoom: (zoomLevel: EZoomLevel): boolean => {
            const zoomLevelSeconds = ZoomLevels[zoomLevel].seconds;
            const {
                seekable: { start, end },
                live: isLive,
            } = this.state[PlayerKeys.EDITOR];

            if (isLive) {
                return Math.abs(start) >= zoomLevelSeconds;
            } else {
                return end >= zoomLevelSeconds;
            }
        },
        canAddThumbnail: () => {
            const { clips, minimumClipDuration } = this.props;
            return clips.some(({ exportClipInTime, exportClipOutTime }) => {
                return exportClipOutTime !== null && Math.abs(exportClipInTime - exportClipOutTime) > minimumClipDuration;
            });
        },
        captureImageData: () => {
            if (this.state[PlayerKeys.EDITOR].waiting) {
                this.pendingThumbnail = true;
                return;
            }
            try {
                const imageData: string = this.playerToImage('editor-player');
                const addAction = updateThumbnailImageData(imageData);
                this.props.dispatch(addAction);
            } catch (error) {
                Console.warn("Processing 'Live Asset Meta':", error);
                const feedback = {
                    title: 'ERROR',
                    message: `<p>Unable to capture a thumbnail at the moment, please try again.</p>`,
                    type: 'thumbnail',
                    canDismiss: true,
                };
                this.props.dispatch(addFeedback(feedback));
            }
        },
    };

    public videoControls: IPlayerControls = {
        mounted: async () => {
            await this.setupEditorPlayer();

            // todo: introduce a second mount for live and let the display/mount of component handle it
            if (this.props.assetType === EAssetType.LIVE) {
                this.setupLivePlayer();
            }

            this.listenToPlayerEvents();
        },
        togglePlay: () => {
            const player = this.players[PlayerKeys.EDITOR];
            player.paused ? this.videoControls.play(PlayerKeys.EDITOR) : this.videoControls.pause(PlayerKeys.EDITOR);
        },
        play: async (playerKey: PlayerKeys) => {
            try {
                await this.players[playerKey].play();
                this.setPlayerState(playerKey, { paused: false });
            } catch (e) {
                Console.warn("Processing 'Video Playback':", e);
            }
        },
        pause: (playerKey) => {
            this.players[playerKey].pause();
            this.setPlayerState(playerKey, { paused: true });
        },
        toggleMute: (playerKey) => {
            const muteState = !this.players[playerKey].muted;
            this.players[playerKey].muted = muteState;
            this.setPlayerState(playerKey, { muted: muteState });
        },
        nudge: (amount: number) => this.players[PlayerKeys.EDITOR].nudge(amount),
        nudgeVolume: (playerKey, amount) => this.players[playerKey].nudgeVolume(amount),
        seek: (playerKey, position) => (this.players[playerKey].currentTime = position),
        seekToWallClock: (exportPosition) => {
            if (this.props.assetType === EAssetType.LIVE) {
                const pdt = this.state[PlayerKeys.EDITOR].loadPDT;
                const exportPositionMillis = exportPosition * 1000;
                const wallClock = new Date(pdt + exportPositionMillis);
                // @ts-ignore
                this.players[PlayerKeys.EDITOR].currentDate = wallClock;
            } else {
                this.videoControls.seek(PlayerKeys.EDITOR, exportPosition);
            }
        },
        getCurrentTime: (playerKey = PlayerKeys.EDITOR) => this.players[playerKey].currentTime,
        getCurrentEditorTime: (playerKey = PlayerKeys.EDITOR) => this.players[playerKey].el.currentTime,
        goLive: () => {
            this.videoControls.seek(PlayerKeys.EDITOR, 0);
            this.videoControls.play(PlayerKeys.EDITOR);
        },
        loop: (start, end) => {
            this.editorContext.toggleEditorMode(EditorModes.PREVIEW);

            if (this.players[PlayerKeys.EDITOR].paused) {
                this.videoControls.play(PlayerKeys.EDITOR);
            }

            this.setPlayerState(PlayerKeys.EDITOR, {
                loop: { start, end },
            });
        },
        stopLoop: () => {
            this.editorContext.toggleEditorMode(EditorModes.EDIT);
            this.setPlayerState(PlayerKeys.EDITOR, {
                loop: undefined,
            });
        },
        destroy: () => {
            const editorPlayer = this.players[PlayerKeys.EDITOR];
            const livePlayer = this.players[PlayerKeys.PREVIEW];

            if (editorPlayer) {
                editorPlayer.destroy();
            }
            if (livePlayer) {
                livePlayer.destroy();
            }

            this.players = {};
        },
    };

    private setPlayerState = (playerKey: PlayerKeys, state: Partial<IPlayerState>) => {
        this.setState((previousState) => {
            const newState: Partial<IEditorState> = {
                [playerKey]: {
                    ...previousState[playerKey],
                    ...state,
                },
            };
            return newState as IEditorState;
        });
    };

    public render() {
        const { assetId, hasEventEnded, hlsStreamUrl, title, thumbnail, assetType } = this.props;
        const {
            durationChangeDelta,
            editorProperties: { editorMode = null, zoomLevel },
            isHotKeyModalOpen,
        } = this.state;

        if (this.getStreamIsDRM()) {
            const feedback: IFeedbackContent = {
                title: 'Cannot Edit Video',
                message: `<p>
                        Only non-DRM videos can currently be edited
                    </p>`,
                canDismiss: true,
            };

            this.props.dispatch(addFeedback(feedback));
            return <Redirect to={{ pathname: PageRoutes.EDITOR }} />;
        }

        const { canResetTimeLineState, canTogglePreview, canExport, resetTimeLineState, isPreviewing } = this.editorContext;

        const editorPlayer = this.state[PlayerKeys.EDITOR];
        const previewPlayer = this.state[PlayerKeys.PREVIEW];

        if (hasEventEnded) {
            return <Redirect to={{ pathname: PageRoutes.EDITOR }} />;
        } else if (hlsStreamUrl && editorPlayer) {
            const editorProviderValue: IEditorContext = {
                ...this.state.editorProperties,
                ...this.editorContext,
            };

            const videoProviderValue: IVideoContext = {
                ...this.videoControls,
                [PlayerKeys.EDITOR]: { ...editorPlayer },
                [PlayerKeys.PREVIEW]: { ...previewPlayer },
                hlsStreamUrl,
                title,
                thumbnail,
                assetType,
                assetId,
                durationChangeDelta,
            };

            return (
                <EditorProvider value={editorProviderValue}>
                    <VideoProvider value={videoProviderValue}>
                        <section className="editor">
                            <EditorHeader title={title} assetType={assetType} />
                            <EditorVideoPanel />
                            <EditorTimelineControls assetType={assetType} zoomLevel={zoomLevel} />
                            <TimelineContainerWrapper
                                setPreviewMode={this.setPreviewMode}
                                setEditMode={this.setEditMode}
                                assetType={assetType}
                                hlsStreamUrl={hlsStreamUrl}
                            />
                            <div className="timeline-section">
                                <TimelineControls
                                    isPaused={editorPlayer.paused}
                                    togglePlay={this.videoControls.togglePlay}
                                    playerNudge={this.videoControls.nudge}
                                    canExport={canExport}
                                    setExportMode={this.setExportMode}
                                    canTogglePreview={canTogglePreview}
                                    isPreviewing={isPreviewing}
                                    togglePreviewMode={this.togglePreviewMode}
                                    canResetTimeLineState={canResetTimeLineState}
                                    resetTimeLineState={resetTimeLineState}
                                    toggleHotKeyModal={this.toggleHotKeyModal}
                                />
                            </div>
                            <HotKeys toggleIsOpen={this.toggleHotKeyModal} isOpen={isHotKeyModalOpen} />
                            <ExportClip
                                isOpen={editorMode === EditorModes.EXPORT}
                                reset={this.resetExportProcess}
                                assetType={assetType}
                                loadPDT={editorPlayer.loadPDT}
                            />
                            <EditClip />
                        </section>
                    </VideoProvider>
                </EditorProvider>
            );
        } else {
            return null;
        }
    }

    private setExportMode = () => {
        this.editorContext.toggleEditorMode(EditorModes.EXPORT);
    };

    private toggleHotKeyModal = () => {
        this.setState((previousState) => ({ isHotKeyModalOpen: !previousState.isHotKeyModalOpen }));
    };

    private resetExportProcess = () => {
        const action = resetExportProcess();
        this.props.dispatch(action);

        this.videoControls.stopLoop();
        this.editorContext.toggleEditorMode(EditorModes.EDIT);
    };

    private setEditMode = () => {
        const action = setPreviewClip(null);
        this.props.dispatch(action);

        this.videoControls.stopLoop();
        this.editorContext.toggleEditorMode(EditorModes.EDIT);
    };

    private setPreviewMode = () => {
        const { clips, activeClipId } = this.props;

        const clip: ITimelineClip = activeClipId
            ? clips.find((c) => c.id === activeClipId)
            : clips.find((c) => c.exportClipOutTime !== null);

        if (clip) {
            const { exportClipInTime, exportClipOutTime, id } = clip;

            const action = setPreviewClip(id);
            this.props.dispatch(action);

            this.videoControls.loop(exportClipInTime, exportClipOutTime);
            this.editorContext.toggleEditorMode(EditorModes.PREVIEW);
        }
    };

    private togglePreviewMode = () => {
        const { previewClipId } = this.props;
        previewClipId ? this.setEditMode() : this.setPreviewMode();
    };

    private getStreamIsDRM = () => {
        return !!this.props.hlsStreamDRM;
    };

    private setupLivePlayer = () => {
        let livePlayer = this.players[PlayerKeys.PREVIEW];

        if (livePlayer) {
            livePlayer.destroy();
        }

        const source: Doris.Source = {
            type: Doris.StreamTypes.HLS,
            url: this.props.hlsStreamUrl,
        };

        livePlayer = this.players[PlayerKeys.PREVIEW] = new Doris.Player('live-preview');

        const liveConfig: Doris.IConfig = {
            autoPlay: Doris.AutoPlayOptions.MUTED,
            hlsConfig: {
                capLevelToPlayerSize: true,
                startFragPrefetch: true,
                liveBackBufferLength: 0,
            },
            debug: {
                enabled: false,
            },
        };
        livePlayer.load(source, liveConfig);
    };

    private setupEditorPlayer = async () => {
        let editorPlayer = this.players[PlayerKeys.EDITOR];

        // tear down players
        if (editorPlayer) {
            editorPlayer.destroy();
        }

        const source: Doris.Source = {
            type: Doris.StreamTypes.HLS,
            url: this.props.hlsStreamUrl,
        };

        if (this.getStreamIsDRM()) {
            return null;
        }

        // todo: this will need updating when dorris is updated -> https://github.com/DiceTechnology/doris/projects/1#card-20068560
        // override player debug by debug application state, as it will change outside control over video
        const newPlayerDebugState = this.props.isDebugMode ? 'ON' : 'OFF';
        localStorage.setItem('PLAYER_DEBUG', newPlayerDebugState);

        editorPlayer = this.players[PlayerKeys.EDITOR] = new Doris.Player('editor-player');

        const editorConfig: Doris.IConfig = {
            autoPlay: Doris.AutoPlayOptions.MUTED,
            hlsConfig: {
                startLevel: 8,
                capLevelToPlayerSize: false, // allow best rendition for connection
                startFragPrefetch: true,
            },
            debug: {
                enabled: true,
            },
        };
        await editorPlayer.load(source, editorConfig);
    };

    private playerToImage = (playerId, width = 1920, height = 1080) => {
        const $video = document.getElementById(playerId).querySelectorAll('video')[0];
        const $canvas = document.createElement('canvas');

        $canvas.width = width;
        $canvas.height = height;
        $canvas.getContext('2d').drawImage($video, 0, 0, $canvas.width, $canvas.height);

        return $canvas.toDataURL('image/jpeg');
    };
}

const getEditorState = (state: IStudioState) => {
    const {
        timeline: {
            clips: { list: clips, activeClipId, previewClipId },
            thumbnails: { list: thumbnails },
        },
        editor: { stream },
    } = state;

    return {
        clips,
        activeClipId,
        previewClipId,
        thumbnails,
        stream,
    };
};

const WrappedEditorContainer = (props) => {
    const { clips, activeClipId, previewClipId, thumbnails, stream } = useShallowEqualSelector(getEditorState);
    const dispatch = useDispatch();
    const {
        isDebugMode,
        settings: { minimumClipDuration },
    } = React.useContext(RootContext);

    return (
        <EditorPageContainer
            isDebugMode={isDebugMode}
            dispatch={dispatch}
            clips={clips}
            activeClipId={activeClipId}
            previewClipId={previewClipId}
            thumbnails={thumbnails}
            minimumClipDuration={minimumClipDuration}
            {...stream}
            {...props}
        />
    );
};

export default withRouter<IEditorRouteProps, React.FunctionComponent>(WrappedEditorContainer);
