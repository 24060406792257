import { PlayerActions, TimelineActions } from '~src/store/timeline/constants';
import { ThumbnailActions } from '~src/store/timeline/thumbnail/constants';
import { ThumbnailUtilities } from '~src/store/timeline/thumbnail/thumbnail.utilities';
import { ITimelineThumbnail, ITimelineThumbnailState } from '~src/store/timeline/thumbnail/types';

const DEFAULT_STATE: ITimelineThumbnailState = {
    list: [],
    hoverThumbnailId: null,
    displayThumbnails: true,
    clipIdPendingThumbnail: null,
    pendingThumbnailId: null,
};

const thumbnailReducer = (state: ITimelineThumbnailState = DEFAULT_STATE, { type, payload }): ITimelineThumbnailState => {
    switch (type) {
        case ThumbnailActions.CANCEL:
            return {
                ...state,
                clipIdPendingThumbnail: null,
                pendingThumbnailId: null,
            };
        case ThumbnailActions.ADD: {
            const { renderThumbnailTime, exportThumbnailTime } = payload;
            const thumbnail = ThumbnailUtilities.buildThumbnail(renderThumbnailTime, exportThumbnailTime);
            return {
                ...state,
                list: [thumbnail, ...state.list],
                pendingThumbnailId: thumbnail.id,
            };
        }
        case ThumbnailActions.ADD_WITH_CLIP: {
            const { thumbnail, clipIdPendingThumbnail } = payload;
            return {
                ...state,
                list: [thumbnail, ...state.list],
                clipIdPendingThumbnail,
                pendingThumbnailId: thumbnail.id,
            };
        }
        case ThumbnailActions.REMOVE: {
            const { id } = payload;
            return {
                ...state,
                list: state.list.filter((thumbnail: ITimelineThumbnail) => thumbnail.id !== id),
            };
        }
        case ThumbnailActions.SET_FOCUS_THUMBNAIL: {
            const { id } = payload;
            return {
                ...state,
                hoverThumbnailId: id,
            };
        }
        case ThumbnailActions.UPDATE_IMAGE_DATA: {
            const thumbnailId = state.pendingThumbnailId;
            return {
                ...state,
                clipIdPendingThumbnail: null,
                pendingThumbnailId: null,
                list: state.list.map((thumbnail: ITimelineThumbnail) => {
                    if (thumbnail.id === thumbnailId) {
                        return {
                            ...thumbnail,
                            imageData: payload,
                            loading: false,
                        };
                    }
                    return thumbnail;
                }),
            };
        }
        case ThumbnailActions.TOGGLE_VISIBILITY:
            return {
                ...state,
                displayThumbnails: !state.displayThumbnails,
            };
        case PlayerActions.LEVEL_LOADED_SHIFT:
            return {
                ...state,
                list: state.list.map(
                    (thumbnail: ITimelineThumbnail): ITimelineThumbnail => {
                        const renderThumbnailTime = thumbnail.renderThumbnailTime;
                        return {
                            ...thumbnail,
                            renderThumbnailTime: renderThumbnailTime - payload,
                        };
                    }
                ),
            };
        case TimelineActions.RESET:
            return DEFAULT_STATE;
        default:
            return state;
    }
};

export { thumbnailReducer };
