import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { paginationAdd, paginationGetNextPage, paginationRemove } from '~src/store/pagination/pagination.actions';
import { getPaginationState } from '~src/store/pagination/pagination.selectors';
import { IContentType, PagerService, StateKey } from '~src/types';
import { useShallowEqualSelector } from '~src/views/hooks';

interface IContainerProps {
    stateKey: StateKey;
    pageService: PagerService;
    render: (results: IContentType[], status: string, hasMoreResults: boolean, getNextPage: () => void) => JSX.Element;
}

interface IProps extends IContainerProps {
    results: IContentType[];
    hasMoreResults: boolean;
    status: string;
    dispatch: Dispatch;
}

const PaginationComponent = ({ dispatch, render, stateKey, results, hasMoreResults, status, pageService }: IProps) => {
    React.useEffect(() => {
        dispatch(paginationAdd(stateKey, pageService));
        return () => {
            dispatch(paginationRemove(stateKey));
        };
    }, []);

    const getNextPage = () => {
        dispatch(paginationGetNextPage(stateKey, pageService));
    };

    return render(results, status, hasMoreResults, getNextPage);
};

const Pagination: React.FunctionComponent<IContainerProps> = ({ stateKey, pageService, render }: IContainerProps) => {
    const dispatch = useDispatch();
    const { results = [], hasMoreResults = false, status = null } = useShallowEqualSelector(getPaginationState(stateKey));
    return (
        <PaginationComponent
            pageService={pageService}
            stateKey={stateKey}
            render={render}
            dispatch={dispatch}
            results={results}
            hasMoreResults={hasMoreResults}
            status={status}
        />
    );
};

export { Pagination };
