import { createContext } from 'react';

import { EZoomLevel } from '~services/zoomLevelService';

import { IEditorContext, EditorModes } from './types';

const DEFAULT_FUNCTION = () => null;

const INITIAL_STATE: IEditorContext = {
    zoomLevel: EZoomLevel.DEFAULT,
    editorMode: EditorModes.EDIT,

    resetTimeLineState: DEFAULT_FUNCTION,
    toggleEditorMode: DEFAULT_FUNCTION,
    canPreview: DEFAULT_FUNCTION,
    isPreviewing: DEFAULT_FUNCTION,
    canTogglePreview: DEFAULT_FUNCTION,
    canExport: DEFAULT_FUNCTION,
    canResetTimeLineState: DEFAULT_FUNCTION,
    canZoom: DEFAULT_FUNCTION,

    /** *******************
     * Thumbnail
     */
    canAddThumbnail: DEFAULT_FUNCTION,
    captureImageData: DEFAULT_FUNCTION,

    /** *******************
     * Timeline zooming
     */
    handleTimelineZoom: DEFAULT_FUNCTION,
    handleTimelineZoomNudge: DEFAULT_FUNCTION,
};

export const EditorContext = createContext<IEditorContext>(INITIAL_STATE);
export const EditorProvider = EditorContext.Provider;
export const EditorConsumer = EditorContext.Consumer;
