import * as React from 'react';

import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { joinStrings, getTimeLabel } from '~services/utilities';
import { TimeFormats } from '~src/types';

interface IContainerProps {
    position: number;
    time: number;
    animated: boolean;
}

interface IProps extends IContainerProps {
    atLiveEdge: boolean;
    isPaused: boolean;
    waiting: boolean;
}

const PlayHeadComponent: React.FC<IProps> = (props) => {
    const { position, atLiveEdge, isPaused, time, animated, waiting } = props;

    const dragImage = React.useRef<HTMLImageElement>(new Image());

    React.useEffect(() => {
        dragImage.current.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    }, [dragImage.current]);

    const onDragStart = (e: React.DragEvent) => {
        e.dataTransfer.setDragImage(dragImage.current, 0, 0);
    };

    const style = { left: `${Math.min(position, 100)}%` };

    const className = joinStrings(['playhead', atLiveEdge && !isPaused && 'playhead--live', animated && !waiting && 'animate']);

    return (
        <div className="playhead-wrapper">
            <div style={style} className={className} draggable={true} onDragStart={onDragStart}>
                <span className="the-head">
                    <span className="the-head__tip" />
                </span>
                <span className="playhead__time">{getTimeLabel(time, TimeFormats.HHmmssSS)}</span>
            </div>
        </div>
    );
};

const PlayHead: React.FunctionComponent<IContainerProps> = (props) => {
    const {
        editorPlayer: { atLiveEdge, paused, waiting },
    } = React.useContext(VideoContext);
    return <PlayHeadComponent {...props} isPaused={paused} atLiveEdge={atLiveEdge} waiting={waiting} />;
};

export { PlayHead };
