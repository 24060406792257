import { ITimelineToggleState } from '~src/store/timeline/toggle//types';
import { TogglesActions } from '~src/store/timeline/toggle/constants';

const DEFAULT_STATE: ITimelineToggleState = {
    isTrackingPlayhead: true,
};

const toggleReducer = (state: ITimelineToggleState = DEFAULT_STATE, { type, payload }): ITimelineToggleState => {
    switch (type) {
        case TogglesActions.TRACK_PLAYHEAD:
            return {
                ...state,
                isTrackingPlayhead: payload.isTrackingPlayhead,
            };
        default:
            return state;
    }
};

export { toggleReducer };
