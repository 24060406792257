import * as React from 'react';

import { IDataTestId } from '~src/views/types';

import './index.scss';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, IDataTestId {}

const LoadMoreButtonComponent: React.FunctionComponent<IProps> = ({ onClick, dataTestId }) => (
    <button className="pagination__btn-loadmore" type="button" onClick={onClick} data-test-id={dataTestId}>
        Load More
    </button>
);

const LoadMoreButton: React.NamedExoticComponent<IProps> = React.memo(LoadMoreButtonComponent);

export { LoadMoreButton };
