import * as React from 'react';

import Button from '~components/Button';
import { CopyIcon } from '~components/Icons/CopyIcon';
import { FilePlusIcon } from '~components/Icons/FilePlusIcon';
import Modal, { TextJustify } from '~components/Modal';
import { SaveAction } from '~containers/EditorPageContainer/types';

interface IProps {
    isSingularClip: boolean;
    setSaveAction: (saveAction: SaveAction) => void;
}

const SaveSelection: React.FunctionComponent<IProps> = (props) => {
    const { isSingularClip, setSaveAction } = props;

    const setSaveActionHandler = (saveAction: SaveAction) => () => {
        setSaveAction(saveAction);
    };

    return (
        <React.Fragment>
            <Modal.Title justify={TextJustify.CENTER}>Export</Modal.Title>
            <Modal.Body justify={TextJustify.CENTER} className="export-modal__save-selection">
                <div className="selection-card">
                    <div className="selection-card__icon">
                        <FilePlusIcon height="50" width="50" />
                    </div>
                    <div className="selection-card__content">
                        <h3>New {isSingularClip ? 'Clip' : 'Clips'}</h3>
                        <p>Create {isSingularClip ? 'a new asset' : 'new assets'} from the newly selected content.</p>
                    </div>
                    <Button className="selection-card__button" onClick={setSaveActionHandler(SaveAction.CREATE)}>
                        Create New {isSingularClip ? 'Clip' : 'Clips'}
                    </Button>
                </div>
                <div className="selection-card">
                    <div className="selection-card__icon">
                        <CopyIcon height="50" width="50" />
                    </div>
                    <div className="selection-card__content">
                        <h3>Overwrite Clip</h3>
                        <p>Replace the exiting asset with the newly selected content.</p>
                    </div>
                    <Button className="selection-card__button" onClick={setSaveActionHandler(SaveAction.OVERWRITE)}>
                        Overwrite Existing Clip
                    </Button>
                </div>
            </Modal.Body>
        </React.Fragment>
    );
};

export { SaveSelection };
