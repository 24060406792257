import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import v4 from 'uuid/v4';

import { Button, ButtonTheme } from '~components/Button';
import ExclamationIcon from '~components/Icons/ExclamationIcon';
import Modal from '~components/Modal';
import { dismissFeedback } from '~src/store/feedback/feedback.actions';
import { IStudioState } from '~src/store/types';
import { useShallowEqualSelector } from '~src/views/hooks';

import './index.scss';

interface IFeedbackContent {
    title: string;
    message?: string | React.ReactElement;
    canDismiss?: boolean;
    type?: string;
}

interface IFeedbackItem extends IFeedbackContent {
    id: string;
}

interface IFeedbackProps {
    feedbackList: IFeedbackItem[];
    dispatch: Dispatch;
}

const FeedbackFactory = ({ title, message, canDismiss, type }: IFeedbackContent): IFeedbackItem => ({
    id: v4(),
    title,
    message,
    canDismiss,
    type,
});

const FeedbackModalComponent = ({ feedbackList, dispatch }: IFeedbackProps) => {
    const [firstFeedbackItem] = feedbackList;
    const onClose = () => dispatch(dismissFeedback(firstFeedbackItem.id));

    // automatically drop focused elements
    if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
    }

    return (
        <Modal.Main hasOverlay={true} className="feedback-modal">
            <Modal.Body justify="center">
                <React.Fragment>
                    <div className="icon">
                        <ExclamationIcon height="24" width="24" />
                    </div>
                    <Modal.Title>{firstFeedbackItem.title}</Modal.Title>
                    {/* TODO: Temporary solution for now. Feedback messages needs a revisit */}
                    <span dangerouslySetInnerHTML={{ __html: firstFeedbackItem.message as string }} />

                    <ul className="inline-list align-center">
                        {firstFeedbackItem.canDismiss && (
                            <li className="list-item">
                                <Button theme={ButtonTheme.BLUE} type="button" onClick={onClose}>
                                    Close
                                </Button>
                            </li>
                        )}
                        {/* todo: not required as of yet, but ground work for passed in actions */}
                        {/*{!!firstFeedbackItem.actions.length && firstFeedbackItem.actions.map(({ text, callback }, index) => (*/}
                        {/*<li key={index} className="list-item">*/}
                        {/*<Button theme="secondary" type="button" onClick={callback}>*/}
                        {/*{text}*/}
                        {/*</Button>*/}
                        {/*</li>*/}
                        {/*))}*/}
                    </ul>
                </React.Fragment>
            </Modal.Body>
        </Modal.Main>
    );
};

const getFeedbackList = (state: IStudioState): IFeedbackItem[] => state.feedback.list;

const FeedbackModal = () => {
    const feedbackList = useShallowEqualSelector(getFeedbackList);
    const dispatch = useDispatch();

    if (!feedbackList.length) {
        return null;
    }

    return <FeedbackModalComponent feedbackList={feedbackList} dispatch={dispatch} />;
};

export { FeedbackModal, FeedbackFactory, IFeedbackContent, IFeedbackItem };
