import { IRealmState } from './types';

enum RealmActions {
    STARTED_CREATING_REALM = 'r/STARTED_CREATING_REALM',
    SUCCESSFULLY_CREATED_REALM = 'r/SUCCESSFULLY_CREATED_REALM',
    ERROR_CREATING_REALM = 'r/ERROR_CREATING_REALM',
    CREATE_REALM = 'r/CREATE_REALM',
}

export const REALM_INITIAL_STATE: IRealmState = {
    isCreating: false,
};

export const TOAST_TIMEOUT = 3000;

export const CREATE_REALM_SUCCESS_NOTIFICATION = {
    title: null,
    message: 'Successfully Created Realm',
};

export { RealmActions };
