import * as React from 'react';

import { EllipseIcon } from '~components/Icons/EllipseIcon';
import { SimpleDropdownMenu } from '~components/SimpleDropdownMenu';
import { joinStrings } from '~services/utilities';

import { ILinkMenuItem, LinkMenuItem } from './MenuItem';
import './index.scss';

interface IProps {
    menuItems: ILinkMenuItem[];
    className?: string;
    triggerChildren?: React.ReactNode;
}

const LinkListDropdownMenu: React.FunctionComponent<IProps> = ({
    menuItems,
    className,
    triggerChildren = <ThreeDotsIcon />,
    ...restProps
}) => {
    const onMenuClickHandler = (callback: ILinkMenuItem['callback'], setVisibility: React.Dispatch<React.SetStateAction<boolean>>) => (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        setVisibility(false);
        callback(event);
    };

    return (
        <SimpleDropdownMenu triggerChildren={triggerChildren} className={joinStrings(['link-menu', className])} {...restProps}>
            {(setVisibility: React.Dispatch<React.SetStateAction<boolean>>) => (
                <ul>
                    {menuItems.map(({ title, titleIcon, callback, className: menuItemClassName, testId }, index) => (
                        <LinkMenuItem
                            key={index}
                            title={title}
                            titleIcon={titleIcon}
                            callback={onMenuClickHandler(callback, setVisibility)}
                            className={menuItemClassName}
                            testId={testId}
                        />
                    ))}
                </ul>
            )}
        </SimpleDropdownMenu>
    );
};

const ThreeDotsIcon: React.FC = () => {
    return (
        <div className="link-menu__three-dots-icon">
            <EllipseIcon />
        </div>
    );
};

export { LinkListDropdownMenu };
