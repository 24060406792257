import { AuthenticationLink } from '@dicetechnology/dice-backoffice-ui-components';

import { PageRoutes } from '~components/Root/constants';

export const ERROR_TOAST_TITLE = 'Unable to reset your password';
export const ERROR_TOAST_MESSAGE = 'We are unable to reset your password. If this persists please contact support';

export const SUCCESS_TOAST_TITLE = 'Password reset';
export const SUCCESS_TOAST_MESSAGE = 'Your password has been reset, please check your email for more information';

export const AUTHENTICATION_LINKS: AuthenticationLink[] = [
    {
        label: 'Back to Login',
        to: PageRoutes.LOGIN,
    },
];
