import { IPaginationBucketState } from '~src/store/pagination/types';
import { IStudioState } from '~src/store/types';
import { StateKey } from '~src/types';

const getPaginationState = (stateKey: StateKey) => (state: IStudioState): IPaginationBucketState => {
    const emptyState: IPaginationBucketState = {
        results: [],
        hasMoreResults: false,
    };
    return state.pagination[stateKey] || emptyState;
};

export { getPaginationState };
