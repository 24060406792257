import Mist from 'dice-mist';
import { sha256 } from 'js-sha256';
import SparkMD5 from 'spark-md5';

import { AUTH_TOKEN_KEY } from '@dicetechnology/dice-unity/lib/services/Http/constants';

import { authenticationService } from '~services/authentication';
import { FileService } from '~services/file';
import { http, httpStargateSpockV1 } from '~services/http';
import { STD__STARGATE_SPOCK_API, STUDIO_API_V1 } from '~services/http/constants';
import { Endpoints } from '~services/s3Service/constants';
import { storageProvider } from '~services/storage';

interface IFileTargets {
    [key: string]: ITarget[];
}

interface ITarget {
    bucketName: string;
    path: string;
    id: number;
}

interface IIThumbnailTargetResponse {
    parsedData: IThumbnailTarget;
}

interface IThumbnailTarget {
    keyId: string;
    region: string;
    targets: IFileTargets;
}

class S3Service {
    private static PART_SIZE_MB = 8388608; // min = 5242880

    public static getTargetsPayload(files: File[]) {
        return { targetsNumberByExtension: FileService.getExtensionTotals(files) };
    }

    public static getFilesWithMeta(files, targets) {
        return files.map((fileInstance) => {
            const extension = FileService.getFIleExtension(fileInstance);
            const data = targets[extension].pop();
            return {
                file: fileInstance,
                data,
            };
        });
    }

    private static async getConfig(keyId, awsRegion, bucket, worker = false, useSpock = false) {
        const authToken = await storageProvider.get(AUTH_TOKEN_KEY);

        const realm = await authenticationService.getRealm();

        const signerBaseUrl = useSpock ? STD__STARGATE_SPOCK_API : STUDIO_API_V1;

        return {
            bucket,
            aws_key: keyId,
            awsRegion,
            worker,
            computeContentMd5: true,
            signHeaders: {
                Authorization: `Bearer ${authToken}`,
                Realm: realm,
                'x-api-key': process.env.STD__X_API_KEY,
            },
            signerUrl: `${signerBaseUrl}${Endpoints.SIGN_UPLOAD}`,
            s3Acceleration: false,
            logging: false,
            mockLocalStorage: true,
            progressIntervalMS: 1000,
            maxConcurrentParts: 6,
            partSize: S3Service.PART_SIZE_MB,
            sendCanonicalRequestToSignerUrl: false,
        };
    }

    public static async upload(files, keyId, awsRegion, bucket, useSpock = false) {
        const baseConfig = await S3Service.getConfig(keyId, awsRegion, bucket, false, useSpock);
        const config = {
            ...baseConfig,
            cryptoMd5Method,
            cryptoHexEncodedHash256,
        };
        return Mist(config, files);
    }

    public static async getUploadTargets(payload, useSpock = false): Promise<IThumbnailTarget> {
        const httpEndpoint = useSpock ? httpStargateSpockV1 : http;
        const { parsedData }: IIThumbnailTargetResponse = await httpEndpoint.post(Endpoints.GENERATE_UPLOAD_TARGETS, payload);
        return parsedData;
    }
}

const cryptoMd5Method = (data) => btoa(SparkMD5.ArrayBuffer.hash(data, true)); // AWS md5 is slow

const cryptoHexEncodedHash256 = (data) => {
    const hash = sha256.create();
    hash.update(data);
    return hash.hex();
};

export { S3Service };
