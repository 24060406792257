import { EditorActions } from '../constants';
import { StreamActions } from './constants';
import { IEditorStreamState } from './types';

const DEFAULT_STATE: IEditorStreamState = {
    assetId: null,
    title: null,
    duration: null,
    hlsStreamUrl: null,
    dashStreamUrl: null,
    hlsStreamDRM: null,
    dashStreamDRM: null,
    streamUrls: null,
    thumbnail: null,
    createdAt: null,
    updatedAt: null,
    hasEventEnded: false,
    assetType: null,
    streamingConfigId: null,
};

const streamReducer = (state = DEFAULT_STATE, { type, payload }): IEditorStreamState => {
    switch (type) {
        case StreamActions.PROCESS_STREAM_SUCCESS:
            const {
                assetId,
                title,
                duration,
                thumbnail,
                hlsStreamUrl,
                dashStreamUrl,
                hlsStreamDRM,
                dashStreamDRM,
                streamUrls,
                createdAt,
                updatedAt,
                assetType,
                streamingConfigId,
            } = payload;

            return {
                ...state,
                assetId,
                title,
                duration,
                hlsStreamUrl,
                dashStreamUrl,
                hlsStreamDRM,
                dashStreamDRM,
                streamUrls,
                thumbnail,
                createdAt,
                updatedAt,
                assetType,
                streamingConfigId,
            };

        case StreamActions.PROCESS_STREAM_FAILURE:
        case StreamActions.STREAM_HAS_ENDED:
            // This could be separated out in the future
            return {
                ...state,
                hasEventEnded: true,
            };
        case EditorActions.RESET:
            return DEFAULT_STATE;
        default:
            return state;
    }
};

export { streamReducer };
