import * as DetectBrowser from 'detect-browser';
import * as moment from 'moment';

import { Http } from '@dicetechnology/dice-unity/lib/services/Http';

import { FeatureDictionary, IParsedPagingResponse, IStringDictionary, MilliSeconds, Seconds, TimeFormats } from '~src/types';

export type ObjectLike = Record<string | number | symbol, any>;

// Extract valid type of keys
export type ValidKeyType<T, K = string | number | symbol> = {
    [I in keyof T]: T[I] extends K ? I : never;
}[keyof T];

enum SafeBrowser {
    'chrome',
}

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const DURATION_DISPLAY_UNITS_START_WITH_DATE = ['days', 'hours', 'minutes', 'seconds'];
export const DURATION_DISPLAY_UNITS_START_WITH_MONTH = ['months', 'days', 'hours', 'minutes'];

export const joinStrings = (classes: Array<string | boolean>, separator = ' '): string => {
    const uniqueSet = new Set(classes);
    return Array.from(uniqueSet)
        .filter((c) => typeof c === 'string' && !!c.trim())
        .join(separator);
};

export const isEmailValid = (email: string): boolean => new RegExp(EMAIL_REGEX).test(email);

export const secsToMilliSecs = (seconds: Seconds): MilliSeconds => {
    return Math.round(seconds * 1000);
};

export const milliSecsToSecs = (milliSeconds: MilliSeconds): Seconds => {
    return milliSeconds / 1000;
};

export const isFeatureEnabled = (featureFlagString: string, features: FeatureDictionary): boolean => {
    return !!features[featureFlagString];
};

export const isSafeBrowser = () => {
    const browserInfo = DetectBrowser.detect();
    return browserInfo && SafeBrowser[browserInfo.name] !== undefined;
};

export const valuesToPercentage = (value1: number, value2: number, percentage: number = 100): number => {
    return (value1 / value2) * percentage;
};

export const getTimeLabel = (time: Seconds, format: TimeFormats): string => {
    const sign = time < 0 ? '-' : '';
    const positiveTime = Math.abs(time);
    const duration = moment.duration(positiveTime, 'seconds').asMilliseconds();
    return `${sign}${moment.utc(duration).format(format)}`;
};

export const getTimeLabelIsoString = (time: Seconds): string => {
    const sign = time < 0 ? '-' : '';
    const positiveTime = Math.abs(Math.round(time * 10) / 10);
    const durationTest = moment.duration(positiveTime, 'seconds').toISOString().replace('PT', '').toLowerCase();

    return `${sign}${durationTest}`;
};
/**
 *
 * @param seconds
 * @return {string} for example: 76 hour(s) 1 minute(s) format
 */
export const convertSecondsToHoursMinutesLabel = (seconds: Seconds): string => {
    const ONE_HOUR_IN_SECS = 3600;
    const hours = Math.floor(seconds / ONE_HOUR_IN_SECS);
    const minutes = Math.floor((seconds % ONE_HOUR_IN_SECS) / 60);
    const hourDisplay = hours > 0 ? hours + (hours === 1 ? ' hour ' : ' hours ') : '';
    const minutesDisplay = minutes > 0 ? minutes + (minutes === 1 ? ' minute ' : ' minutes ') : '';

    return hourDisplay + minutesDisplay;
};

/**
 *
 * @param seconds
 * @return {string} for example: 6 day(s), 11 hour(s), 3 min(s)  format
 */
export const convertSecondsToDurationLabel = (seconds: Seconds, units: string[] = DURATION_DISPLAY_UNITS_START_WITH_DATE): string => {
    const durationObject = moment.duration(seconds, 'seconds');

    // Get each of the unit value from moment and create a string
    const durationString = units.reduce((strArray, unit) => {
        const value = durationObject[unit]();
        const unitText = value > 1 ? unit : unit.slice(0, -1);
        if (value >= 1) {
            strArray.push(`${value} ${unitText}`);
        }
        return strArray;
    }, []);

    return durationString.join(', ');
};

/**
 *
 * @param durationInSecs
 * @return {string} for 34:00 format or 01:34:00 (when over an hour)
 */
export const convertSecondsToMomentTimeFormat = (durationInSecs: Seconds): string => {
    const durationObject = moment.duration(durationInSecs, 'seconds');
    const padAndSlice = (value: number) => ('0' + value).slice(-2);
    const hours = durationObject.hours();
    const minutes = durationObject.minutes();
    const seconds = durationObject.seconds();

    return [...(hours > 0 ? [padAndSlice(hours)] : []), padAndSlice(minutes), padAndSlice(seconds)].join(':');
};

export const parsePagingRequest = async <T extends {}>(
    http: Http,
    url: string,
    size: number,
    paramLastSeen: number
): Promise<IParsedPagingResponse<T>> => {
    const queryParams: IStringDictionary = {
        size: size.toString(),
    };

    if (paramLastSeen) {
        queryParams.lastSeen = paramLastSeen.toString();
    }

    const {
        parsedData: {
            pagingResponse: { lastSeen, moreDataAvailable: hasMoreResults },
            results,
        },
    } = await http.get(url, { queryParams });

    return {
        results,
        hasMoreResults,
        lastSeen,
    };
};

export const convertArrayToObject = <T extends ObjectLike, K extends ValidKeyType<T>>(array: T[], key: K) => {
    return array.reduce((acc, curr: T) => {
        acc[curr[key]] = curr;
        return acc;
    }, {} as Record<T[K], T>);
};
