import * as debounce from 'lodash.debounce';
import * as React from 'react';

import { ChevronLeft } from '~components/Icons/ChevronLeft';
import { ChevronRight } from '~components/Icons/ChevronRight';
import { joinStrings } from '~services/utilities';
import useDebounce from '~src/views/hooks/useDebounce';

import './index.scss';

const MOVE_DEBOUNCE = 100;
const CHANGE_DEBOUNCE = 1500;

interface IProps {
    maxPosition: number;
    minPosition: number;
    position: number;
    canMoveUp: boolean;
    canMoveDown: boolean;
    onMove: (newPositon: number) => void;
}

export const MovePosition: React.FC<IProps> = ({ position, canMoveUp, canMoveDown, maxPosition, minPosition, onMove }) => {
    const [inputValue, setInputValue] = React.useState<number>(position);
    const [hasError, setHasError] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setInputValue(position);
    }, [position]);

    const debouncedPosition = useDebounce(inputValue, CHANGE_DEBOUNCE);

    React.useEffect(() => {
        // Make sure we have a value (user has entered something in input)
        if (debouncedPosition && position !== debouncedPosition) {
            const isOutsideRange = debouncedPosition > maxPosition || debouncedPosition < minPosition;
            setHasError(isOutsideRange);

            if (!isOutsideRange) {
                onMove(debouncedPosition);
                inputRef.current.blur();
            }
        }
    }, [debouncedPosition]);

    const debouncedMove = debounce((toPosition: number) => {
        onMove(toPosition);
    }, MOVE_DEBOUNCE);

    const moveUp = () => debouncedMove(position - 1);
    const moveDown = () => debouncedMove(position + 1);

    const onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        const newPosition = parseInt(value.trim(), 10);

        setInputValue(newPosition);
    };

    const onBlur = () => {
        setInputValue(position);
        setHasError(false);
    };

    const formattedInputValue = isNaN(inputValue) ? '' : inputValue;

    return (
        <span className="pod__order-edit">
            <button className="pod__order-btn" onClick={moveUp} title="Move up" disabled={!canMoveUp}>
                <ChevronLeft />
            </button>
            <input
                type="text"
                className={joinStrings(['pod__order-input', hasError && 'pod__order-input--has-error'])}
                value={formattedInputValue}
                onChange={onChange}
                min={minPosition}
                max={maxPosition}
                onBlur={onBlur}
                ref={inputRef}
            />
            <button className="pod__order-btn" onClick={moveDown} title="Move down" disabled={!canMoveDown}>
                <ChevronRight />
            </button>
        </span>
    );
};
