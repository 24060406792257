import * as React from 'react';

import { CardGrid } from '~components/CardGrid';
import { Pagination } from '~components/Pagination';
import { EAssetType } from '~containers/EditorPageContainer/types';
import { PaginationContent } from '~pages/Browse/components/BrowseSection/PaginationContent';
import {
    BROWSE_CLIP_PAGER_FEEDBACK,
    BROWSE_LIVE_PAGER_FEEDBACK,
    BROWSE_VOD_PAGER_FEEDBACK,
    HITS_PER_PAGE,
} from '~pages/Browse/components/constants';
import { IFeedback, OpenPreviewModal } from '~pages/Browse/components/types';
import { getClips } from '~services/clip';
import { getLiveEvents } from '~services/live';
import { getVods } from '~services/vod';
import { IContentType } from '~src/types';

interface IProps {
    onOpenPreviewModal: OpenPreviewModal;
}

const getPaginationContent = (
    pagerFeedback: IFeedback,
    onOpenPreviewModal: OpenPreviewModal,
    assetType: Exclude<EAssetType, EAssetType.ARCHIVE_VOD>,
    loadMoreDataTestId: string
) => (results: IContentType[], status: string, hasMoveResults: boolean, getMoreResults: VoidFunction) => (
    <PaginationContent
        results={results}
        status={status}
        hasMoveResults={hasMoveResults}
        getMoreResults={getMoreResults}
        onOpenPreviewModal={onOpenPreviewModal}
        pagerFeedback={pagerFeedback}
        assetType={assetType}
        loadMoreDataTestId={loadMoreDataTestId}
    />
);

const BrowseSectionComponent: React.FunctionComponent<IProps> = ({ onOpenPreviewModal }) => {
    const eventsPager = getLiveEvents(HITS_PER_PAGE);
    const clipsPager = getClips(HITS_PER_PAGE);
    const vodsPager = getVods(HITS_PER_PAGE);
    return (
        <div className="browse-section">
            <CardGrid.Grid dataTestId="cardGrid-live">
                <CardGrid.Title dataTestId="cardGrid-liveTitle">LIVE</CardGrid.Title>
                <Pagination
                    stateKey="live"
                    pageService={eventsPager}
                    render={getPaginationContent(
                        BROWSE_LIVE_PAGER_FEEDBACK,
                        onOpenPreviewModal,
                        EAssetType.LIVE,
                        'cardGrid-liveLoadMoreButton'
                    )}
                />
            </CardGrid.Grid>

            <CardGrid.Grid dataTestId="cardGrid-recentlyCreated">
                <CardGrid.Title dataTestId="cardGrid-recentlyCreatedTitle">Recently Created</CardGrid.Title>
                <Pagination
                    stateKey="clip"
                    pageService={clipsPager}
                    render={getPaginationContent(
                        BROWSE_CLIP_PAGER_FEEDBACK,
                        onOpenPreviewModal,
                        EAssetType.CLIP,
                        'cardGrid-recentlyCreatedLoadMoreButton'
                    )}
                />
            </CardGrid.Grid>

            <CardGrid.Grid dataTestId="cardGrid-videos">
                <CardGrid.Title dataTestId="cardGrid-videosTitle">Videos</CardGrid.Title>
                <Pagination
                    stateKey="vod"
                    pageService={vodsPager}
                    render={getPaginationContent(
                        BROWSE_VOD_PAGER_FEEDBACK,
                        onOpenPreviewModal,
                        EAssetType.VOD,
                        'cardGrid-videosLoadMoreButton'
                    )}
                />
            </CardGrid.Grid>
        </div>
    );
};

const BrowseSection: React.NamedExoticComponent<IProps> = React.memo(BrowseSectionComponent);

export { BrowseSection };
