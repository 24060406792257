import { ICurrentUserResponse } from '~services/profile';

import { IRootContext } from './types';

export const INITIAL_STATE: IRootContext = {
    authorised: false,
    setAuthorised: (authorised: boolean, currentUser: ICurrentUserResponse) => null,
    isDebugMode: false,
    algoliaSettings: null,
    settings: {},
    features: {},
};

export enum PageRoutes {
    // auth routes
    LANDING_PAGE = '/',
    CHROME_ONLY = '/chrome-only',
    FORGOTTEN_PASSWORD = '/forgotten-password',
    LOGIN = '/login',
    LOGOUT = '/logout',
    SET_PASSWORD = '/set-password',
    // broadcaster routes
    LIVE_CHANNELS = '/broadcaster/live-channels',
    LIVE_CHANNEL_MANAGER = '/broadcaster/live-channels/:id',
    UPCOMING_CHANNEL_MANAGER = '/broadcaster/upcoming-channels/:id',
    UPCOMING_CHANNELS = '/broadcaster/upcoming-channels',
    // clip routes
    EDITOR = '/editor',
    EDITOR_LIVE = '/editor/live/:id',
    EDITOR_CLIP = '/editor/clip/:id',
    EDITOR_VOD = '/editor/vod/:id',
}

export enum TabTypes {
    LIVE = 'LIVE',
    UPCOMING = 'UPCOMING',
}
