import * as React from 'react';

import UserSolidIcon from '~components/Icons/LockSolidIcon';
import { TrashIcon } from '~components/Icons/TrashIcon';

interface IProps {
    hasUpdate: boolean;
    isEditable: boolean;
    isRemoved: boolean;
    isNew: boolean;
    hasMoved: boolean;
    onRemoveUndo: VoidFunction;
}

export const EditState: React.FC<IProps> = (props) => {
    let editState: JSX.Element | React.ReactText;

    if (props.isRemoved) {
        editState = (
            <React.Fragment>
                <TrashIcon />
                Deleted <button onClick={props.onRemoveUndo}>(Undo)</button>
            </React.Fragment>
        );
    } else if (props.isNew) {
        editState = 'Added';
    } else if (props.hasMoved) {
        editState = 'Moved';
    } else {
        editState = 'Edited';
    }

    return (
        <React.Fragment>
            {props.hasUpdate && <div className="pod-card__pod-edit-state">{editState}</div>}
            {!props.isEditable && (
                <div className="pod-card__video-lock">
                    <UserSolidIcon />
                </div>
            )}
        </React.Fragment>
    );
};
