import { AuthenticationLink } from '@dicetechnology/dice-backoffice-ui-components';

import { PageRoutes } from '~components/Root/constants';

export const SET_PASSWORD_TOAST_TITLE = 'Set new password';
export const PASSWORD_USED_TOO_RECENTLY = 'passwordUsedTooRecently';
export const PASSWORD_USED_TOO_RECENTLY_ERROR = 'Password used too recently';
export const PASSWORD_IS_TOO_COMMON = 'Password is too common';

export const SET_PASSWORD_TOAST_MESSAGE = {
    SUCCESS: 'Your new password was successfully updated.',
    FAILED: 'Your new password failed to update.',
    FAILED_TOKEN_INVALID: 'The token is invalid.',
    FAILED_CONFIG_FETCH: 'Failed to fetch config',
    PASSWORD_USED_TOO_RECENTLY: 'Password used too recently',
} as const;

export const SET_PASSWORD_FORM_SERVER_ERROR_KEY = {
    INVALID_RESET_PASSWORD_TOKEN: 'invalidResetPasswordToken',
    NOT_FOUND: 'Not Found',
    PASSWORD_ALREADY_USED: 'passwordAlreadyUsed',
    PASSWORD_IS_TOO_COMMON: 'passwordIsTooCommon',
} as const;

export const AUTHENTICATION_LINKS: AuthenticationLink[] = [
    {
        label: 'Back to Login',
        to: PageRoutes.LOGIN,
    },
];
