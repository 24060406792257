import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Input } from '~components/Form/TextInput';
import { RegularSearchIcon } from '~components/Icons/RegularSearchIcon';
import { RegularTimesIcon } from '~components/Icons/RegularTimesIcon';
import { RootContext } from '~components/Root/context';
import { TSearchTerm } from '~components/Root/types';
import { IAlgoliaSettings } from '~services/settings';
import { joinStrings } from '~services/utilities';
import { setSearchTerm } from '~src/store/search/search.actions';
import { getSearchTermState } from '~src/store/search/search.selectors';
import { useShallowEqualSelector } from '~src/views/hooks';

import './index.scss';
import './index.scss';

interface IProps {
    searchTerm?: TSearchTerm;
    resetSearchTerm?: VoidFunction;
    searchPlaceholder: string;
    onChange: ({ currentTarget }: React.SyntheticEvent<HTMLInputElement, Event>) => void;
}

const canInitAlgolia = (algoliaSettings): algoliaSettings is IAlgoliaSettings => algoliaSettings && algoliaSettings.appName !== undefined;

const SearchFieldComponent: React.FunctionComponent<IProps> = ({ searchTerm, searchPlaceholder, resetSearchTerm, onChange }) => {
    const refSearchTerm = React.useRef<string>(searchTerm);

    const [currentSearchTerm, setCurrentSearchTerm] = React.useState<string>(searchTerm);

    React.useEffect(() => {
        refSearchTerm.current = searchTerm;
    }, [searchTerm]);

    React.useEffect(() => {
        return () => {
            if (searchTerm) {
                resetSearchTerm();
            }
        };
    }, []);

    const keyDownHandler = (event: React.SyntheticEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    };

    const onChangeHandler = (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
        setCurrentSearchTerm(e.currentTarget.value);
        onChange(e);
    };

    const inputClassNames = joinStrings(['form-group__control', currentSearchTerm && 'has-value']);

    return (
        <div className="search-field-container">
            <div className="form-group">
                <div className="form-group__control-wrapper">
                    <RegularSearchIcon className="form-group__control-icon" height="16" width="16" fill="#979aa9" />
                    <Input
                        className={inputClassNames}
                        type="text"
                        id="search"
                        name="search"
                        value={searchTerm}
                        onChange={onChangeHandler}
                        autoComplete="off"
                        onKeyDown={keyDownHandler}
                        data-test-id="searchForm-searchInput"
                    />
                    <div className="interaction">
                        <label className="form-group__label" htmlFor="search">
                            {searchPlaceholder}
                        </label>
                        <button
                            className="form-group__clear"
                            type="button"
                            onClick={resetSearchTerm}
                            data-test-id="searchForm-closeSearchButton"
                        >
                            <RegularTimesIcon height="16" width="16" fill="#979aa9" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SearchFieldMemo: React.NamedExoticComponent<IProps> = React.memo(SearchFieldComponent);

const SearchField = () => {
    const dispatch = useDispatch();
    const { algoliaSettings } = React.useContext(RootContext);
    const searchTerm = useShallowEqualSelector(getSearchTermState);

    const onChange = ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => {
        const action = setSearchTerm(currentTarget.value);
        dispatch(action);
    };

    const resetSearchTerm = () => {
        const action = setSearchTerm('');
        dispatch(action);
    };

    return canInitAlgolia(algoliaSettings) ? (
        <SearchFieldMemo searchPlaceholder="Search videos" searchTerm={searchTerm} onChange={onChange} resetSearchTerm={resetSearchTerm} />
    ) : null;
};

export { SearchField, SearchFieldComponent };
