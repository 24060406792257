export const initialPlayerState = {
    ready: false,
    paused: true,
    atLiveEdge: false,
    currentTime: 0,
    duration: 0,
    live: null,
    seekable: { start: 0, end: 0 },
    waiting: false,
    muted: true,
    bufferedTimeRanges: [],
    loadPDT: 0,
    loop: null,
    frameRate: null,
};
