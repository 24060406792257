import { IClip } from '~services/clip/types';
import { PaginationActions } from '~src/store/pagination/constants';
import { PagerService, StateKey } from '~src/types';

const paginationAdd = (stateKey: StateKey, pageService: PagerService) => ({
    type: PaginationActions.ADD_SAGA,
    payload: {
        stateKey,
        pageService,
    },
});

const paginationRemove = (stateKey: StateKey) => ({
    type: PaginationActions.REMOVE,
    payload: {
        stateKey,
    },
});

const paginationGetNextPage = (stateKey: StateKey, pageService: PagerService) => ({
    type: PaginationActions.GET_NEXT_PAGE_SAGA,
    payload: {
        stateKey,
        pageService,
    },
});

const paginationUpdateResult = (stateKeys: StateKey[], result: IClip) => ({
    type: PaginationActions.UPDATE_RESULT,
    payload: {
        stateKeys,
        result,
    },
});

export { paginationAdd, paginationRemove, paginationGetNextPage, paginationUpdateResult };
