import { combineReducers, Reducer } from 'redux';

import { exportReducer } from '~src/store/editor/export/export.reducer';
import { streamReducer } from '~src/store/editor/stream/stream.reducer';

const editorReducer: Reducer = combineReducers({
    export: exportReducer,
    stream: streamReducer,
});

export { editorReducer };
