import { Console } from '~services/console';

interface IFullScreenMethods {
    // entering fullscreen
    webkitRequestFullscreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    msRequestFullscreen?: () => Promise<void>;
}

interface IFullScreenElement extends IFullScreenMethods, HTMLElement {}

interface IDocumentWithFullScreen extends Document {
    // fullscreen exiting
    webkitExitFullscreen?: () => Promise<void>;
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;

    // fullscreen state
    webkitFullscreenElement?: Element;
    mozFullScreenElement?: Element;
    msFullscreenElements?: Element;
}

class FullScreenService {
    public enterFullscreen(element: IFullScreenElement): void {
        this.processEnterFullscreen(element);
    }

    public exitFullscreen(): void {
        this.processExitFullscreen();
    }

    public isFullScreen() {
        const document: IDocumentWithFullScreen = window.document;
        return (
            document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElements
        );
    }

    private async processEnterFullscreen(element: IFullScreenElement): Promise<void> {
        try {
            if (element.requestFullscreen) {
                await element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) {
                await element.webkitRequestFullscreen();
            } else if (element.mozRequestFullScreen) {
                await element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                await element.msRequestFullscreen();
            } else {
                Console.warn('Full Screen not supported');
            }
        } catch (error) {
            Console.warn("Processing 'Full Screen Request'", error);
        }
    }

    private async processExitFullscreen(): Promise<void> {
        const document: IDocumentWithFullScreen = window.document;
        try {
            if (document.exitFullscreen) {
                await document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                await document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                await document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                await document.msExitFullscreen();
            } else {
                Console.warn('Full Screen not supported');
            }
        } catch (error) {
            Console.warn("Processing 'Full Screen Request'", error);
        }
    }
}

const fullScreenService = new FullScreenService();

export { fullScreenService };
