import * as moment from 'moment';
import * as React from 'react';

import CloseIcon from '~components/Icons/CloseIcon';
import { Console } from '~services/console';
import { joinStrings } from '~services/utilities';
import { getApplicationVersion } from '~services/version';
import { IAppVersion } from '~services/version/types';
import { KeyCode } from '~src/views/types';

import './index.scss';

const DEFAULT_STATE = {
    frontend: undefined,
    backend: undefined,
};

const VersionPanel = () => {
    const [{ frontend, backend }, setMeta] = React.useState<IAppVersion>(DEFAULT_STATE);

    const [isOpen, toggleIsOpen] = React.useState<boolean>(false);

    const closeVersionPanel = () => {
        toggleIsOpen(false);
    };

    React.useEffect(() => {
        const keyDownHandler = ({ keyCode, altKey, ctrlKey }: KeyboardEvent) => {
            if (ctrlKey && altKey && keyCode === KeyCode.V) {
                toggleIsOpen((prevState) => !prevState);
            }
        };

        window.addEventListener('keydown', keyDownHandler);

        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    React.useEffect(() => {
        const fetchVersion = async (): Promise<void> => {
            try {
                const versionMeta = await getApplicationVersion();
                setMeta(versionMeta);
            } catch (e) {
                Console.warn("Processing 'Studio Version Meta':", e);
            }
        };
        fetchVersion();
    }, []);

    const classNames = joinStrings(['version-panel', isOpen && 'version-panel--open']);

    return (
        <aside className={classNames}>
            <button type="button" className="version-panel__close-btn" onClick={closeVersionPanel}>
                <CloseIcon height="20" width="20" fill="#ffffff" />
            </button>

            <h2 className="version-panel__title">Studio Version</h2>

            <section className="version-panel__section">
                <h3 className="version-panel__section__title">Backend</h3>
                {backend && (
                    <dl className="version-panel__content">
                        <dt className="key">Deployed on:</dt>
                        <dd className="value">
                            <time dateTime={backend.deployedAt}>{moment(backend.deployedAt).format('DD-MM-YYYY - hh:mm:ss')}</time>
                        </dd>

                        <dt className="key">Deployed by:</dt>
                        <dd className="value">{backend.deployedBy}</dd>

                        <dt className="key">Environment:</dt>
                        <dd className="value">{backend.envName}</dd>

                        <dt className="key">Instance Id:</dt>
                        <dd className="value">{backend.instanceId}</dd>

                        <dt className="key">Region:</dt>
                        <dd className="value">{backend.region}</dd>

                        <dt className="key">Version:</dt>
                        <dd className="value">{backend.version}</dd>
                    </dl>
                )}
            </section>

            <section className="version-panel__section">
                <h3 className="version-panel__section__title">Frontend</h3>
                {frontend && (
                    <dl className="version-panel__content">
                        <dt className="key">UI version:</dt>
                        <dd className="value">{frontend.version}</dd>
                        <dt className="key">Player version:</dt>
                        <dd className="value">{frontend.player}</dd>
                    </dl>
                )}
            </section>
        </aside>
    );
};

export { VersionPanel };
