import debounce from 'lodash.debounce';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { ThumbnailItem } from '~components/Editor/ThumbnailPanel/ThumbnailItem';
import { InlineNotification } from '~components/InlineNotification';
import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { removeThumbnail, setFocusThumbnail } from '~src/store/timeline/thumbnail/thumbnail.actions';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';
import { IStudioState } from '~src/store/types';
import { Seconds } from '~src/types';
import { useShallowEqualSelector } from '~src/views/hooks';

import './index.scss';

interface IProps {
    dispatch: Dispatch;
    thumbnails: ITimelineThumbnail[];
    seekToWallClock: (time: Seconds) => void;
}

enum MouseAction {
    MOUSEENTER = 'mouseenter',
    MOUSELEAVE = 'mouseleave',
}

const ThumbnailsPanelComponent: React.FunctionComponent<IProps> = (props) => {
    const { dispatch, seekToWallClock, thumbnails } = props;
    if (!thumbnails.length) {
        return <InlineNotification message="No thumbnails currently added" />;
    }

    const setThumbnailFocusDispatchHandler = (payload) => {
        const action = setFocusThumbnail(payload);
        dispatch(action);
    };

    const debounceSetThumbnailFocus = debounce(setThumbnailFocusDispatchHandler, 250);

    const setThumbnailFocusHandler = (type: string, id: ITimelineThumbnail['id']) => {
        if (type === MouseAction.MOUSEENTER || type === MouseAction.MOUSELEAVE) {
            const payload = type === MouseAction.MOUSEENTER ? id : null;
            debounceSetThumbnailFocus(payload);
        }
    };

    const removeThumbnailHandler = (id: ITimelineThumbnail['id']) => {
        const action = removeThumbnail(id);
        dispatch(action);
    };

    const getThumbnailItem = ({ id, imageData, exportThumbnailTime }: ITimelineThumbnail) => (
        <ThumbnailItem
            key={id}
            id={id}
            imageData={imageData}
            exportThumbnailTime={exportThumbnailTime}
            setThumbnailFocus={setThumbnailFocusHandler}
            removeThumbnail={removeThumbnailHandler}
            seekToThumbnail={seekToWallClock}
        />
    );

    return <div className="thumbnail-panel-list">{thumbnails.map(getThumbnailItem)}</div>;
};

const ThumbnailsPanelMemo: React.NamedExoticComponent<IProps> = React.memo(ThumbnailsPanelComponent);

const getThumbnailState = (state: IStudioState) => {
    const { list: thumbnails } = state.timeline.thumbnails;
    return {
        thumbnails,
    };
};

const ThumbnailsPanel: React.FunctionComponent = () => {
    const { thumbnails } = useShallowEqualSelector<{ thumbnails: ITimelineThumbnail[] }>(getThumbnailState);
    const { seekToWallClock } = React.useContext(VideoContext);
    const dispatch = useDispatch();
    return <ThumbnailsPanelMemo thumbnails={thumbnails} seekToWallClock={seekToWallClock} dispatch={dispatch} />;
};

export { ThumbnailsPanel };
