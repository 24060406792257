import * as React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import { LinkListDropdownMenu } from '~components/LinkListDropdownMenu';
import { ILinkMenuItem } from '~components/LinkListDropdownMenu/MenuItem';
import { IEditableRange } from '~src/store/channelManager/types';

import { MovePosition } from '../MovePosition';

interface IProps {
    podNumber: number;
    firstEditablePodNumber: IEditableRange['first'];
    lastEditablePodNumber: IEditableRange['last'];
    onMove: (newPosition: number) => void;
    onEdit: VoidFunction;
    onRemove: VoidFunction;
    onOpenPreview: VoidFunction;
    isEditable: boolean;
}

const Handle = SortableHandle(() => (
    <div className="pod-card__dragger-wrapper">
        <div className="pod-card__dragger-handle" />
    </div>
));

export const PodPosition: React.FC<IProps> = (props) => {
    const { podNumber, firstEditablePodNumber, lastEditablePodNumber } = props;

    const menuOptions = React.useMemo<ILinkMenuItem[]>(() => {
        const previewOption = { title: 'VIDEO PREVIEW', callback: props.onOpenPreview };

        if (props.isEditable) {
            return [
                /**
                 * This feature is hidden as it does not offer any extra functionality in its current state
                 * If re-enabling, re-add props.onEdit to dependency array
                 */
                // { title: 'POD OPTIONS', callback: props.onEdit },
                previewOption,
                { title: 'DELETE', callback: props.onRemove, className: 'pod-card__delete-menu-item' },
            ];
        }

        return [previewOption];
    }, [props.onRemove, props.onOpenPreview, props.isEditable]);

    const canMoveUp = firstEditablePodNumber < podNumber;
    const canMoveDown = lastEditablePodNumber > podNumber;

    return (
        <React.Fragment>
            <div className="pod-card__pod-position">
                <div className="pod-card__pod-position-display">{props.podNumber}</div>
                <div className="pod-card__pod-position-move">
                    <MovePosition
                        position={podNumber}
                        canMoveDown={canMoveDown}
                        canMoveUp={canMoveUp}
                        minPosition={firstEditablePodNumber}
                        maxPosition={lastEditablePodNumber}
                        onMove={props.onMove}
                    />
                </div>
            </div>
            <Handle />

            <LinkListDropdownMenu menuItems={menuOptions} className="pod-card__more-options" />
        </React.Fragment>
    );
};
