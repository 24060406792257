import * as moment from 'moment';
import v4 from 'uuid/v4';

import { IClipComponent } from '~services/clip/types';
import { ISettingsSettings } from '~services/settings';
import { secsToMilliSecs } from '~services/utilities';
import { IExportClipWithMeta, ITimelineClip } from '~src/store/timeline/clip/types';
import { Base64ImageUri, MilliSeconds, Seconds, V4Uid } from '~src/types';

interface IRange {
    start: number;
    end: number;
}

const createRange = (start: number, end: number): IRange => ({
    start,
    end,
});

const isRangeIntersecting = (rangeA: IRange, rangeB: IRange): boolean => {
    const maxRangeStart = Math.max(rangeA.start, rangeB.start);

    // handle clip intersection with a single point where end can be null
    const rangeEndA = rangeA.end === null ? rangeA.start : rangeA.end;
    const rangeEndB = rangeB.end === null ? rangeB.start : rangeB.end;

    const minRangeEnd = Math.min(rangeEndA, rangeEndB);

    return maxRangeStart <= minRangeEnd;
};

const createTimelineClip = (renderClipInTime: Seconds, exportClipInTime: Seconds, thumbnailId: V4Uid = null): ITimelineClip => ({
    id: v4(),
    title: `Clip - ${moment().format('DD/MM/YYYY @ HHmm')}`,
    thumbnailId,

    // in marker times
    renderClipInTime,
    exportClipInTime,

    // out marker times
    renderClipOutTime: null,
    exportClipOutTime: null,
});

const getClipById = (clips: ITimelineClip[], id: ITimelineClip['id']): ITimelineClip => {
    return clips.find((clip) => clip.id === id);
};

const getExportableClips = (clips: ITimelineClip[], minimumClipDuration: ISettingsSettings['minimumClipDuration']): ITimelineClip[] => {
    return clips.filter(({ exportClipInTime, exportClipOutTime }) => {
        if (exportClipOutTime === null) {
            return false;
        }
        const clipDuration = Math.abs(exportClipInTime - exportClipOutTime);
        return clipDuration > minimumClipDuration;
    });
};

const isClipValid = (
    { exportClipInTime, exportClipOutTime }: ITimelineClip,
    minimumClipDuration: ISettingsSettings['minimumClipDuration']
): { valid: boolean; message?: string } => {
    if (exportClipOutTime === null) {
        return {
            valid: false,
            message: "This clip doesn't have an out marker set",
        };
    } else if (Math.abs(exportClipInTime - exportClipOutTime) < minimumClipDuration) {
        return {
            valid: false,
            message: `The duration of the clip needs to be greater than ${minimumClipDuration} seconds`,
        };
    }

    return {
        valid: true,
    };
};

// todo: revisit when refactor of ITimelineClip
interface IExportClipWithMetaPayload {
    id?: V4Uid;
    title?: string;
    exportClipInTime?: number;
    exportClipOutTime?: number;
    thumbnail?: string;
    loadPDT?: number;
}

class ClipUtilities {
    private static MINIMUM_COMPONENT_COUNT = 1;

    private static buildComponent(inTime: MilliSeconds, outTime: MilliSeconds, pdt: MilliSeconds): IClipComponent {
        if (!inTime) {
            return undefined;
        }

        const offsetMS = secsToMilliSecs(inTime);
        const duration = outTime - inTime;
        const durationMS = secsToMilliSecs(duration);

        const component: IClipComponent = {
            offsetMS,
            durationMS,
        };

        if (pdt) {
            component.startTimeMS = pdt;
        }

        return component;
    }

    public static getDurationFromComponent(component: IClipComponent): Seconds {
        if (!component) {
            return 0;
        }
        return component.durationMS / 1000;
    }

    public static isCombined(clip: IExportClipWithMeta): boolean {
        return ClipUtilities.getComponentCount(clip) > ClipUtilities.MINIMUM_COMPONENT_COUNT;
    }

    public static getComponentCount(clip: IExportClipWithMeta): number {
        return clip.components.length;
    }

    public static buildExportClipWithMeta(payload: IExportClipWithMetaPayload = {}): IExportClipWithMeta {
        const { id = v4(), title = null, exportClipInTime = null, exportClipOutTime = null, thumbnail = null, loadPDT = null } = payload;

        const clipTitle = title !== null ? title : `Combined clip - ${moment().format('DD/MM/YYYY @ HHmm')}`;
        const component = ClipUtilities.buildComponent(exportClipInTime, exportClipOutTime, loadPDT);

        const duration = ClipUtilities.getDurationFromComponent(component);

        return {
            components: component ? [component] : [],
            meta: {
                id,
                title: clipTitle,
                thumbnail,
                duration,
            },
        };
    }

    public static combineClipComponents(targetClip: IExportClipWithMeta, sourceClips: IExportClipWithMeta[]): IExportClipWithMeta {
        const components = sourceClips.map((clip) => clip.components[0]).sort((a, b) => a.offsetMS - b.offsetMS);

        const duration = sourceClips.reduce((accumulator, clip) => {
            return accumulator + clip.meta.duration;
        }, 0);

        targetClip.components = components;
        targetClip.meta.duration = duration;

        return targetClip;
    }

    public static setTitle(title: string, clip: Readonly<IExportClipWithMeta>): IExportClipWithMeta {
        return {
            ...clip,
            meta: {
                ...clip.meta,
                title,
            },
        };
    }

    public static setThumbnail(thumbnail: Base64ImageUri, clip: Readonly<IExportClipWithMeta>): IExportClipWithMeta {
        return {
            ...clip,
            meta: {
                ...clip.meta,
                thumbnail,
            },
        };
    }

    /* enforce static usage: prevent instances being created */
    private constructor() {
        /* noop */
    }
}

export {
    // move these to ClipUtilities
    isRangeIntersecting,
    createRange,
    createTimelineClip,
    getClipById,
    getExportableClips,
    isClipValid,
    ClipUtilities,
};
