import * as React from 'react';

const FilePlusIcon = (props) => {
    return (
        <svg viewBox="0 0 384 512" {...props}>
            <path d="M369.9,97.9,286,14A48,48,0,0,0,252.1-.1H48A48.16,48.16,0,0,0,0,48V464a48,48,0,0,0,48,48H336a48,48,0,0,0,48-48V131.9A48.23,48.23,0,0,0,369.9,97.9Zm-22.6,22.7a15.73,15.73,0,0,1,4.2,7.4H256V32.5a15.73,15.73,0,0,1,7.4,4.2ZM336,480H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H224V136a23.94,23.94,0,0,0,24,24H352V464A16,16,0,0,1,336,480ZM288,300v8a12,12,0,0,1-12,12H208v68a12,12,0,0,1-12,12h-8a12,12,0,0,1-12-12V320H108a12,12,0,0,1-12-12v-8a12,12,0,0,1,12-12h68V220a12,12,0,0,1,12-12h8a12,12,0,0,1,12,12v68h68A12,12,0,0,1,288,300Z" />
        </svg>
    );
};

export { FilePlusIcon };
