import * as moment from 'moment';
import { useEffect } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ChannelDeleteModal } from '~components/ChannelManager/ChannelDetails/ChannelDeleteModal';
import { ChannelSettingsModal } from '~components/ChannelManager/ChannelDetails/ChannelSettingsModal';
import { Dropdown } from '~components/ChannelManager/ChannelDetails/Dropdown';
import { LinkListDropdownMenu } from '~components/LinkListDropdownMenu';
import { ListPagination } from '~components/ListPagination';
import LoadingSpinner from '~components/LoadingSpinner';
import { NavLogo } from '~components/NavLogo';
import { PageRoutes, TabTypes } from '~components/Root/constants';
import { SearchFieldComponent } from '~components/SearchField';
import { authenticationService } from '~services/authentication';
import { getListOfChannels, updateChannelDetails } from '~services/channels';
import {
    ApiFetchStates,
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT_FIELD,
    DEFAULT_SORT_ORDER,
    DEFAULT_SEARCH_SORT_FIELD,
    DEFAULT_SEARCH_SORT_ORDER,
    SortFields,
} from '~services/channels/constants';
import { IChannelDetails, SortOrder } from '~services/channels/types';
import { Console } from '~services/console';
import { convertSecondsToHoursMinutesLabel, joinStrings } from '~services/utilities';
import { IChannelSettings } from '~src/store/channelManager/types';
import { EToastType } from '~src/store/toast/constants';
import { addToast } from '~src/store/toast/toast.actions';
import { Role } from '~src/types';
import useDebounce from '~src/views/hooks/useDebounce';

import './index.scss';

enum SortOption {
    BEST_MATCH,
    TITLE_ASC,
    TITLE_DESC,
    CREATION_DATE_ASC,
    CREATION_DATE_DESC,
}

const sortOptions = [
    { label: 'Best match', value: SortOption.BEST_MATCH },
    { label: 'Title Asc', value: SortOption.TITLE_ASC },
    { label: 'Title Desc', value: SortOption.TITLE_DESC },
    { label: 'Creation date Asc', value: SortOption.CREATION_DATE_ASC },
    { label: 'Creation date Desc', value: SortOption.CREATION_DATE_DESC },
];

interface IChannelsPageProps {
    tabType: TabTypes;
}

const SEARCH_DEBOUNCE = 700;

const ChannelsPage: React.FC<IChannelsPageProps> = React.memo(({ tabType }) => {
    const [totalItems, setTotalItems] = React.useState<number>();
    const [channels, setChannels] = React.useState<IChannelDetails[]>([]);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [searchParam, setSearchParam] = React.useState<string>('');
    const [pageState, setPageState] = React.useState<ApiFetchStates>(ApiFetchStates.LOADING);
    const [selectedSortOption, setSelectedSortOption] = React.useState<SortOption>(SortOption.BEST_MATCH);
    const [sortOrder, setSortOrder] = React.useState(DEFAULT_SEARCH_SORT_ORDER);
    const [sortField, setSortField] = React.useState(DEFAULT_SEARCH_SORT_FIELD);
    const searchParamDebounced = useDebounce(searchParam, SEARCH_DEBOUNCE);
    const dispatch = useDispatch();

    const history = useHistory();

    React.useEffect(() => {
        refreshChannelsList(currentPage, searchParam);
    }, [tabType, currentPage, searchParamDebounced, selectedSortOption]);

    const refreshChannelsList = async (page?: number, search?: string) => {
        setPageState(ApiFetchStates.LOADING);
        setCurrentPage(page);

        const currentSortField = !!search ? sortField : DEFAULT_SORT_FIELD;
        const currentSortOrder = !!search ? sortOrder : DEFAULT_SORT_ORDER;
        const payload = {
            isLive: tabType === TabTypes.LIVE,
            page,
            title: search,
            sortField: currentSortField,
            sortOrder: currentSortOrder,
        };
        try {
            const { results = [], total } = await getListOfChannels(payload);
            if (total !== 0) {
                setChannels(results);
                setTotalItems(total);
                setPageState(ApiFetchStates.LOADED);
            } else {
                setPageState(ApiFetchStates.NO_CONTENT);
            }
        } catch (e) {
            Console.warn('Get lists of channels', e);
            const action = addToast(
                'Unable to get channels',
                'We are unable to get the list of channels. If this persists please contact support.',
                EToastType.DANGER,
                5000,
                'channelsPage-refreshChannelsListFeedback'
            );
            dispatch(action);
        }
    };

    const handleChannelRoute = (id: number) => () => {
        if (tabType === TabTypes.LIVE) {
            history.push(`${PageRoutes.LIVE_CHANNELS}/${id}`);
        } else {
            history.push(`${PageRoutes.UPCOMING_CHANNELS}/${id}`);
        }
    };

    const getActiveTabClassName = (tabToRender: TabTypes): string => joinStrings([tabToRender === tabType && 'active-tab']);

    const handleTabClick = (selected: TabTypes) => () => {
        if (selected !== tabType && selected === TabTypes.LIVE) {
            history.push(`${PageRoutes.LIVE_CHANNELS}`);
        } else if (selected !== tabType) {
            history.push(`${PageRoutes.UPCOMING_CHANNELS}`);
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPage(1);
        setSearchParam(e.target.value);
    };

    const handleSortChange = (selected: SortOption) => {
        let newSortOrder = DEFAULT_SEARCH_SORT_ORDER;
        let newSortField = DEFAULT_SEARCH_SORT_FIELD;

        switch (selected) {
            case SortOption.BEST_MATCH:
                newSortOrder = SortOrder.DESC;
                newSortField = SortFields.SIMILARITY;
                break;
            case SortOption.TITLE_ASC:
                newSortOrder = SortOrder.ASC;
                newSortField = SortFields.TITLE;
                break;
            case SortOption.TITLE_DESC:
                newSortOrder = SortOrder.DESC;
                newSortField = SortFields.TITLE;
                break;
            case SortOption.CREATION_DATE_ASC:
                newSortOrder = SortOrder.ASC;
                newSortField = SortFields.CREATED_AT;
                break;
            case SortOption.CREATION_DATE_DESC:
                newSortOrder = SortOrder.DESC;
                newSortField = SortFields.CREATED_AT;
                break;
        }

        setSortField(newSortField);
        setSortOrder(newSortOrder);
        setSelectedSortOption(selected);
        setCurrentPage(1);
    };

    const contentSectionClassName = joinStrings([
        'content-page__content',
        pageState !== ApiFetchStates.LOADED && 'content-page__content--hide',
    ]);

    const [showDeleteChannelModal, setShowDeleteChannelModal] = React.useState<boolean>(false);
    const [showEditChannelModal, setShowEditChannelSettingsModal] = React.useState<boolean>(false);
    const [selectedChannel, setSelectedChannel] = React.useState<IChannelDetails>(null);

    const onShowDeleteChannelModal = (channel: IChannelDetails) => {
        return () => {
            setSelectedChannel(channel);
            setShowDeleteChannelModal(true);
        };
    };

    const onHideDeleteChannelModal = () => {
        setSelectedChannel(null);
        setShowDeleteChannelModal(false);
    };

    const onDeleteChannel = () => {
        setShowDeleteChannelModal(false);
        setSelectedChannel(null);
        refreshChannelsList(currentPage, searchParam);
    };

    const onShowEditChannelSettingsModal = (channel: IChannelDetails) => {
        return () => {
            setSelectedChannel(channel);
            setShowEditChannelSettingsModal(true);
        };
    };

    const onHideEditChannelSettingsModal = () => {
        setSelectedChannel(null);
        setShowEditChannelSettingsModal(false);
    };

    const onEditChannelSettings = async (channelSettings: IChannelSettings) => {
        setShowEditChannelSettingsModal(false);
        setSelectedChannel(null);
        await updateChannelDetails({
            ...selectedChannel,
            targetAdvertDuration: channelSettings.targetAdvertDuration,
            title: channelSettings.title,
            logo: channelSettings.logo,
            enableBackupStream: channelSettings.enableBackupStream,
        });
        refreshChannelsList(currentPage, searchParam);
    };

    const getMenuOptions = (channel: IChannelDetails) => {
        return [
            { title: 'EDIT CHANNEL SETTINGS', callback: onShowEditChannelSettingsModal(channel) },
            { title: 'DELETE CHANNEL', callback: onShowDeleteChannelModal(channel) },
        ];
    };

    const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

    useEffect(() => {
        const setAdminView = async () => {
            const role = await authenticationService.getRole();
            if (role === Role.ADMIN) {
                setIsAdmin(true);
            }
        };

        setAdminView();
    }, []);

    const isLiveTabSelected = tabType === TabTypes.LIVE;

    const searchContainerClassName = joinStrings(['search-container', !!searchParam && 'search-is-active']);

    return (
        <div className="content-page">
            {showDeleteChannelModal && (
                <ChannelDeleteModal
                    channelLogoUrl={selectedChannel.channelLogoUrl}
                    channelTitle={selectedChannel.title}
                    channelId={selectedChannel.channelId}
                    onCancel={onHideDeleteChannelModal}
                    onDelete={onDeleteChannel}
                />
            )}
            {showEditChannelModal && (
                <ChannelSettingsModal
                    canEditSettings={true}
                    canEditTargetAdvertDuration={false}
                    channelTitle={selectedChannel.title}
                    onCancel={onHideEditChannelSettingsModal}
                    onConfirm={onEditChannelSettings}
                    editState={{
                        targetAdvertDuration: selectedChannel.targetAdvertDuration,
                        title: selectedChannel.title,
                        logo: selectedChannel.logo,
                        enableBackupStream: selectedChannel.enableBackupStream,
                    }}
                />
            )}
            <section className="content-page__header">
                <NavLogo className="content-page__header-nav-logo" />
                <div className="content-page__header-inner">
                    <div className="content-page__header-cover" />
                    <h1>Virtual Channel Manager</h1>
                </div>
            </section>
            <ul className="content-page__tab-section">
                <li>
                    <button className={getActiveTabClassName(TabTypes.LIVE)} onClick={handleTabClick(TabTypes.LIVE)}>
                        <span className="red-dot" />
                        Live Now
                    </button>
                </li>
                <li>
                    <button className={getActiveTabClassName(TabTypes.UPCOMING)} onClick={handleTabClick(TabTypes.UPCOMING)}>
                        Upcoming
                    </button>
                </li>
            </ul>
            <div className={searchContainerClassName}>
                <Dropdown
                    disabled={!searchParam}
                    options={sortOptions}
                    selected={selectedSortOption}
                    uniqueId="sortOptionDropdown"
                    onSelected={handleSortChange}
                />
                <SearchFieldComponent onChange={handleSearchChange} searchPlaceholder="Channel search" />
            </div>
            {pageState === ApiFetchStates.LOADING && (
                <section className="content-page__no-content text-center">
                    <LoadingSpinner />
                    Loading Channels
                </section>
            )}
            {pageState === ApiFetchStates.NO_CONTENT && (
                <section className="content-page__no-content text-center">
                    <span>There are no channels yet.</span>
                </section>
            )}
            <div className={contentSectionClassName}>
                <table className="content-page__content-table">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Ad Duration</th>
                            <th>{isLiveTabSelected ? 'Currently Live' : 'Channel Start Time'}</th>
                            <th>{isLiveTabSelected ? 'Time Remaining' : 'Total Duration'}</th>
                            <th>Queued Items</th>
                            {isAdmin && <th />}
                        </tr>
                    </thead>
                    <tbody>
                        {channels.map((channel) => {
                            return (
                                <tr key={channel.channelId} tabIndex={0} role="button" onClick={handleChannelRoute(channel.channelId)}>
                                    <td>
                                        {channel.channelLogoUrl && (
                                            <span className="channel-icon">{<img src={channel.channelLogoUrl} width={47} />}</span>
                                        )}
                                        <span className="channel-title">{channel.title}</span>
                                    </td>
                                    <td>{channel.targetAdvertDuration} seconds</td>
                                    <td>
                                        {channel.isLive
                                            ? channel.currentlyLiveContent?.title
                                            : moment(channel.channelStartTime).format('lll')}
                                    </td>
                                    <td>{convertSecondsToHoursMinutesLabel(channel.timeRemaining)}</td>
                                    <td>{channel.queuedContentPods} items</td>
                                    {isAdmin && (
                                        <td>
                                            <LinkListDropdownMenu
                                                menuItems={getMenuOptions(channel)}
                                                className="channel-details__edit-menu"
                                            />
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <ListPagination count={totalItems} perPage={DEFAULT_PAGE_SIZE} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
        </div>
    );
});

export { ChannelsPage };
