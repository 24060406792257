import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import TimesThinIcon from '~components/Icons/TimesThinIcon';
import LoadingSpinner from '~components/LoadingSpinner';
import Modal from '~components/Modal';
import { clearDeleteChannelRequiresConfirmation, deleteChannel } from '~src/store/channelManager/channelManager.actions';
import { getChannelDeleteRequiresConfirmation, getChannelIsDeleting } from '~src/store/channelManager/channelManager.selectors';

import './index.scss';

interface IProps {
    channelLogoUrl: string;
    channelTitle: string;
    channelId: number;
    onCancel: VoidFunction;
    onDelete: VoidFunction;
}

export const ChannelDeleteModal: React.FunctionComponent<IProps> = (props) => {
    const { channelLogoUrl, channelTitle, channelId, onCancel, onDelete } = props;

    const [channelTitleConfirmation, setChannelTitleConfirmation] = React.useState<string>('');

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // prevent any global shortcuts from being triggered
        e.stopPropagation();
    };

    const handleSetChannelNameConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChannelTitleConfirmation(e.target.value);
    };

    const dispatch = useDispatch();

    const channelDeleteRequiresConfirmation = useSelector(getChannelDeleteRequiresConfirmation);
    const channelIsDeleting = useSelector(getChannelIsDeleting);

    const handleDeleteChannel = () => {
        const forceDelete = channelDeleteRequiresConfirmation;
        dispatch(deleteChannel(channelId, forceDelete, onDelete));
    };

    const handleCancel = () => {
        dispatch(clearDeleteChannelRequiresConfirmation());
        onCancel();
    };

    const $component = (
        <Modal.Main hasOverlay={true} className="channel-manager__modal channel-manager__channel-delete-modal">
            <div className="channel-manager__channel-delete-modal__title">
                <img src={channelLogoUrl} className="channel-manager__channel-delete-modal__logo" />
                {channelTitle}
            </div>
            <button className="channel-manager__channel-delete-modal__close-btn" onClick={handleCancel}>
                <TimesThinIcon />
            </button>
            <Modal.Body className="channel-manager__channel-delete-modal__body">
                {channelIsDeleting && <LoadingSpinner />}
                <div className="channel-manager__channel-delete-modal__confirm-action">
                    <h2>Delete {channelTitle}?</h2>

                    {channelDeleteRequiresConfirmation && (
                        <div>
                            <div>
                                Please type the name of this channel, <span className="highlighted">{channelTitle}</span> to confirm.
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="channel-manager__channel-delete-modal__confirmation"
                                    value={channelTitleConfirmation}
                                    onChange={handleSetChannelNameConfirmation}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <button className="channel-manager__channel-delete-modal__action-btn" type="button" onClick={handleCancel}>
                            Cancel
                        </button>
                        <button
                            className="channel-manager__channel-delete-modal__action-btn channel-manager__channel-delete-modal__force-action-btn"
                            type="button"
                            onClick={handleDeleteChannel}
                            disabled={channelDeleteRequiresConfirmation && channelTitleConfirmation !== channelTitle}
                        >
                            Delete Channel
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal.Main>
    );

    return ReactDOM.createPortal($component, document.getElementById('modal'));
};
