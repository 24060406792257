import * as React from 'react';

import { IContentPod } from '~services/channels/types';

import { TimelineTotalPercentageBar } from '../../PodCard/TimelineBar';
import './index.scss';

export const GroupedCardUI: React.FC<{ contentPod: IContentPod }> = ({ contentPod }) => {
    return (
        <>
            <div className="grouped-pod__title">{contentPod.content.title}</div>
            <div className="grouped-pod__bar">
                <TimelineTotalPercentageBar
                    contentDuration={contentPod.content.duration}
                    podDuration={contentPod.duration}
                    adBreaks={contentPod.adBreaks}
                />
            </div>
        </>
    );
};
