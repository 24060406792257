import { TogglesActions } from '~src/store/timeline/toggle/constants';

const setIsTrackingPlayhead = (isTrackingPlayhead: boolean) => ({
    type: TogglesActions.TRACK_PLAYHEAD,
    payload: {
        isTrackingPlayhead,
    },
});

export { setIsTrackingPlayhead };
