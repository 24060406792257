enum ClipActions {
    // in marker management
    ADD_NEW_CLIP = 'timeline/clips/ADD_NEW_CLIP',
    ADD_CLIP_IN_MARKER_SAGA = 'timeline/clips/ADD_IN_MARKER_SAGA',
    ADD_CLIP_IN_MARKER = 'timeline/clips/ADD_IN_MARKER',

    // out marker management
    ADD_CLIP_OUT_MARKER_SAGA = 'timeline/clips/ADD_CLIP_OUT_MARKER_SAGA',
    ADD_CLIP_OUT_MARKER_BY_ID = 'timeline/clips/ADD_CLIP_OUT_MARKER_BY_ID',

    // title
    SET_TITLE_SAGA = 'timeline/clips/SET_TITLE_SAGA',
    SET_TITLE = 'timeline/clips/SET_TITLE',

    // thumbnail
    SET_THUMBNAIL_SAGA = 'timeline/clips/SET_THUMBNAIL_SAGA',
    SET_THUMBNAIL = 'timeline/clips/SET_THUMBNAIL',
    REMOVE_THUMBNAIL_BY_THUMBNAIL_ID = 'timeline/clip/REMOVE_THUMBNAIL_BY_THUMBNAIL_ID',

    // removing
    REMOVE_CLIP_SAGA = 'timeline/clip/REMOVE_CLIP_SAGA',
    REMOVE_CLIP = 'timeline/clip/REMOVE_CLIP',

    // focus toggles
    SET_FOCUS_CLIP = 'timeline/clip/SET_FOCUS_CLIP',
    SET_EDIT_CLIP = 'timeline/clip/SET_EDIT_CLIP',

    SET_PREVIEW_CLIP_SAGA = 'timeline/clip/SET_PREVIEW_CLIP_SAGA',
    SET_PREVIEW_CLIP = 'timeline/clip/SET_PREVIEW_CLIP',

    SET_ACTIVE_CLIP = 'timeline/clip/SET_ACTIVE_CLIP',
    SET_EXPORT_CLIP = 'timeline/clip/SET_EXPORT_CLIP',
}

export { ClipActions };
