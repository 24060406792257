import * as React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { IStudioState } from '~src/store/types';

const useTimeout = (timeout: number) => {
    const timerRef = React.useRef<number | null>(null);

    React.useEffect(() => {
        return () => {
            if (timerRef.current !== null) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    const setTimer = (callback) => {
        timerRef.current = window.setTimeout(() => {
            callback();
        }, timeout);
    };

    const clearTimer = () => window.clearTimeout(timerRef.current);

    return {
        setTimer,
        clearTimer,
    };
};

function useShallowEqualSelector<T>(selector: (state: IStudioState) => T): T {
    return useSelector<IStudioState, T>(selector, shallowEqual);
}

export { useTimeout, useShallowEqualSelector };
