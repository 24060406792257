import * as React from 'react';

import { joinStrings } from '~services/utilities';
import { useDropdownMenu } from '~src/views/hooks/useDropdownMenu';

import './index.scss';

export enum DropdownAlign {
    LEFT = 'LEFT',
    CENTER = 'CENTER',
    RIGHT = 'RIGHT',
}

interface IProps {
    className?: string;
    dropdownAlign?: DropdownAlign;
    dropdownClassName?: string;
    triggerClassName?: string;
    triggerChildren: React.ReactNode;
    closeOnMouseOut?: boolean;
    destroyOnClose?: boolean;
}

const SimpleDropdownMenu: React.FunctionComponent<IProps> = ({
    className,
    dropdownClassName,
    triggerClassName,
    children,
    triggerChildren,
    dropdownAlign = DropdownAlign.RIGHT,
    closeOnMouseOut = true,
    destroyOnClose = false,
}) => {
    const { menuRef, onMouseLeave, onMouseEnter, onClickHandler, isVisible, setVisibility } = useDropdownMenu<HTMLDivElement>();

    const classNames = joinStrings(['dropdown-menu', isVisible && 'dropdown-menu--open', className]);

    const mouseListeners = closeOnMouseOut ? { onMouseLeave, onMouseEnter } : {};

    const shouldRender = destroyOnClose ? isVisible : true;

    return (
        <div className={classNames} onClick={onClickHandler} {...mouseListeners}>
            <button type="button" className={joinStrings(['dropdown-menu__trigger-button', triggerClassName])}>
                {triggerChildren}
            </button>
            {shouldRender && (
                <div
                    className={joinStrings([
                        'dropdown-menu__dropdown',
                        `dropdown-menu__dropdown--align-${dropdownAlign.toLowerCase()}`,
                        dropdownClassName,
                    ])}
                    ref={menuRef}
                >
                    {typeof children === 'function' ? children(setVisibility) : children}
                </div>
            )}
        </div>
    );
};

export { SimpleDropdownMenu };
