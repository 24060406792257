import * as React from 'react';

import { ConfirmDialog } from '~components/ConfirmDialog';
import { FileTimesLightIcon } from '~components/Icons/FileTimesLightIcon';
import { LoadingSpinner, LoadingSpinnerSize } from '~components/LoadingSpinner';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { ITimelineThumbnail } from '~src/store/timeline/thumbnail/types';

interface IProps {
    removeThumbnail: (id: ITimelineThumbnail['id']) => void;
    setThumbnailFocus: (type: string, id: ITimelineThumbnail['id']) => void;
    seekToThumbnail: (exportThumbnailTime: ITimelineThumbnail['exportThumbnailTime']) => void;

    // thumbnail specific props Partial<ITimelineThumbnail>
    id: ITimelineThumbnail['id'];
    imageData: ITimelineThumbnail['imageData'];
    exportThumbnailTime: ITimelineThumbnail['exportThumbnailTime'];
}

const REMOVE_TITLE = 'Remove Thumbnail?';
const REMOVE_MESSAGE = 'Are you sure you want to remove this thumbnail?';

const ThumbnailItemComponent: React.FunctionComponent<IProps> = (props) => {
    const { id, imageData, exportThumbnailTime, removeThumbnail, setThumbnailFocus, seekToThumbnail } = props;

    const [renderDialog, setRenderDialog] = React.useState<boolean>(false);

    const seekToThumbnailHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        seekToThumbnail(exportThumbnailTime);
    };

    const mouseMoveHandler = ({ type }: React.MouseEvent<HTMLDivElement>) => setThumbnailFocus(type, id);

    const removeThumbnailHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        removeThumbnail(id);
    };

    const handleShowDialog = (renderDialogFlag: boolean) => () => setRenderDialog(renderDialogFlag);

    return (
        <React.Fragment>
            {renderDialog && (
                <ConfirmDialog
                    title={REMOVE_TITLE}
                    message={REMOVE_MESSAGE}
                    cancel={handleShowDialog(false)}
                    confirm={removeThumbnailHandler}
                />
            )}
            <div
                className="thumbnail-panel-item"
                onMouseEnter={mouseMoveHandler}
                onMouseLeave={mouseMoveHandler}
                onClick={seekToThumbnailHandler}
            >
                <div className="thumbnail-panel-item__thumbnail">
                    <img src={imageData} />
                    {!imageData && <LoadingSpinner size={LoadingSpinnerSize.SMALL} />}
                </div>

                {imageData && (
                    <ul className="inline-list thumbnail-panel-item__actions">
                        <li className="list-item">
                            <button onClick={handleShowDialog(true)}>
                                <Tooltip message="Remove thumbnail" position={TooltipPositions.TOP}>
                                    <FileTimesLightIcon />
                                </Tooltip>
                            </button>
                        </li>
                    </ul>
                )}
            </div>
        </React.Fragment>
    );
};

const ThumbnailItem: React.NamedExoticComponent<IProps> = React.memo(ThumbnailItemComponent);

export { ThumbnailItem };
