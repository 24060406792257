import * as throttle from 'lodash.throttle';
import * as React from 'react';

import { joinStrings } from '~services/utilities';

import './index.scss';

type OptionValue = string | number;

interface ICustomSelectEvent {
    event: React.MouseEvent<HTMLButtonElement>;
    value: OptionValue;
}

interface IProps {
    value: string | number;
    options: OptionValue[];
    onChange: (event: ICustomSelectEvent) => void;
    className?: Element['className'];
    showFilter?: boolean;
}

const CustomSelect: React.FunctionComponent<IProps> = ({ value, options, onChange, className, showFilter = false }) => {
    const classNames = joinStrings(['custom-select', className]);

    const [filterQuery, setFilterQuery] = React.useState<string>('');

    const [filteredOptions, setFilteredOptions] = React.useState<OptionValue[]>(options);

    const handleFilterQueryChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setFilterQuery(target.value);
        delayedFilterCallback(target.value);
    };

    const delayedFilterCallback = throttle((filterValue: string) => {
        // Empty filter should return all values
        if (!filterValue) {
            setFilteredOptions(options);
            return;
        }

        setFilteredOptions(
            options.filter((option) => {
                // Case insensitive search
                const normalisedOptionValue = option.toString().toLowerCase();
                const normalisedFilterValue = filterValue.toLowerCase();

                return normalisedOptionValue.indexOf(normalisedFilterValue) !== -1;
            })
        );
    }, 100);

    const onClick = (option: OptionValue) => (event: React.MouseEvent<HTMLButtonElement>) => {
        onChange({ value: option, event });
    };

    return (
        <div className={classNames}>
            <span className="custom-select__active-value">{value}</span>
            <ul className="custom-select__option-list">
                {showFilter && (
                    <li className="custom-select__option-item">
                        <input
                            type="text"
                            className="custom-select__filter"
                            value={filterQuery}
                            onChange={handleFilterQueryChange}
                            placeholder="Type to filter"
                        />
                    </li>
                )}
                {filteredOptions.map((option) => (
                    <li key={option} className="custom-select__option-item">
                        <button type="button" onClick={onClick(option)}>
                            {option}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export { CustomSelect, ICustomSelectEvent };
