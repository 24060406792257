import * as moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IEndLabel } from '~components/ChannelManager/PodScroller/types';
import { CopyPasteIcon } from '~components/Icons/CopyPasteIcon';
import { MultipleMoveIcon } from '~components/Icons/MultipleMoveIcon';
import { TickIcon } from '~components/Icons/TickIcon';
import { LinkListDropdownMenu } from '~components/LinkListDropdownMenu';
import { ILinkMenuItem } from '~components/LinkListDropdownMenu/MenuItem';
import { useControls } from '~containers/ChannelManagerContainer/ChannelManagerContext';
import { IChannelManagerContext } from '~containers/ChannelManagerContainer/types';
import { IAdBreak, IContentPod, IVllContent } from '~services/channels/types';
import { joinStrings, convertSecondsToHoursMinutesLabel } from '~services/utilities';
import { getDurationLabel } from '~services/utilities/time';
import { SECONDS_IN_MINUTE } from '~services/utilities/time/constants';
import { toggleBatchActionPodSelection } from '~src/store/channelManager/channelManager.actions';
import {
    getChannelDetailsState,
    getLastEditedState,
    getLastActionState,
    getBatchActionState,
} from '~src/store/channelManager/channelManager.selectors';
import { BatchActionMode } from '~src/store/channelManager/constants';
import { IEditableRange, ChangeAction, IBatchAction } from '~src/store/channelManager/types';
import { Seconds } from '~src/types';

import { ContentPreview } from './ContentPreview';
import { EditState } from './EditState';
import { PodPosition } from './PodPosition';
import { TimelineTotalPercentageBar } from './TimelineBar';
import './index.scss';

interface IProps extends IContentPod {
    batchActionMode: IBatchAction['mode'];
    batchActionSelectedPodUuids: IBatchAction['selectedPodUuids'];
    shouldAnimate: boolean;
    firstEditablePodNumber: IEditableRange['first'];
    lastEditablePodNumber: IEditableRange['last'];
    showUSDateTimeFormat: boolean;
    onPodMove: IChannelManagerContext['onPodMove'];
    onPodRemove: IChannelManagerContext['onPodRemove'];
    onPodEdit: IChannelManagerContext['onPodEdit'];
    onPodRemoveUndo: IChannelManagerContext['onPodRemoveUndo'];
    onCopyPasteBatchActionConfirmed: IChannelManagerContext['onCopyPasteBatchActionConfirmed'];
    onMultipleMoveBatchActionConfirmed: IChannelManagerContext['onMultipleMoveBatchActionConfirmed'];
    onAddContent: IChannelManagerContext['onAddContent'];
    prev: IContentPod | IEndLabel;
    next: IContentPod | IEndLabel;
}

export const PodCardComponent: React.NamedExoticComponent<IProps> = React.memo((props) => {
    const {
        podNumber,
        duration,
        content,
        firstEditablePodNumber,
        lastEditablePodNumber,
        shouldAnimate,
        isRemoved,
        isEdited,
        hasMoved,
        isNew,
        isLive,
        isFirstEditable,
        isLastEditable,
        isEditable,
        uuid,
        showUSDateTimeFormat,
        batchActionMode,
        batchActionSelectedPodUuids,
        adBreaks,
        onAddContent,
        next,
    } = props;

    const dispatch = useDispatch();

    const handleOnMove = (newPosition: number) => {
        if (!isNaN(newPosition)) {
            props.onPodMove(podNumber, newPosition, ChangeAction.MOVE_BY_POSITION);
        }
    };

    const handleOnRemove = () => {
        props.onPodRemove(uuid);
    };

    const handleOnRemoveUndo = () => {
        props.onPodRemoveUndo(uuid);
    };

    const handleOnEdit = () => {
        props.onPodEdit(uuid);
    };

    const handleOnOpenPreview = () => {
        window.open(props.content.externalUrl, '_blank');
    };

    const handleOnCheckSelection = () => {
        dispatch(toggleBatchActionPodSelection(uuid));
    };

    const handleActionBeforeOrAfter = (position: number) => () => {
        if (batchActionMode === BatchActionMode.COPY_PASTE) {
            props.onCopyPasteBatchActionConfirmed(position);
        } else {
            props.onMultipleMoveBatchActionConfirmed(position);
        }
    };

    const handleAddContent = (position: number) => () => {
        onAddContent(position);
    };

    const hasUpdate = isRemoved || isEdited || hasMoved || isNew;
    const startTime = moment(props.startTime);
    const endTime = startTime.clone().add(duration, 'seconds');
    const durationLabel = !isRemoved && `${getDurationLabel(showUSDateTimeFormat, startTime, duration)}`;
    const durationLeft = isLive ? endTime.diff(moment.utc(), 'seconds') : duration;
    const durationLeftText = durationLeft < SECONDS_IN_MINUTE ? `${durationLeft} seconds` : convertSecondsToHoursMinutesLabel(durationLeft);

    const isInBatchActionMode = !!batchActionMode;
    const isInBatchSelection = batchActionSelectedPodUuids.includes(uuid);
    const batchSelectionIndex = batchActionSelectedPodUuids.indexOf(uuid) + 1;
    const isInCopyPasteMode = batchActionMode === BatchActionMode.COPY_PASTE;
    const isInMultipleDeleteMode = batchActionMode === BatchActionMode.MULTIPLE_DELETE;

    const canSelectForBatchAction = !isRemoved && (isInCopyPasteMode || (isEditable && isInBatchActionMode));
    const showActionMenu = !!batchActionSelectedPodUuids.length && !isInMultipleDeleteMode && isEditable && !isRemoved;
    const isNextLocked = 'isEditable' in next ? next.isEditable : false;
    const showBetweenActionMenu = isNextLocked && !isInMultipleDeleteMode && !isInBatchActionMode && !isInCopyPasteMode && !isRemoved;
    const actionTitle = isInCopyPasteMode ? 'Paste selection here' : 'Move selection here';
    const betweenActionTitle = 'Add content here';
    const actionTitleIcon = isInCopyPasteMode ? <CopyPasteIcon /> : <MultipleMoveIcon />;

    const podItemClassNames = joinStrings([
        'pod-item',
        isInBatchActionMode && 'pod-item--is-clickable',
        isLive && 'pod-item--is-live',
        isLastEditable && 'pod-item--is-last',
        isRemoved && 'pod-item--is-removed',
        (!isEditable || isInBatchActionMode) && 'pod-item--is-locked',
        shouldAnimate && 'pod-item--fade-in',
    ]);

    const podCardClassNames = joinStrings(['pod-card', hasUpdate && 'pod-card--has-update', isInBatchSelection && 'pod-card--is-selected']);

    return (
        <div className={podItemClassNames}>
            <div className="pod-item__schedule__info-wrapper">
                {!isRemoved && <div className="pod-item__schedule__label">{isLive ? 'Live now' : durationLabel}</div>}
                <div className="pod-item__schedule__info">
                    {!isRemoved && (
                        <React.Fragment>
                            <span className="pod-item__schedule__dot-icon" />
                            <div className="pod-item__schedule__next-event-in">
                                {durationLeftText}
                                {isLive && ' remaining'}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
            {showActionMenu && isFirstEditable && (
                <ActionAtPosition
                    actionTitle={actionTitle}
                    actionTitleIcon={actionTitleIcon}
                    onAction={handleActionBeforeOrAfter(podNumber - 1)}
                    className="is-first-pod"
                />
            )}
            {showActionMenu && (
                <ActionAtPosition
                    actionTitle={actionTitle}
                    actionTitleIcon={actionTitleIcon}
                    onAction={handleActionBeforeOrAfter(podNumber)}
                />
            )}

            {showBetweenActionMenu && (
                <ActionAtPosition
                    actionTitle={betweenActionTitle}
                    actionTitleIcon={actionTitleIcon}
                    onAction={handleAddContent(podNumber)}
                    className="pod-item__between-action-wrapper"
                />
            )}

            <div className={podCardClassNames} onClick={canSelectForBatchAction ? handleOnCheckSelection : null}>
                {isRemoved && <div className="pod-card__deleted-overlay" />}
                {!isRemoved && !isInBatchActionMode && (
                    <PodPosition
                        podNumber={podNumber}
                        firstEditablePodNumber={firstEditablePodNumber}
                        lastEditablePodNumber={lastEditablePodNumber}
                        onEdit={handleOnEdit}
                        onMove={handleOnMove}
                        onRemove={handleOnRemove}
                        onOpenPreview={handleOnOpenPreview}
                        isEditable={isEditable}
                    />
                )}
                {(!isEditable || isInBatchActionMode) && <div className="pod-card__pod-position-fixed">{podNumber}</div>}
                {canSelectForBatchAction && (
                    <div className="pod-card__action-selector">
                        <span className="pod-card__action-selector__counter">{isInBatchSelection && batchSelectionIndex}</span>
                        <span className="pod-card__action-selector__checked">
                            <TickIcon width="10" />
                        </span>
                    </div>
                )}
                <ContentPodDetails content={content} duration={duration} adBreaks={adBreaks} />
                <EditState
                    hasUpdate={hasUpdate}
                    hasMoved={hasMoved}
                    isNew={isNew}
                    isRemoved={isRemoved}
                    isEditable={isEditable}
                    onRemoveUndo={handleOnRemoveUndo}
                />
            </div>
        </div>
    );
});

const ContentPodDetails: React.FC<{ content: IVllContent; duration: Seconds; adBreaks: IAdBreak[] }> = (props) => {
    return (
        <>
            <ContentPreview {...props.content} />
            <div className="pod-card__timeline-wrapper">
                <div className="pod-card__pod-info">
                    POD: <span>{convertSecondsToHoursMinutesLabel(props.duration)}</span>
                </div>
                <TimelineTotalPercentageBar
                    contentDuration={props.content.duration}
                    podDuration={props.duration}
                    adBreaks={props.adBreaks}
                />
            </div>
        </>
    );
};

export const PodCard: React.NamedExoticComponent<
    IContentPod & { showUSDateTimeFormat: boolean; prev: IContentPod | IEndLabel; next: IContentPod | IEndLabel }
> = React.memo((props) => {
    const {
        onPodMove,
        onPodRemove,
        onPodRemoveUndo,
        onPodEdit,
        onCopyPasteBatchActionConfirmed,
        onMultipleMoveBatchActionConfirmed,
        onAddContent,
    } = useControls();
    const { editableRange } = useSelector(getChannelDetailsState);
    const lastEdited = useSelector(getLastEditedState);
    const lastAction = useSelector(getLastActionState);
    const { mode, selectedPodUuids } = useSelector(getBatchActionState);

    let shouldAnimate = false;
    if (lastEdited && lastEdited === props.uuid && (lastAction === ChangeAction.ADD_TO_POSITION || lastAction === ChangeAction.UPDATE)) {
        shouldAnimate = true;
    }

    return (
        <PodCardComponent
            {...props}
            firstEditablePodNumber={editableRange.first}
            lastEditablePodNumber={editableRange.last}
            batchActionMode={mode}
            batchActionSelectedPodUuids={selectedPodUuids}
            shouldAnimate={shouldAnimate}
            showUSDateTimeFormat={props.showUSDateTimeFormat}
            onPodMove={onPodMove}
            onPodRemove={onPodRemove}
            onPodRemoveUndo={onPodRemoveUndo}
            onPodEdit={onPodEdit}
            onCopyPasteBatchActionConfirmed={onCopyPasteBatchActionConfirmed}
            onMultipleMoveBatchActionConfirmed={onMultipleMoveBatchActionConfirmed}
            onAddContent={onAddContent}
        />
    );
});

PodCard.displayName = 'PodCard';

const ActionAtPosition: React.FC<{ actionTitle: string; actionTitleIcon: JSX.Element; onAction: VoidFunction; className?: string }> = (
    props
) => {
    const menuOptions = React.useMemo<ILinkMenuItem[]>(
        () => [{ title: props.actionTitle, callback: props.onAction, titleIcon: props.actionTitleIcon }],
        [props.actionTitle, props.actionTitleIcon, props.onAction]
    );
    return (
        <div className={joinStrings(['pod-item__action-wrapper', props.className])}>
            <LinkListDropdownMenu menuItems={menuOptions} className="pod-item__action-menu" />
        </div>
    );
};
