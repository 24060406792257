import * as React from 'react';
import { Link } from 'react-router-dom';

import { PageRoutes } from '~components/Root/constants';
import { EAssetType } from '~containers/EditorPageContainer/types';

interface IEditorHeaderProps {
    title: string;
    assetType: EAssetType;
}

const EditorHeader: React.NamedExoticComponent<IEditorHeaderProps> = React.memo(({ title = '', assetType }: IEditorHeaderProps) => (
    <header className="editor__header">
        <Link to={PageRoutes.EDITOR} className="get-started-btn">
            <i className="get-started-icon" />
        </Link>
        <h1 className="video-title">{title}</h1>
        {assetType === EAssetType.LIVE && <div className="live-tag">{EAssetType.LIVE}</div>}
    </header>
));

export { EditorHeader };
