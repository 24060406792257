import { IStudioState } from '~src/store/types';

import { IChannelManagerState } from './types';

export const getPageLoadedState = (state: IStudioState): IChannelManagerState['hasPageLoaded'] => state.channelManager.hasPageLoaded;

export const getContentPodsState = (state: IStudioState): IChannelManagerState['contentPods'] => state.channelManager.contentPods;

export const getChannelDetailsState = (state: IStudioState): IChannelManagerState['channelDetails'] => state.channelManager.channelDetails;

export const getChannelAdminDetailsState = (state: IStudioState): IChannelManagerState['channelAdminDetails'] =>
    state.channelManager.channelAdminDetails;

export const getMuxerVersionsState = (state: IStudioState): IChannelManagerState['muxerVersions'] => state.channelManager.muxerVersions;

export const getFeatureFlagsState = (state: IStudioState): IChannelManagerState['featureFlags'] => state.channelManager.featureFlags;

export const getPendingPublishPodNumbersState = (state: IStudioState): IChannelManagerState['pendingPublishPodNumbers'] =>
    state.channelManager.pendingPublishPodNumbers;

export const getPodManagementState = (state: IStudioState): IChannelManagerState['podManagement'] => state.channelManager.podManagement;

export const getLastEditedState = (state: IStudioState): IChannelManagerState['lastEdited'] => state.channelManager.lastEdited;

export const getLastActionState = (state: IStudioState): IChannelManagerState['lastAction'] => state.channelManager.lastAction;

export const getPublishingState = (state: IStudioState): IChannelManagerState['isPublishing'] => state.channelManager.isPublishing;

export const getShowConfirmationOverwriteModalState = (state: IStudioState): IChannelManagerState['showConfirmOverwriteModal'] =>
    state.channelManager.showConfirmOverwriteModal;

export const getBatchActionState = (state: IStudioState): IChannelManagerState['batchAction'] => state.channelManager.batchAction;

export const getChannelDeleteRequiresConfirmation = (state: IStudioState): IChannelManagerState['channelDeleteRequiresConfirmation'] =>
    state.channelManager.channelDeleteRequiresConfirmation;

export const getChannelIsDeleting = (state: IStudioState): IChannelManagerState['channelIsDeleting'] =>
    state.channelManager.channelIsDeleting;

export const getPreviewSchedulePaneVisibility = (state: IStudioState): IChannelManagerState['previewSchedulePaneVisibility'] =>
    state.channelManager.previewSchedulePaneVisibility;

export const getPreviewSchedulePreviousSettings = (state: IStudioState): IChannelManagerState['previewSchedulePanePreviousSettings'] =>
    state.channelManager.previewSchedulePanePreviousSettings;

export const getContentPodScrollState = (state: IStudioState): IChannelManagerState['contentPodScrollState'] =>
    state.channelManager.contentPodScrollState;

export const getChannelHasActiveModal = (state: IStudioState): IChannelManagerState['hasActiveModal'] =>
    state.channelManager.hasActiveModal;
