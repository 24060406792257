import { ICalendarPreviewSettings } from '~components/ChannelManager/PreviewSchedule/types';
import { IMuxerConfiguration } from '~containers/ChannelManagerContainer/types';
import {
    IChannelAdminStreamingConfig,
    IChannelAdminStreamingConfigMuxer,
    IMuxerVersions,
    IVllContent,
    IVllContentBase,
    MuxerAction,
    IContentPod,
} from '~services/channels/types';
import { EServiceStatus } from '~services/http/enums';

import { createAction } from '../actions';
import { BatchActionMode, ChannelManagerActions } from './constants';
import {
    IChannelManagerState,
    IPodSelectedPayload,
    IContentSelectedPayload,
    UUID,
    IChannelSettings,
    ChangeAction,
    IMuxerAction,
} from './types';

// Saga actions

export const setFeatureFlags = () => createAction(ChannelManagerActions.FEATURE_FLAG_SAGA);

export const pageLoad = (channelId: number) => createAction(ChannelManagerActions.INIT_PAGE_SAGA, { channelId });

export const podMoved = (from: number, to: number, changeAction: ChangeAction) =>
    createAction(ChannelManagerActions.POD_MOVED_SAGA, { from, to, changeAction });

export const podRemoved = (uuid: UUID) => createAction(ChannelManagerActions.POD_REMOVED_SAGA, { uuid });

export const podRemovedUndo = (uuid: UUID) => createAction(ChannelManagerActions.POD_REMOVED_UNDO_SAGA, { uuid });

export const podUpdated = (podSelectedPayload: IPodSelectedPayload) =>
    createAction(ChannelManagerActions.POD_UPDATED_SAGA, podSelectedPayload);

export const multiplePodAdded = () => createAction(ChannelManagerActions.MULTIPLE_POD_ADDED_SAGA);

export const contentSelectedToAddToList = (content: IVllContentBase) =>
    createAction(ChannelManagerActions.CONTENT_SELECTED_TO_ADD_TO_LIST_SAGA, { content });

export const contentSelectedToPreview = (contentSelectedPayload: IContentSelectedPayload) =>
    createAction(ChannelManagerActions.CONTENT_SELECTED_TO_PREVIEW_SAGA, contentSelectedPayload);

export const editPod = (uuid: UUID) => createAction(ChannelManagerActions.EDIT_POD_SAGA, { uuid });

export const publishContent = () => createAction(ChannelManagerActions.PUBLISH_CONTENT_SAGA);

export const synchroniseChannelStreamingConfigs = () => createAction(ChannelManagerActions.SYNCHRONISE_CHANNEL_STREAMING_CONFIGS_SAGA);

export const provisionMuxer = (muxerConfiguration: IMuxerConfiguration) =>
    createAction(ChannelManagerActions.PROVISION_MUXER, { muxerConfiguration });

export const channelAdminDetailsUpdated = (channelAdminDetails: IChannelManagerState['channelAdminDetails']) =>
    createAction(ChannelManagerActions.CHANNEL_ADMIN_DETAILS_UPDATED, { channelAdminDetails });

export const muxerUpdated = (muxer: IChannelAdminStreamingConfigMuxer) => createAction(ChannelManagerActions.MUXER_UPDATED, { muxer });

export const muxerAction = (
    muxerId: IChannelAdminStreamingConfigMuxer['muxerId'],
    streamingConfigId: IChannelAdminStreamingConfig['streamingConfigId'],
    action: MuxerAction,
    forceAction: boolean = false
) => createAction(ChannelManagerActions.MUXER_ACTION, { muxerId, streamingConfigId, action, forceAction });

export const setChannelDgeEventId = (dgeEventId: string) => createAction(ChannelManagerActions.SET_CHANNEL_DGE_EVENT_ID, { dgeEventId });

export const checkMuxerStatus = (muxerId: IChannelAdminStreamingConfigMuxer['muxerId']) =>
    createAction(ChannelManagerActions.CHECK_MUXER_STATUS_ACTION, { muxerId });

export const refreshMuxers = (hideSuccessNotification: boolean = false) =>
    createAction(ChannelManagerActions.REFRESH_CHANNEL_ADMIN_DETAILS, { hideSuccessNotification });

export const cancelMuxerAction = () => createAction(ChannelManagerActions.CANCEL_MUXER_ACTION);

export const channelSettingsChanged = (settings: IChannelSettings) =>
    createAction(ChannelManagerActions.CHANNEL_SETTINGS_CHANGED_SAGA, { settings });

export const batchActionConfirmed = (positionToAdd?: number) =>
    createAction(ChannelManagerActions.BATCH_ACTION_CONFIRMED_SAGA, { positionToAdd });

export const deleteChannel = (channelId: number, forceDelete: boolean, callback: VoidFunction) =>
    createAction(ChannelManagerActions.DELETE_CHANNEL, { channelId, forceDelete, callback });

export const clearDeleteChannelRequiresConfirmation = () => createAction(ChannelManagerActions.CLEAR_CHANNEL_DELETE_REQUIRES_CONFIRMATION);

// Reduce actions

export const pageLoaded = (
    contentPods: IChannelManagerState['contentPods'],
    channelDetails: IChannelManagerState['channelDetails'],
    muxerVersions: IMuxerVersions
) => createAction(ChannelManagerActions.PAGE_LOADED, { contentPods, channelDetails, muxerVersions });

export const featureFlagsLoaded = (featureFlags: IChannelManagerState['featureFlags']) =>
    createAction(ChannelManagerActions.FEATURE_FLAGS_LOADED, { featureFlags });

export const podsUpdated = (
    contentPods: IChannelManagerState['contentPods'],
    lastChangeDetails: { lastEdited: IChannelManagerState['lastEdited']; lastAction: IChannelManagerState['lastAction'] },
    pendingPublishPodNumbers: IChannelManagerState['pendingPublishPodNumbers']
) =>
    createAction(ChannelManagerActions.CONTENT_PODS_UPDATED, {
        contentPods,
        lastChangeDetails,
        pendingPublishPodNumbers,
    });

export const totalContentSizeUpdated = (
    timeRemaining: IChannelManagerState['channelDetails']['timeRemaining'],
    queuedContentPods: IChannelManagerState['channelDetails']['queuedContentPods']
) => createAction(ChannelManagerActions.TOTAL_CONTENT_SIZE_CHANGED, { timeRemaining, queuedContentPods });

export const contentRangeDetailsUpdated = (
    editableRange: IChannelManagerState['channelDetails']['editableRange'],
    scrollableDateTimeRange: IChannelManagerState['channelDetails']['scrollableDateTimeRange']
) => createAction(ChannelManagerActions.CONTENT_RANGE_DETAILS_CHANGED, { editableRange, scrollableDateTimeRange });

export const openPodManagementModal = (currentStep: IChannelManagerState['podManagement']['currentStep'], insertPosition?: number) =>
    createAction(ChannelManagerActions.POD_MANAGEMENT_MODAL_OPENED, { currentStep, insertPosition });

export const changePodManagementModalView = (
    currentStep: IChannelManagerState['podManagement']['currentStep'],
    prevStep: IChannelManagerState['podManagement']['prevStep']
) => createAction(ChannelManagerActions.POD_MANAGEMENT_MODAL_VIEW_CHANGED, { currentStep, prevStep });

export const selectedContentListUpdated = (displayContent: IVllContentBase) =>
    createAction(ChannelManagerActions.SELECTED_CONTENT_LIST_UPDATED, { displayContent });

export const removeSelectedContentListItem = (position: number) =>
    createAction(ChannelManagerActions.REMOVE_SELECTED_CONTENT_LIST_ITEM, { position });

export const sortSelectedContentListItem = (from: number, to: number) =>
    createAction(ChannelManagerActions.SORT_SELECTED_CONTENT_LIST_ITEM, { from, to });

export const contentByIdMapUpdated = (contentId: number, status: EServiceStatus, content?: IVllContent) =>
    createAction(ChannelManagerActions.CONTENT_BY_ID_MAP_UPDATED, { contentId, content, status });

export const closePodManagementModal = () => createAction(ChannelManagerActions.POD_MANAGEMENT_MODAL_CLOSED);

export const resetLastEditedChanges = () => createAction(ChannelManagerActions.RESET_LAST_EDITED_CHANGES);

export const resetPage = () => createAction(ChannelManagerActions.RESET_PAGE);

export const startedProvisioningMuxer = () => createAction(ChannelManagerActions.STARTED_PROVISIONING_MUXER);

export const successfullyProvisionedMuxer = () => createAction(ChannelManagerActions.SUCCESSFULLY_PROVISIONED_MUXER);

export const errorProvisioningMuxer = () => createAction(ChannelManagerActions.ERROR_PROVISIONING_MUXER);

export const startedRefreshingMuxers = () => createAction(ChannelManagerActions.STARTED_REFRESHING_CHANNEL_ADMIN_DETAILS);

export const successfullyRefreshedMuxers = () => createAction(ChannelManagerActions.SUCCESSFULLY_REFRESHED_CHANNEL_ADMIN_DETAILS);

export const errorRefreshingMuxers = () => createAction(ChannelManagerActions.ERROR_REFRESHING_CHANNEL_ADMIN_DETAILS);

export const successfulMuxerAction = () => createAction(ChannelManagerActions.SUCCESSFUL_MUXER_ACTION);

export const muxerActionRequiresConfirmation = (action: IMuxerAction) =>
    createAction(ChannelManagerActions.MUXER_ACTION_REQUIRES_CONFIRMATION, action);

export const channelDeleteRequiresConfirmation = () => createAction(ChannelManagerActions.CHANNEL_DELETE_REQUIRES_CONFIRMATION);

export const startedDeletingChannel = () => createAction(ChannelManagerActions.STARTED_DELETING_CHANNEL);

export const startedSynchronisingChannelStreamingConfigs = () =>
    createAction(ChannelManagerActions.STARTED_SYNCHRONISING_CHANNEL_STREAMING_CONFIGS);

export const successfullySynchronisedChannelStreamingConfigs = () =>
    createAction(ChannelManagerActions.SUCCESSFULLY_SYNCHRONISED_CHANNEL_STREAMING_CONFIGS);

export const errorSynchronisingChannelStreamingConfigs = () =>
    createAction(ChannelManagerActions.ERROR_SYNCHRONISING_CHANNEL_STREAMING_CONFIGS);

export const startedPublishingChanges = () => createAction(ChannelManagerActions.STARTED_PUBLISHING_CHANGES);

export const successfullyPublishedChanges = () => createAction(ChannelManagerActions.SUCCESSFULLY_PUBLISHED_CHANGES);

export const errorPublishingChanges = () => createAction(ChannelManagerActions.ERROR_PUBLISHING_CHANGES);

export const confirmationNeededToOverwrite = () => createAction(ChannelManagerActions.CONFIRMATION_NEEDED_TO_OVERWRITE);

export const overwriteConfirmed = () => createAction(ChannelManagerActions.OVERWRITE_CONFIRMED);

export const overwriteCancelled = () => createAction(ChannelManagerActions.OVERWRITE_CANCELLED);

export const toggleBatchActionMode = (mode: BatchActionMode) => createAction(ChannelManagerActions.TOGGLE_BATCH_ACTION_MODE, { mode });

export const deactivateBatchActionMode = () => createAction(ChannelManagerActions.DEACTIVATE_BATCH_ACTION_MODE);

export const toggleBatchActionPodSelection = (uuid: UUID) =>
    createAction(ChannelManagerActions.TOGGLE_BATCH_ACTION_POD_SELECTION, { uuid });

export const sneekPreviewSchedule = () => createAction(ChannelManagerActions.SNEEK_PREVIEW_SCHEDULE);
export const fullScreenPreviewSchedule = () => createAction(ChannelManagerActions.FULL_SCREEN_PREVIEW_SCHEDULE);
export const closePreviewSchedule = () => createAction(ChannelManagerActions.CLOSE_PREVIEW_SCHEDULE);
export const setPreviewSchedulePreviousSettings = (settings: ICalendarPreviewSettings) =>
    createAction(ChannelManagerActions.SET_PREVIEW_SETTINGS, { settings });

export const scrollToContentPod = (contentPod: IContentPod) => createAction(ChannelManagerActions.SCROLL_TO_CONTENT_POD, contentPod);

export const openedActiveModal = () => createAction(ChannelManagerActions.CHANNEL_OPENED_ACTIVE_MODAL);

export const closedActiveModal = () => createAction(ChannelManagerActions.CHANNEL_CLOSED_ACTIVE_MODAL);

export type ReduceActions = ReturnType<
    | typeof pageLoaded
    | typeof resetPage
    | typeof podsUpdated
    | typeof totalContentSizeUpdated
    | typeof contentRangeDetailsUpdated
    | typeof openPodManagementModal
    | typeof closePodManagementModal
    | typeof selectedContentListUpdated
    | typeof removeSelectedContentListItem
    | typeof sortSelectedContentListItem
    | typeof contentByIdMapUpdated
    | typeof changePodManagementModalView
    | typeof resetLastEditedChanges
    | typeof startedPublishingChanges
    | typeof successfullyPublishedChanges
    | typeof errorPublishingChanges
    | typeof startedSynchronisingChannelStreamingConfigs
    | typeof successfullySynchronisedChannelStreamingConfigs
    | typeof errorSynchronisingChannelStreamingConfigs
    | typeof provisionMuxer
    | typeof channelAdminDetailsUpdated
    | typeof muxerUpdated
    | typeof setChannelDgeEventId
    | typeof successfulMuxerAction
    | typeof successfullyRefreshedMuxers
    | typeof muxerActionRequiresConfirmation
    | typeof cancelMuxerAction
    | typeof startedProvisioningMuxer
    | typeof startedRefreshingMuxers
    | typeof errorRefreshingMuxers
    | typeof successfullyProvisionedMuxer
    | typeof errorProvisioningMuxer
    | typeof confirmationNeededToOverwrite
    | typeof overwriteConfirmed
    | typeof overwriteCancelled
    | typeof toggleBatchActionMode
    | typeof deactivateBatchActionMode
    | typeof toggleBatchActionPodSelection
    | typeof sneekPreviewSchedule
    | typeof fullScreenPreviewSchedule
    | typeof closePreviewSchedule
    | typeof scrollToContentPod
    | typeof setPreviewSchedulePreviousSettings
    | typeof deleteChannel
    | typeof channelDeleteRequiresConfirmation
    | typeof clearDeleteChannelRequiresConfirmation
    | typeof featureFlagsLoaded
    | typeof openedActiveModal
    | typeof closedActiveModal
>;
