import { put, takeEvery, select } from 'redux-saga/effects';

import { ExportActions } from '~src/store/editor/export/constants';
import { setExportClip, setPreviewClip } from '~src/store/timeline/clip/clip.actions';
import { ITimelineClip } from '~src/store/timeline/clip/types';
import { IStudioState } from '~src/store/types';

const getExportClipId = (state: IStudioState): ITimelineClip['id'] => state.timeline.clips.exportClipId;

function* handleExportReset() {
    const setPreviewClipAction = setPreviewClip(null);
    yield put(setPreviewClipAction);

    const exportClipId: ITimelineClip['id'] = yield select(getExportClipId);

    if (exportClipId) {
        const setExportClipAction = setExportClip(null);
        yield put(setExportClipAction);
    }

    yield put({ type: ExportActions.RESET });
}

function* handleExportSingleClip(action) {
    const { id } = action.payload;

    const setExportClipAction = setExportClip(id);
    yield put(setExportClipAction);
}

export const exportSagas = [
    takeEvery(ExportActions.RESET_SAGA, handleExportReset),
    takeEvery(ExportActions.EXPORT_SINGLE_CLIP_SAGA, handleExportSingleClip),
];
