import * as React from 'react';

import { AVAILABLE_TIME_ZONES } from '~components/ChannelManager/PreviewSchedule/constants';
import { ResetIcon } from '~components/Icons/ResetIcon';

import './index.scss';

interface IProps {
    timezoneSetting: string;
    className?: string;
    setTimezone: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onReset: VoidFunction;
}

export const TimezoneFilter = <T extends string>(props: IProps) => {
    const { timezoneSetting, setTimezone, className, onReset } = props;

    return (
        <div className={className} title="Time zone">
            <label className="time-zone">
                <select value={timezoneSetting} onChange={setTimezone} className="time-zone__filter">
                    {AVAILABLE_TIME_ZONES.map((tz) => (
                        <option key={tz.value} value={tz.value}>
                            {tz.label}
                        </option>
                    ))}
                </select>
                <div className="time-zone__icon" onClick={onReset} title="Reset to GMT">
                    <ResetIcon height={24} width={30} />
                </div>
            </label>
        </div>
    );
};
