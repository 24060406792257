import { EAssetType } from '~containers/EditorPageContainer/types';
import { IFeedback } from '~pages/Browse/components/types';

const CARD_SIZE_SETTINGS = {
    [EAssetType.VOD]: {
        height: 128,
        width: 190,
        layout: 'vertical' as 'vertical',
    },
    [EAssetType.CLIP]: {
        height: 128,
        width: 190,
        layout: 'vertical' as 'vertical',
    },
    [EAssetType.LIVE]: {
        height: 100,
        width: 140,
        layout: 'horizontal-live' as 'horizontal-live',
    },
};

const HITS_PER_PAGE = 12;

const BROWSE_LIVE_PAGER_FEEDBACK: IFeedback = {
    noResults: {
        title: 'No live events currently streaming to this account',
    },
    serverError: {
        title: 'Unable to view Live events',
        message: 'Due to access permissions you are unable to view this content, please contact your administrator to gain access',
    },
};
const BROWSE_CLIP_PAGER_FEEDBACK: IFeedback = {
    noResults: {
        title: 'No recently created clips exported from this account',
    },
    serverError: {
        title: 'Unable to view Recently Created clips',
        message: 'Due to access permissions you are unable to view this content, please contact your administrator to gain access',
    },
};
const BROWSE_VOD_PAGER_FEEDBACK: IFeedback = {
    noResults: {
        title: 'No videos are associated with this account',
    },
    serverError: {
        title: 'Unable to view Videos',
        message: 'Due to access permissions you are unable to view this content, please contact your administrator to gain access',
    },
};

const SEARCH_CLIP_PAGER_FEEDBACK: IFeedback = {
    noResults: {
        title: 'Your search "{{searchTerm}}" did not match any recently created clips',
    },
    serverError: {
        title: 'Unable to view Recently Created clips',
        message: 'Due to access permissions you are unable to view this content, please contact your administrator to gain access',
    },
};
const SEARCH_VOD_PAGER_FEEDBACK: IFeedback = {
    noResults: {
        title: 'Your search "{{searchTerm}}" did not match any videos',
    },
    serverError: {
        title: 'Unable to view Videos',
        message: 'Due to access permissions you are unable to view this content, please contact your administrator to gain access',
    },
};

export {
    HITS_PER_PAGE,
    CARD_SIZE_SETTINGS,
    BROWSE_LIVE_PAGER_FEEDBACK,
    BROWSE_CLIP_PAGER_FEEDBACK,
    BROWSE_VOD_PAGER_FEEDBACK,
    SEARCH_CLIP_PAGER_FEEDBACK,
    SEARCH_VOD_PAGER_FEEDBACK,
};
