import * as moment from 'moment-timezone';

import { Seconds, TimeFormats } from '~src/types';

export const getTimezoneConformedMoment = (momentInstance: moment.Moment, timezone?: string) =>
    timezone ? momentInstance.tz(timezone) : momentInstance;

export const getLocalisedFormattedTime = (
    value: moment.MomentInput,
    showUSDateTimeFormat: boolean,
    currentTimeFormat = '',
    timezone?: string
) => {
    const dateInstance = getTimezoneConformedMoment(moment(value, currentTimeFormat), timezone);
    return dateInstance.format(showUSDateTimeFormat ? TimeFormats.hmmA : TimeFormats.HHmm);
};

export const getDurationLabel = (showUSDateTimeFormat: boolean, startTime: moment.MomentInput, duration: Seconds, timezone?: string) => {
    const endTime = moment(startTime).add(duration, 'seconds');

    const startTimeLabel = getLocalisedFormattedTime(startTime, showUSDateTimeFormat, '', timezone);
    const endTimeLabel = getLocalisedFormattedTime(endTime, showUSDateTimeFormat, '', timezone);
    return `${startTimeLabel} - ${endTimeLabel}`;
};
