import * as React from 'react';

import VideoSolidIcon from '~components/Icons/VideoSolidIcon';
import { Tooltip, TooltipPositions } from '~components/Tooltip';
import { EditorContext } from '~containers/EditorPageContainer/EditorContext';
import { VideoContext } from '~containers/EditorPageContainer/VideoContext';
import { EAssetType, EditorModes } from '~containers/EditorPageContainer/types';
import { joinStrings } from '~services/utilities';

interface IProps {
    isPaused: boolean;
    atLiveEdge: boolean;
    editorMode: EditorModes;
    goLive: VoidFunction;
}

const LiveButtonComponent: React.FunctionComponent<IProps> = ({ isPaused, atLiveEdge, editorMode, goLive }: IProps) => {
    const classNames = joinStrings(['timeline-controls__btn btn-live', atLiveEdge && !isPaused && 'btn-live--is-live']);

    const onClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.currentTarget.blur(); // dismiss focus on button interaction
        if (!atLiveEdge) {
            goLive();
        }
    };

    return (
        <Tooltip message={!atLiveEdge && 'Go live'} position={TooltipPositions.TOP}>
            <button className={classNames} onClick={onClick} disabled={editorMode !== EditorModes.EDIT || atLiveEdge}>
                <VideoSolidIcon /> {EAssetType.LIVE}
            </button>
        </Tooltip>
    );
};

const LiveButtonMemo: React.NamedExoticComponent<IProps> = React.memo(LiveButtonComponent);

const LiveButton: React.FunctionComponent = () => {
    const {
        goLive,
        editorPlayer: { atLiveEdge, paused },
    } = React.useContext(VideoContext);
    const { editorMode } = React.useContext(EditorContext);

    return <LiveButtonMemo isPaused={paused} atLiveEdge={atLiveEdge} editorMode={editorMode} goLive={goLive} />;
};

export { LiveButton };
