import * as React from 'react';

import { joinStrings } from '~services/utilities';

import './index.scss';

enum ButtonSize {
    SMALL = 'button--small',
    LARGE = 'button--large',
}

enum ButtonTheme {
    BLUE = 'button--blue',
    BLACK = 'button--black',
    GREY = 'button--grey',
}

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    theme?: ButtonTheme;
    size?: ButtonSize;
}

const Button: React.FunctionComponent<IProps> = (props) => {
    const { theme = ButtonTheme.BLUE, size = ButtonSize.LARGE, className, ...rest } = props;

    const classNames = joinStrings(['button', theme, size, className]);

    return (
        <button className={classNames} {...rest}>
            {props.children}
        </button>
    );
};

export { Button, ButtonSize, ButtonTheme };

export default Button;
