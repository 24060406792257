import { ExportStep } from '~containers/EditorPageContainer/types';
import { ExportActions } from '~src/store/editor/export/constants';
import { IEditorExportState } from '~src/store/editor/export/types';

import { EditorActions } from '../constants';

const DEFAULT_STATE: IEditorExportState = {
    step: null,
    saveAction: null,
    errorCount: null,
    totalClips: null,
};

const exportReducer = (state: IEditorExportState = DEFAULT_STATE, { type, payload }): IEditorExportState => {
    switch (type) {
        case ExportActions.SET_STEP:
            return {
                ...state,
                step: payload.step,
                saveAction: payload.saveAction || state.saveAction,
            };
        case ExportActions.RESET_STEP:
            return {
                ...state,
                step: ExportStep.SAVE_SELECTION,
                saveAction: null,
            };
        case ExportActions.SET_ERROR_COUNT:
            return {
                ...state,
                errorCount: payload.errorCount,
                totalClips: payload.totalClips,
            };
        case ExportActions.SET_SAVE_ACTION:
            return {
                ...state,
                saveAction: payload.saveAction,
            };
        case EditorActions.RESET:
        case ExportActions.RESET:
            return DEFAULT_STATE;
        default:
            return state;
    }
};

export { exportReducer };
