import * as React from 'react';

import './index.scss';

interface IProps {
    title?: string;
    subTitle?: string;
    icon?: JSX.Element;
    callToActionList?: JSX.Element[];
}

const PageTitleComponent: React.FunctionComponent<IProps> = ({ title, subTitle, icon = null, callToActionList = null }) => {
    const buildLinkList = () => {
        return (
            <ul className="page__cta-list">
                {callToActionList.map((cta, index) => (
                    <li key={index}>{cta}</li>
                ))}
            </ul>
        );
    };

    return (
        <div className="page__title">
            {icon}
            <div>
                <h1 data-test-id="pageTitle-mainTitle">{title}</h1>
                <p data-test-id="pageTitle-subTitle">{subTitle}</p>
                {callToActionList && buildLinkList()}
            </div>
        </div>
    );
};

const PageTitle: React.NamedExoticComponent<IProps> = React.memo(PageTitleComponent);

export { PageTitle };
