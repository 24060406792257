import * as React from 'react';

export const useMoveElementIntoView = <T extends HTMLElement>(isVisible: boolean, element: T) => {
    React.useLayoutEffect(() => {
        if (element && isVisible) {
            const boundingClientRect = element.getBoundingClientRect();

            if (boundingClientRect.left < 0) {
                element.style.left = '0';
                element.style.right = 'auto';
            } else if (boundingClientRect.right > window.innerWidth) {
                element.style.left = 'auto';
                element.style.right = '0';
            }
        }
    }, [isVisible, element]);
};
